import { Injectable } from '@angular/core';
import { FirstQueueComponent } from '@app/onboarding/components/first-queue/first-queue.component';
import { TipService } from '@app/onboarding/services/tip.service';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxHttpClient } from '../ngx-http-client';
import { EncodeToEntitiesPipe } from '../pipes/encode-to-entities.pipe';
import { catchAndSurfaceError } from '../utils/dg-error-helpers';
import { ModalService } from './modal.service';
@Injectable({
  providedIn: 'root',
})
export class QueueService {
  public i18n = this.translateService.instant(['QueueSvc_InternalError']);
  public notificationCountModified$: Subject<void>;

  constructor(
    private http: NgxHttpClient,
    private translateService: TranslateService,
    private tipService: TipService,
    private authService: AuthService,
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private trackerService: TrackerService,
    private translate: TranslateService,
    private windowLayoutService: WindowLayoutService
  ) {
    this.notificationCountModified$ = new Subject<void>();
  }

  public get(count?: number, skip?: number): Observable<any> {
    return this.http
      .get('/queue/getqueueitems', {
        params: {
          skip,
          count,
        },
      })
      .pipe(catchAndSurfaceError(this.i18n.QueueSvc_InternalError));
  }

  public post(
    referenceType: string,
    referenceId: number,
    dateDue?: string,
    item?: any,
    trackingArea?: string,
    element?: HTMLElement
  ): Observable<any> {
    return this.http
      .post('/queue/addqueueitem', {
        referenceType,
        referenceId,
        dateDue,
      })
      .pipe(
        tap((response) => {
          if (this.tipService.onboardHistory.indexOf('firstqueue') === -1) {
            this.tipService.setOnboardHistory('firstqueue');
            const authUser = this.authService.authUser;

            const options = {
              windowClass: 'xlg-modal',
              inputs: {
                vanityUrl: authUser.viewerProfile.vanityUrl,
                isIframe: this.windowLayoutService.isIframe,
              },
            };
            // TODO: Add focusable element to pass to focusStack service
            this.modalService.show(FirstQueueComponent, options).subscribe();
          }
          this.notify();
          this.trackerService.trackEventData({
            action: referenceType + ' Saved For Later',
            category: referenceType,
            element,
            label: '',
            properties: {
              referenceType,
              referenceId,
              isEndorsed: item?.isEndorsed,
              dateDue,
              location: trackingArea,
            },
          });
          return response;
        }),
        catchAndSurfaceError(this.i18n.QueueSvc_InternalError)
      );
  }

  public put(
    queueItemId: string,
    referenceType: string,
    referenceId: number,
    dateDue?: string,
    element?: HTMLElement
  ): Observable<any> {
    return this.http
      .put('/queue/updatequeueitem', {
        queueItemId,
        dateDue,
      })
      .pipe(
        tap((response) => {
          this.notify();
          this.trackerService.trackEventData({
            action: 'Queue Item Updated',
            category: referenceType,
            element,
            properties: {
              referenceType,
              referenceId,
              dateDue,
            },
          });
          return response;
        }),
        catchAndSurfaceError(this.i18n.QueueSvc_InternalError)
      );
  }

  public complete(
    referenceType: string,
    referenceId: number,
    dateDue?: string,
    element?: HTMLElement
  ): Observable<any> {
    return this.http
      .put('/queue/completequeueitem', {
        referenceType,
        referenceId,
      })
      .pipe(
        tap((response) => {
          this.notify();
          this.trackerService.trackEventData({
            action: 'Queue Item Completed',
            category: referenceType,
            element,
            properties: {
              referenceType,
              referenceId,
              dateDue,
            },
          });
          return response;
        }),
        catchAndSurfaceError(this.i18n.QueueSvc_InternalError)
      );
  }

  public deleteItem(
    queueItemId: string | number,
    referenceType: string,
    referenceId: number,
    dateDue?: string,
    item?: {
      isModal?: any;
    },
    element?: HTMLElement
  ): Observable<any> {
    return this.http
      .post('/queue/deletequeueitem', {
        queueItemId,
      })
      .pipe(
        tap((response) => {
          this.notify();
          this.trackerService.trackEventData({
            action: 'Queue Item Removed',
            category: referenceType,
            element,
            properties: {
              referenceType,
              referenceId,
              dateDue,
            },
          });
          return response;
        }),
        catchAndSurfaceError(this.i18n.QueueSvc_InternalError)
      );
  }

  /**
   * @deprecated Where possible, use {@link QueueService.post}
   * and {@link QueueService.deleteItem} directly instead!
   * Be sure to also update your resource
   *
   * @example
   * ```
   * // before the method...
   * const wasQueued = !!resource.isQueued;
   * // after...
   *  .subscribe((result) => {
   *    // either result will contain our new queueItemId,
   *    // or it won't; for deleted queue items, setting this
   *    // to null/undefined is exactly what we want.
   *    resource.queueItemId = result?.data;
   *    resource.isQueued = !wasQueued;
   *  });
   * ```
   */
  public handleQueue(
    resource: {
      dateDue?: string;
      isModal?: any;
      isQueued?: boolean;
      queueItemId: string | number;
      referenceType: string;
      referenceId: number;
      title?: string;
    },
    element?: HTMLElement
  ) {
    // remove already-queued items from the queue
    if (resource.isQueued) {
      return this.deleteItem(
        resource.queueItemId,
        resource.referenceType,
        resource.referenceId,
        null,
        resource,
        element
      );
    }
    // otherwise, add item to queue
    return this.post(
      resource.referenceType,
      resource.referenceId,
      null,
      resource,
      null,
      element
    ).pipe(
      tap(() => {
        this.notifierService.showSuccess(
          this.translate.instant('QueueSvc_ItemAddedFormat', {
            item: this.encodeToEntitiesPipe.transform(resource.title),
          })
        );
      })
    );
  }

  public updateQueueActivity(): Observable<any> {
    return this.http.post('/queue/updatequeueactivity', null);
  }
  private notify = () => {
    this.notificationCountModified$.next();
  };
}
