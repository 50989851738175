<dgx-pathway-author-note-edit
  *ngIf="step.note"
  [step]="step"
  [pathwayId]="pathwayId"
  [pathwayVersion]="pathwayVersion"
></dgx-pathway-author-note-edit>
<dgx-learning-resource-card
  [resource]="step.reference | inputToLearningResource: 'Pathway'"
  [referenceId]="step.referenceId"
  [durationDisplay]="step.reference.durationDisplay"
  [durationHours]="step.reference.durationHours"
  [durationMinutes]="step.reference.durationMinutes"
  [displayTitle]="
    (step.title ? step.title : step.reference.title) | htmlToPlaintext
  "
  [displaySummary]="
    (step.description ? step.description : step.reference.summary)
      | htmlToPlaintext
  "
  [displayImageUrl]="step.imageUrl"
  [hideCompleteButton]="true"
  [hideIsViewed]="true"
  [menuConfig]="menuConfig"
  cardStyle="stack"
>
  <div *ngIf="!isOnlyChild" class="additionalActions ib guts-p-r-1">
    <button
      type="button"
      [attr.aria-label]="'Pathways_ReorderItems' | translate"
      (click)="onReorderStepsClick()"
      data-dgat="pathway-author-subsection-e8f"
    >
      <df-icon icon="arrow-up-down" class="color-ebony-a61"></df-icon>
    </button>
  </div>
</dgx-learning-resource-card>
