<div *ngIf="!useSuggestedSkillsView" class="tag-control">
  <dgx-simple-field-wrapper
    *ngIf="!hideLabel; else tagsSearch"
    class="l_flex-grow"
    [label]="label"
    [isRequired]="required"
    [tipText]="tagToolTip"
    [icon]="tooltipIcon"
    [showFullWidthLabel]="showFullWidthLabel"
    labelFor="tagSearch"
    ><ng-container
      *ngTemplateOutlet="tagsSearch; context: { shouldHideLabel: true }"
    ></ng-container
  ></dgx-simple-field-wrapper>
</div>

<ng-template let-shouldHideLabel="shouldHideLabel" #tagsSearch>
  <dgx-tags-search
    class="guts-m-b-half"
    [placeholderText]="placeholder"
    [hideTopTags]="hideTopTags"
    [hideLabel]="shouldHideLabel || hideLabel"
    [hint]="hint"
    [allowExistingOnly]="allowExistingOnly"
    [showHintAlone]="showHintAlone"
    [topTags]="topTags"
    [tags]="tags"
    [suggestSkills]="suggestSkills"
    [useSuggestedSkillsView]="useSuggestedSkillsView"
    [useNewTagsDesign]="useNewTagsDesign"
    [loadingJobRoleAISkillSuggestions]="loadingJobRoleAISkillSuggestions"
    (addTag)="onAddTag($event)"
    (removeTag)="onRemoveTag($event)"
  ></dgx-tags-search>
</ng-template>

<div *ngIf="useSuggestedSkillsView" class="l_flex l_flex-column">
  <label>{{ label }}</label>
  <span class="l_flex-grow">
    <ng-container
      *ngTemplateOutlet="tagsSearch; context: { shouldHideLabel: true }"
    ></ng-container>
  </span>
</div>
