import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ContentEditableService {
  constructor() {}

  // move cursor to front
  public setCaretToEnd(target /*: HTMLDivElement*/) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(target);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach(); // optimization
    // set scroll to the end if multiline
    target.scrollTop = target.scrollHeight;
  }
}
