import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ContentActionPipe } from '@app/shared/pipes/content-action.pipe';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { TrackerService } from '@app/shared/services/tracker.service';
import {
  NormalizedRecommendation,
  RecentRecommendeesResponse,
  RecommendationForUser,
  RecommendationGroupModel,
  RecommendationsModel,
  RecommendationsParams,
  RecommendationStatus,
  RecommendationUpdateParams,
  RecommendationUserInsights,
  RecommendationUserModel,
  UserProfileRecommendationsModel,
  UserProfileRecommendationsModelShared,
} from '../recommendations.api';
import {
  CanRecommendInputs,
  RecommendeeType,
  RecommendingItem,
} from '../recommendations.model';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TagsApi } from '@app/tags/tag-api.model';
import { Member } from '@app/user/user-api.model';
import { ResourceType } from '@app/shared/models/core-api.model';
import { WindowToken } from '@app/shared/window.token';
import { privacyNumFromPrivacyLevel } from '@app/shared/utils/visibility-helpers';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { UserRecommendationType } from '@app/shared/models/core.enums';

export enum RecommendationType {
  RequiredLearning = 'RequiredLearning',
  Recommendation = 'Recommendation',
}

// This enum was originally key - number paired but is now key - string
// due to dg-casing
export enum RecommendationStatusIdTypes {
  Pending = 'Pending',
  Queued = 'Queued',
  Dismissed = 'Dismissed',
  Complete = 'Complete',
  Private = 'Private',
}

/** Provides methods for working with content recommendations and assignments */
@Injectable({
  providedIn: 'root',
})
export class RecommendationsService extends ApiServiceBase {
  public static readonly maxTopUsers = 10;
  public notificationCountModified$: Subject<void>;

  private i18n = this.translate.instant([
    'RecommendationsSvc_ProblemAccessingRecommendations',
    'RecommendationsSvc_ProblemAddingRecommendations',
    'RecommendationsSvc_ProblemDeleteRecommendationsFormat',
    'RecommendationsSvc_ProblemSearchingMembers',
    'RecommendationsSvc_ProblemSearchingGroups',
    'RecommendationsSvc_SuccessDelete',
    'RecommendationsSvc_SuccessDeleteAssignment',
    'RecommendationsSvc_UpdateRecommendation',
    'RecommendationsSvc_UpdateAssignment',
    'RecommendationsSvc_ProblemUpdatingRecommendation',
    'RecommendationsSvc_GetUsersError',
    'RecommendationsSvc_UsersTitle',
    'RecommendationsSvc_SharedWith',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient,
    private contentActionPipe: ContentActionPipe,
    private displayTypePipe: DisplayTypePipe,
    private datePipe: DatePipe,
    private tracker: TrackerService,
    private authService: AuthService,
    private notifier: NotifierService,
    private tagRatingTracker: TagRatingTrackerService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super(
      http,
      translate.instant('RecommendationsSvc_ProblemAccessingRecommendations')
    );

    this.notificationCountModified$ = new Subject<void>();
  }

  /** Gets a recommendation's insights data for users it has been shared with */
  public getUserStatusByRecommendation(args: {
    recommendationId: number;
    recommendationType?: RecommendationType;
    skip?: number;
    take?: number;
  }): Observable<RecommendationUserInsights> {
    return this.get('/recommendations/getuserstatusbyrecommendation', args);
  }

  /** Updates the status of a recommendation for the current user */
  public updateRecommendationStatus(args: {
    recommendation: RecommendationForUser;
    status: RecommendationStatus;
    item: { isModal?: boolean }; // TODO: type this as soon as we can sort out what exactly it is (ie, what has an isModal property). Likely not a RecommendingItem, but maybe a LearningResourceViewModel?
  }): Observable<void> {
    const { recommendation, status, item } = args;
    return this.post<void>(
      '/recommendations/updaterecommendationstatus',
      {
        recommendationId: recommendation.recommendationId,
        recommendationStatus: status,
      },
      this.i18n.RecommendationsSvc_ProblemUpdatingRecommendation
    ).pipe(
      tap((response) => {
        this.notify();

        if (status === 'Dismissed' && !item.isModal) {
          this.tracker.trackEventData({
            action: 'Recommendation Dismissed',
            properties: recommendation,
          });
        }

        // if assigned learning completed
        if (
          status === 'Complete' &&
          recommendation.recommendationType === 'RequiredLearning' &&
          !item.isModal
        ) {
          this.tracker.trackEventData({
            action: 'Assignment Completed',
            properties: recommendation,
          });
        }

        return response;
      })
    );
  }

  /** Builds a URL for a recommendation to be created */
  public getShareUrl(item: RecommendingItem): string {
    // Pathways have a PublicUrl to use instead of the InternalUrl if InviteUrl isn't available (see above)
    const path = item.publicUrl || item.internalUrl;
    if (!path) {
      return '';
    }
    // Get OrganizationCode based on user's current context
    const authUser = this.authService.authUser;
    const orgCode =
      authUser && authUser.defaultOrgInfo
        ? authUser.defaultOrgInfo.organizationCode
        : '';
    let baseUrl = `https://${this.windowRef.location.host}${path}`;

    if (orgCode && baseUrl.indexOf('orgsso=') === -1) {
      // NOTE: In a perfect world without IE11 we'd use new URL(..)
      // here to parse baseUrl and correctly add a new query param.
      const sep = baseUrl.indexOf('?') === -1 ? '?' : '&';
      baseUrl = `${baseUrl}${sep}orgsso=${encodeURIComponent(orgCode)}`;
    }

    if (item.inputType) {
      baseUrl += `&inputType=${item.inputType}`;
    }

    return baseUrl;
  }

  /** Gets a list of recommendations via their IDs */
  public getRecommendationsByIds(
    ids: number[],
    includeCreatorDetails?: boolean
  ): Observable<RecommendationsModel<RecommendationForUser>> {
    return this.get('/recommendations/getrecommendationsbyids', {
      ids,
      includeCreatorDetails,
    });
  }

  /** Gets recommendations received by the current user, paged and filtered by type */
  public getReceivedRecommendations(args: {
    recommendationType: RecommendationType;
    skip: number;
    take: number;
    doCache: boolean;
  }): Observable<RecommendationsModel<RecommendationForUser>> {
    // Note use of http directly here so we can control caching
    return this.http.get('/recommendations/getreceivedrecommendations', {
      params: {
        recommendationType: args.recommendationType || 'Recommendation',
        skip: args.skip,
        take: args.take,
      },
      cache: args.doCache,
    });
  }

  /** Gets recommendations received by the current user, filtered by type and organized by status */
  public getUserProfileRecommendations(
    recommendationType: RecommendationType.Recommendation
  ): Observable<UserProfileRecommendationsModelShared<RecommendationForUser>>;
  public getUserProfileRecommendations(
    recommendationType: RecommendationType.RequiredLearning
  ): Observable<UserProfileRecommendationsModel<RecommendationForUser>>;
  public getUserProfileRecommendations(): Observable<
    UserProfileRecommendationsModel<RecommendationForUser>
  >;
  public getUserProfileRecommendations(
    recommendationType?: RecommendationType
  ): Observable<UserProfileRecommendationsModel<RecommendationForUser>> {
    return this.get<any>('/recommendations/getuserprofilerecommendations', {
      recommendationType,
      useResourceImages: true,
    });
  }

  /** Deletes an existing recommendation
   * @param recommendationId ID of the recommendation to delete
   * @param title Title to be included in tracking data
   * @param recommendationType Recommendation type to be included in tracking data
   */
  public deleteRecommendation(
    recommendationId: number,
    title: string,
    recommendationType: RecommendationType
  ): Observable<void> {
    return this.post<void>(
      '/recommendations/deleterecommendation',
      {
        recommendationId,
      },
      this.translate.instant(
        'RecommendationsSvc_ProblemDeleteRecommendationsFormat',
        { title }
      )
    ).pipe(
      tap(() => {
        const options =
          recommendationType === 'Recommendation'
            ? {
                action: 'Recommendation Deleted',
                successMessage: this.i18n.RecommendationsSvc_SuccessDelete,
              }
            : {
                action: 'Assignment Deleted',
                successMessage:
                  this.i18n.RecommendationsSvc_SuccessDeleteAssignment,
              };

        this.tracker.trackEventData({
          action: options.action,
          properties: {
            recommendationId,
          },
        });
        this.notifier.showSuccess(options.successMessage);
      })
    );
  }

  /** Creates a recommendation of a content item to a set of users or groups
   * @param params Recomendation parameters to be sent with the API call
   * @param itemTypeDisplay Item type string to be used as the tracking category
   * @param initiator Originating HTML element to be included in tracking data
   */
  public addRecommendation(
    params: RecommendationsParams,
    itemTypeDisplay: string,
    initiator?: HTMLElement
  ): Observable<void> {
    const recommendation = {
      ...params,
      recommendationType: params.recommendationType ?? 'Recommendation',
      dateDue: this.datePipe.transform(params.dateDue, 'yyyy-MM-dd'),
    };
    const isAssignment =
      recommendation.recommendationType === 'RequiredLearning';
    const isAdmin =
      this.authService.isAdminUser || this.authService.isTechnicalAdminUser;
    const messageAction = isAssignment ? 'Assigned' : 'Recommended';
    const messageUser = isAdmin ? 'Admin' : 'Learner';

    return this.post<{
      hasIneligibleUsers: boolean;
      eligibleCount: number;
      status: string;
    }>(
      '/recommendations/add',
      recommendation,
      this.i18n.RecommendationsSvc_ProblemAddingRecommendations
    ).pipe(
      tap(({ hasIneligibleUsers, eligibleCount }) => {
        // coerce to a number (eligibleCount may be a string or undefined)
        eligibleCount = eligibleCount ? +eligibleCount : 0;
        this.notify();

        const resource = recommendation.resource;
        let messageFormatKey: string;

        /**
         * Including i18n keys to allow for search matching
         *
         * RecommendationsSvc_InputAssignedFormat
         * RecommendationsSvc_InputAssignedFormatIneligibleAdmin
         * RecommendationsSvc_InputAssignedFormatIneligibleLearner
         * RecommendationsSvc_InputAssignedFormatNoEligibleUsersAdmin
         * RecommendationsSvc_InputAssignedFormatNoEligibleUsersLearner
         * RecommendationsSvc_InputRecommendedFormat
         * RecommendationsSvc_InputRecommendedFormatIneligibleAdmin
         * RecommendationsSvc_InputRecommendedFormatIneligibleLearner
         * RecommendationsSvc_InputRecommendedFormatNoEligibleUsersAdmin
         * RecommendationsSvc_InputRecommendedFormatNoEligibleUsersLearner
         */

        if (resource.resourceType === 'Tag') {
          messageFormatKey =
            eligibleCount === 0
              ? `RecommendationsSvc_Input${messageAction}FormatNoEligibleUsers${messageUser}`
              : hasIneligibleUsers
              ? `RecommendationsSvc_Input${messageAction}FormatIneligible${messageUser}`
              : `RecommendationsSvc_Input${messageAction}Format`;
        } else {
          if (eligibleCount === 0) {
            messageFormatKey =
              'RecommendationsSvc_ProblemAddingRecommendations';
          } else {
            messageFormatKey = `RecommendationsSvc_Input${messageAction}Format`;
          }
        }

        const message = this.translate.instant(messageFormatKey, {
          title: resource.title,
        });

        if (eligibleCount > 0) {
          this.notifier.showSuccess(message);
        } else {
          this.notifier.showError(message);
        }

        const recommendationTrackData = {
          ...resource,
          recommendationType: recommendation.recommendationType,
          recommendedTo: (recommendation.groupIds.length === 0
            ? 'User'
            : 'Group') as RecommendeeType,
          isAssignment,
          dueDate: recommendation.dateDue as string, // ensured is string above
          action: recommendation.action,
        };
        if (resource.resourceType === 'Tag') {
          this.tagRatingTracker.trackSkillRecommended(
            recommendationTrackData.action as any,
            resource as TagsApi.TagDetails,
            recommendationTrackData.isAssignment,
            recommendationTrackData.recommendedTo,
            recommendationTrackData.dueDate,
            initiator
          );
        } else {
          this.tracker.trackEventData({
            action: resource.resourceType + ' Shared',
            category: itemTypeDisplay,
            properties: recommendationTrackData,
            element: initiator,
          });
        }
      }),
      map(() => void 0)
    );
  }

  /** Updates a recommendation's due date and/or comment */
  public updateRecommendation(
    params: RecommendationUpdateParams
  ): Observable<any> {
    const recommendation = {
      ...params,
      dateDue: this.datePipe.transform(params.dateDue, 'yyyy-MM-dd'),
    };

    return this.post(
      '/recommendations/update',
      recommendation,
      this.i18n.RecommendationsSvc_ProblemUpdatingRecommendation
    ).pipe(
      tap((response) => {
        const isRecommendation =
          recommendation.recommendationType === 'Recommendation';
        const trackEvent = isRecommendation
          ? 'Recommendation Updated'
          : 'Assignment Updated';

        const successMessage = isRecommendation
          ? this.i18n.RecommendationsSvc_UpdateRecommendation
          : this.i18n.RecommendationsSvc_UpdateAssignment;

        this.tracker.trackEventData({
          action: trackEvent,
          properties: recommendation,
        });
        this.notifier.showSuccess(successMessage);

        return response;
      })
    );
  }

  /** Gets a list of organization members with whom content can be shared */
  public findMembers(params: {
    nameFilter: string;
    resourceId: number;
    resourceType: ResourceType;
    isOrgContent?: boolean;
    count?: number;
  }): Observable<Member[]> {
    return this.get(
      '/recommendations/findmembers',
      {
        ...params,
        isOrgContent: !!params.isOrgContent,
      },
      this.i18n.RecommendationsSvc_ProblemSearchingMembers
    );
  }

  /** Gets a list of organization groups that content can be shared with */
  public findGroups(params: {
    nameFilter: string;
    resourceId: number;
    resourceType: ResourceType;
    count?: number;
  }): Observable<RecommendationGroupModel[]> {
    return this.get(
      '/recommendations/findgroups',
      params,
      this.i18n.RecommendationsSvc_ProblemSearchingGroups
    );
  }

  /** Gets a list of suggested users that have recently been shared with */
  public getRecentRecommendees(
    includeGroups: boolean
  ): Observable<(RecommendationUserModel | RecommendationGroupModel)[]> {
    return this.get<RecentRecommendeesResponse>(
      '/recommendations/getrecentusersrecommended',
      {
        recommendationType: '',
        skip: 0,
        take: 10,
        membersOnly: !includeGroups,
      }
    ).pipe(
      map((result) => {
        // returns the API which has two arrays (Groups, Members) concat the two into one array (combined)
        const combined = includeGroups
          ? [...result.groups, ...result.members]
          : result.members;

        return combined.sort(
          (a, b) =>
            new Date(b.recommendedAt).getTime() -
            new Date(a.recommendedAt).getTime()
        );
      })
    );
  }

  public getUserProfileAssignedLearning(recommendationType): Observable<any> {
    return this.get('/recommendations/GetUserProfileAssignedLearning', {
      recommendationType,
      useResourceImages: true,
    });
  }

  /** Marks the user as having just visited the recommendations tab */
  public updateRecommendationsActivity(): Observable<void> {
    return this.post('/recommendations/updateactivity', {});
  }

  /** Normalizes a recommendation into a viewmodel-esque structure */
  public normalizeRecommendation(
    recommendation: RecommendationForUser
  ): NormalizedRecommendation {
    const reference = { ...recommendation.reference };

    const contentAction = this.contentActionPipe
      .transform(recommendation.referenceType)
      .toLowerCase();

    const displayType = this.displayTypePipe
      .transform(recommendation.referenceType)
      .toLowerCase();

    const dateDue = this.datePipe.transform(
      recommendation.dateDue,
      'mediumDate'
    );

    const comment = recommendation.creator ? recommendation.comment : null;

    return {
      ...recommendation,
      _contentAction: contentAction,
      _displayType: displayType,
      dateCompleted: recommendation.dateCompleted,
      dateCreated: recommendation.dateCreated,
      dateDue,
      comment,
      reference,
      rawDateDue: recommendation.dateDue,
    };
  }

  /** Normalizes an array of recommendations */
  public normalizeRecommendations(
    recommendations: RecommendationForUser[]
  ): NormalizedRecommendation[] {
    return recommendations.map((item) => {
      return this.normalizeRecommendation(item);
    });
  }

  /**
   * Returns true if the recommendation is required learning (i.e. an assignment)
   * @param item
   */
  public isRequired(item: NormalizedRecommendation) {
    return (
      item.recommendationType === RecommendationType.RequiredLearning &&
      !item.creator &&
      !item.userProfileKey
    );
  }

  public hasPrivateOrAnonymous(
    data: Partial<{ totalAnonymousUsers: number; totalPrivateUsers: number }>
  ): boolean {
    return data?.totalAnonymousUsers > 0 || data?.totalPrivateUsers > 0;
  }

  public hasAnonymous(data: Partial<{ totalAnonymousUsers: number }>): boolean {
    return data?.totalAnonymousUsers > 0;
  }

  /**
   * Used to determine if a resource can be recommended based on privacy level and resource validation
   *
   * @param CanRecommendInputs
   * @returns boolean
   */
  public canRecommendResource(resource: CanRecommendInputs): boolean {
    if (!resource.isValid || !resource.privacyLevel) {
      return false;
    }
    const privacyLevel = isNaN(resource.privacyLevel)
      ? privacyNumFromPrivacyLevel(resource.privacyLevel)
      : resource.privacyLevel;
    return privacyLevel === Visibility.private ? false : true;
  }

  private notify(): void {
    this.notificationCountModified$.next();
  }
}
