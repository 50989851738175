import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

// services
import { AuthService } from '@app/shared/services/auth.service';
import { GroupService } from '@app/shared/services/group.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';

// types
import { GroupMember } from '@app/groups/group-api';
import { camelCaseKeys } from '@app/shared/utils/property';

// misc
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';

@Component({
  selector: 'dgx-group-edit-member-modal',
  templateUrl: './group-edit-member-modal.component.html',
})
export class GroupEditMemberModalComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public userId: any;
  @Input() public isPending: any;
  @Input() public group: any;

  @ViewChild('softRemoveBtn') public softRemoveBtn: ElementRef;
  @ViewChild('cancelRemovalBtn') public cancelRemoveBtn: ElementRef;

  public allowEditPermissions: boolean;
  public allowRemove: boolean;
  public assignedRoleId: number;
  public availableRoles: any[];
  public confirmRemoval = false;
  public loading = true;
  public member: GroupMember;
  public orgId = this.authService.authUser.defaultOrgId;
  public removing = false;
  public selectedRole: any;
  public submitting = false;
  public userGroupPermissions;

  public i18n = this.translate.instant([
    'Core_Cancel',
    'Core_Save',
    'Core_Loading',
    'dgOrgEditMemberModal_RemoveUser',
    'GroupFormSvc_ConfirmRemoval',
    'GroupFormSvc_ConfirmRemovalMessage',
    'GroupFormSvc_EditUserSettings',
    'GroupFormSvc_History',
    'GroupFormSvc_PendingMember',
    'GroupFormSvc_RemoveFromGroup',
    'GroupFormSvc_Removing',
    'GroupFormSvc_Role',
    'GroupFormSvc_TopCategories',
    'OrgMemberFormCtrl_RemovedMemberSuccessMessage',
  ]);

  constructor(
    private groupService: GroupService,
    private translate: TranslateService,
    private tracker: TrackerService,
    private notifier: NotifierService,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private encodeToEntitiesPipe: EncodeToEntitiesPipe
  ) {
    super();
  }

  public ngOnInit(): void {
    const details$ = this.getMemberDetails();
    const permissions$ = this.getMemberPermissions();
    const roles$ = this.getAvailableRoles();

    forkJoin([details$, permissions$, roles$])
      .pipe(take(1))
      .subscribe((result) => {
        // member details
        this.member = camelCaseKeys(result[0]);
        // member permission
        this.userGroupPermissions = result[1];
        // available roles
        const roleData = result[2];
        const availableRoles = [];
        for (const x in roleData) {
          if (roleData[x]) {
            availableRoles.push(roleData[x]);
          }
        }
        this.availableRoles = availableRoles;

        let defaultRoleId;
        for (const x in this.availableRoles) {
          if (this.availableRoles[x].name === 'Member') {
            defaultRoleId = this.availableRoles[x].roleId;
            break;
          }
        } // HACK: until we support a real "default role" flag

        let existingRoleId = null;
        if (this.member.roleId) {
          existingRoleId = this.member.roleId;
        } else if (this.member.role && this.member.role.roleId) {
          existingRoleId = this.member.role.roleId;
        }

        this.assignedRoleId = existingRoleId ? existingRoleId : defaultRoleId;
        this.allowEditPermissions =
          this.userGroupPermissions.includes('EditPermissions');
        this.allowRemove = this.userGroupPermissions.includes('RemoveMembers');
        this.availableRoles.forEach((role) => {
          const availablePermissionsI18n = [];
          role.labelKey = 'Role' + role.name.replace(/\s/g, '');
          role.name = this.translate.instant('Core_' + role.labelKey);
          role.permissions.forEach((perm) => {
            availablePermissionsI18n.push(
              this.translate.instant(
                'dgGroupSecurity_' + perm.name.replace(/\s/g, '')
              )
            );
          });
          role.permissionNames = availablePermissionsI18n.join(', ');
        });

        this.member.categories = this.member.categories.slice(0, 5); // limit to top 5 categories
        this.loading = false;
      });
  }

  public getOrderedAvailableRoles(roles: any) {
    return roles.filter((role) => role.roleId === this.assignedRoleId);
  }

  public submit = () => {
    this.loading = true;
    if (this.allowEditPermissions) {
      this.availableRoles.forEach((role) => {
        if (role.roleId === this.assignedRoleId) {
          this.selectedRole = role;
          this.loading = false;
          return;
        }
      });
      this.submitting = true;
      if (this.isPending) {
        return this.groupService
          .setPendingMemberRole(
            this.group.groupId,
            this.member.userProfileId,
            this.assignedRoleId
          )
          .subscribe(() => {
            this.loading = false;
            this.activeModal.close();
            this.notifier.showSuccess(
              this.translate.instant(
                'GroupMemberFormCtrl_UpdateMemberRoleFormat',
                {
                  memberName: this.encodeToEntitiesPipe.transform(
                    this.member.name
                  ),
                }
              )
            );
          });
      } else {
        return this.groupService
          .setMemberRole(
            this.group.groupId,
            this.member.userProfileId,
            this.assignedRoleId,
            this.group,
            this.selectedRole
          )
          .subscribe(() => {
            this.loading = false;
            this.activeModal.close();
            this.notifier.showSuccess(
              this.translate.instant(
                'GroupMemberFormCtrl_UpdateMemberRoleFormat',
                {
                  memberName: this.encodeToEntitiesPipe.transform(
                    this.member.name
                  ),
                }
              )
            );
            this.tracker.trackEventData({
              action: 'Edit User Settings Modal Clicked',
              properties: {
                GroupId: this.group.groupId,
                GroupName: this.group.name,
                Selection: 'Role toggle',
                Location: 'Group Insights',
              },
            });
          });
      }
    }
  };

  public softRemove = () => {
    this.confirmRemoval = true;
    this.cancelRemoveBtn.nativeElement.focus();
  };

  public cancelRemove = () => {
    this.confirmRemoval = false;
    this.softRemoveBtn.nativeElement.focus();
  };

  public remove = () => {
    this.removing = true;
    this.tracker.trackEventData({
      action: 'Edit User Settings Modal Clicked',
      properties: {
        GroupId: this.group.groupId,
        GroupName: this.group.name,
        Selection: 'Remove From Group',
        Location: 'Group Insights',
      },
    });
    if (this.isPending) {
      return this.groupService
        .removePendingInvite(
          this.group.groupId,
          this.member.userProfileId as unknown as number
        )
        .subscribe(() => {
          this.activeModal.close();
        });
    } else {
      if (
        this.authService.authUser.viewerProfile.userProfileId ===
        this.member.userProfileId
      ) {
        return this.groupService.leave(this.group).subscribe(() => {
          this.activeModal.close();
          // reload necessary at this point to re-establish context of user permissions
          window.location.href = `/orgs/${this.orgId}/groups`;
        });
      } else {
        return this.groupService
          .removeMember(
            this.group.groupId,
            this.member.userProfileId,
            this.group
          )
          .subscribe(() => {
            this.activeModal.close();
            this.notifier.showSuccess(
              this.i18n.OrgMemberFormCtrl_RemovedMemberSuccessMessage
            );
          });
      }
    }
  };

  public dismiss = () => {
    this.activeModal.dismiss();
  };

  private getMemberDetails = () => {
    return this.groupService.getMemberDetail(
      this.group.groupId,
      this.userId,
      this.isPending
    );
  };

  private getMemberPermissions = () => {
    return this.groupService.getMemberPermissions(this.group.groupId);
  };

  private getAvailableRoles = () => {
    return this.groupService.getRoles(this.group.groupId);
  };
}
