import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { ReportingService } from '@app/insights/services/reporting.service';
import { Recommendation } from '@app/profile/recommendations-api.model';
import { RecommendationsDetailModalComponent } from '@app/recommendations/components/recommendations-detail-modal/recommendations-detail-modal.component';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import {
  DataColumn,
  DataColumnDate,
  DataColumnList,
} from '@app/shared/components/data-table/data-table';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { camelCaseKeys, pascalCaseKeys } from '@app/shared/utils/property';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'dgx-profile-share-activity',
  templateUrl: './profile-share-activity.component.html',
})
export class ProfileShareActivityComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @ViewChild('title', { static: true })
  public titleTemplate: TemplateRef<any>;
  @ViewChild('userCount', { static: true })
  public userCountTemplate: TemplateRef<any>;
  @ViewChild('groupCount', { static: true })
  public groupCountTemplate: TemplateRef<any>;
  @ViewChild('commentCount', { static: true })
  public commentCountTemplate: TemplateRef<any>;
  @ViewChild('dateCreated', { static: true })
  public dateCreatedTemplate: TemplateRef<any>;

  public allActivity = [];
  public hasMoreItems = true;
  public loading = false;
  public skip = 0;
  public take = 50;
  public recommendation: Recommendation;
  public i18n = this.translate.instant([
    'Core_Title',
    'Core_Type',
    'Core_People',
    'Core_Groups',
    'Core_Comments',
    'dgProfileShareActivity_DateShared',
    'dgProfileShareActivity_ShareActivity',
    'dgProfileShareActivity_PeopleSharedWith',
    'dgProfileShareActivity_GroupsSharedWith',
    'dgTagRating_Comments',
  ]);
  public columns: (DataColumn | DataColumnDate | DataColumnList)[];

  constructor(
    private translate: TranslateService,
    private inputService: InputsService,
    private modalService: ModalService,
    private focusStackService: FocusStackService,
    private reportingService: ReportingService,
    private detailsModalService: DetailsModalService,
    private displayTypePipe: DisplayTypePipe,
    private recommendationsModalService: RecommendationsModalService,
    private cdr: ChangeDetectorRef,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initializedColumns();
    this.getShareActivity();
  }

  public getPeopleAriaLabel(item: any) {
    const params = { title: item.title, count: item.userCount };
    const label =
      item.userCount === 1
        ? 'dgProfileShareActivity_NumPeopleSharedWithSingular'
        : 'dgProfileShareActivity_NumPeopleSharedWithPlural';
    return this.translate.instant(label, params);
  }

  public getGroupsAriaLabel(item: any) {
    const params = { title: item.title, count: item.groupCount };
    const label =
      item.groupCount === 1
        ? 'dgProfileShareActivity_NumGroupsSharedWithSingular'
        : 'dgProfileShareActivity_NumGroupsSharedWithPlural';
    return this.translate.instant(label, params);
  }

  public getCommentsAriaLabel(item: any) {
    const params = { title: item.title, count: item.commentCount };
    const label =
      item.commentCount === 1
        ? 'dgProfileShareActivity_NumCommentsSingular'
        : 'dgProfileShareActivity_NumCommentsPlural';
    return this.translate.instant(label, params);
  }

  public getShareActivity(): void {
    this.loading = true;
    this.reportingService
      .GetShareActivity({
        skip: 0,
        take: 50,
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe((response) => {
        this.allActivity = [
          ...this.allActivity,
          ...response.recommendations.map((recommendation) => {
            return {
              ...recommendation,
              title: recommendation.reference.title,
              displayType: this.getDisplayType(recommendation),
            } as Recommendation;
          }),
        ];

        this.hasMoreItems = response.hasMoreItems;
        if (this.hasMoreItems) {
          this.skip = this.allActivity.length;
        }
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  public openRecommendation(e, recommendation: Recommendation): void {
    if (this.isInputRecommendation(recommendation)) {
      this.inputService
        .getInputAndStatistics({
          inputId: recommendation.referenceId,
          inputType: recommendation.referenceType,
        } as InputIdentifier)
        .pipe(
          concatMap((details) => {
            this.inputService.mapInputStatistics(
              [pascalCaseKeys(details.input)],
              details.statistics
            );
            return this.detailsModalService.openDetails(
              camelCaseKeys({ model: details.input }),
              {
                trackingElement: e.target as HTMLElement,
                expandComments: true,
              }
            );
          }),
          this.takeUntilDestroyed()
        )
        .subscribe();
    } else {
      this.windowRef.open(recommendation.reference.internalUrl);
    }
  }

  public handleOpenGroupModal(e, recommendationId: number): void {
    this.recommendationsModalService
      .showGroupShareModal(recommendationId, e)
      .subscribe();
  }

  public handleOpenUsersModal(e, recommendation: Recommendation): Subscription {
    if (e) {
      this.focusStackService.push(e.target);
    }
    const inputs = {
      isSkillCoach: false,
      isUserActivity: true,
      recommendation: recommendation,
    };
    return this.modalService
      .show<void>(RecommendationsDetailModalComponent, { inputs })
      .pipe(this.takeUntilDestroyed())
      .subscribe();
  }

  public isInputRecommendation(recommendation: Recommendation): boolean {
    return !!recommendation.reference.inputType;
  }

  private getDisplayType(recommendation: Recommendation): string {
    return this.displayTypePipe.transform(
      recommendation.referenceType + (recommendation.action || '')
    );
  }

  private initializedColumns = (): void => {
    this.columns = [
      {
        label: 'Core_Title',
        headClasses: 'l_w40',
        template: this.titleTemplate,
      },
      {
        label: 'Core_Type',
        headClasses: 'l_w15',
        prop: 'displayType',
      },
      {
        label: 'Core_People',
        headClasses: 'l_w10 center-text',
        template: this.userCountTemplate,
        bodyClasses: 'center-text',
      },
      {
        label: 'Core_Groups',
        headClasses: 'l_w10 center-text',
        template: this.groupCountTemplate,
        bodyClasses: 'center-text',
      },
      {
        label: 'Core_Comments',
        headClasses: 'l_w10 center-text',
        template: this.commentCountTemplate,
        bodyClasses: 'center-text',
      },
      {
        label: 'dgProfileShareActivity_DateShared',
        headClasses: 'l_w15 right-text',
        template: this.dateCreatedTemplate,
        bodyClasses: 'right-text',
      },
    ];
  };
}
