import { LDFlagsService } from '../ld-flags.service';

export class ProfileLDFlags {
  /***
   *  Team: Profile
   *  Created Date: Old (2+ years)
   *  Planned Cleanup: TBD [PD-91559]
   *  Visibility:
   *    - Pre-release: Remove points
   *
   *  Some customers have requested that points not be shown to learners.
   */
  public get pointsDecomission(): boolean {
    return this.ldFlagsService.getFlag('points-decommission', false);
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
