import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ChartsService } from '@app/charts/charts.service';
import { LearningChartService } from '@app/charts/components/learning-chart/learning-chart.service';
import {
  UserInsights,
  UserInsightsService,
} from '@app/insights/services/user-insights.service';
import { Profile } from '@app/profile/profile.model';
import { ProfileService } from '@app/profile/services/profile.service';
import { InputType } from '@app/shared/models/core-api.model';
import { ReportingContentService } from '@app/shared/services/reporting-content/reporting-content.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { TagsApi } from '@app/tags/tag-api.model';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'dgx-personal-insights',
  templateUrl: './personal-insights.component.html',
  styleUrls: ['./personal-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalInsightsComponent implements OnInit, AfterViewInit {
  public readonly CLASS_NAME = 'PersonalInsightsComponent';

  @Input() public owner: Profile;

  // dgxLearningChart component Output binding
  @Output() public getChartData: EventEmitter<{
    params: any;
  }> = new EventEmitter<{ params: any }>();

  public metricStr: string;
  public resolver: any;
  public totalDisplayData: any = null;
  public popularDataList: any = {};

  private i18n: Record<any, string> = {};

  constructor(
    private translate: TranslateService,
    private trackerService: TrackerService,
    private learningChartService: LearningChartService,
    private userInsightsService: UserInsightsService,
    private reportingContentService: ReportingContentService,
    private profileService: ProfileService,
    private ldFlagService: LDFlagsService,
    public cdr: ChangeDetectorRef
  ) {
    this.metricStr = this.ldFlagService.profile.pointsDecomission
      ? 'Core_Completions'
      : 'ReportingContentSvc_Points';
  }

  public get ownerIsViewing() {
    return this.profileService.ownerIsViewing;
  }

  public ngOnInit(): void {
    this.trackerService.setLocation('Profile Insights');

    this.i18n = this.translate.instant([
      'ReportingContentSvc_InternalContent',
      'ReportingContentSvc_ExternalContent',
      'ReportingContentSvc_Name',
      'ReportingContentSvc_Points',
      'Core_Completions',
    ]);
    this.trackerService.trackEventData({
      action: 'Profile Insights Viewed',
      properties: {
        userId: this.owner.id,
        isActiveLearner: this.owner.isEngaged,
        ProfileType: this.profileService.getProfileType(),
      },
    });
  }

  public ngAfterViewInit(): void {
    this.getChartData.subscribe((event) => {
      this.getLearningSummary(event);
    });
    this.loadChartsModule();
  }

  public getLearningSummary({ params }: any) {
    this.getCharts(params);
  }

  private buildPopularData(categories: any[], startDate: Date, endDate: Date) {
    // Popular Categories
    this.popularDataList = {
      Headers: [
        this.i18n.ReportingContentSvc_Name,
        '',
        this.i18n[this.metricStr],
      ],
      Categories: camelCaseKeys(categories),
      StartDate: startDate,
      EndDate: endDate,
    };
    this.cdr.detectChanges();
  }

  private getCharts(params) {
    const dataTypes = this.reportingContentService.getDataTypes();
    switch (params.dataType) {
      case dataTypes.points:
        return this.getInsightsChart(
          params.period,
          params.startDate,
          params.endDate,
          params.inputType,
          dataTypes.points,
          params.tag
        );
      default:
        return this.getInsightsChart(
          params.period,
          params.startDate,
          params.endDate,
          params.inputType,
          dataTypes.items,
          params.tag
        );
    }
  }

  private getInsightsChart(
    period: string,
    startDate: Date,
    endDate: Date,
    inputType: InputType | InputType[],
    chartType: string,
    tag: TagsApi.Tag
  ) {
    const isInputType = this.reportingContentService.isInputType(inputType);
    if (isInputType) {
      inputType = [inputType] as InputType[];
    }

    const tagName: string = this.reportingContentService.setTagWhenDefault(tag);

    this.userInsightsService
      .getUserInsights(
        this.owner.id,
        period,
        startDate,
        endDate,
        inputType,
        tagName
      )
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.totalDisplayData = null;
          return throwError(errorResponse);
        })
      )
      .subscribe((data: UserInsights) => {
        const { activityHistogram, popularCategories } = data;

        const chartData = this.learningChartService.extractChartData(
          activityHistogram,
          chartType
        );

        this.totalDisplayData = chartData;
        this.buildPopularData(popularCategories, startDate, endDate);
      });
  }

  /**
   * Lazy load Charts module
   */
  private loadChartsModule(): void {
    ChartsService.loadModule().then((m) => {
      this.resolver = m.ComponentResolver;
      this.cdr.markForCheck();
    });
  }
  public getComponent(name: string): any {
    if (this.resolver) {
      return this.resolver.getComponent(name);
    }
    return null;
  }
  public getChartInputs(name: string) {
    let config = {};
    if (!this.resolver) {
      return config;
    }
    switch (name) {
      case 'dgxLearningChart':
        config = {
          chart: this.totalDisplayData,
          setSelectedPeriod: true,
          hasDrillDown: false,
          location: 'Profile Insights',
          isPersonal: true,
          getChartData: this.getChartData,
        };
        break;
      case 'dgxPopularDataChart':
        config = {
          data: this.popularDataList,
          isUser: true,
          location: 'Profile Insights',
        };
        break;
    }
    return config;
  }
}
