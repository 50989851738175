import { NavigationEnd, Router } from '@angular/router';

import produce from 'immer';
import { Observable, combineLatest, forkJoin, of, pipe } from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';

import {
  AuthService,
  ContextService,
  LDFlagsService,
  NavigationService,
  TargetsService,
  WebEnvironmentService,
  TrackerService,
} from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';
import { TagTypes } from '@app/analytics/models/constants';
import { OrgHelpMenuService } from '@app/orgs/services/org-help-menu.service';
import { OrganizationSupportInfo } from '@app/orgs/services/orgs.model';
import { TeamFlagsService } from '@app/team/services/team-flags.service';
import { LayoutConfiguration_v2 } from '@degreed/apollo-angular';

import { AuthUser } from '../../account/account-api.model';

import * as DegreedLayout from './data/degreed.config.json';
import * as WellsFargoLayout from './data/wells-fargo.config.json';
import * as MicronLayout from './data/micron.config.json';

// import * as AllianzLayout from './data/allianz.config.json';
// import * as StarBucksLayout from './data/starbucks.config.json';

const customizations = {
  901724: WellsFargoLayout, // Production
  700059: WellsFargoLayout, // Beta 2: "Staging"
  700043: WellsFargoLayout, // Beta 1: "Beta"
  1: MicronLayout, // Development
  // 901746: StarBucksLayout,
  // 10095: AllianzLayout,
  // 10013: AllianzLayout,
};

let layout$: Observable<LayoutConfiguration_v2>;

/**
 * Return a LayoutConfiguration_v2 object based on the user's default organization
 * NOTE: Currently we use compiled configuration files.
 *       !! This configuration should be constructed on the server
 *
 * @returns Observable<LayoutConfiguration_v2>
 */
export const buildLayoutConfiguration_v2 = (
  router: Router,
  auth: AuthService,
  translate: TranslateService,
  environment: WebEnvironmentService,
  targets: TargetsService,
  navigation: NavigationService,
  featureFlag: LDFlagsService,
  context: ContextService,
  teamFlags: TeamFlagsService,
  helpMenu: OrgHelpMenuService,
  trackerService: TrackerService,
  analyticsGuard: AnalyticsGuard
): Observable<LayoutConfiguration_v2> => {
  // When route changes (observed by router.events), update the layout configuration to hide/show options
  const buildRouteWatcher = () => {
    const isNavigationEnd = (event) => event instanceof NavigationEnd;
    const navigateEnd$ = router.events.pipe(
      filter(isNavigationEnd),
      startWith({ url: window.location.pathname })
    );

    return watchNavigationEnd(navigateEnd$);
  };

  // Customize the configuration JSON based on the user's organization, i18n and other information
  const buildLayout = pipe(
    switchMap((user: AuthUser | undefined) => {
      // Gather async data before customizing layout
      const organizationId = user?.defaultOrgId;
      const supportInfo$ = helpMenu.getSupportInfo(organizationId);
      const learnInSSOUrl$ = navigation.getLearnInSSOUrl(organizationId);
      const featuredPlanId$ = targets
        .getBrowseTarget(organizationId)
        .pipe(map((target) => target.targetId));

      return forkJoin([
        of(user),
        supportInfo$,
        featuredPlanId$,
        learnInSSOUrl$,
      ]);
    }),
    map(
      ([
        user,
        supportInfo,
        featuredPlanId,
        learnInSSOUrl,
      ]): LayoutConfiguration_v2 => {
        const analyticsUrl = analyticsGuard.getParentUrl(
          TagTypes.PRODUCT_SWITCHER
        );
        const organizationId = user?.defaultOrgId;

        // Note: Update i18n first to get any placeholder variables used in translations that need to be replaced afterward
        let config = (customizations[user?.defaultOrgId] ??
          DegreedLayout) as LayoutConfiguration_v2;
        config = updatei18n(config, translate);
        config = updateUser(config, user, environment);
        config = updateUrls(
          config,
          user,
          featuredPlanId,
          learnInSSOUrl,
          analyticsUrl
        );
        config = updateNavigation(config, user, featureFlag, teamFlags);
        config = updateSwitcher(
          config,
          user,
          auth,
          featureFlag,
          analyticsGuard,
          context
        );
        config = updateHelpMenu(config, organizationId, supportInfo);
        config = addAnalyticTrackers(config, trackerService);

        return config;
      }
    )
  );

  if (!layout$) {
    layout$ = auth.authUser$.pipe(buildLayout, switchMap(buildRouteWatcher()));
  }

  return layout$;
};

/**
 * Replace all `<user>` tokens with current user's vanityUrl
 * Replace profile nav item image with current user's picture
 * @param configuration LayoutConfiguration_v2
 * @param user AuthUser
 */
function updateUser(
  configuration: LayoutConfiguration_v2,
  user: AuthUser | undefined,
  environment: WebEnvironmentService
): LayoutConfiguration_v2 {
  const userName = user?.viewerProfile.vanityUrl || '';
  const organizationId = user?.defaultOrgId;
  let profileImage = user?.viewerProfile.picture;
  const visitor = (node, key) => {
    switch (key) {
      case 'homeUrl':
      case 'href':
      case 'routerLink':
        const text: string = node[key];
        if (text.includes('<user>'))
          node[key] = text.replace('<user>', userName);
        if (text.includes('<orgId>'))
          node[key] = text.replace('<orgId>', organizationId?.toString());
        break;
    }
  };

  // Update Profile image
  if (profileImage) {
    if (profileImage.startsWith('~')) {
      profileImage = environment.getBlobUrl(profileImage);
    }
    const profileItem = configuration.navigation.top.find(
      (item) => item.featureKey === 'profile'
    );
    if (profileItem) profileItem.image = profileImage;
  }

  return traverse(configuration, visitor);
}

/**
 * Update the navigation items based on feature flags and user permissions
 */
function updateNavigation(
  configuration: LayoutConfiguration_v2,
  user: AuthUser | undefined,
  featureFlag: LDFlagsService,
  teamFlags: TeamFlagsService
) {
  const userName = user?.viewerProfile.vanityUrl || '';
  const useLearnerHub = featureFlag.useLearnerHub;

  const homeItem = configuration.navigation.top.find(
    (item) => item.featureKey === 'home'
  );
  for (let subItem of homeItem.subItems) {
    switch (subItem.featureKey) {
      case 'home-dashboard':
        subItem.routerLink = useLearnerHub
          ? `/${userName}/learnerhub/home`
          : subItem.routerLink;
        break;
      case 'home-assignments':
        subItem.routerLink = useLearnerHub
          ? `/${userName}/learnerhub/assignments`
          : subItem.routerLink;
        break;
    }
  }

  // TODO: Not used until Q3 version of layout component when product switcher goes away
  // const showAcademies = !!user && featureFlag.showAcademies;

  const showNotifications = !!user;
  const showProfile = !!user;
  const showAssistant = featureFlag.lxpDegreedAssistant;
  const showOpportunities = user?.hasCareerMobility;
  const showSkillCoach =
    user?.isSkillInventoryClient || user?.isSkillAnalyticsClient
      ? user?.isManager
      : user?.isManager &&
        user?.defaultOrgInfo.settings.enableTeamSpace &&
        (user?.defaultOrgInfo.settings.skillCoachFullOrgAccess ||
          teamFlags.teamSpaceEnabled);

  configuration.navigation.top = configuration.navigation.top.reduce(
    (results, item) => {
      let result = item;
      switch (item.featureKey) {
        case 'opportunities':
          result = showOpportunities ? result : null;
          break;
        case 'skill-coach':
          result = showSkillCoach ? result : null;
          break;
        case 'assistant':
          result = showAssistant ? result : null;
          break;
        case 'notifications':
          result = showNotifications ? result : null;
          break;
        case 'profile':
          result = showProfile ? result : null;
          break;
      }

      return result ? [...results, result] : results;
    },
    []
  );

  const showFlexEd = !user?.isPexUser && user?.isPexOrg;
  const profileItem = configuration.navigation.top.find(
    (item) => item.featureKey === 'profile'
  );
  profileItem.subItems = profileItem.subItems.reduce((results, item) => {
    let result = item;
    switch (item.featureKey) {
      case 'flex-ed':
        result = showFlexEd ? result : null;
        break;
    }

    return result ? [...results, result] : results;
  }, []);

  return configuration;
}

/**
 * Update the product switcher items based on feature flags and user permissions
 */
function updateSwitcher(
  configuration: LayoutConfiguration_v2,
  user: AuthUser | undefined,
  auth: AuthService,
  featureFlag: LDFlagsService,
  analyticsGuard: AnalyticsGuard,
  context: ContextService
) {
  const showAnalytics = analyticsGuard.isAuthorized;
  const showLearnIn = featureFlag.showLearnInProductSwitcher;
  const showExtendedEnterprise = user?.hasChannel;
  const showManageOrg =
    (auth.userCanManageLearnerOrg ||
      auth.userCanViewReporting ||
      auth.userCanManageSkillInventory ||
      auth.userCanManageSkillAnalytics) &&
    !context.isChannel();
  const showDegreedSkills =
    user?.defaultOrgInfo?.settings.enableSkillsPlatform &&
    user?.defaultOrgInfo?.permissions.manageSkillsPlatform;

  configuration.switcherNavigation.items =
    configuration.switcherNavigation.items.reduce((results, item) => {
      let result = item;
      switch (item.productKey) {
        case 'lxp':
          item.subItems = item.subItems.reduce((results, subItem) => {
            let result = subItem;
            switch (subItem.featureKey) {
              case 'manage-lxp':
                result = showManageOrg ? result : null;
                break;
              case 'extended-enterprise':
                result = showExtendedEnterprise ? result : null;
                break;
              case 'advanced-analytics':
                result = showAnalytics ? result : null;
                break;
            }
            return result ? [...results, result] : results;
          }, []);
          break;
        case 'academies':
          result = showLearnIn ? result : null;
          break;
        case 'skills-platform':
          result = showDegreedSkills ? result : null;
          break;
      }

      return result ? [...results, result] : results;
    }, []);

  return configuration;
}

/**
 * Update the navigation items with the correct URLs
 */
function updateUrls(
  configuration: LayoutConfiguration_v2,
  user: AuthUser,
  featuredPlanId: number,
  learnInSSOUrl: string,
  analyticsUrl: string
) {
  const showFeatured =
    featuredPlanId &&
    !user?.isSkillAnalyticsClient &&
    !user?.isSkillInventoryClient;
  const visitor = (node, key) => {
    const text = () => node[key];
    switch (key) {
      case 'routerLink':
        node[key] = text().replace(
          '<featuredPlanId>',
          featuredPlanId.toString()
        );
        break;
      case 'href':
        node[key] = text()
          .replace('<analyticsUrl>', analyticsUrl)
          .replace('<learnInSSOUrl>', learnInSSOUrl);
        break;
      case 'text':
        // This replacement relies on i18n being updated beforehand
        node[key] = text().replace(
          '{{orgName}}',
          user?.defaultOrgInfo?.name || 'LXP'
        );
        break;
    }
  };

  if (!showFeatured) {
    configuration.navigation.top = configuration.navigation.top.filter(
      (item) => item.featureKey !== 'featured'
    );
  }

  return traverse(configuration, visitor);
}

/**
 * config = updateHelpMenu(config, organizationId, helpMenu)
 * Update the help menu items based on the organization's support info
 */
const updateHelpMenu = (
  configuration: LayoutConfiguration_v2,
  organizationId: number,
  supportInfo: OrganizationSupportInfo
) => {
  if (!organizationId) {
    supportInfo = {
      phone: '800.311.7061',
    };
  }

  const helpItem = configuration.navigation.bottom.find(
    (item) => item.featureKey === 'help'
  );
  helpItem.subItems = helpItem.subItems.reduce((results, item) => {
    let result = item;
    switch (item.featureKey) {
      case 'knowledge-center':
        result = supportInfo?.helpLink
          ? { ...result, href: supportInfo.helpLink }
          : null;
        break;
      case 'cookie-notice':
        result = supportInfo?.showCookieLink ? result : null;
        break;
      case 'faq':
        result = supportInfo?.faq ? { ...result, href: supportInfo.faq } : null;
        break;
      case 'custom-link':
        result = supportInfo?.customLink
          ? {
              ...result,
              text: supportInfo.customText,
              href: supportInfo.customLink,
            }
          : null;
        break;
      case 'phone':
        result = supportInfo?.phone
          ? {
              ...result,
              text: supportInfo.phone,
              href: `tel:${supportInfo.phone}`,
            }
          : null;
        break;
      case 'email':
        result =
          supportInfo?.email && !supportInfo?.email.includes('@degreed.com')
            ? {
                ...result,
                text: supportInfo.email,
                href: `mailto:${supportInfo.email}`,
              }
            : null;
        break;
    }

    return result ? [...results, result] : results;
  }, []);

  return configuration;
};

/**
 * For any `i18n` field, replace the associated field with translated value
 * @returns LayoutConfiguration_v2
 */
function updatei18n(
  configuration: LayoutConfiguration_v2,
  translate: TranslateService
): LayoutConfiguration_v2 {
  const MAPPINGS = {
    i18n: 'text',
    headerTitleI18n: 'headerTitle',
    buttonI18n: 'buttonText',
  };
  const i18n = i18nWithLog(translate);
  const visitor = (node, key) => {
    // For each node, check if it has an translation field
    switch (key) {
      case 'i18n':
      case 'headerTitleI18n':
      case 'buttonI18n':
        const field = MAPPINGS[key];
        node[field] = i18n(node[key], node[field]);
        break;
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Translation util that logs missing translations
 */
const i18nWithLog =
  (translate: TranslateService) => (key: string, fallback: string) => {
    let value = key ? translate.instant(key) : fallback;
    if (value === key || value === '') {
      console.error(`i18n: ${key} ==> ${value}, fallback: ${fallback}`);
      value = fallback;
    }

    return value || fallback;
  };

/**
 * Should we show the Search features in the header area? Since the Search content pages already
 * have their own search bar, we hide the global search bar when viewing the `/learning` pages.
 */
const watchNavigationEnd =
  (navigate$: Observable<{ url: string }>) =>
  (layout: LayoutConfiguration_v2): Observable<LayoutConfiguration_v2> => {
    return navigate$.pipe(
      map(({ url }) => {
        const isSearchVisible = layout.features.search?.visible;
        const showGlobalSearch = !url.includes('/learning');
        const shouldUpdate = isSearchVisible !== showGlobalSearch;

        layout = shouldUpdate
          ? produce(layout, (draft) => {
              if (isSearchVisible !== showGlobalSearch) {
                draft.features.search.visible = showGlobalSearch;
              }
            })
          : layout;

        return layout;
      }),
      distinctUntilChanged()
    );
  };

/**
 * Add analytics tracking to report clicks on the navigation items
 * @returns
 */
function addAnalyticTrackers(
  configuration: LayoutConfiguration_v2,
  trackerService: TrackerService
): LayoutConfiguration_v2 {
  // Build analytics data for tracking
  const makeTrackData = (key: string) => {
    return {
      category: '',
      label: '',
      action: 'Global Navigation Item Clicked',
      properties: { itemClicked: key },
    };
  };
  const visitor = (node, key, showLog = true) => {
    switch (key) {
      case 'analytics':
        const itemId = node.analytics;
        const defaultVal = node.text || '';

        // Inject callback tracking function
        node['trackEvent'] = () => {
          const msg = `analytics with key: ${itemId} for ${defaultVal} (${node.i18n || ''})`;

          if (showLog) console.log(`Report ${msg}`);
          if (!itemId)
            console.error(
              `Missing analytics key: ${itemId} for ${defaultVal} (${node.i18n || ''})`
            );

          trackerService.trackEventData(makeTrackData(itemId));
        };
        break;
    }
  };

  return traverse(configuration, visitor);
}

// *************************************************
// Tree Utils
// *************************************************

// Depth-first node tree scanning
const traverse = (node, visit: (node, key) => void) => {
  for (let key in node) {
    if (typeof node[key] === 'object') {
      traverse(node[key], visit);
    } else {
      visit(node, key);
    }
  }
  return node;
};
