import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrgInfo } from '@app/account/account-api.model';
import { AuthUser } from '@app/account/account-api.model';
import { OrgViewBaseComponent } from '@app/orgs/components/org-view-base/org-view-base.component';
import { RouterComponents } from '@app/orgs/constants';
import {
  ManagedGroup,
  OrgGroupsService,
} from '@app/orgs/services/org-groups.service';
import {
  DataColumn,
  DataColumnDate,
  DataColumnList,
} from '@app/shared/components/data-table/data-table.d';
import { AuthService } from '@app/shared/services/auth.service';
import { GroupService } from '@app/shared/services/group.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dgx-org-groups',
  templateUrl: './org-groups.component.html',
})
export class OrgGroupsComponent extends OrgViewBaseComponent implements OnInit {
  @ViewChild('groupName', { static: true })
  public groupNameTemplate: TemplateRef<any>;
  @ViewChild('actionLink', { static: true })
  public actionLinkTemplate: TemplateRef<any>;

  @ViewChild('visibilityTemplate', { static: true })
  public visibilityTemplate: TemplateRef<any>;

  @ViewChild('memberCountTemplate', { static: true })
  public memberCountTemplate: TemplateRef<any>;

  public authUser: AuthUser;
  public bulkEdit: boolean = false;
  public canManageGroups: boolean;
  public canSelectRows: boolean;
  public columns: (DataColumn | DataColumnDate | DataColumnList)[] = [];
  public groups: ManagedGroup[];
  public hasMoreItems: boolean;
  public isLoading: boolean = false;
  public orgInfo: OrgInfo;
  public propTrackingKey = 'groupId';
  public searchTerm: string;
  public skip: number = 0;
  public take: number = 20;

  public readonly CLASS_NAME = RouterComponents.ORG_GROUPS;

  public i18n = this.translateService.instant([
    'OrgGroupsCtrl_RequestPending',
    'OrgGroupsCtrl_Leave',
    'Core_Join',
    'OrgGroupsCtrl_Dynamic',
    'OrgGroupsCtrl_Static',
    'OrgGroupsCtrl_ThreeOrLess',
  ]);

  constructor(
    private authService: AuthService,
    private groupService: GroupService,
    private orgGroupsService: OrgGroupsService,
    private translateService: TranslateService
  ) {
    super();
  }

  public get selectedGroups() {
    return this.groups?.filter((group) => group.isSelected);
  }

  public get showBulkActions(): boolean {
    return this.selectedGroups?.length > 0;
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.orgInfo = this.authUser.orgInfo.find(
      (x) => x.organizationId === this.orgId
    );
    this.canManageGroups = this.orgInfo.permissions.manageGroups;
    this.columns = this.getColumns();
    this.doSearch();
    this.orgGroupsService.trackGroupAction({
      action: 'Org Groups Viewed',
    });
  }

  public doSearch(term?: string) {
    this.searchTerm = term;
    this.reset();
    this.getGroups();
    if (term) {
      this.orgGroupsService.trackGroupAction({
        action: 'Org Groups Searched',
      });
    }
  }

  public leaveGroup(group: ManagedGroup) {
    this.orgGroupsService
      .showLeaveGroupModal(group)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => this.doSearch(this.searchTerm));
  }

  public joinGroup(group: ManagedGroup) {
    this.groupService
      .join(
        {
          groupId: group.groupId,
          name: group.name,
          privacyLevel: group.privacyLevel,
          userCount: group.userCount,
          interestNames: null,
        },
        null
      )
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => this.doSearch(this.searchTerm));
  }

  public requestMembership(group: ManagedGroup) {
    return this.groupService
      .requestMembership({
        groupId: group.groupId,
        name: group.name,
        privacyLevel: group.privacyLevel,
        userCount: group.userCount,
      })
      .subscribe(() => this.doSearch(this.searchTerm));
  }

  public selectNone() {
    this.groups = this.groups.map((group) => ({
      ...group,
      isSelected: false,
    }));
  }

  public getGroups() {
    // prevent multiple requests
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    return this.orgGroupsService
      .groupsToManage(
        this.orgId,
        this.skip,
        this.take,
        this.searchTerm,
        this.canManageGroups
      )
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.groups =
          this.skip === 0
            ? response.groups
            : this.groups.concat(response.groups);
        this.hasMoreItems = response.hasMoreGroups;
        this.skip += this.take;
      });
  }

  public getAriaLabel(i18nKey: string, name: string) {
    return this.translateService.instant(i18nKey, { name });
  }

  public getMemberCountDisplay(group: ManagedGroup) {
    return group.membershipType === 'Dynamic' && group.userCount <= 3
      ? this.i18n.OrgGroupsCtrl_ThreeOrLess
      : group.userCount;
  }

  private getColumns(): (DataColumn | DataColumnDate | DataColumnList)[] {
    return [
      {
        label: 'OrgGroupsCtrl_GroupName',
        headClasses: 'l_w60',
        canSort: false,
        template: this.groupNameTemplate,
      } as DataColumn,
      {
        label: 'GroupSettingsCtrl_PrivacySettings',
        headClasses: 'l_w20',
        template: this.visibilityTemplate,
      },
      {
        label: 'OrgGroupsCtrl_GroupMembers',
        template: this.memberCountTemplate,
        headClasses: 'l_w15 right-text guts-p-r-5',
        bodyClasses: 'right-text guts-p-r-5',
      },
      {
        label: 'Core_Actions',
        headClasses: 'a11y-hide-text',
        template: this.actionLinkTemplate,
      },
    ];
  }

  private reset() {
    this.groups = [];
    this.skip = 0;
  }
}
