<form (ngSubmit)="onSubmit()" name="postForm" [formGroup]="postForm">
  <dgx-modal
    [canCancel]="true"
    [isLoading]="isLoading"
    [useDefaultForm]="false"
    [useDefaultSubmitButton]="false"
    [canCancel]="true"
    (dismiss)="confirmationModal()"
    bodyClasses="guts-p-h-2 form-wrap"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ header }}
    </ng-container>

    <!-- Modal Body -->
    <ng-container class="modal-body">
      <ng-container *ngIf="editing && (editMessage || clonedEditMessage)">
        <df-local-notification
          class="block guts-m-b-1-half"
          size="small"
          [text]="clonedEditMessage ? clonedEditMessage : editMessage"
          [type]="NotificationType.info"
        ></df-local-notification>
      </ng-container>

      <div class="grid grid--bleed">
        <div class="grid__col-12 guts-m-t-1 guts-p-b-2">
          <label for="title">Coach Name</label>

          <input
            type="text"
            class="df-text-input df-text-input-border"
            [placeholder]="'Type to search for a coach'"
            dgxAutofocus
            autocomplete="off"
            data-dgat="coachForm-ef3"
            [attr.aria-describedby]="''"
            formControlName="coachName"
            [ngbTypeahead]="search"
            [resultTemplate]="bookListItem"
            [resultFormatter]="formatBookResult"
            [inputFormatter]="formatBookResult"
            (selectItem)="onSelectCoach($event.item)"
          />
          <dgx-validate-field
            *ngIf="showTitleErrors"
            [message]="titleErrorMsg"
          ></dgx-validate-field>
        </div>
      </div>
      @if (selectedCoach) {
        <a class="l_flexbar" data-dgat="bookListItem-f24">
          <div>
            <img
              alt="{{
                'BookFormCtrl_BookCoverAltFormat'
                  | translate: { title: selectedCoach.title }
              }}"
              [src]="selectedCoach.imageUrl"
              class="tw-h-20 tw-w-20 tw-object-cover"
            />
          </div>
          <div class="l_flex-top">
            <div class="guts-p-l-1">
              <p class="guts-p-b-0 tw-text-sm tw-font-semibold">
                {{ selectedCoach.title }}
              </p>
              <p *ngIf="selectedCoach.subtitle" class="tw-text-xs">
                {{ selectedCoach.subtitle }}
              </p>
            </div>
          </div>
        </a>
      }
    </ng-container>
    <!-- Modal Footer -->
    <ng-container class="modal-footer">
      <!-- <button
        df-button
        dfButtonType="passive"
        class="guts-m-r-1"
        (click)="preview()"
        [disabled]="isSubmitDisabled"
        data-dgat="postForm-483"
      >
        {{ i18n.Core_Preview }}
      </button> -->
      <button
        df-button
        dfButtonType="primary"
        type="submit"
        [disabled]="isSubmitDisabled || isSubmitPending"
        [class.is_disabled]="isSubmitDisabled || isSubmitPending"
        [attr.aria-disabled]="isSubmitDisabled || isSubmitPending"
        [attr.aria-label]="
          isSubmitPending
            ? i18n.Core_Loading
            : editing
              ? i18n.PostFormCtrl_EditPostSave
              : i18n.PostFormCtrl_CreatePostSave
        "
        data-dgat="postForm-4a3"
      >
        <df-spinner-button [isSpinning]="isSubmitPending">
          {{ editing ? i18n.PostFormCtrl_EditPostSave : 'Save' }}
        </df-spinner-button>
      </button>
    </ng-container>
  </dgx-modal>
</form>

<ng-template #bookListItem let-item="result">
  <div class="listbox--option">
    <a class="l_flexbar" data-dgat="bookListItem-f24">
      <div>
        <img
          alt="{{
            'BookFormCtrl_BookCoverAltFormat' | translate: { title: item.title }
          }}"
          [src]="item.imageUrl"
          class="tw-h-20 tw-w-20 tw-object-cover"
        />
      </div>
      <div class="l_flex-top">
        <div class="guts-p-l-1">
          <p class="guts-p-b-0 tw-text-sm tw-font-semibold">{{ item.title }}</p>
          <p *ngIf="item.subtitle" class="tw-text-xs">
            {{ item.subtitle }}
          </p>
        </div>
      </div>
    </a>
  </div>
</ng-template>
