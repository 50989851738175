import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { DocumentTitleService } from '@app/shared/services/document-title/document-title.service';
import { AuthService } from './shared/services/auth.service';
import { EngagedLearnerService } from './shared/services/engaged-learner.service';
import { TrackerService } from './shared/services/tracker.service';
import { UserTourService } from './shared/services/user-tour.service';
import { VendorIntegrationsService } from './shared/services/vendor-integrations.service';
import { TagRatingService } from './tags/services/tag-rating.service';
import { A11yService } from './shared/services/a11y.service';

@Component({
  selector: 'dgx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @HostListener('document:mousedown')
  handleMouseClick() {
    this.a11yService.addMouseFocus();
  }

  @HostListener('document:keyup.tab')
  @HostListener('document:keyup.shift.tab')
  handleKeyUp() {
    this.a11yService.removeMouseFocus();
  }

  constructor(
    private documentTitleService: DocumentTitleService,
    private tagRatingService: TagRatingService,
    private engagedLearnerService: EngagedLearnerService,
    private userTourService: UserTourService,
    private authService: AuthService,
    private vendorIntegrationsService: VendorIntegrationsService,
    private trackerService: TrackerService,
    private a11yService: A11yService
  ) {}

  public ngOnInit(): void {
    // Set the document title tag value for a given Angular route
    // after the navigation ends is successful
    this.documentTitleService.register();

    // Some Tag rating email notification urls include properties to trigger modals
    this.tagRatingService.checkForRatingEmails();

    // Congratulate user for achieving engaged learner status
    this.engagedLearnerService.checkForEngagedLearnerStatusChange();

    // Start chameleon tour for registered orgs
    this.userTourService.loadTourIfEnabled();
  }

  ngAfterViewInit() {
    this.initializeQualtrics();
    this.initialAnalyticsTracking();
  }

  /**
   * Loads qualtrics script into DOM if login is valid and third party experience management isn't disabled.
   * If third party experience management is disabled, add an empty div to the dom for automated testing
   */
  private initializeQualtrics() {
    if (
      !this.authService.isLoggedIn ||
      this.authService.authUser?.defaultOrgInfo?.settings
        .disableThirdPartyExperienceManagement === true
    ) {
      const div = document.createElement('div');
      div.className = 'reduce-tracking-enabled';
      document.body.appendChild(div);
    } else {
      this.vendorIntegrationsService.loadQualtrics({
        orgId: this.authService.authUser.defaultOrgId,
        isRestricted: this.authService.authUser.isRestrictedViewerProfile,
        onboardDate: this.authService.authUser.viewerProfile.onboardDate,
        lastVisitDate: this.authService.authUser.viewerProfile.lastLogin,
      });
    }
  }

  private initialAnalyticsTracking() {
    this.trackerService.trackIdentify().subscribe();
  }
}
