import { Injectable } from '@angular/core';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { parseImageDataForBE } from '@app/user-content/user-input-v2/utils/image-data-utils';
import { getMappedInferredSkills } from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';
import { PathwayStep } from '@dg/pathways-rsm';
import { DurationConverterService } from '@dg/shared-services';
import {
  VideoApiInputEdit,
  VideoEntryApi,
  VideoMappingToAPI,
  VideoModel,
} from '../video.model';

@Injectable({
  providedIn: 'root',
})
export class VideoMapperService {
  constructor(
    private imageUrlService: ImageUrlService,
    private durationConverterService: DurationConverterService
  ) {}

  public toViewModel(
    source: VideoEntryApi,
    videoModel: VideoModel
  ): VideoModel {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    const addToCatalog = !(
      source.orgContentMetadata?.hideFromCatalog ?? !videoModel.addToCatalog
    );
    return {
      addToCatalog:
        !source.orgContentMetadata?.hideFromCatalog ?? videoModel.addToCatalog,
      accessible: !!source.accessible ?? !!videoModel.orgContentMetadata,
      createdBy: source.createdBy ?? videoModel.createdBy,
      durationHours: source.durationHours ?? videoModel.durationHours,
      durationMinutes: source.durationMinutes ?? videoModel.durationMinutes,
      entryUrl: source.entryUrl ?? videoModel.entryUrl,
      externalId: source.externalId ?? videoModel.externalId,
      file: source.hostedContentDetails
        ? {
            name: source.hostedContentDetails.fileName,
            size: source.hostedContentDetails.fileSize,
          }
        : undefined,
      fileManaged: source.fileManaged ?? videoModel.fileManaged,
      format: source.format ?? videoModel.format,
      groups,
      hasBrokenUrl: source.hasBrokenUrl,
      highConfidenceInferredSkills:
        getMappedInferredSkills(source?.highConfidenceInferredSkills) ??
        videoModel?.highConfidenceInferredSkills, // Note: BE returns a string[] that doesn't map to TagsApi.Tag[]
      hostedContentDetails: source.hostedContentDetails,
      // imageSizeId: source.imageSizeId,
      imageUrl: source.imageUrl,
      inputId: source.inputId,
      inputType: source.inputType,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      language: source.language,
      length: source.length,
      mediaLength: this.durationConverterService.fromSecondsToMinutes(
        source.mediaLength
      ),
      mediaType: source.mediaType,
      mediumConfidenceInferredSkills: getMappedInferredSkills(
        source.mediumConfidenceInferredSkills
      ), // Note: BE returns a string[] that doesn't map to TagsApi.Tag[]
      organizationId: source.organizationId,
      orgContentMetadata: {
        groupIds: groups,
        hideFromCatalog: !addToCatalog,
      }, // for On edit visibility
      owner: source.owner ? source.owner : videoModel.owner,
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      publishDate: source.publishDate,
      sourceName: source.sourceName,
      summary: source.summary,
      tags: source.tags ?? [],
      title: source.title,
      // useQuickCheck: !!source.useQuickCheck,
      //userMediaId: source.userMediaId, // only valid for user completions
    } as any as VideoModel;
  }

  // TODO: mapping params for api
  public toApiParameters(source: VideoModel): VideoMappingToAPI {
    return {
      accessible: !!source.accessible,
      comment: source.comment,
      entryUrl: source.entryUrl,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      externalId: source.externalId,
      format: source.format,
      hostedContentDetails:
        source.hostedContentDetails as HostedContentMetadata,
      // imageSizeId: source.imageSizeId,
      inputId: source.inputId,
      inputType: source.inputType,
      language: source.language,
      mediaLength: this.durationConverterService.fromMinutesToSeconds(
        source.mediaLength
      ),
      organizationId: source.orgContentMetadata?.hideFromCatalog
        ? null
        : source.organizationId,
      orgContentMetadata: {
        hideFromCatalog: source.orgContentMetadata.hideFromCatalog,
        groupIds: source.orgContentMetadata.groupIds ?? [],
      },
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      publishDate: source.publishDate,
      sourceName: source.sourceName,
      summary: source.summary,
      tags: source.tags,
      title: source.title,
      useQuickCheck: false, // TODO: currently videos this is always false check if global needs this or just delete!!source.useQuickCheck,
      // userMediaId: source.userMediaId, // only valid for user completions
      ...parseImageDataForBE(source, this.imageUrlService),
    };
  }

  /**
   * Called both when editing a fresh, never-before-edited local step
   * and when editing an already-edited step. Prefer properties from
   * the top-level where they exist (as top-level props will be the
   * non-internal step, if there's both a local and internal version,
   * and internal edits will never call this method), falling back to
   * the reference as necessary.
   *
   * @param reference - Reference parameter of our step.
   * @param step - All other step properties.
   * @param model - ViewModel.
   */
  public fromStepToViewModel(
    { reference, ...step }: PathwayStep,
    model: VideoModel
  ): VideoModel {
    const editEntry: VideoApiInputEdit = {
      entryUrl: reference.url,
      title: step.title ?? reference.title,
      sourceName: reference.source,
      summary: step.description ?? reference.summary,
      durationHours: step.durationHours ?? reference.durationHours,
      durationMinutes: step.durationMinutes ?? reference.durationMinutes,
      primaryContactResourceId: reference.primaryContactResourceId,
      primaryContactResourceType: reference.primaryContactResourceType,
      imageUrl: step.imageUrl ?? reference.imageUrl,
    } as unknown as VideoApiInputEdit;

    // Map response to view model
    return this.toViewModel(editEntry, model);
  }

  /**
   * Only called on detail edit. Update top-level step properties.
   *
   * @param source
   * @param step
   */
  public toStep(source: VideoModel, step: PathwayStep): VideoModel {
    return {
      ...source,
      ...step,
      description: source.summary,
      title: source.title,
      ...parseImageDataForBE(source, this.imageUrlService),
    } as unknown as VideoModel;
  }
}
