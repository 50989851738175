import {
  Injectable,
  ApplicationRef,
  createComponent,
  EnvironmentInjector,
  inject,
} from '@angular/core';
@Injectable()
export class CoachDynamicLoaderService {
  private componentRef: any;
  private appRef: ApplicationRef = inject(ApplicationRef);
  private environmentInjector: EnvironmentInjector =
    inject(EnvironmentInjector);

  public async loadCoachBuilder(
    coachId: string,
    pathwayId?: number
  ): Promise<void> {
    if (this.componentRef) {
      this.destroyComponent();
    }

    const { CoachBotComponent } = await import(
      '../components/coach-bot/coach-bot.component'
    );

    this.componentRef = createComponent(CoachBotComponent, {
      environmentInjector: this.environmentInjector,
    });

    this.componentRef.instance.coachId = coachId;
    this.componentRef.instance.pathwayId = pathwayId;

    this.appRef.attachView(this.componentRef.hostView);

    document.body.appendChild(this.componentRef.location.nativeElement);

    this.componentRef.instance.closed.subscribe(() => {
      this.destroyComponent();
    });
  }

  private destroyComponent(): void {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
