import { Injectable, Type } from '@angular/core';
import { InputAddType, InputShowFormParams } from '@app/inputs/inputs.model';
import { InputType } from '@app/shared/models/core-api.model';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalOptions, ModalService } from '@app/shared/services/modal.service';
import { TaskModalComponent } from '@app/user-content/user-input/task-modal/task-modal.component';
import { Observable } from 'rxjs';
import { PathwayEventService } from './pathway-event.service';
import { PathwayMediaService } from './pathway-media.service';

import {
  InputCreationFeedback,
  InputUpdateResult,
  UpdateInputParameters,
  UpdateTaskParameters,
} from '@app/inputs/inputs-api.model';
import { PathwayTaskService } from '@app/pathways/services/inputs/pathway-task.service';
import { InputsFacadeBase } from '@app/user-content/services/inputs-facade-base';
import { PostFormComponent } from '@app/user-content/user-input/post-form/post-form.component';
import { PathwayAssessmentService } from './pathway-assessment.service';
import { PathwayBookService } from './pathway-book.service';
import { PathwayCourseService } from './pathway-course.service';
import { PathwayEpisodeService } from './pathway-episode.service';
import { PathwayPostService } from './pathway-post.service';
import { CoachFormComponent } from '@app/user-content/user-input/coach-form/coach-form.component';

interface InputModalConfig {
  component: Type<any>;
  inputTypeService: any;
  domainService: InputsFacadeBase<any, any>;
}
type InlineInputSubmitter = (
  input: any
) => Observable<InputCreationFeedback | InputUpdateResult>;

export type AllPathwayInputParameters =
  | UpdateTaskParameters
  | UpdateInputParameters
  | InputShowFormParams;

/**
 * Provides web helper service for managing user input form modals used in Pathways and replaces the logic that was being done by ajs UserInputFormSvc in Pathways.
 * The modal components forms being used are shared with Global Add components.  This service handles showing the component input form based on the type requested and
 * creating the submit function that the type uses for submittal.  The submit processing is handled by the caller instead of the Global Add Component
 * this was done to keep the modals consistent with the existing pathway flow which closes the modal immediately.
 */
@Injectable({
  providedIn: 'root',
})
export class PathwayInputModalService {
  public submitFn: InlineInputSubmitter;

  // Create Config
  private readonly modalComponentConfig: Partial<
    Record<InputType, InputModalConfig>
  > = {
    Task: {
      component: TaskModalComponent,
      inputTypeService: this.pathwayTaskService,
      domainService: undefined,
    },
    Post: {
      component: PostFormComponent,
      inputTypeService: this.pathwayPostService,
      domainService: undefined,
    },
    Coach: {
      component: CoachFormComponent,
      inputTypeService: this.pathwayPostService,
      domainService: undefined,
    },
  };

  constructor(
    private modalService: ModalService,
    private pathwayMediaService: PathwayMediaService,
    private pathwayTaskService: PathwayTaskService,
    private pathwayBookService: PathwayBookService,
    private pathwayAssessmentService: PathwayAssessmentService,
    private pathwayEpisodeService: PathwayEpisodeService,
    private pathwayCourseService: PathwayCourseService,
    private pathwayEventService: PathwayEventService,
    private focusStackService: FocusStackService,
    private pathwayPostService: PathwayPostService
  ) {}

  public create({
    inputType,
    organizationId,
    canManageContent,
    isPathwayBinAdd,
    pathwayId,
    sourceTarget,
    modalOptions,
  }: Partial<InputShowFormParams>) {
    return this.showForm({
      inputType,
      organizationId,
      canManageContent,
      isPathwayBinAdd,
      pathwayId,
      sourceTarget,
      modalOptions,
    });
  }

  public edit({
    inputType,
    inputId,
    pathwayId,
    sourceTarget,
    isEditing,
    modalOptions,
  }: Partial<InputShowFormParams>) {
    return this.showForm({
      inputType,
      inputId,
      pathwayId,
      sourceTarget,
      isEditing,
      modalOptions,
    });
  }

  /**
   *
   * @param {InputShowFormParams} options
   * @returns {Observable<unknown>}
   */
  public showForm(options: InputShowFormParams) {
    const { modalOptions, sourceTarget, isEditing, ...inputs } = options;
    const modalComponentConfig = this.getModalComponentConfig(
      options.inputType
    );

    const submit = !isEditing
      ? modalComponentConfig.inputTypeService.addNewInput.bind(
          modalComponentConfig.inputTypeService
        )
      : modalComponentConfig.inputTypeService.updateInput.bind(
          modalComponentConfig.inputTypeService
        );

    inputs.load = isEditing
      ? modalComponentConfig.inputTypeService.getInput.bind(
          modalComponentConfig.inputTypeService
        )
      : undefined;

    this.submitFn = (input) => {
      return submit(input);
    };

    this.focusStackService.push(sourceTarget);

    const providedInputs = {
      inputs: { ...inputs, domainService: modalComponentConfig.domainService },
    };
    return this.showFormComponent(modalComponentConfig.component, {
      ...modalOptions, // restructure options for modal service
      ...providedInputs,
    });
  }

  /**
   * Gets the assigned component type for a particular type of input
   * @param {string} type
   * @returns Type<any>
   */
  private getModalComponentConfig(type: InputAddType): InputModalConfig {
    return this.modalComponentConfig[type];
  }

  /**
   * Uses the ngx version of ModalService to open a modal using a component
   * components should be defined by the `ModalComponent` mapping at the top of this file.
   *
   * @param {Type<any>} modalComponent
   * @param {ModalOptions} modalOptions
   * @returns Observable<AllUserMediaParametersViewModel>
   */
  private showFormComponent(
    modalComponent: Type<any>,
    modalOptions: ModalOptions
  ): Observable<AllPathwayInputParameters> {
    const size =
      modalOptions.inputs.inputType === 'Post' ? 'xlg-modal' : 'lg-modal';
    modalOptions = { windowClass: size, ...modalOptions };
    return this.modalService.show<AllPathwayInputParameters>(
      modalComponent,
      modalOptions
    );
  }
}
