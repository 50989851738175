import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  of,
} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { freeze } from 'immer';
import { SearchAutocompleteDataService } from './search-autocomplete.data-service';
import {
  SearchAutocompleteState,
  SearchAutocompleteViewModel,
} from './search-autocomplete.model';
import {
  SearchSubmitMethod,
  SearchTrackerService,
  SearchTypeaheadInitLocations,
} from '@app/search/services';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { TranslateService } from '@ngx-translate/core';
import {
  initState,
  shouldShowAutocomplete,
  shouldShowInitiation,
  shouldShowSuggestions,
  termIsMinLength,
} from './search-initiation-autocomplete.helpers';
import { SearchNavigationService } from '@app/shared/services';
import { SearchSuggestionSource } from '@app/search/search-api.model';

@Injectable()
export class SearchAutocompleteFacade {
  public vm$: Observable<SearchAutocompleteViewModel>;

  private defaultSuggestions$: Observable<ParentItemViewModel[]>;
  private state$: BehaviorSubject<SearchAutocompleteState>;

  constructor(
    private searchTrackerService: SearchTrackerService,
    private searchNavigationService: SearchNavigationService,
    private searchAutocompleteDataService: SearchAutocompleteDataService,
    private translateService: TranslateService
  ) {}

  public initializeVM(
    initialState: Partial<SearchAutocompleteState>
  ): Observable<SearchAutocompleteViewModel> {
    this.state$ = new BehaviorSubject<SearchAutocompleteState>({
      ...initState(),
      ...initialState,
    });

    this.defaultSuggestions$ = this.searchAutocompleteDataService
      .searchDefaultSuggestions()
      .pipe(shareReplay(1));

    return combineLatest([this.state$, this.defaultSuggestions$]).pipe(
      map(this.addViewModelData.bind(this)),
      map(this.addViewModelAPI.bind(this)),
      shareReplay<SearchAutocompleteViewModel>({ refCount: true }) // prevent triggering multiple HTTP requests
    );
  }

  /**
   * Update the search term and request suggestions if term is long enough
   */
  public async updateSearchTerm(searchTerm: string) {
    // Set the correct source for skills only search
    const source = this.state$.value.termSuggestionsOnly
      ? SearchSuggestionSource.AutoSuggestSkills
      : undefined;

    const suggestions$ =
      this.searchAutocompleteDataService.searchAutocompleteSuggestions(
        searchTerm,
        source
      );

    return await firstValueFrom(
      termIsMinLength(searchTerm) ? suggestions$ : of([])
    ).then((suggestions) => {
      this.updateState({
        searchTerm,
        suggestions,

        // need to reset natural language if the value changes
        queryResponse: null, // Natural Language POC
        references: null, // Natural Language POC
      });
    });
  }

  //// Natural Language POC
  public doNaturalLanguageSearch(query: string) {
    this.updateState({
      runningNLQuery: true,
      queryResponse: null,
      references: null,
    });

    let mode: 'init' | 'references' | 'message' = 'init';

    this.searchAutocompleteDataService
      .searchNaturalLanguage(query)
      .subscribe((message: string) => {
        let queryResponse = '';
        let references = this.state$.value.references;

        if (message.includes('___References___')) {
          mode = 'references';
          return;
        } else if (message.includes('___Response___')) {
          mode = 'message';
          return;
        } else if (message.includes('___END___')) {
          return;
        }

        switch (mode) {
          case 'references':
            references = JSON.parse(message);
            break;
          case 'message':
            queryResponse = message; //  message.replace(/<br\s*\/?>/gi, '');
        }

        queryResponse = !!this.state$.value.queryResponse
          ? this.state$.value.queryResponse + queryResponse
          : queryResponse;

        this.updateState({
          query,
          queryResponse,
          references,
          runningNLQuery: false,
        });
      });
  }

  /**
   * Patch the current state and optionally reset any properties
   * that aren't passed on the `state` argument
   *
   * @example
   * // update the `searchTerm`
   * this.facade.updateState({ searchTerm: 'foo' });
   *
   * // update the `searchTerm` and reset all other properties to defaults
   * this.facade.updateState({ searchTerm: 'foo'}, true);
   */
  public updateState(
    state: Partial<SearchAutocompleteState>,
    reset: boolean = false
  ) {
    this.state$.next({
      ...(reset ? initState() : this.state$.value),
      ...state,
    });
  }

  /**
   * Add computed properties to the VM
   */
  private addViewModelData([
    state,
    defaultSuggestions,
  ]): Observable<SearchAutocompleteState> {
    const initState = {
      ...state,
      defaultSuggestions,
    };

    // Iterate through mutator functions to add computed properties
    // Note that the order is important - computed properties added
    // by one mutator function may be used by a subsequent function
    return [
      shouldShowSuggestions,
      shouldShowInitiation,
      shouldShowAutocomplete,
    ].reduce(
      (state, mutator) => ({
        ...state,
        ...mutator(state),
      }),
      initState
    );
  }

  /**
   * Add API methods to the VM
   */
  private addViewModelAPI(
    state: SearchAutocompleteState
  ): SearchAutocompleteViewModel {
    const api = {
      updateSearchTerm: this.updateSearchTerm.bind(this),
      initiationSkillSelect: this.initiationSkillSelect.bind(this),
      initiationFilterSelect: this.initiationFilterSelect.bind(this),
      emptySearch: this.emptySearch.bind(this),
      emptyMarketplaceSearch: this.emptyMarketplaceSearch.bind(this),
      autosuggestSelect: this.autosuggestSelect.bind(this),
      typeSelect: this.typeSelect.bind(this),
      suggestionSelect: this.suggestionSelect.bind(this),
    };

    return freeze({ ...state, ...api }, true);
  }

  /**
   * Handle selection of skills in SearchInitiationComponent
   */
  private async initiationSkillSelect(suggestionVm: SimpleItemViewModel) {
    // Set the appropriate submit method for the `Search Executed` event
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.skillsClicked,
    });

    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await firstValueFrom(
      this.searchTrackerService.typeaheadClicked({
        searchTerm: undefined,
        type: suggestionVm.model.type || suggestionVm.model.resourceType,
        keywordsClicked: suggestionVm.title,
        typeaheadInitiationLocation: SearchTypeaheadInitLocations.navSearchBar,
        isCleanSearch: false,
      })
    ).then(() => {
      this.searchNavigationService.searchLearnings({
        term: suggestionVm.title,
        appliedFacets: [], // clear previous filters
      });
    });
  }

  /**
   * Handle selection of filters in SearchInitiationComponent
   */
  private async initiationFilterSelect(filter: string) {
    // Set the appropriate submit method for the `Search Executed` event
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.filtersClicked,
    });

    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await firstValueFrom(
      this.searchTrackerService.typeaheadClicked({
        searchTerm: undefined,
        type: filter,
        keywordsClicked: filter,
        typeaheadInitiationLocation: SearchTypeaheadInitLocations.navSearchBar,
        isCleanSearch: false,
        filter: [filter],
      })
    ).then(() => {
      this.searchNavigationService.searchLearnings({
        term: undefined, // clear previous term
        appliedFacets: [
          {
            id: 'Type',
            name: 'Type',
            values: [filter],
          },
        ],
      });
    });
  }

  /**
   * Handle selection of `Go to Search`
   */
  private async emptySearch() {
    // Set the appropriate submit method for the `Search Executed` event
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.typeaheadClicked,
    });

    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await firstValueFrom(
      this.searchTrackerService.typeaheadClicked({
        searchTerm: undefined,
        type: undefined,
        keywordsClicked: undefined,
        typeaheadInitiationLocation: SearchTypeaheadInitLocations.navSearchBar,
        isCleanSearch: true,
      })
    ).then(() => {
      this.searchNavigationService.searchLearnings({
        term: undefined,
        appliedFacets: [], // clear facets
      });
    });
  }

  /**
   * Handle selection of `Go to Marketplace`
   */
  public async emptyMarketplaceSearch() {
    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await firstValueFrom(
      this.searchTrackerService.typeaheadClicked({
        searchTerm: undefined,
        type: undefined,
        keywordsClicked: undefined,
        typeaheadInitiationLocation: SearchTypeaheadInitLocations.navSearchBar,
        isCleanSearch: true,
      })
    ).then(() => {
      this.searchNavigationService.searchLearnings({
        term: '',
        appliedFacets: [], // clear facets
        isMarketplaceCat: true,
        orgId: -2,
      });
    });
  }

  /**
   * Handle selection of autosuggested skills
   */
  private async autosuggestSelect(vm: SimpleItemViewModel) {
    const { initiationLocation } = this.state$.value;
    const isSearchPageInitiated =
      initiationLocation === SearchTypeaheadInitLocations.searchBar;
    const searchTerm = vm.title;

    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.skillsClicked,
    });

    // Init tracking w/current state value (previous searchTerm)
    const trackTypeaheadClicked = this.trackTypeaheadClicked(
      vm,
      this.state$.value
    );

    // Immediately update term
    this.updateState({ searchTerm });

    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await trackTypeaheadClicked.then(() => {
      // If user initiates from a search tab (not app header)
      // do search within context of that tab (Skills/Users/etc)
      if (isSearchPageInitiated) {
        return this.searchNavigationService.searchWithinContext(searchTerm);
      }

      // ...otherwise do a global search
      this.searchNavigationService.searchLearnings({
        term: searchTerm,
      });
    });
  }

  /**
   * Handle selection of `Type` header in SearchAutocompleteComponent
   */
  private async typeSelect(vm: SimpleItemViewModel) {
    if (!vm.allowNavigation) {
      return;
    }

    const { searchTerm } = this.state$.value;

    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await this.trackTypeaheadClicked(vm, this.state$.value).then(() => {
      this.searchWithinCategoryType(searchTerm, vm);
    });
  }

  /**
   * Handle selection of one of the `Type` subitems
   */
  private async suggestionSelect(vm: SimpleItemViewModel) {
    // Navigation may trigger page reload so don't navigate until tracking is complete
    return await this.trackTypeaheadClicked(vm, this.state$.value).then(() => {
      this.searchWithinSuggestionType(vm);
    });
  }

  private searchWithinCategoryType(term: string, vm: SimpleItemViewModel) {
    switch (vm.title) {
      case 'Core_Skills':
        return this.searchNavigationService.searchSkills(term);
      case 'LearningSearch_People':
        return this.searchNavigationService.searchPeople(term);
      case 'LearningSearch_Groups':
        return this.searchNavigationService.searchGroups(term);
      case 'Core_Opportunities':
        return this.searchNavigationService.searchOpportunities(term);
      default:
        // Plans/Pathways
        this.searchNavigationService.searchLearnings({
          term,
          appliedFacets: [
            {
              id: 'Type',
              name: 'Type',
              values: vm.types,
            },
          ],
        });
    }
  }

  private searchWithinSuggestionType(vm: SimpleItemViewModel) {
    switch (vm.model.type) {
      case 'Tag':
        return this.searchNavigationService.searchSkills(vm.title);
      case 'User':
        return this.searchNavigationService.searchPeople(vm.title);
      case 'Group':
        return this.searchNavigationService.searchGroups(vm.title);
      case 'Opportunity':
        return this.searchNavigationService.searchOpportunities(vm.title);
      case 'Pathway':
      case 'Target':
        return this.searchNavigationService.navigateToUrl(vm.model.url);
      default:
        // Learning types
        this.searchNavigationService.searchLearnings({
          term: vm.title,
          viewLearningId: vm.model.id,
        });
    }
  }

  private trackTypeaheadClicked(
    vm: SimpleItemViewModel,
    { searchTerm, initiationLocation }: SearchAutocompleteState
  ) {
    const type = vm.model?.type || vm.model?.resourceType || vm.types.join(',');
    return firstValueFrom(
      this.searchTrackerService.typeaheadClicked({
        searchTerm,
        type,
        keywordsClicked: this.translateService.instant(vm.title),
        typeaheadInitiationLocation: initiationLocation,
        isCleanSearch: false,
        filter: vm.types,
      })
    );
  }
}
