<div [ngClass]="wrapperClasses" class="grid__col-12 guts-p-h-0 guts-p-b-3">
  <!-- Navigation Tabs -->
  <!-- A11y Note:
    These clickable elements are intentionally NOT <button> or <a> tags.
    Using the tabkey to focus tabs will cause them to be scrolled into
    view so these are not needed for proper a11y handling.
  -->
  <nav
    #nav="ngbNav"
    ngbNav
    class="nav-tabs tabnav wrapper"
    [attr.aria-label]="i18n.A11y_SecondaryPagesNavType"
    [class.border-bottom]="!underlineSelectedTabOnly"
  >
    <df-icon
      *ngIf="showLeftArrow"
      (click)="scrollTabs($event, 'left')"
      class="js-scrollbutton tabnav__scrollbutton tabnav__scrollbutton--left"
      data-dgat="tabs-d86"
      icon="chevron-left"
      size="medium"
    ></df-icon>
    <div #scrollElement class="tabnav__list">
      <div
        #rla="routerLinkActive"
        *ngFor="let tab of tabList"
        [routerLinkActiveOptions]="{ exact: tab.isExact }"
        class="tabnav__item"
        data-dgat="tab-navigation-fec"
        routerLinkActive="is_active"
        ngbNavItem
      >
        <!-- Allow router params to be optional -->
        <a
          [attr.aria-current]="rla.isActive ? 'page' : null"
          [attr.aria-selected]="rla.isActive"
          [attr.data-dgat]="tab.dgat"
          [queryParams]="tab.queryParams"
          [queryParamsHandling]="tab.queryParamsHandling || queryParamsHandling"
          [routerLink]="
            tab.routerLinkParams
              ? [tab.routerLink, tab.routerLinkParams]
              : tab.routerLink
          "
          (click)="onTabSwitch($event, tab.label)"
          ngbNavLink
          class="tabnav__link"
          [class.nav-tabs__underlineSelectedTabOnly]="
            rla.isActive && underlineSelectedTabOnly
          "
          >{{ tab.label }}</a
        >
      </div>
    </div>
    <df-icon
      *ngIf="showRightArrow"
      (click)="scrollTabs($event, 'right')"
      class="js-scrollbutton tabnav__scrollbutton tabnav__scrollbutton--right"
      data-dgat="tabs-8db"
      icon="chevron-right"
      size="medium"
    ></df-icon>
  </nav>
</div>
