<dgx-modal
  [canCancel]="hideCancel ? false : true"
  [cancelButtonText]="cancelButtonText"
  [isHeaderBorderless]="!title || !description"
  [submitButtonText]="confirmButtonText"
  [useDefaultSubmitButton]="confirmButtonText ? true : false"
  (dismiss)="onDismiss()"
  submitButtonType="destructive"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <ng-container *ngIf="description">{{ title }}</ng-container>
  </ng-container>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div
      *ngIf="description; else titleAsDescription"
      [innerHTML]="description"
    ></div>
    <ng-template #titleAsDescription>
      <h3 id="dialogTitle" class="h3 center-text">{{ title }}</h3>
    </ng-template>
  </ng-container>
</dgx-modal>
