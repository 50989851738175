import { ElementRef, Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { FeedbackService } from '@app/feedback/services/feedback.service';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputPermissionsService } from '@app/inputs/services/input-permissions.service';
import { ConfirmMovePathwayModalComponent } from '@app/orgs/components/confirm-move-pathway-modal/confirm-move-pathway-modal.component';
import { OrgPathwaysService } from '@app/orgs/services/org-pathways.service';
import { AddToPathwayModalService } from '@app/pathways/services/add-to-pathway-modal.service';
import { PathwayAddEditFormModalService } from '@app/pathways/services/pathway-add-edit-form-modal-service';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { QueueService } from '@app/shared/services/queue.service';
import { TargetsService } from '@app/shared/services/targets.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { TargetService } from '@app/target/services/target.service';
import { Target } from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ResourceSectionType } from '@app/learner-home/learner-home-api.model';
import { EndorseContentService } from '../services/endorse-content.service';

@Injectable({ providedIn: 'root' })
export class ContentCardService {
  /* eslint-disable @typescript-eslint/member-ordering */
  public onUpdate$ = new Subject<LearningResourceViewModel>();

  private indexesOfExpandedCards: BehaviorSubject<number[]> =
    new BehaviorSubject<number[]>([]);
  public indexesOfExpandedCards$: Observable<number[]> =
    this.indexesOfExpandedCards.asObservable();

  private mostRecentIndexClicked: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);

  public mostRecentIndexClicked$: Observable<number> =
    this.mostRecentIndexClicked.asObservable();

  private lazyChunkIndex: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public lazyChunkIndex$: Observable<number> =
    this.lazyChunkIndex.asObservable();

  private sectionWithExpandedCards: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public sectionWithExpandedCards$: Observable<any> =
    this.sectionWithExpandedCards.asObservable();

  private numberOfColumnsOfCards: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public numberOfColumnsOfCards$: Observable<number> =
    this.numberOfColumnsOfCards.asObservable();

  public hasFeaturedPlan: boolean = false;
  public i18n = this.translateService.instant([
    'Core_SaveForLater',
    'Core_Unsave',
    'Core_Share',
    'Core_AddToPathway',
    'Core_AddToJobRole',
    'Core_ReportAProblem',
    'Core_Clone',
    'Core_YesSure',
    'Core_CannotBeUndone',
    'Core_WouldYouLikeToProceed',
    'Targets_MoveConfirmationMessage',
    'dgTagRating_RemoveFromPlan',
    'Core_FeatureResource',
    'Core_UnfeatureResource',
    'Core_MoveToGlobal',
  ]);
  /* eslint-enable @typescript-eslint/member-ordering */

  constructor(
    private authService: AuthService,
    private addToPathwayModalService: AddToPathwayModalService,
    private feedbackService: FeedbackService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private orgPathwaysService: OrgPathwaysService,
    private pathwayAddEditFormModalService: PathwayAddEditFormModalService,
    private sharedTargetService: SharedTargetService,
    private translateService: TranslateService,
    private targetService: TargetService,
    private targetsService: TargetsService,
    private recommendationsModalService: RecommendationsModalService,
    private queueService: QueueService,
    private inputPermissionsService: InputPermissionsService,
    private endorseContentService: EndorseContentService
  ) {
    const authUser = this.authService.authUser;
    if (authUser) {
      this.targetsService
        .getBrowseTarget(authUser.defaultOrgId)
        .subscribe((data) => (this.hasFeaturedPlan = !!data?.targetId));
    }
  }

  public setIndexesOfExpandedCards(indexes: number[]) {
    this.indexesOfExpandedCards.next(indexes);
  }

  public getIndexesOfExpandedCards(): Observable<number[]> {
    return this.indexesOfExpandedCards$;
  }

  public setMostRecentIndexClicked(index: number) {
    this.mostRecentIndexClicked.next(index);
  }

  public getMostRecentIndexClicked(): Observable<number> {
    return this.mostRecentIndexClicked$;
  }

  public setLazyChunkIndex(index: number) {
    this.lazyChunkIndex.next(index);
  }

  public getLazyChunkIndex(): Observable<number> {
    return this.lazyChunkIndex$;
  }

  public setSectionWithExpandedCards(section: any) {
    this.sectionWithExpandedCards.next(section);
  }

  public getSectionWithExpandedCards(): Observable<any> {
    return this.sectionWithExpandedCards$;
  }

  public setNumberOfColumnsOfCards(numberOfColumns: number) {
    this.numberOfColumnsOfCards.next(numberOfColumns);
  }

  public getNumberOfColumnsOfCards(): Observable<number> {
    return this.numberOfColumnsOfCards$;
  }

  public getColumnCountForCards(): number {
    // Depending on the location of the cards, the number of columns at different screen sizes may vary
    // The breakpoint for any classes prefixed with grid__col-lg is 1025px
    // The breakpoint for any classes prefixed with grid__col-md is 992px
    // The breakpoint for any classes prefixed with grid__col-lg sm 769px
    // The breakpoint for any classes prefixed with grid__col-lg xs 481px

    const path = window.location.pathname.split('/');
    const pages = ['profile', 'overview', 'collection', 'plan'];
    if (path.some((p) => pages.includes(p))) {
      if (window.innerWidth >= 1025) {
        return 4;
      } else if (window.innerWidth >= 769) {
        return 3;
      } else if (window.innerWidth >= 481) {
        return 2;
      }
      return 1;
    } else if (window.location.pathname.includes('dashboard')) {
      return window.innerWidth >= 769 ? 3 : 1;
    } else if (window.location.pathname.includes('profile')) {
      if (window.innerWidth >= 1025) {
        return 4;
      } else if (window.innerWidth >= 992) {
        return 3;
      } else if (window.innerWidth >= 769) {
        return 2;
      }
      return 1;
    } else if (window.location.pathname.includes('learning')) {
      if (window.innerWidth >= 992) {
        return 4;
      } else if (window.innerWidth >= 769) {
        return 3;
      } else if (window.innerWidth >= 481) {
        return 2;
      }
      return 1;
    } else if (window.location.pathname.includes('academies')) {
      if (window.innerWidth >= 992) {
        return 3;
      } else if (window.innerWidth >= 769) {
        return 3;
      } else if (window.innerWidth >= 481) {
        return 2;
      }
    }
  }

  public shouldCardExpand(
    cardIndex?: number,
    currentSection?: string | ResourceSectionType
  ): Observable<boolean> {
    return combineLatest([
      this.getIndexesOfExpandedCards(),
      this.getSectionWithExpandedCards(),
    ]).pipe(
      filter(
        ([expandedIndexes, expandedSection]) =>
          !!expandedIndexes && !!expandedSection
      ),
      map(([expandedIndexes, expandedSection]) => {
        const indexesOfCardsToExpand = expandedIndexes;
        const sectionWithExpandedCards = expandedSection;

        if (
          !!this.sectionWithExpandedCards &&
          indexesOfCardsToExpand.includes(cardIndex)
        ) {
          return sectionWithExpandedCards === currentSection;
        } else if (
          sectionWithExpandedCards &&
          currentSection !== sectionWithExpandedCards &&
          indexesOfCardsToExpand.includes(cardIndex)
        ) {
          return true;
        }
        return false;
      })
    );
  }

  // On the profile => saved and provile => shared tabs, content cards are lazy loaded in chunks of 5 cards at a time
  public shouldLazyLoadedCardExpand(
    itemIndex: number,
    lazyChunkIndex: number,
    section: string = null
  ): Observable<boolean> {
    return combineLatest([
      this.getIndexesOfExpandedCards(),
      this.getSectionWithExpandedCards(),
      this.getLazyChunkIndex(),
      this.getMostRecentIndexClicked(),
      this.getNumberOfColumnsOfCards(),
    ]).pipe(
      filter(
        ([
          expandedIndexes,
          expandedSection,
          expandedLazyChunkIndex,
          indexClicked,
          numberOfColumns,
        ]) => {
          return (
            !!expandedIndexes &&
            expandedLazyChunkIndex !== null &&
            expandedLazyChunkIndex !== undefined &&
            indexClicked !== null &&
            indexClicked !== undefined &&
            !!numberOfColumns
          );
        }
      ),
      map(
        ([
          expandedIndexes,
          expandedSection,
          expandedLazyChunkIndex,
          indexClicked,
          numberOfColumns,
        ]) => {
          const sectionWithExpandedCards = expandedSection;
          const indexesOfCardsToExpand = expandedIndexes;
          const lazyChunkIndexOfCardToExpand = expandedLazyChunkIndex;
          const mostRecentIndexClicked = indexClicked;
          const numberOfColumnsOfCards = numberOfColumns;
          const doesSectionExist = !!sectionWithExpandedCards;

          if (indexesOfCardsToExpand.includes(itemIndex)) {
            if (numberOfColumnsOfCards === 4) {
              if (lazyChunkIndexOfCardToExpand % 2 === 0) {
                switch (mostRecentIndexClicked) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                    const isCardWithinRowAndChunkToExpand =
                      (itemIndex === 0 ||
                        itemIndex === 1 ||
                        itemIndex === 2 ||
                        itemIndex === 3) &&
                      lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                    return doesSectionExist
                      ? isCardWithinRowAndChunkToExpand &&
                          sectionWithExpandedCards === section
                      : isCardWithinRowAndChunkToExpand;
                  case 4:
                  case 5:
                    const isCardWithinRowToExpandWithSameChunkIndex =
                      (itemIndex === 4 || itemIndex === 5) &&
                      lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                    const isCardWithinRowToExpandWithDifferentChunkIndex =
                      (itemIndex === 0 || itemIndex === 1) &&
                      lazyChunkIndexOfCardToExpand !== lazyChunkIndex;
                    return doesSectionExist
                      ? (isCardWithinRowToExpandWithSameChunkIndex ||
                          isCardWithinRowToExpandWithDifferentChunkIndex) &&
                          sectionWithExpandedCards === section
                      : isCardWithinRowToExpandWithSameChunkIndex ||
                          isCardWithinRowToExpandWithDifferentChunkIndex;
                  default:
                    return false;
                }
              } else if (lazyChunkIndexOfCardToExpand % 2 !== 0) {
                switch (mostRecentIndexClicked) {
                  case 0:
                  case 1:
                  case 4:
                  case 5:
                    const isCardWithinRowToExpandWithSameChunkIndex =
                      (itemIndex === 0 || itemIndex === 1) &&
                      lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                    const isCardWithinRowToExpandWithDifferentChunkIndex =
                      (itemIndex === 4 || itemIndex === 5) &&
                      lazyChunkIndexOfCardToExpand !== lazyChunkIndex;
                    return doesSectionExist
                      ? (isCardWithinRowToExpandWithSameChunkIndex ||
                          isCardWithinRowToExpandWithDifferentChunkIndex) &&
                          sectionWithExpandedCards === section
                      : isCardWithinRowToExpandWithSameChunkIndex ||
                          isCardWithinRowToExpandWithDifferentChunkIndex;
                  case 2:
                  case 3:
                    const isCardWithinRowAndChunkToExpand =
                      (itemIndex === 2 ||
                        itemIndex === 3 ||
                        itemIndex === 4 ||
                        itemIndex === 5) &&
                      lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                    return doesSectionExist
                      ? isCardWithinRowAndChunkToExpand &&
                          sectionWithExpandedCards === section
                      : isCardWithinRowAndChunkToExpand;
                  default:
                    return false;
                }
              }
            } else if (numberOfColumnsOfCards === 3) {
              let isCardWithinRowAndChunkToExpand;
              switch (mostRecentIndexClicked) {
                case 0:
                case 1:
                case 2:
                  isCardWithinRowAndChunkToExpand =
                    (itemIndex === 0 || itemIndex === 1 || itemIndex === 2) &&
                    lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                  return doesSectionExist
                    ? isCardWithinRowAndChunkToExpand &&
                        sectionWithExpandedCards === section
                    : isCardWithinRowAndChunkToExpand;
                case 3:
                case 4:
                case 5:
                  isCardWithinRowAndChunkToExpand =
                    (itemIndex === 3 || itemIndex === 4 || itemIndex === 5) &&
                    lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                  return doesSectionExist
                    ? isCardWithinRowAndChunkToExpand &&
                        sectionWithExpandedCards === section
                    : isCardWithinRowAndChunkToExpand;
                default:
                  break;
              }
            } else if (numberOfColumnsOfCards === 2) {
              let isCardWithinRowAndChunkToExpand;
              switch (mostRecentIndexClicked) {
                case 0:
                case 1:
                  isCardWithinRowAndChunkToExpand =
                    (itemIndex === 0 || itemIndex === 1) &&
                    lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                  return doesSectionExist
                    ? isCardWithinRowAndChunkToExpand &&
                        sectionWithExpandedCards === section
                    : isCardWithinRowAndChunkToExpand;
                case 2:
                case 3:
                  isCardWithinRowAndChunkToExpand =
                    (itemIndex === 2 || itemIndex === 3) &&
                    lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                  return doesSectionExist
                    ? isCardWithinRowAndChunkToExpand &&
                        sectionWithExpandedCards === section
                    : isCardWithinRowAndChunkToExpand;
                case 4:
                case 5:
                  isCardWithinRowAndChunkToExpand =
                    (itemIndex === 4 || itemIndex === 5) &&
                    lazyChunkIndexOfCardToExpand === lazyChunkIndex;
                  return doesSectionExist
                    ? isCardWithinRowAndChunkToExpand &&
                        sectionWithExpandedCards === section
                    : isCardWithinRowAndChunkToExpand;
                default:
                  break;
              }
            } else if (numberOfColumnsOfCards === 1) {
              const isCardWithinRowAndChunkToExpand =
                mostRecentIndexClicked === itemIndex &&
                lazyChunkIndexOfCardToExpand === lazyChunkIndex;
              return doesSectionExist
                ? isCardWithinRowAndChunkToExpand &&
                    sectionWithExpandedCards === section
                : isCardWithinRowAndChunkToExpand;
            }
          }
          return false;
        }
      )
    );
  }

  public getMenuConfig({
    content,
    authUser,
    userRecommendationType,
    modifyOptionsFn,
    sectionType,
  }: {
    content: LearningResourceViewModel;
    authUser?: AuthUser;
    userRecommendationType?: UserRecommendationType;
    modifyOptionsFn?: ModifyOptionsFnType<LearningResourceViewModel>;
    sectionType?: string;
  }): MenuViewModel[] {
    if (this.isRequired(userRecommendationType)) {
      return [
        {
          title: this.i18n.Core_ReportAProblem,
          isHidden: () => {
            return !authUser;
          },
          defaultAction: (_, popoverTrigger) => {
            this.feedbackService
              .showReportProblemModal(
                {
                  itemType:
                    this.feedbackService.getFeedbackItemTypeFromResource(
                      content.resourceType
                    ),
                  itemId: content.resourceId,
                  contextType:
                    this.feedbackService.feedbackContextType.RequiredLearning,
                  sectionType,
                  contextId: '',
                },
                popoverTrigger.nativeElement
              )
              .subscribe();
          },
        },
      ];
    } else {
      const defaultConfig = [
        {
          title: this.i18n.Core_MoveToGlobal,
          isHidden: () => {
            if (
              content.isPathway ||
              (content.isTarget && !content.targetIsDirectory)
            ) {
              const userHasPermission = content.isPathway
                ? authUser?.hasChannelManagePathwayPermission
                : authUser?.hasChannelManageTargetPermission;
              if (userHasPermission) {
                const org = authUser.defaultOrgInfo;
                const isTenantOrgContent =
                  org &&
                  org.type === 'Tenant' &&
                  org.organizationId === content.organizationId;
                const hasProvider = !!content?.providerSummary?.id;
                return !isTenantOrgContent || hasProvider;
              } else {
                return true;
              }
            } else {
              return true;
            }
          },
          defaultAction: () => {
            this.moveToGlobal(content);
          },
        },
        {
          title: this.i18n.Core_Clone,
          isHidden: () =>
            !authUser ||
            (!content?.isTarget && !content?.isPathway) ||
            (content?.isTarget && !authUser?.canManageTargets),
          defaultAction: (_, popoverTrigger) =>
            content.isTarget
              ? this.cloneTarget(content, popoverTrigger)
              : this.clonePathway(content, popoverTrigger),
        },
        {
          title: this.i18n.Core_SaveForLater,
          isHidden: () => {
            return (
              content.isTarget ||
              content.completionInfo?.isCompleted ||
              content.isQueued
            );
          },
          defaultAction: (event) => {
            this.saveForLater(content, event.target as HTMLElement);
          },
        },
        {
          title: this.i18n.Core_Unsave,
          isHidden: () => {
            return (
              content.isTarget ||
              content.completionInfo?.isCompleted ||
              !content.isQueued
            );
          },
          defaultAction: (event) => {
            this.unSave(content, event.target as HTMLElement);
          },
        },
        {
          title: this.i18n.Core_Share,
          isHidden: () => {
            return !authUser?.canRecommendItems || content.isInputCollection;
          },
          defaultAction: (_, popoverTrigger) => {
            this.showShareModal(content, popoverTrigger);
          },
        },

        {
          title: this.i18n.Core_AddToPathway,
          isHidden: () =>
            !authUser ||
            !this.inputPermissionsService.canAddToPathway(
              content.resourceType
            ) ||
            content.isInputCollection,
          defaultAction: (event, popoverTrigger) => {
            this.addToPathwayModalService
              .showAddToPathwayForm(
                content.resourceId,
                content.resourceType,
                content.organizationId,
                content.isEndorsed,
                popoverTrigger.nativeElement
              )
              .subscribe();
          },
        },
        {
          title: this.i18n.Core_AddToJobRole,
          isHidden: () =>
            !authUser ||
            !this.inputPermissionsService.canAddToTarget(content.resourceType),
          defaultAction: (_, { nativeElement }) => {
            this.targetService
              .showAddToTargetModal('JobRole', content.model, nativeElement)
              .subscribe();
          },
        },
        {
          title: this.i18n.Core_FeatureResource,
          isHidden: () =>
            !this.canFeatureContent(content, authUser) ||
            content.isFeatured ||
            this.hasFeaturedPlan,

          defaultAction: () => this.featureContent(content),
        },
        {
          title: this.i18n.Core_UnfeatureResource,
          isHidden: () =>
            !this.canFeatureContent(content, authUser) ||
            !content.isFeatured ||
            this.hasFeaturedPlan,

          defaultAction: () => this.unFeatureContent(content),
        },
        this.endorseContentService.endorseContentMenuOption(content),
        this.endorseContentService.removeEndorsementContentMenuOption(content),
        {
          title: this.i18n.Core_ReportAProblem,
          isHidden: () => {
            return !authUser;
          },
          defaultAction: (_, popoverTrigger) => {
            this.feedbackService
              .showReportProblemModal(
                {
                  itemType:
                    this.feedbackService.getFeedbackItemTypeFromResource(
                      content.resourceType
                    ),
                  itemId: content.resourceId,
                  sectionType,
                },
                popoverTrigger.nativeElement
              )
              .subscribe();
          },
        },
      ];
      return modifyOptionsFn
        ? modifyOptionsFn(content, defaultConfig)
        : defaultConfig;
    }
  }

  public isRequired(type) {
    return type === UserRecommendationType.RequiredLearning;
  }

  public showShareModal(
    content: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ) {
    return (
      this.recommendationsModalService
        // linter error,
        //  Argument of type 'ProviderLearningResource' is not assignable to parameter of type 'RecommendingItem'.
        .showShareModal(content.model as any, popoverTrigger?.nativeElement)
        .subscribe()
    );
  }

  public canFeatureContent(
    content: LearningResourceViewModel,
    authUser: AuthUser
  ) {
    return (
      authUser &&
      (content.isPathway || content.isTarget) &&
      authUser.canFeaturePathways &&
      content.organizationId &&
      (content.privacyLevel === 1 ||
        (content.privacyLevel as any) === 'Public' ||
        content.privacyLevel === 2 ||
        (content.privacyLevel as any) === 'Group')
    );
  }
  private saveForLater(
    content: LearningResourceViewModel,
    element: HTMLElement
  ): void {
    this.queueService
      .post(
        content.resourceType,
        content.resourceId,
        null,
        content,
        null,
        element
      )
      .subscribe((queueItemId) => {
        content.queueItemId = queueItemId;
        content.isQueued = true;
        this.onUpdate$.next(content);
        this.notifierService.showSuccess(
          this.translateService.instant('Pathways_ItemSavedFormat', {
            title: content.title,
          })
        );
      });
  }

  private unSave(content: LearningResourceViewModel, element: HTMLElement) {
    return this.queueService
      .deleteItem(
        content.queueItemId,
        content.resourceType,
        content.resourceId,
        null,
        null,
        element
      )
      .subscribe(() => {
        content.queueItemId = null;
        content.isQueued = false;
        this.onUpdate$.next(content);
      });
  }

  private clonePathway(
    content: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ) {
    const modalOptions = {
      pathId: content.resourceId,
      forCloning: true,
      itemToAdd: null,
      preventRedirect: undefined,
      isChannel: false,
      disableEndorsedOption: false,
      sourceTarget: popoverTrigger.nativeElement,
    };
    this.pathwayAddEditFormModalService.openModal(modalOptions).subscribe();
  }

  private cloneTarget(
    content: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ) {
    return this.targetService
      .showTargetCloneModal({
        target: content.model as unknown as Target, // we will have a target at this point
        context: 'default',
      })
      .subscribe();
  }
  private featureContent(content: LearningResourceViewModel) {
    if (content.isPathway) {
      return this.orgPathwaysService
        .featurePathway(content.resourceId)
        .subscribe(() => {
          content.isFeatured = true;
        });
    } else if (content.isTarget) {
      return this.sharedTargetService
        .featureTarget(content.resourceId, true)
        .pipe(finalize(() => (content.isFeatured = true)));
    }
  }
  private unFeatureContent(content: LearningResourceViewModel) {
    if (content.isPathway) {
      return this.orgPathwaysService
        .unfeaturePathway(content.resourceId)
        .subscribe(() => {
          content.isFeatured = false;
        });
    } else if (content.isTarget) {
      return this.sharedTargetService
        .featureTarget(content.resourceId, false)
        .pipe(finalize(() => (content.isFeatured = false)));
    }
  }

  private moveToGlobal(content: LearningResourceViewModel) {
    if (content.isTarget && !content.targetIsDirectory) {
      const inputs: SimpleModalInputBindings = {
        canCancel: true,
        submitButtonText: this.i18n.Core_YesSure,
        headerText: this.i18n.Core_WouldYouLikeToProceed,
        bodyText: this.i18n.Targets_MoveConfirmationMessage,
        secondaryBodyText: this.i18n.Core_CannotBeUndone,
        secondaryBodyClasses: 'color-destructive font-semibold',
      };

      return this.modalService
        .show(SimpleModalComponent, { inputs })
        .pipe(
          switchMap(() => {
            return this.sharedTargetService.moveTargetToChannel(
              content.resourceId
            );
          })
        )
        .subscribe();
    } else if (content.isPathway) {
      this.modalService
        .show(ConfirmMovePathwayModalComponent, {
          inputs: { content },
        })
        .pipe(
          switchMap(() => {
            return this.orgPathwaysService.movePathwayToChannel(
              content.resourceId
            );
          })
        )
        .subscribe();
    }
  }

  public expandCard(titleElement, title, tileIndex, lazyChunkIndex) {
    let showEllipsis;
    const numberOfColumns = this.getColumnCountForCards();
    this.setNumberOfColumnsOfCards(numberOfColumns);

    const newIndexesOfCardsToExpand = this.getNewIndexesOfCardsToExpand(
      numberOfColumns,
      tileIndex,
      lazyChunkIndex
    );

    let indexesOfPreviouslyExpandedCards: number[] = [];

    this.getIndexesOfExpandedCards()
      .pipe()
      .subscribe((indexes) => (indexesOfPreviouslyExpandedCards = indexes));

    if (indexesOfPreviouslyExpandedCards.length === 0) {
      titleElement.style.overflow = 'visible';
      showEllipsis = false;
    }

    this.setIndexesOfExpandedCards(newIndexesOfCardsToExpand);

    showEllipsis =
      title.nativeElement.clientHeight < title.nativeElement.scrollHeight;

    return [showEllipsis, newIndexesOfCardsToExpand];
  }

  public getNewIndexesOfCardsToExpand(
    numberOfColumns: number,
    tileIndex: number,
    lazyChunkIndex: number
  ): number[] {
    if (numberOfColumns === 4) {
      if (lazyChunkIndex !== null && lazyChunkIndex !== undefined) {
        if (lazyChunkIndex % 2 === 0) {
          switch (tileIndex) {
            case 0:
            case 1:
            case 2:
            case 3:
              return [0, 1, 2, 3];
            case 4:
            case 5:
              return [4, 5, 0, 1];
            default:
              return [tileIndex];
          }
        } else if (lazyChunkIndex % 2 !== 0) {
          switch (tileIndex) {
            case 0:
            case 1:
              return [4, 5, 0, 1];
            case 2:
            case 3:
            case 4:
            case 5:
              return [2, 3, 4, 5];
            default:
              return [tileIndex];
          }
        }
      } else if (tileIndex % 4 === 0) {
        return [tileIndex, tileIndex + 1, tileIndex + 2, tileIndex + 3];
      } else if ((tileIndex - 1) % 4 === 0) {
        return [tileIndex - 1, tileIndex, tileIndex + 1, tileIndex + 2];
      } else if ((tileIndex - 2) % 4 === 0) {
        return [tileIndex - 2, tileIndex - 1, tileIndex, tileIndex + 1];
      } else if ((tileIndex - 3) % 4 === 0) {
        return [tileIndex - 3, tileIndex - 2, tileIndex - 1, tileIndex];
      }
    } else if (numberOfColumns === 3) {
      if (lazyChunkIndex !== null && lazyChunkIndex !== undefined) {
        switch (tileIndex) {
          case 0:
          case 1:
          case 2:
            return [0, 1, 2];
          case 3:
          case 4:
          case 5:
            return [3, 4, 5];
          default:
            break;
        }
      } else if (tileIndex % 3 === 0) {
        return [tileIndex, tileIndex + 1, tileIndex + 2];
      } else if ((tileIndex - 1) % 3 === 0) {
        return [tileIndex - 1, tileIndex, tileIndex + 1];
      } else if ((tileIndex - 2) % 3 === 0) {
        return [tileIndex - 2, tileIndex - 1, tileIndex];
      }
    } else if (numberOfColumns === 2) {
      if (lazyChunkIndex !== null && lazyChunkIndex !== undefined) {
        switch (tileIndex) {
          case 0:
          case 1:
            return [0, 1];
          case 2:
          case 3:
            return [2, 3];
          case 4:
          case 5:
            return [4, 5];
          default:
            break;
        }
      } else if (tileIndex % 2 === 0) {
        return [tileIndex, tileIndex + 1];
      } else if ((tileIndex - 1) % 2 === 0) {
        return [tileIndex - 1, tileIndex];
      }
    }
    return [tileIndex];
  }

  public resizeCardsByIndexes(
    currentIndexes: number[],
    newIndexesOfCardsToExpand: number[],
    elementId: string
  ) {
    if (currentIndexes.length === 0) {
      // If - no cards in any rows have been expanded yet, and the clicked row should expand
      const elementsToExpand: HTMLElement[] = newIndexesOfCardsToExpand.map(
        (index) => document.getElementById(`${elementId}${index}`)
      );
      elementsToExpand.map((element: HTMLElement) =>
        element?.style.setProperty('max-height', 'none', 'important')
      );
    } else {
      // Else - new cards in the newly clicked row should be expanded and old row of cards should return to default size
      const elementsToShrink: HTMLElement[] = currentIndexes.map((index) =>
        document.getElementById(`${elementId}${index}`)
      );
      const elementsToExpand: HTMLElement[] = newIndexesOfCardsToExpand.map(
        (index) => document.getElementById(`${elementId}${index}`)
      );

      elementsToShrink.map((element: HTMLElement) =>
        element?.style.setProperty('max-height', 'revert')
      );
      elementsToExpand.map((element: HTMLElement) =>
        element?.style.setProperty('max-height', 'none', 'important')
      );
    }

    return newIndexesOfCardsToExpand;
  }
}
