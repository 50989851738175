import { Inject, Injectable } from '@angular/core';
import { MediaEntryApiEntity, RenderMode } from '../user-input.model';
import { EventApiEntity } from '../event-modal/repository/user-event.entity.model';
import { ActionFeedback } from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { Repository } from '@app/shared/patterns/repository/repository';
import { InputsRepository } from '@app/shared/patterns/repository/inputs-repository';
import {
  USER_EVENT_REPOSITORY,
  USER_ARTICLE_REPOSITORY,
  USER_VIDEO_REPOSITORY,
  USER_COURSE_REPOSITORY,
  USER_EPISODE_REPOSITORY,
  USER_BOOK_REPOSITORY,
  USER_ASSESSMENT_REPOSITORY,
  USER_EXPERIENCE_REPOSITORY,
} from '@app/user-content/user-input/user-input-repo.tokens';
import { UserInputsRepository } from '@app/shared/patterns/repository/user-inputs-repository';
import {
  EVENT_REPOSITORY,
  ARTICLE_REPOSITORY,
  VIDEO_REPOSITORY,
  COURSE_REPOSITORY,
  BOOK_REPOSITORY,
  ASSESSMENT_REPOSITORY,
  EPISODE_REPOSITORY,
} from '../input-repo.tokens';
import { CourseApiEntity } from '../course-form/repository/course.entity.model';
import { BookApiEntity } from '../book-forms/repository/book.entity.model';
import { ExperienceApiEntity } from '../experience-modal/model/experience-form.model';
import { EpisodeApiEntity } from '../episode-forms/repository/episode.entity.model';
import { ObsoleteOrgInputsParameters } from '@app/orgs/services/orgs.model';
import { CoachApiEntity } from '../coach-forms/repository/coach.entity.model';

@Injectable({ providedIn: 'root' })
export class RepositoryFactoryService {
  constructor(
    // Event
    @Inject(EVENT_REPOSITORY)
    private eventInputsRepository: InputsRepository<EventApiEntity>,
    @Inject(USER_EVENT_REPOSITORY)
    private eventUserInputsRepository: UserInputsRepository<EventApiEntity>,
    // Article
    @Inject(ARTICLE_REPOSITORY)
    private articleInputsRepository: InputsRepository<MediaEntryApiEntity>,
    @Inject(USER_ARTICLE_REPOSITORY)
    private articleUserInputsRepository: UserInputsRepository<MediaEntryApiEntity>,
    @Inject(VIDEO_REPOSITORY)
    private videoInputsRepository: InputsRepository<MediaEntryApiEntity>,
    @Inject(USER_VIDEO_REPOSITORY)
    private videoUserInputsRepository: UserInputsRepository<MediaEntryApiEntity>,
    @Inject(COURSE_REPOSITORY)
    private courseInputsRepository: InputsRepository<CourseApiEntity>,
    @Inject(USER_COURSE_REPOSITORY)
    private courseUserInputsRepository: UserInputsRepository<CourseApiEntity>,
    @Inject(EPISODE_REPOSITORY)
    private episodeInputsRepository: InputsRepository<EpisodeApiEntity>,
    @Inject(USER_EPISODE_REPOSITORY)
    private episodeUserInputsRepository: UserInputsRepository<EpisodeApiEntity>,
    @Inject(BOOK_REPOSITORY)
    private bookInputsRepository: InputsRepository<BookApiEntity>,
    @Inject(USER_BOOK_REPOSITORY)
    private bookUserInputsRepository: UserInputsRepository<BookApiEntity>,
    // Assessment
    @Inject(ASSESSMENT_REPOSITORY)
    private assessmentInputsRepository: InputsRepository<CourseApiEntity>,
    @Inject(USER_ASSESSMENT_REPOSITORY)
    private assessmentUserInputsRepository: UserInputsRepository<CourseApiEntity>,
    @Inject(USER_EXPERIENCE_REPOSITORY)
    private experienceRepository: UserInputsRepository<ExperienceApiEntity>,
    @Inject(USER_EXPERIENCE_REPOSITORY)
    private coachRepository: UserInputsRepository<CoachApiEntity>
  ) {}

  public getRepository<
    TPayload,
    TResult extends ActionFeedback<any, any> = ActionFeedback<any, any>,
  >(
    inputType: InputType,
    mode: RenderMode
  ): Repository<TPayload, TResult, ObsoleteOrgInputsParameters<TPayload>> {
    switch (inputType.toLocaleLowerCase()) {
      case 'event':
        return (mode === RenderMode.UserProfile
          ? this.eventUserInputsRepository
          : this.eventInputsRepository) as unknown as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'article':
        return (mode === RenderMode.UserProfile
          ? this.articleUserInputsRepository
          : this.articleInputsRepository) as unknown as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'video':
        return (
          mode === RenderMode.UserProfile
            ? this.videoUserInputsRepository
            : (this.videoInputsRepository as unknown)
        ) as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'course':
        return (
          mode === RenderMode.UserProfile
            ? this.courseUserInputsRepository
            : (this.courseInputsRepository as unknown)
        ) as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'book':
        return (
          mode === RenderMode.UserProfile
            ? this.bookUserInputsRepository
            : (this.bookInputsRepository as unknown)
        ) as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'assessment':
        return (
          mode === RenderMode.UserProfile
            ? this.assessmentUserInputsRepository
            : (this.assessmentInputsRepository as unknown)
        ) as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'position':
        return this.experienceRepository as unknown as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'episode':
        return (
          mode === RenderMode.UserProfile
            ? this.episodeUserInputsRepository
            : (this.episodeInputsRepository as unknown)
        ) as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      case 'coach':
        return this.coachRepository as unknown as Repository<
          TPayload,
          TResult,
          ObsoleteOrgInputsParameters<TPayload>
        >;
      default:
        throw new Error('No repository for input type');
    }
  }
}
