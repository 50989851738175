// core
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// services
import { AuthService } from '@app/shared/services/auth.service';
import { DateRangeService } from '@app/shared/services/date-range.service';
import { FilterService } from '@app/shared/services/filter.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamFlagsService } from './team-flags.service';
import { LocalizationService } from '@app/shared/localization.service'

// types/models
import { Filter } from '@app/shared/components/filter/filter.component';
import { InsightsFilterType } from '@app/team/team.enums';
import { InputDetails } from '@app/inputs/inputs-api.model';
import {
  GetSkillDetailsManagerRating,
  ProfileSkillStates,
  UserSkillDetailsManagerRating,
  UserSkillSources,
} from '@app/groups/group-api';
import {
  GetManagersOfManager,
  LearningCompletionDetails,
  ManagerDirectReport,
  TeamPopularTag,
  TeamTagRelationship,
} from '../team.model';
import { Facet } from '@app/embedded-learning/embedded-learning-api.model';
import {
  BaseInsightsContent,
  GetTopLearnerInsights,
  KPI,
} from '@app/insights/insights-api.model';
import { UserProfileSummary } from '@app/user/user-api.model';

//util
import { formatInsightsDateLabels } from '@app/insights/utils'

// interfaces
export interface TeamTopTagRatingsGetResponse {
  hasMoreItems: boolean;
  items: any[];
  facets?: any[];
  filters?: Filter[];
  users?: any[];
  managerFocusTagIds?: any[];
}

export enum LearningInsightsTabIds {
  ToDo = 'todo',
  Completed = 'completed',
}

export interface TeamRecommendation {
  alreadyCompleted?: boolean;
  dateCreated: string;
  isDueLater?: boolean;
  isDueSoon?: boolean;
  isOverdue?: boolean;
  recommendationId: number;
  referenceInfo: InputDetails;
  recommendationType: string;
  userProfileKey: number;
  usersCompleted: number;
  usersPending: number;
  userInfo: {
    firstName: string;
    lastName: string;
    name: string;
    userProfileKey: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  // Public
  public readonly managersBaseUrl: string = '/managers';

  public i18n = this.translateService.instant([
    'Team_FocusLabel',
    'Core_Source',
    'Core_Skills',
  ]);

  constructor(
    private authService: AuthService,
    private filterService: FilterService,
    private http: NgxHttpClient,
    private translateService: TranslateService,
    private trackerService: TrackerService,
    private dateRangeService: DateRangeService,
    private teamFlagsService: TeamFlagsService,
    private localizationService: LocalizationService
  ) {}

  // Public methods
  // Learning Insights

  /**
   * Get a single user for a given `userProfileKey`
   */
  public getUserByKey(userKey: number): Observable<UserProfileSummary> {
    return this.http.get<UserProfileSummary>('/managers/getuser', {
      params: {
        userKey,
      },
    });
  }

  /**
   * Get assigned learnings for the managers team members
   *
   * @param args {
   *  {number} orgId (required),
   *  {number} managerProfileKey (required),
   *  {string} type (required),
   *  {number} maxHistoryMonths (required),
   *  {number} skip (required),
   *  {number} take (required),
   *  {string} orderBy (required),
   *  {string} sort (required),
   *  {Filter[]} filters (required)
   *  {boolean} completed (optional)
   * }
   */
  // TODO: add types
  public getAssignedLearnings(args: {
    orgId: number;
    userProfileKey: number;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    type: string;
    maxHistoryMonths: number;
    skip: number;
    take: number;
    orderBy: string;
    sort: string;
    filters: Filter[];
    completed: boolean;
  }) {
    const {
      orgId,
      userProfileKey,
      managerProfileKey,
      includeFullManagerHierarchy,
    } = args;

    // Assign defaults to undefineds
    const type = args.type ? args.type : 'RequiredLearning';
    const maxHistoryMonths = args.skip ? args.skip : 12;
    const skip = args.skip ? args.skip : 0;
    const take = args.take ? args.take : 20;
    const orderBy = args.orderBy ? args.orderBy : 'AssignedDate';
    const sort = args.sort ? args.sort : 'Descending';
    const facets = !args.filters?.length
      ? []
      : this.filterService.filtersToFacets(args.filters);

    if (args.completed !== undefined) {
      // completed should only be passed in when the new-assignments-list-labels LD flag is true.
      facets.push({
        id: 'completed',
        name: 'completed',
        values: [args.completed],
      });
    }

    return this.http.get<{ items: TeamRecommendation[]; totalCount: number }>(
      `${this.managersBaseUrl}/GetRecommendationForTeam`,
      {
        params: {
          orgId,
          userProfileKey,
          subLevelManagerKey: managerProfileKey,
          includeFullManagerHierarchy,
          type,
          maxHistoryMonths,
          skip,
          take,
          orderBy,
          sort,
          facets: JSON.stringify(facets),
        },
      }
    );
  }

  /**
   * Build up a team page URL with arguments based off the filters
   *
   * @param args {
   *  {string} page (required),
   *  {Date} startDate,
   *  {Date} endDate,
   *  {boolean} includeFullManagerHierarchy,
   *  {number} managerProfileKey,
   *  {InputType | ''} inputType,
   *  {Filter[]} facets - facet filters
   *  {any} extraParam - if non-filter params need to be included
   * }
   */
  public buildFilteredUrl(args: {
    pageUrl: string;
    startDate?: Date;
    endDate?: Date;
    includeFullManagerHierarchy?: boolean;
    managerProfileKey?: number;
    inputType?: string;
    facets?: Filter[];
    extraParam?: any;
  }): string {
    // Transform the params to strings
    const startDate = args.startDate
      ? this.dateRangeService.getDateFormat(args.startDate)
      : '';

    const endDate = args.endDate
      ? this.dateRangeService.getDateFormat(args.endDate)
      : '';

    const includeFullManagerHierarchy =
      args.includeFullManagerHierarchy?.toString();

    const managerProfileKey = args.managerProfileKey
      ? args.managerProfileKey?.toString()
      : '';

    const selectedUsers =
      this.filterService
        .filtersToFacets(args.facets)
        .find((f) => f.id === InsightsFilterType.Users)?.values ?? '';

    let params = {
      startDate,
      endDate,
      includeFullManagerHierarchy,
      manager: managerProfileKey,
      selectedUsers,
      inputType: args.inputType,
      ...args.extraParam,
    };

    // remove empty params
    params = Object.entries(params).filter(([_key, value]) => {
      return !!value;
    });

    const paramsStringified = new URLSearchParams(params).toString();

    return `${args.pageUrl}?${paramsStringified}`;
  }

  /**
   * Get popular skills of team
   *
   * @param args {
   *  {number} orgId (required),
   *  {number} userProfileKey (required),
   *  {number} managerProfileKey (required),
   *  {boolean} includeFullManagerHierarchy (required),
   *  {Date} startDate (required),
   *  {Date} endDate (required),
   *  {number} count (required),
   *  {Filter[]} filters (required)
   * }
   */
  // TODO: add types
  public getPopularSkills(args: {
    orgId: number;
    userProfileKey: number;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    startDate: Date;
    endDate: Date;
    count?: number;
    filters?: Filter[];
  }) {
    const {
      orgId,
      userProfileKey,
      managerProfileKey,
      includeFullManagerHierarchy,
      startDate,
      endDate,
    } = args;
    const count = args.count ?? 10;
    const facets = !args.filters?.length
      ? []
      : this.filterService.filtersToFacets(args.filters);

    return this.http.get<TeamPopularTag[]>(
      `${this.managersBaseUrl}/GetTeamPopularTags`,
      {
        params: {
          orgId,
          userProfileKey,
          subLevelManagerKey: managerProfileKey,
          includeFullManagerHierarchy,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          count,
          facets: JSON.stringify(facets),
        },
      }
    );
  }

  /**
   * Get top learner statistics
   */
  public getTopLearnerInsights(args: {
    orgId: number;
    userProfileKey: number;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    startDate: Date;
    endDate: Date;
    count?: number;
    filters?: Filter[];
  }): Observable<GetTopLearnerInsights[]> {
    const {
      orgId,
      userProfileKey,
      managerProfileKey,
      includeFullManagerHierarchy,
      startDate,
      endDate,
    } = args;
    const count = args.count ? args.count : 10; // Defaults to 10
    const facets = !args.filters?.length
      ? []
      : this.filterService.filtersToFacets(args.filters);

    return this.http.get(`${this.managersBaseUrl}/GetTopLearnerInsights`, {
      params: {
        orgId,
        userProfileKey,
        subLevelManagerKey: managerProfileKey,
        includeFullManagerHierarchy,
        count,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        facets: JSON.stringify(facets),
      },
    });
  }

  /**
   * Get learning insights kpi data
   *
   * @param args {
   *  {number} orgId (required),
   *  {number} userProfileKey (required),
   *  {number} managerProfileKey (required),
   *  {boolean} includeFullManagerHierarchy (required),
   *  {Date} startDate (required),
   *  {Date} endDate (required),
   *  {Filter[]} filters (required)
   * }
   */
  public getTeamLearningKpis(args: {
    orgId: number;
    userProfileKey: number;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    startDate: Date;
    endDate: Date;
    filters: Filter[];
  }): Observable<KPI[]> {
    const {
      orgId,
      userProfileKey,
      managerProfileKey,
      includeFullManagerHierarchy,
      startDate,
      endDate,
    } = args;

    const facets = !args.filters?.length
      ? []
      : this.filterService.filtersToFacets(args.filters);

    return this.http.get(`${this.managersBaseUrl}/GetTeamLearningInsightKPIs`, {
      params: {
        orgId,
        managerProfileKey: userProfileKey,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        subLevelManagerKey: managerProfileKey,
        includeFullManagerHierarchy,
        facets: JSON.stringify(facets),
      },
    });
  }

  /**
   * Evaluate if user is authorized to access Skill Coach
   */
  public isAuthorized() {
    const authUser = this.authService.authUser;
    let isAuthorized = false;

    if (authUser?.isSkillInventoryClient || authUser?.isSkillAnalyticsClient) {
      isAuthorized = authUser?.isManager;
    } else {
      isAuthorized = !!(
        authUser?.isManager &&
        authUser?.defaultOrgInfo?.settings?.enableTeamSpace &&
        (authUser?.defaultOrgInfo?.settings?.skillCoachFullOrgAccess ||
          this.teamFlagsService.teamSpaceEnabled)
      );
    }

    return isAuthorized;
  }

  /**
   * Get learning summary insights data
   *
   * @param args {
   *  {number} orgId (required),
   *  {Date} startDate (required),
   *  {Date} endDate (required),
   *  {string} inputTypes (required),
   *  {number} managerProfileKey (required),
   *  {boolean} includeFullManagerHierarchy (required),
   *  {Filter[]} filters (required)
   * }
   */
  public getLearningSummary(args: {
    orgId: number;
    startDate: Date;
    endDate: Date;
    inputTypes: string;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    filters: Filter[];
  }): Observable<BaseInsightsContent> {
    const facets = !args.filters?.length
      ? []
      : this.filterService.filtersToFacets(args.filters);

    return this.http.get<BaseInsightsContent>(
      `${this.managersBaseUrl}/GetLearningSummaryChartInsights`,
      {
        params: {
          orgId: args.orgId,
          startDate: this.dateRangeService.getDateFormat(args.startDate),
          endDate: this.dateRangeService.getDateFormat(args.endDate),
          inputTypes: args.inputTypes,
          subLevelManagerKey: args.managerProfileKey,
          includeFullManagerHierarchy: args.includeFullManagerHierarchy,
          facets: JSON.stringify(facets),
        },
      }
    ).pipe(
      map(response => ({
        ...response,
        xAxisLabels: formatInsightsDateLabels(
          response.xAxisLabels,
          response.xAxisInterval,
          this.localizationService.userLocale
        )
      }))
    )
  }

  /**
   * Get filter facets for Skill Coach Learning Insights
   */
  public teamLearningInsightFacet(
    orgId: number,
    userProfileKey: number,
    includeFullManagerHierarchy?: boolean
  ): Observable<{ filters: Filter[] }> {
    return this.http
      .get<{ facets: Facet[] }>(
        `${this.managersBaseUrl}/TeamLearningInsightFacet`,
        {
          params: {
            orgId,
            userProfileKey,
            includeFullManagerHierarchy,
          },
        }
      )
      .pipe(
        map(({ facets }) => {
          const filters = this.filterService.facetsToFilters(facets, [
            { showEmptyOptions: true },
          ]);

          return {
            filters: filters.map((filter) => ({
              ...filter,
              canSearch: true,
              ignoreCount: true,
              showEmptyOptions: true,
            })),
          };
        })
      );
  }

  /**
   * Get list of users that are assigned to a particular assigned learning
   * to populate modal for assigned learning progress
   *
   * @param {number} orgId - Required. Organization Id.
   * @param {number} resourceId - Required. ResourceId
   * @param {string} resourceType - Required. ResourceType
   * @param {Date} dueDate - Required. ResourceType
   * @param {Date} dateCreated - Required. ResourceType
   * @param {string} recommendationAction
   * @param count
   * @param skip
   * @param {number} assignedByUpk - Assigned UPK.
   * @param {number} managerProfileKey - Optional. Selected manager's UPK.
   * @param {boolean} includeFullManagerHierarchy - Optional. Defaults to false.
   */
  // TODO: add types
  public getRecommendationUsers(
    orgId: number,
    resourceId: number,
    resourceType: string,
    dueDate: number,
    dateCreated: Date,
    recommendationAction: string,
    count: number = 20,
    skip: number = 0,
    assignedByUpk?: number,
    managerProfileKey?: number,
    includeFullManagerHierarchy: boolean = false
  ): Observable<any> {
    this.trackerService.trackEventData({
      action: 'Skill Coach Assignments Modal Viewed',
      properties: { OrgId: orgId },
    });

    return this.http.get<any>('/managers/GetRecommendationUsersByResource', {
      params: {
        orgId,
        resourceId,
        resourceType,
        assignedByUpk,
        dueDate,
        dateCreated,
        recommendationAction,
        count,
        skip,
        subLevelManagerKey: managerProfileKey,
        includeFullManagerHierarchy,
      },
    });
  }

  // Insights

  /**
   * Get the direct reports of a manager
   *
   * @param args {
   *  {orgId} (required),
   *  {string} nameFilter (optional),
   *  {number} count (optional),
   *  {number} skip (optional),
   *  {string} orderBy (optional),
   *  {boolean} sortDescending (optional),
   *  {boolean} includeOptOut (optional)
   * }
   */
  public managerDirectReports(
    orgId: number,
    nameFilter = null,
    count = 20,
    skip = 0,
    orderBy = null,
    sortDescending = false,
    includeOptOut = true
  ): Observable<ManagerDirectReport> {
    return this.http.get(`${this.managersBaseUrl}/managerdirectreports`, {
      params: {
        orgId,
        nameFilter,
        count,
        skip,
        orderBy,
        sortDescending,
        includeOptOut,
      },
    });
  }

  /**
   * Get a summary of the direct reports of a manager
   */
  public managerDirectReportSummary(
    orgId: number,
    includeOptOut: boolean = true
  ): Observable<{ totalCount: number }> {
    return this.http.get(`${this.managersBaseUrl}/managerdirectreportsummary`, {
      params: {
        orgId,
        includeOptOut,
      },
    });
  }

  /**
   * Get learning completion details for a manager's reports
   *
   * @param args {
   *  {number} orgId (required),
   *  {Date} startDate (required),
   *  {Date} endDate (required),
   *  {string} inputType (required),
   *  {number} managerProfileKey (required),
   *  {boolean} includeFullManagerHierarchy (required),
   *  {number} count (required),
   *  {number} skip (required),
   *  {Filter[]} filters (required)
   *  {boolean} isDescending (required),
   *  {string} orderBy (required),
   * }
   */
  public getLearningCompletionDetails(args: {
    orgId: number;
    startDate: Date;
    endDate: Date;
    inputType: string;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    count: number;
    skip: number;
    filters: Filter[];
    isDescending: boolean;
    orderBy: string;
  }): Observable<LearningCompletionDetails> {
    const {
      orgId,
      startDate,
      endDate,
      inputType,
      managerProfileKey,
      includeFullManagerHierarchy,
      count,
      skip,
      filters,
      isDescending,
      orderBy,
    } = args;

    const facets = !filters?.length
      ? []
      : this.filterService.filtersToFacets(filters);

    return this.http.get(
      `${this.managersBaseUrl}/getlearningcompletiondetails`,
      {
        params: {
          orgId,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          inputTypes: inputType,
          subLevelManagerKey: managerProfileKey,
          includeFullManagerHierarchy,
          skip,
          count,
          facets: JSON.stringify(facets),
          sortOrder: orderBy,
          sort: isDescending ? 'Descending' : 'Ascending',
        },
      }
    );
  }

  /**
   * Get data for list of members in relation to Skills Chart Skills
   *
   * @param orgId - Required. Organization ID.
   * @param tagId - Required. Id of Tag (Skill).
   * @param ratingType - Optional. Self or Manager rating type
   * @param onlyFocusSkills - Optional. Send if fetching data for Focus Skills
   * @param userProfileKey - Optional
   * @param subLevelManagerKey - Optional. Manager of managers
   * @param includeFullManagerHierarchy - Optional. Defaults to false.
   * @param filters - Optional for facet filters.
   */
  public getTeamTagRelationship(
    orgId: number,
    tagId: number,
    ratingType?: string,
    onlyFocusSkills?: boolean,
    userProfileKey?: number,
    subLevelManagerKey?: number,
    includeFullManagerHierarchy?: boolean,
    filters?: Filter[]
  ): Observable<TeamTagRelationship[]> {
    const facets = !filters?.length
      ? []
      : this.filterService.filtersToFacets(filters);

    return this.http.get(`${this.managersBaseUrl}/GetTeamTagRelationship`, {
      params: {
        orgId,
        tagId,
        userProfileKey,
        ratingType,
        onlyFocusSkills,
        subLevelManagerKey,
        includeFullManagerHierarchy,
        facets: JSON.stringify(facets),
      },
    });
  }

  /**
   * Get data for charts
   *
   * @param orgId - Required. Organization ID.
   * @param rawFilters - Optional. Array of facets being used to filter the data.
   * @param subLevelManagerKey - Optional. UserProfileKey of the subLevel manager to be filtered with
   * @param includeFullManagerHierarchy - Optional. defaults to false, whether or not to include full hierarchy or just get direct reports
   */
  public getTeamTopTagRatingsBreakdown(
    orgId: number,
    rawFilters: Filter[] = [],
    subLevelManagerKey?: number,
    includeFullManagerHierarchy?: boolean
  ): Observable<TeamTopTagRatingsGetResponse> {
    // Filter out 'faux' filter, TeamFocusSkill filter as that is handled fully on FE, doesn't have to be sent to BE
    const filters = rawFilters.length
      ? rawFilters.filter((obj) => obj.id !== 'TeamFocusSkill')
      : [];
    const facets =
      // If neither defaultFacets nor filters have any content,
      // set to an empty array.
      !filters.length ? [] : this.filterService.filtersToFacets(filters);

    return this.http
      .get<any>(`${this.managersBaseUrl}/GetTeamTopTagRatingsBreakdown`, {
        params: {
          orgId,
          facets: JSON.stringify(facets),
          subLevelManagerKey,
          includeFullManagerHierarchy,
        },
      })
      .pipe(map((response) => this.formatResponse(response)));
  }

  /**
   * Get Skills available in team
   *
   * @param orgId - Required. Organization ID.
   * @param count - Optional. Number of results, defaults to 12.
   * @param skip - Optional. Skip for pagination, defaults to 0.
   * @param searchTerm - Optional. Search term for skills, defaults to null.
   * @param ratingType - Optional. Skill rating type, defaults to self.
   * @param orderBy - Optional. Sort order by column, defaults to Name.
   * @param sort - Optional. Sort order direction, defaults to ascending
   * @param managerKey - Optional. UserProfileKey of the subLevel manager to be filtered with
   * @param directReportsOnly - Boolean. False includes full manager's hierarchy.
   * @param filters - Optional. Filter by facets of member keys.
   */
  public getTeamSkills(
    orgId: number,
    count?: number,
    skip?: number,
    searchTerm?: string,
    ratingType?: string,
    orderBy?: string,
    sort?: string,
    managerKey?: number,
    directReportsOnly?: boolean,
    filters?: any[]
  ): Observable<GetSkillDetailsManagerRating> {
    const facets = !filters?.length
      ? []
      : this.filterService.filtersToFacets(filters);

    return this.http.get(`${this.managersBaseUrl}/GetSkills`, {
      params: {
        orgId,
        count,
        skip,
        searchTerm,
        ratingType,
        orderBy,
        sort,
        subLevelManagerKey: managerKey,
        includeFullManagerHierarchy: directReportsOnly,
        facets: JSON.stringify(facets),
      },
    });
  }

  /**
   * Update the status of the team focus skill
   *
   * @param orgId - Required. Organization ID.
   * @param tagId - Required. Tag Id of the skill
   * @param isTeamFocusSkill - Required. True sets it as a focus skill
   */
  public updateTeamFocusSkill(
    orgId: number,
    tagId: number,
    isTeamFocusSkill: boolean
  ): Observable<boolean> {
    return this.http.post(`${this.managersBaseUrl}/UpdateTeamFocusSkill`, {
      orgId,
      tagId,
      isTeamFocusSkill,
    });
  }

  /**
   * Get the object associated with the focus filter
   */
  public getFocusFilter(
    focusSkillCount: number = 0,
    teamFocusSkillOnly: boolean,
    tooltip: string = '',
    isDisabled: boolean = false
  ) {
    return {
      id: 'TeamFocusSkill',
      canSearch: true,
      ignoreCount: false,
      isDisabled: isDisabled,
      isSelectionFilter: false,
      showEmptyOptions: false,
      title: this.i18n.Team_FocusLabel,
      isCheckboxFilter: true,
      checkboxFilterClass: 'btn--ghost',
      checkboxFilterId: 'TeamFocusSkill',
      checkboxFilterCount: focusSkillCount,
      tooltip: tooltip,
      filters: [
        {
          title: this.i18n.Team_FocusLabel,
          subitems: [
            {
              title: this.i18n.Team_FocusLabel,
              isSelected: teamFocusSkillOnly,
              model: {
                name: 'TeamFocusSkill',
                count: focusSkillCount,
                filter: false,
                id: 'TeamFocusSkill',
              },
            },
          ],
        },
      ],
    } as unknown as Filter;
  }

  /**
   * Get the object associated with the sources filter
   */
  public getSourceFilter(sources: ProfileSkillStates[]) {
    const subitems = sources.map((source) => ({
      ...source,
      model: { ...source, filter: false },
    }));

    return {
      id: 'SkillSources',
      canSearch: false,
      ignoreCount: true,
      isDisabled: false,
      isSelectionFilter: false,
      showEmptyOptions: false,
      title: this.i18n.Core_Source,
      checkboxFilterClass: 'btn--ghost',
      filters: [
        {
          id: 'skillSources',
          title: this.i18n.Core_Source,
          isSelectionFilter: true,
          subitems,
        },
      ],
    } as unknown as Filter;
  }

  /**
   * Get the object associated with the onProfile filter
   */
  public getOnProfileFilter(sources: ProfileSkillStates[]) {
    const subitems = sources.map((source) => ({
      ...source,
      model: { ...source, filter: false },
    }));

    return {
      id: 'OnProfile',
      canSearch: false,
      ignoreCount: true,
      isDisabled: false,
      isSelectionFilter: false,
      title: this.i18n.Core_Skills,
      checkboxFilterClass: 'btn--ghost',
      filters: [
        {
          id: 'onProfile',
          title: this.i18n.Core_Skills,
          isSelectionFilter: true,
          subitems,
        },
      ],
    } as unknown as Filter;
  }

  /**
   * Get a list of managers under a manager
   *
   * @param {number} orgId - Required. Organization Id.
   * @param {boolean} managerProfileKey - Required. UPK.
   * @param {boolean} includeOptOut - Defaults to true.
   */
  public getManagersOfManager(
    orgId: number,
    managerProfileKey: number,
    includeOptOut: boolean = true
  ): Observable<GetManagersOfManager[]> {
    return this.http.get(`${this.managersBaseUrl}/GetManagersOfManager`, {
      params: {
        orgId,
        managerProfileKey,
        includeOptOut,
      },
    });
  }

  /**
   * Get learning completions of an input for a team
   *
   * @param args {
   *  {number} orgId (required),
   *  {Date} startDate (required),
   *  {Date} endDate (required),
   *  {number} inputId (required),
   *  {string} inputType (required),
   *  {number} managerProfileKey (required),
   *  {boolean} includeFullManagerHierarchy (required),
   *  {number} count (optional - if empty, uses default),
   *  {number} skip (optional - if empty, uses default),
   *  {Filter[]} filters (required)
   * }
   */
  // TODO: add types
  public getLearningCompletions(args: {
    orgId: number;
    startDate: Date;
    endDate: Date;
    inputId: number;
    inputType: string;
    managerProfileKey: number;
    includeFullManagerHierarchy: boolean;
    count?: number;
    skip?: number;
    filters: Filter[];
  }) {
    const defaultCount = 250; // user-list-form's expectation
    const defaultSkip = 0;
    const {
      orgId,
      startDate,
      endDate,
      inputId,
      inputType,
      managerProfileKey,
      includeFullManagerHierarchy,
      count = args.count ?? defaultCount,
      skip = args.count ?? defaultSkip,
      filters,
    } = args;
    const facets = !filters?.length
      ? []
      : this.filterService.filtersToFacets(filters);
    return this.http.get<any>(
      `${this.managersBaseUrl}/GetLearningCompletionsByInput`,
      {
        params: {
          orgId,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          inputType,
          inputId,
          subLevelManagerKey: managerProfileKey,
          includeFullManagerHierarchy,
          skip,
          count,
          facets: JSON.stringify(facets),
        },
      }
    );
  }

  public getUserSkillTargetList(
    orgId: number,
    userKey: number
  ): Observable<UserSkillSources[]> {
    return this.http.get('/managers/getUserSkillTargetListAsync', {
      params: { orgId, userKey },
    });
  }

  public getUserTagDetails(
    userProfileKey: number,
    orgId: number,
    searchTerm: string,
    teamFocusSkillsOnly: boolean,
    sourceSkillTarget: (string | number)[],
    profileSkillsStates: (string | number)[]
  ): Observable<UserSkillDetailsManagerRating> {
    return this.http.post('/managers/usertagdetails', {
      userProfileKey,
      orgId,
      searchTerm,
      teamFocusSkillsOnly,
      sourceSkillTarget,
      profileSkillsStates,
    });
  }

  /**
   * Take object and format it into a filter object
   *
   * @param {
   *  facets - list of facets - Required
   *  items - list of response items - Required
   *  {boolean} hasMoreItems - Whether or not more items are available - Required
   *  managerFocusTagIds - Required
   *  users - list of associated users - Required
   * }
   */
  private formatResponse({
    facets,
    items,
    hasMoreItems,
    managerFocusTagIds,
    users,
  }: TeamTopTagRatingsGetResponse): TeamTopTagRatingsGetResponse {
    const filters = this.filterService.facetsToFilters(facets, [
      { showEmptyOptions: true },
      {
        showEmptyOptions: true,
        isSelectionFilter: true,
      },
      {
        showEmptyOptions: true,
        canSearch: true,
      },
    ]);
    return {
      filters: filters.map((filter) => ({
        ...filter,
        canSearch: true,
        ignoreCount: true,
        showEmptyOptions: true,
      })),
      items,
      hasMoreItems,
      managerFocusTagIds,
      users,
    };
  }
}
