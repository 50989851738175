import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { fadeIn } from '@app/shared/animations/animations';
import {
  DateRange,
  DateRangeService,
} from '@app/shared/services/date-range.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowToken } from '@app/shared/window.token';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDropdown,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateParserFormatter } from './custom-date-parser-formatter';

/**
 * ReportingRangePicker component is a ng-bootstrap dropdown component which consists of a predefined list of date intervals for the user to choose for reporting purposes.
 * If the user chooses to customize a specific date interval, the nested ng-bootstrap datepicker is rendered which allows for a date range to be selected.
 *
 * TODO: 1. If there are NO AngularJS host component containers, do the following items:
 *          a. Remove ReportingRangePicker component from the ChartsSharedModule
 *          b. Add ReportingRangePicker component to ChartsModule (lazy loaded)
 *       2. Extract common css classes to a global datepicker.scss (TBD)
 */
@Component({
  selector: 'dgx-reporting-range-picker',
  templateUrl: './reporting-range-picker.component.html',
  styleUrls: ['./reporting-range-picker.component.scss'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter,
    },
  ],
  animations: [fadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingRangePickerComponent implements OnChanges, OnInit {
  @Input() public appearance: 'plain' | 'dropdown';
  @Input() public disabled?: boolean = false;
  @Input() public location: string;
  @Input() public maxDays: number;
  @Input() public selection: (range: DateRange) => void;
  @Input() public value: {
    startDate: number;
    endDate: number;
  };

  @ViewChild('ngbDropdown')
  public ngbDropdown: NgbDropdown;

  @HostBinding('class') @Input() public hostClass?: string;

  @HostBinding('attr.data-dgat') public dataDgat = 'reporting-range-picker';

  public classes: {
    button: string;
    content: string;
  } = {
    button: '',
    content: '',
  };
  public customStartDate: NgbDate;
  public customEndDate: NgbDate;
  public datepickerState: 'calendar' | 'quickSelect' = 'quickSelect';
  public isOpen: boolean = false;
  public labels = {
    startDate: '',
    endDate: '',
  };
  public maxDate: NgbDate;
  public minDate: NgbDate;
  public maxDaysBoundaries: number[];
  public validationMessage: string;

  private currentRange: DateRange;
  private dateDisplayFormat: string = 'MMM d, yyyy';

  constructor(
    private calendar: NgbCalendar,
    private cdr: ChangeDetectorRef,
    public customDateParserFormatter: CustomDateParserFormatter,
    private datePipe: DatePipe,
    private dateRangeService: DateRangeService,
    private trackerService: TrackerService,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    const lang: string =
      this.windowRef.navigator.language ||
      (this.windowRef.navigator as any).userLanguage ||
      '';
    this.dateDisplayFormat =
      lang.startsWith('ko') || lang.startsWith('zh')
        ? 'customDate'
        : this.dateDisplayFormat;
  }

  public get isValid(): boolean {
    return this.customStartDate !== null && this.customEndDate !== null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const { startDate, endDate } = changes.value.currentValue;
      this.updateLabels(startDate, endDate);
      this.setCustomDates(new Date(startDate), new Date(endDate));
    }

    // Set the default style
    this.appearance = this.appearance || 'plain';

    if (changes.appearance || changes.disabled) {
      const baseClass = 'reporting-range-picker__button';
      if (this.appearance === 'dropdown') {
        this.classes = {
          button: baseClass + ' btn btn-dropdown',
          content: 'reporting-range-picker__value',
        };
      } else {
        // plain
        this.classes = {
          button: baseClass,
          content: 'par par--light',
        };
      }
      if (this.disabled) {
        this.classes.button += ' disabled is_disabled';
      }
    }
  }

  public ngOnInit(): void {
    this.maxDays = this.maxDays || 365;
    const boundaryOptions = [7, 30, 60, 90, 365];
    this.maxDaysBoundaries = boundaryOptions.filter(
      (days) => this.maxDays >= days
    );
    // For insights/reporting we want to exclude today according to UTC time
    const minDate = new Date(
      this.dateRangeService.getPastTwoYearsExcludeTodayUTC()
    );
    const maxDate = this.dateRangeService.getDaysAgoUTC(1);
    this.maxDate = this.customDateParserFormatter.getNgbDate(maxDate);
    if (!(this.customStartDate && this.customEndDate)) {
      this.setCustomDates(minDate, maxDate);
      this.currentRange = this.getDateRange();
    }
  }

  public apply() {
    const dateRange = this.getDateRange();
    if (dateRange.daysBetween <= this.maxDays) {
      this.validationMessage = undefined;
      this.currentRange = dateRange;
      this.updateLabels(dateRange.startDate, dateRange.endDate);
      this.selection(dateRange);
      this.closeDropdown();
      this.trackerService.trackEventData({
        action: 'Insights Date Range Selector Clicked',
        properties: {
          Selection: 'Specific Dates',
          StartDate: new Date(dateRange.startDate),
          EndDate: new Date(dateRange.endDate),
          DayCount: dateRange.daysBetween,
          Location: this.location,
        },
      });
    } else {
      this.validationMessage = this.translateService.instant(
        'OrgReportingCtrl_DateMaxValidationFormat',
        {
          numDays: this.maxDays,
        }
      );
      return false;
    }
  }

  public dateSelect(date: NgbDate): void {
    if (!this.customStartDate && !this.customEndDate) {
      this.customStartDate = date;
    } else if (
      this.customStartDate &&
      !this.customEndDate &&
      date &&
      date.after(this.customStartDate)
    ) {
      this.customEndDate = date;
    } else {
      this.customEndDate = null;
      this.customStartDate = date;
    }
  }

  public getLabel(days: number): string {
    if (days === 365) {
      return this.translateService.instant('Core_CalendarPastYear');
    }
    return this.translateService.instant('Core_CalendarDayCount', {
      dayCount: days,
    });
  }

  public getSpecificDates(days: number): string {
    const dateRange = this.dateRangeService.getPastDaysExcludeTodayUTC(days);
    const startDate = this.datePipe.transform(
      dateRange.startDate,
      this.dateDisplayFormat
    );
    const endDate = this.datePipe.transform(
      dateRange.endDate,
      this.dateDisplayFormat
    );
    return startDate + ' - ' + endDate;
  }

  public isWithinRange(date: NgbDate) {
    return (
      this.customStartDate &&
      this.customEndDate &&
      date.after(this.customStartDate) &&
      date.before(this.customEndDate)
    );
  }

  public isRangeEnd(date: NgbDate) {
    return (
      date.equals(this.customStartDate) ||
      (this.customEndDate && date.equals(this.customEndDate))
    );
  }
  public isStRange(date: NgbDate) {
    return date.equals(this.customStartDate);
  }

  public isEdRange(date: NgbDate) {
    return this.customEndDate && date.equals(this.customEndDate);
  }

  public setSpecificDates(days: number) {
    const dateRange = this.dateRangeService.getPastDaysExcludeTodayUTC(days);
    this.updateLabels(dateRange.startDate, dateRange.endDate);
    this.selection({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      daysBetween: days,
    });
    this.closeDropdown();
    this.trackerService.trackEventData({
      action: 'Insights Date Range Selector Clicked',
      properties: {
        Selection: 'Quick Select',
        StartDate: new Date(dateRange.startDate),
        EndDate: new Date(dateRange.endDate),
        DayCount: days,
        Location: this.location,
      },
    });
  }

  public toggleCustomDate(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.validationMessage = undefined;
    this.closeDropdown();
  }

  public toggled(event: boolean): void {
    if (event) {
      if (this.currentRange) {
        this.setCustomDates(
          new Date(this.currentRange.startDate),
          new Date(this.currentRange.endDate)
        );
      }
    } else {
      if (this.datepickerState === 'calendar') {
        this.datepickerState = 'quickSelect';
      }
    }
    this.cdr.detectChanges();
  }

  public validateInput(
    currentValue: NgbDate | null,
    input: string
  ): NgbDate | null {
    const parsed = this.customDateParserFormatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  private closeDropdown(): void {
    this.isOpen = false;
    this.datepickerState = 'quickSelect';
    this.ngbDropdown.close();
  }

  private getDateRange(): DateRange {
    const startDate = this.customDateParserFormatter.getUtcDate(
      this.customStartDate
    );
    const endDate = this.customDateParserFormatter.getUtcDate(
      this.customEndDate
    );
    const daysBetween = this.dateRangeService.getNumberOfDays(
      new Date(startDate),
      new Date(endDate)
    );
    return { startDate, endDate, daysBetween };
  }

  private setCustomDates(startDate: Date, endDate: Date): void {
    this.customStartDate = this.customDateParserFormatter.getNgbDate(startDate);
    this.customEndDate = this.customDateParserFormatter.getNgbDate(endDate);
  }

  private updateLabels(startDate: any, endDate: any) {
    this.labels.startDate = this.datePipe.transform(
      startDate,
      this.dateDisplayFormat
    );
    this.labels.endDate = this.datePipe.transform(
      endDate,
      this.dateDisplayFormat
    );
  }
}
