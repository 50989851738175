import { resourceTypeIcon } from './../../../utils/common-utils/resource-type-icon';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { PathwayEnrollmentService } from '@app/pathways/services/pathway-enrollment.service';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { merge, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LiveEventsService } from '../live-events/live-events.service';
import { ContentCardService } from './content-card.service';

import { PathwayDetailsModel } from '@app/pathways/pathway-api.model';
import { ProfileService } from '@app/profile/services/profile.service';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { FollowTargetModal } from '@app/target/components/modals/follow-target-modal/follow-target-modal.component';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { EndorseContentService } from '../services/endorse-content.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { Target } from '@app/target/target-api.model';
import { CardFormat } from '@app/resource-launcher/components/tile-launcher/tile-launcher.component';
import { CatalogSource } from '@app/shared/utils/tracker-helper';
import { showJoinedButton } from '@app/academies/utils/academy.utils';

/**
 * The content card component will display inputs, pathways, targets and directories.
 * The `dgxTileLauncher` should be used to display cards rather than this component directly.
 */
@Component({
  selector: 'dgx-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() cardFormat: CardFormat = CardFormat.Standard;
  /** Should be false in all cases *except* when a child of the DgxCarousel component. */
  @Input() public appendMenuToBody = false;
  /** Required - the learning resource to display  */
  @Input() public content: LearningResourceViewModel;
  /** Information about the content's recommendation status. If this is passed in, an assignment and required
   * badge will be displayed. If the content is required, the appropriate footer options will be shown.
   */
  @Input() public sectionType?: string;
  @Input() public recommendationInfo?: RecommendationInfo;
  /** When true,  the image on the card will be hidden */
  @Input() public hideImage?: boolean = false;
  /** When true, the footer on the card will be hidden. */
  @Input() public hideFooter?: boolean = false;
  /** When true, all actions on the card will be disabled */
  @Input() public isPreview?: boolean = false;
  /** To modify options in the menu. */
  @Input()
  public modifyOptionsFn?: ModifyOptionsFnType<LearningResourceViewModel>;
  @Input() public isSelected?: boolean = false;
  @Input() public isClickable?: boolean = true;
  @Input() public expandCard?: boolean;
  @Input() public lazyChunkIndex?: number;
  @Input() public cardIndex?: number;
  @Input() public currentlyExpandedIndexes?: number[];
  @Output()
  public clickedTitleEllipses: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  @ViewChild('title') public title: ElementRef<HTMLElement>;

  @ViewChild('link') public link: ElementRef<HTMLElement>;
  public menuConfig?: MenuViewModel[];
  public endorsedSrc?: string;
  public clickable: boolean = false;
  public showActionMenu: boolean = true;
  public opensModal: boolean = false;
  public showProvider: boolean = true;
  public showMoreDetails: boolean;
  public showCompletionButton: boolean;
  public liveUrl: string;
  public inputCompletionTooltip: string;
  public showEllipsesButton: boolean;
  public indexesOfCardsToExpand: number[] = [];
  public sectionWithExpandedCards: string;
  public lazyChunkIndexOfCardToExpand: number;

  /** use '' for text trim delimiter to account for long titles
   * with no spaces (i.e. URLS) and other languages
   */
  public textTrimDelimiter = '';
  public i18n = this.translate.instant([
    'dgContentTile_Authorship',
    'dgContentTile_Verified',
    'Core_CEUEligible',
    'Core_MarkAsComplete',
    'Core_ViewDetails',
    'Core_Following',
    'Core_Follow',
    'Core_Share',
    'Core_YesSure',
    'dgPathwayTile_WithdrawExplanation',
    'dgPathwayTile_UnfollowConfirm',
    'Core_Completed',
    'A11y_ExpandForTitle',
    'Core_Completed',
  ]);

  public showFollowPathwayButton: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private translate: TranslateService,
    private contentCardService: ContentCardService,
    private modalService: ModalService,
    private detailsModalService: DetailsModalService,
    private orgEndorsedService: OrgEndorsedService,
    private sharedTargetService: SharedTargetService,
    private trackerService: TrackerService,
    private liveEventsService: LiveEventsService,
    private pathwayEnrollmentService: PathwayEnrollmentService,
    private profileService: ProfileService,
    private recommendationsService: RecommendationsService,
    private endorseContentService: EndorseContentService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
  }

  public get isLargeFormat() {
    return CardFormat.Large === this.cardFormat;
  }

  public get canRecommend() {
    return this.recommendationsService.canRecommendResource({
      isValid:
        (this.content.isPathway ||
          this.content.isTarget ||
          this.content.isv2Academy) &&
        this.authService.authUser?.canRecommendItems,
      privacyLevel: this.content?.privacyLevel,
    });
  }

  public get url() {
    return this.liveUrl || this.content.externalUrl || this.content.url;
  }

  /////// POC - Related Content Recommendations
  public get hasRelatedContent() {
    return (this.content.model as any).hasRelatedContent;
  }

  public get showJoinedButton() {
    return showJoinedButton(this.content);
  }

  public ngOnInit() {
    merge(
      this.contentCardService.onUpdate$,
      this.endorseContentService.onUpdate$
    ).subscribe((resourceUpdated: LearningResourceViewModel) => {
      if (
        resourceUpdated.resourceId === this.content.resourceId &&
        resourceUpdated.resourceType === this.content.resourceType
      )
        this.cdr.detectChanges();
    });

    this.contentCardService
      .getLazyChunkIndex()
      .pipe(this.takeUntilDestroyed())
      .subscribe((lazyChunkIndexOfCardToExpand) => {
        this.lazyChunkIndexOfCardToExpand = lazyChunkIndexOfCardToExpand;
      });
  }

  public ngAfterViewInit(): void {
    this.showEllipsesButton =
      this.title.nativeElement.clientHeight <
      this.title.nativeElement.scrollHeight;

    this.cdr.detectChanges();
  }

  public ngOnChanges({
    content,
    isPreview,
    recommendationInfo,
    menuConfig,
    expandCard,
    isSelected,
    isClickable,
  }: SimpleChanges): void {
    if (
      expandCard &&
      expandCard.previousValue === false &&
      expandCard.currentValue === true
    ) {
      this.cdr.detectChanges();
      this.showEllipsesButton =
        this.title.nativeElement.clientHeight <
        this.title.nativeElement.scrollHeight;
    }
    if (
      expandCard &&
      expandCard.previousValue === true &&
      expandCard.currentValue === false
    ) {
      this.cdr.detectChanges();
      this.showEllipsesButton =
        this.title.nativeElement.clientHeight <
        this.title.nativeElement.scrollHeight;
    }
    if (isPreview) {
      this.showActionMenu = !!this.authService.authUser && !this.isPreview;
    }

    if (
      content ||
      recommendationInfo ||
      menuConfig ||
      isSelected ||
      isClickable
    ) {
      if (this.content.isLive) {
        // we know a session is live, but now we need to figure out which one and grab its URL
        const liveSession = this.liveEventsService.getLiveSession(
          this.content.liveSessions
        );
        this.liveUrl = liveSession.locationUrl;
      }
      this.opensModal =
        (this.content.resourceType === 'Video' && this.content.canPlayVideo) ||
        !!this.content.liveSessions ||
        this.content.resourceType === 'Task';

      this.clickable =
        !this.isSelected && this.isClickable && (!!this.url || this.opensModal);

      this.showProvider = this.getShowProvider();
      this.showCompletionButton = this.content.isInput && !this.content.isLive;
      this.showFollowPathwayButton =
        this.content.canFollow && this.content.isPathway;

      if (
        (this.profileService.isProfilePage &&
          !this.profileService.ownerIsViewing) ||
        this.content.isAcademy ||
        this.content.isv2Academy
      ) {
        // don't show the completion or follow button if we're on a profile page - overview, collection etc - and the page is
        // not being viewed by the profile owner
        this.showCompletionButton = false;
        // new requirement is to not show the follow button on pathways within the context of the pathways profile page
        // this allows us to revert to the old functionality if required.
        this.showFollowPathwayButton = false;
      }
      this.showMoreDetails =
        !this.content.isLive &&
        (!!this.content.internalUrl || this.opensModal) &&
        !this.content.isInputCollection &&
        this.recommendationInfo?.recommendationType !== 'RequiredLearning';

      if (!this.hideFooter) {
        this.menuConfig = this.contentCardService.getMenuConfig({
          content: this.content,
          authUser: this.authService.authUser,
          userRecommendationType: this.recommendationInfo?.recommendationType,
          modifyOptionsFn: this.modifyOptionsFn,
          sectionType: this.sectionType,
        });
      }
      // note 1: this is only needed while BofA wants the images to be
      // hidden.  this can be removed once they adopt the new layout
      // where plans have images set.
      //
      // note 2: BofA does not want directory images hidden when the `disablePlanImage` is true
      if (this.hideImage !== true) {
        this.hideImage =
          this.content.isTarget && !this.content.targetIsDirectory
            ? this.authService.authUser?.defaultOrgInfo?.settings
                .disablePlanImage
            : false;

        this.endorsedSrc = this.orgEndorsedService.getEndorsedSrc(
          this.authService.authUser?.defaultOrgId,
          this.isPreview
        );
      }

      this.inputCompletionTooltip = this.content.completionInfo?.isCompleted
        ? this.i18n.Core_Completed
        : this.i18n.Core_MarkAsComplete;
    }
  }

  public onClickTitleEllipses(titleElement) {
    // A different number of columns of cards will display depending on the browser size and what area of the app the user is in
    const numberOfColumns = this.contentCardService.getColumnCountForCards();
    this.contentCardService.setNumberOfColumnsOfCards(numberOfColumns);

    // In this.getNewIndexesOfCardsToExpand(), we need to get the indexes of the additional cards in the row to expand based on the clicked index AND...
    // the number of columns of cards
    const newIndexesOfCardsToExpand =
      this.contentCardService.getNewIndexesOfCardsToExpand(
        numberOfColumns,
        this.cardIndex,
        this.lazyChunkIndex
      );

    let indexesOfPreviouslyExpandedCards: number[] = [];

    this.contentCardService
      .getSectionWithExpandedCards()
      .pipe(this.takeUntilDestroyed())
      .subscribe((section) => {
        this.sectionWithExpandedCards = section;
      });
    this.contentCardService
      .getIndexesOfExpandedCards()
      .pipe(this.takeUntilDestroyed())
      .subscribe((indexes) => (indexesOfPreviouslyExpandedCards = indexes));

    if (
      this.sectionWithExpandedCards &&
      this.sectionWithExpandedCards !== this.sectionType
    ) {
      this.expandCard = false;
    }

    if (indexesOfPreviouslyExpandedCards.length === 0) {
      titleElement.style.overflow = 'visible';
      this.showEllipsesButton = false;
    }

    this.indexesOfCardsToExpand = newIndexesOfCardsToExpand;

    // Set state in content card service
    this.contentCardService.setIndexesOfExpandedCards(
      newIndexesOfCardsToExpand
    );
    this.contentCardService.setSectionWithExpandedCards(this.sectionType);
    this.contentCardService.setLazyChunkIndex(this.lazyChunkIndex);
    this.contentCardService.setMostRecentIndexClicked(this.cardIndex);

    // Only the profile collection component is using this event emitter
    this.clickedTitleEllipses.emit(newIndexesOfCardsToExpand);
  }

  public openDetails(e: MouseEvent) {
    this.content.recommendationInfo = this.recommendationInfo;
    this.detailsModalService
      .openDetails(this.content)
      .subscribe(({ resource }) => {
        if (resource) {
          // update the content item from any changes that occurred in the more details modal
          this.content = resource;
          this.cdr.detectChanges();
        }
      });
  }

  public onEnrollClick(e: MouseEvent) {
    if (!this.isPreview) {
      this.content.isEnrolled ? this.unenroll() : this.enroll();
    }
  }

  public onImageClick(e: MouseEvent) {
    if (this.clickable && !this.isPreview) {
      e.stopPropagation();
      this.trackContentClicked(e);
      this.link.nativeElement.click();
    }
  }

  public viewItem(e: MouseEvent) {
    this.openDetails(e);
    // TODO:kg need to hook up a/b testing here. OG behavior = open a new tab. New behavior = open the more details modal

    this.trackContentClicked(e);
  }

  public shareContent(event) {
    if (!this.isPreview) {
      this.contentCardService.showShareModal(this.content, event);
    }
  }

  public onFollowTargetClick() {
    if (this.isPreview) {
      return;
    }
    if (this.content.isFollowing) {
      this.unfollowTarget();
    } else {
      this.followTarget();
    }
  }

  public openLiveSession() {
    this.windowRef.open(this.liveUrl, '_blank', 'noopener,nofollow');
    const currentLiveSession = this.liveEventsService.getLiveSession(
      this.content.liveSessions
    );
    const trackingProperties = this.liveEventsService.getTrackingProperties(
      this.content,
      currentLiveSession
    );
    this.trackerService.trackEventData({
      action: 'JoinSession',
      properties: trackingProperties,
    });
  }

  public trackContentClicked(event: Event) {
    if (this.isPreview) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.trackerService.trackEventData({
      action: 'Content Clicked',
      properties: {
        catalogSource: this.content.internalUrl
          ? CatalogSource.Internal
          : CatalogSource.External,
        contentId: this.content.resourceId,
        contentName: this.content.title,
        contentType: this.content.resourceType,
        hasImage: !!this.content.imageUrl,
        isEndorsed: this.content.isEndorsed,
        organizationId: this.content.organizationId,
        hostedType: this.content.model.hostedType,
        SectionType: this.sectionType,
        providerId: this.content.providerSummary?.id,
        relationship:
          this.recommendationInfo?.recommendationType == 'RequiredLearning'
            ? 'isRequired'
            : this.recommendationInfo?.recommendationType == 'AssignedLearning'
              ? 'isAssigned'
              : this.recommendationInfo?.recommendationType == 'Recommendation'
                ? 'isShared'
                : 'Null',
      },
    });
  }

  public addNewQueryParam(href: string, queryParam: string): string {
    return href?.indexOf('?') > -1
      ? `${href}&${queryParam}`
      : `${href}?${queryParam}`;
  }

  public getContentCardAriaLabel(content: LearningResourceViewModel) {
    const translatedContentType = this.translate.instant(
      `Core_${content.resourceType}`
    );
    const contentTitle = content.title;
    return `${translatedContentType} - ${contentTitle}`;
  }

  private enroll() {
    const pathway: PathwayDetailsModel = this.content as any;
    pathway.id = this.content.resourceId;
    this.content.isEnrolled = true;
    this.pathwayEnrollmentService.enroll(pathway).subscribe({
      error: () => {
        this.content.isEnrolled = false;
      },
    });
  }

  private unenroll() {
    const pathway: PathwayDetailsModel = this.content as any;
    pathway.id = this.content.resourceId;
    this.content.isEnrolled = false;
    this.pathwayEnrollmentService
      .unenrollConfirmation(pathway)
      .pipe(
        catchError((e) => {
          this.content.isEnrolled = true;
          return throwError(e);
        })
      )
      .subscribe();
  }

  private followTarget() {
    const inputs = {
      target: this.content,
    };

    return this.modalService
      .show<boolean>(FollowTargetModal, { inputs })
      .subscribe((followPlan) => {
        if (followPlan) {
          this.content.isFollowing = true;
          this.cdr.markForCheck();
        }
      });
  }

  private unfollowTarget() {
    const inputs = {
      bodyText: this.translate.instant('dgTargetTile_UnfollowExplanation', {
        targetType: this.sharedTargetService.getTypeDisplayName(
          this.content.targetType
        ),
      }),
      canCancel: true,
      headerText: this.translate.instant('dgTargetTile_UnfollowConfirm', {
        targetType: this.sharedTargetService.getTypeDisplayName(
          this.content.targetType
        ),
      }),
      submitButtonText: this.i18n.Core_YesSure,
    };

    this.modalService
      .show(SimpleModalComponent, {
        inputs,
      })
      .pipe(
        switchMap(() => {
          return this.sharedTargetService.unfollowTarget(
            this.content.model as unknown as Target
          );
        })
      )
      .subscribe(() => {
        this.content.isFollowing = false;
        this.cdr.markForCheck();
      });
  }

  private getShowProvider() {
    const isClientProvider =
      this.authService.authUser?.defaultOrgInfo?.settings.isClientProvider;

    return !isClientProvider && !!this.content.providerSummary?.name;
  }
}
