import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** A helpful base class to extend for directives and components that maintain hot Observable subscriptions over their lifetime.
 * If inheritance isn't an option, the {@link SubscriptionManagerService} contains the same functionality as an alternative.
 * @description To use this, call the pipeable operator {@link takeUntilDestroyed} prior to subscribing which will automatically `complete`
 * the observable when the component is destroyed.
 * ```myHotObservable.pipe(this.takeUntilDestroyed()).subscribe(...)```
 *  Derived directives that implement {@link OnDestroy} themselves must call base.ngOnDestroy() too.
 */
@Directive()
export abstract class SubscriberBaseDirective implements OnDestroy {
  private destroying = new Subject<void>();

  public ngOnDestroy(): void {
    this.destroying.next();
    this.destroying.complete();
  }

  protected takeUntilDestroyed<T>() {
    return <T>(source: Observable<T>) =>
      source.pipe(takeUntil<T>(this.destroying));
  }
}
