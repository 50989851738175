import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InputDetails } from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';
import {
  InputType,
  RecommendationInfo,
} from '@app/shared/models/core-api.model';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { ActionButtonSize } from '../action-button/action-button.component';
import { UserRecommendationHeaderItem } from '@app/shared/components/user-recommendation-header/user-recommendation-header.component';

@Component({
  selector: 'dgx-input-completion',
  templateUrl: './input-completion.component.html',
  styleUrls: ['./input-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCompletionComponent implements OnInit {
  @Input() public input: LearningResourceViewModel;
  @Input() public isCompleted = false;
  /** *Must be used with `tooltip`.* */
  @Input() public iconOnly: boolean = false;
  /** Add a (translated) tooltip. */
  @Input() public tooltip: string;
  /** When the recommendation type is required, the external link will show.  */
  @Input() public recommendationInfo?:
    | UserRecommendationHeaderItem
    | RecommendationInfo;
  /** Size of the button. Default xs */
  @Input() public size?: ActionButtonSize = 'xs';
  /** Uses the search button styles if true */
  @Input() public isSearchResultCard?: boolean = false;
  /** When the component is finished updating. Returns true/false indicating success. */
  @Output() public updated = new EventEmitter<boolean>();

  public isCompletionInFlight = false;
  public isRequiredContent: boolean = false;
  public masteryPoints: number;
  public newlyCompleted = false;

  public i18n = this.translate.instant([
    'Core_MarkAsComplete',
    'Core_Completed',
    'Core_Pts',
    'Core_VisitProvider',
  ]);

  constructor(
    @Inject(WindowToken) private windowRef: Window,
    private inputsService: InputsService,
    private webEnvironmentService: WebEnvironmentService,
    private translate: TranslateService,
    private userInputsService: UserInputsService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  public get isNewlyCompleted() {
    return this.isCompleted && !!this.masteryPoints;
  }

  public get completeButtonLabel() {
    if (!this.iconOnly) {
      return this.isCompleted
        ? this.i18n.Core_Completed
        : this.i18n.Core_MarkAsComplete;
    }
  }

  public get completionTooltip(): string {
    return this.tooltip
      ? this.input.completionInfo.isCompleted
        ? this.i18n.Core_Completed
        : this.tooltip
      : undefined;
  }

  public ngOnInit() {
    this.isRequiredContent =
      this.recommendationInfo?.recommendationType ===
      UserRecommendationType.RequiredLearning;
  }

  public onCompleteClick() {
    // prevent second click while API calls pending
    if (this.isCompletionInFlight) {
      return;
    }
    this.isCompletionInFlight = true;

    // If an external completion, only visit the external content
    if (
      this.input.completionInfo.isCompleted &&
      this.input.completionInfo.externalCompletionOnly
    ) {
      return this.viewExternalContent();
    }

    // call the appropriate toggle in 'this' context
    const toggle = this.input.completionInfo.isCompleted
      ? () => this.uncompleteInput()
      : () => this.completeInput();
    this.isCompleted = !this.isCompleted;
    toggle().subscribe(
      () => this.updated.emit(true),
      () => this.updated.emit(false),
      () => {
        this.tooltip =
          this.tooltip === this.i18n.Core_MarkAsComplete
            ? this.i18n.Core_Completed
            : this.i18n.Core_MarkAsComplete;

        this.isCompletionInFlight = false;
        this.cdr.markForCheck();
      }
    );
  }

  public viewExternalContent() {
    const url =
      this.input?.externalUrl || this.input?.internalUrl || this.input?.url;
    if (url) {
      this.windowRef.open(url, '_blank');
    }
    return;
  }

  private completeInput() {
    // Is the item new to our system?
    if (!this.input.resourceType) {
      // add it to the system
      return this.inputsService
        .addExternalInput(this.input.model as InputDetails)
        .pipe(
          switchMap((r: any) => {
            this.input.resourceId = r.data;
            return this.addUserInput();
          }),
          catchError((e) => {
            this.input.setCompletionState(undefined);
            return throwError(e);
          })
        );
    } else {
      return this.addUserInput();
    }
  }

  private addUserInput() {
    const tags = [];
    if (this.input.model.tags) {
      if (Array.isArray(this.input.model.tags)) {
        // in the content catalog, the Tags property is an array.
        this.input.model.tags.forEach((tag) => {
          tags.push({ name: tag.name, title: tag.name });
        });
      } else {
        for (const tag of this.input.model.tags.split(',')) {
          tags.push({ name: tag, title: tag });
        }
      }
    }

    return this.userInputsService
      .addUserInput(
        {
          inputType: this.input.resourceType as InputType,
          inputId: this.input.resourceId,
        },
        this.input.getTrackingCopy(),
        this.webEnvironmentService.analyticsAppLocation,
        this.input.pathwayStepDetails?.isOptional,
        this.elementRef.nativeElement,
        tags
      )
      .pipe(
        tap((r) => {
          if (r.masteryPoints !== undefined) {
            this.masteryPoints = r.masteryPoints;
          }
          this.input.setCompletionState(r.userInputId);
        })
      );
  }

  private uncompleteInput() {
    const completionId = this.input.completionInfo.userInputId;
    this.input.setCompletionState(undefined);

    return this.userInputsService
      .deleteUserInput(
        {
          inputId: this.input.resourceId,
          inputType: this.input.resourceType as InputType,
          userInputId: this.input.completionInfo.userInputId,
        },
        this.input.pathwayStepDetails?.isOptional,
        this.elementRef.nativeElement
      )
      .pipe(
        catchError((e) => {
          this.input.setCompletionState(completionId); // restore old state if call failed
          return throwError(e);
        }),
        finalize(() => {
          this.inputsService.notifyInputModified(
            this.input.resourceType as InputType
          );
        })
      );
  }
}
