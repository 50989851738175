import showdown from 'showdown';

const alwaysDisabledOptions = [
  'codeBlocks',
  'codeSpans',
  'githubCodeBlocks',
  'headers',
  'horizontalRule',
  'outdent',
  'strikethrough',
  'underline',
];
const imageOption = 'images'; // conditionally disabled

type SubParserFunc = (
  text: string,
  options: any,
  globals: any,
  name?: string // provided optionally since original parser doesn't require
) => string;
const parserMap = new Map<any, SubParserFunc>();

// Save the original image parser
const defaultImageParser: SubParserFunc = showdown.subParser(imageOption);

// Replace with the proxy one
// subParser(name)       - Get a registered subParser
// subParser(name, func) - Register a subParser
showdown.subParser(imageOption, (text, options, globals) =>
  dynamicProxySubParser(text, options, globals, imageOption)
);

// Disable subparsers for elements we don't support
for (const parserName of alwaysDisabledOptions) {
  disableDefaultSubParser(parserName);
}

/** Creates a Showdown.js markdown converter, limited to our supported options, optionally with image support */
export function createShowdownConverter({
  allowImages = false,
  openLinksInNewWindow = false,
  encodeEmails = false
}: {
  allowImages?: boolean;
  openLinksInNewWindow?: boolean;
  encodeEmails?: boolean
}) {
  const converter = new showdown.Converter({
    simplifiedAutoLink: true,
    simpleLineBreaks: true,
    openLinksInNewWindow,
    encodeEmails
  });

  parserMap.set(
    converter,
    allowImages ? defaultImageParser : bypassedSubParser
  );
  return converter;
}

function disableDefaultSubParser(parserName: string) {
  showdown.subParser(parserName, (text, options, globals) =>
    bypassedSubParser(text, options, globals, parserName)
  );
}

function dynamicProxySubParser(
  text: string,
  options: any,
  globals: any,
  name: string
) {
  const parser = parserMap.get(globals.converter);
  return parser(text, options, globals, name);
}

function bypassedSubParser(
  text: string,
  options: any,
  globals: any,
  name: string
) {
  text = globals.converter._dispatch(name + '.before', text, options, globals);
  text = globals.converter._dispatch(name + '.after', text, options, globals);
  return text;
}
