import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import { Observable } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';

// misc
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { VideoModel } from '@app/user-content/user-input-v2/inputs/video/video.model';
import { VideoCatalogFacade } from '@app/user-content/user-input-v2/inputs/video/services/catalog/video-catalog.facade';
import {
  maxFifteenSkillsValidator,
  atLeastOneRequiredValidator,
  contentOwnerIdValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import {
  validateEntryUrlError,
  hasBrokenUrlValidator,
} from '@app/user-content/user-input-v2/utils/catalog-form-utils';

import { DgError } from '@app/shared/models/dg-error';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { handleVideoFocusOnSubmit } from '@app/user-content/user-input-v2/inputs/video/services/video.utils';
import { ModalService } from '@dg/shared-services';
import { AuthorVideoModalComponent } from '@app/author/components/author-video-modal/author-video-modal.component';
import { AzureUserAuthoredResult } from '@app/content-hosting';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import {
  onFormControlUpdate,
  markFormAsTouched,
} from '@app/shared/utils/angular-form-field-helpers';
@Component({
  selector: 'dgx-video-catalog',
  templateUrl: './video-catalog.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoCatalogComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @ViewChild('entryUrl')
  public entryUrlInput: ElementRef<HTMLElement>;
  @ViewChild('form') public form: NgForm;

  public videoCatalogForm: FormGroup;
  public heading: string;
  public vm$: Observable<VideoModel>;
  public showExpandedForm: boolean = false;
  public i18n = this.translate.instant([
    'Core_Description',
    'Core_GeneralErrorMessage',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Or',
    'Core_Skills',
    'Core_Source',
    'Core_Title',
    'dgContentHosting_DragAndDrop',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContentForm_Record',
    'dgOrgInternalContentForm_RecordAVideo',
    'dgOrgInternalContentForm_ReRecordVideo',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContentForm_VideoFormat',
    'dgOrgInternalContentForm_VideoFormatSelectPlaceholder',
    'dgOrgInternalContentForm_VideoDescriptionPlaceholder',
    'dgOrgInternalContentForm_VideoTimeLimit',
    'MediaFormCtrl_AddVideo',
    'MediaFormCtrl_EditVideo',
    'MediaFormCtrl_ValidContentOwnerRequired',
    'MediaFormCtrl_SaveVideo',
    'MediaFormCtrl_TitleRequired',
    'MediaFormCtrl_UrlRequired',
    'MediaFormCtrl_UrlOrRecordingRequired',
    'MediaFormCtrl_UrlOrVideoUploadRequired',
    'MediaFormCtrl_UrlOrVideoUploadOrRecordingRequired',
    'MediaFormCtrl_UrlOrUploadRequired',
    'MediaFormCtrl_VideoUrl',
  ]);
  public isRecordedVideo: boolean = false;

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private facade: VideoCatalogFacade,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private modalService: ModalService
  ) {
    super();
    this.vm$ = this.facade.viewModel$ as any as Observable<VideoModel>;
  }

  public async ngOnInit(): Promise<void> {
    await this.facade.initializeViewModel(this.context);

    this.heading = this.facade.snapshot.inputContext.isEditing
      ? this.i18n.MediaFormCtrl_EditVideo
      : this.i18n.MediaFormCtrl_AddVideo;
    if (this.facade.snapshot.inputContext.isEditing) {
      await this.facade.initializeEdit();
      this.initializeExpandedEditForm();
      return;
    }
    // Setup the view model
    this.initializeForm();
  }
  /****************************************************
   * On form page submissions
   ****************************************************/

  public async onNext(form: FormGroupDirective) {
    markFormAsTouched(this.videoCatalogForm);
    if (this.videoCatalogForm.invalid) {
      return;
    }

    if (
      (!!this.facade.snapshot.hostedContentDetails &&
        !!this.videoCatalogForm.get('contentUploader')?.value) ||
      this.isRecordedVideo
    ) {
      // NOTE: unlike Articles, Videos use the entryUrl when uploading&recording videos
      // videos are degreed://user
      await this.facade.onNext(null);
    } else {
      await this.onEntryUrlNext();
    }

    // reset the submitted state on the form
    form?.resetForm(this.videoCatalogForm.value);

    this.loadExpandedForm();
  }

  /**
   * On form Submission check if the form is valid
   */
  public async onSubmit(): Promise<void> {
    markFormAsTouched(this.videoCatalogForm);
    if (this.videoCatalogForm.invalid) {
      handleVideoFocusOnSubmit(this.videoCatalogForm.controls);
      return;
    }
    const entryUrlValue = this.videoCatalogForm.get('entryUrl')?.value;
    if (
      !!entryUrlValue &&
      !this.videoCatalogForm.get('contentUploader')?.value
    ) {
      await this.facade.fetchUrlDuplicates(entryUrlValue);
      if (this.facade.snapshot.duplicateCount > 0) {
        this.videoCatalogForm.get('entryUrl').markAsTouched();
        return;
      }
    }
    await this.facade.onSubmit(this.videoCatalogForm);
    this.activeModal.close(this.facade.snapshot);
  }

  /**
   * When the URL has duplicates we display a message with the option to view the duplicates in a modal
   * Open the view duplicates modal
   */
  public openViewDuplicates(): void {
    this.facade.viewDuplicates();
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  /**
   * On the initial catalog form page if the
   * @returns
   */
  public validateEntryUrlError(): boolean {
    return validateEntryUrlError(
      this.translate,
      this.facade.snapshot.isInitialForm,
      this.videoCatalogForm.get('entryUrl'),
      this.videoCatalogForm.hasError('atLeastOneRequired'),
      this.facade.snapshot.hasBrokenUrl,
      this.facade.snapshot.entryUrl
    );
  }

  /**
   * Calculate the correct error message to display
   *
   * @returns string translation for URL error message to be shown
   */
  public get getUrlErrorMessage(): string {
    if (this.videoCatalogForm.hasError('atLeastOneRequired')) {
      const shouldShowContentUploader =
        !!this.facade.snapshot.shouldShowContentUploader;
      const shouldShowRecordVideoButton =
        !!this.facade.snapshot.shouldShowRecordVideoButton;
      if (shouldShowContentUploader && shouldShowRecordVideoButton)
        return this.i18n.MediaFormCtrl_UrlOrVideoUploadOrRecordingRequired;
      if (shouldShowContentUploader)
        return this.i18n.MediaFormCtrl_UrlOrVideoUploadRequired;
      if (shouldShowRecordVideoButton)
        return this.i18n.MediaFormCtrl_UrlOrRecordingRequired;
    }

    const hasBrokenUrlValidatorcos = hasBrokenUrlValidator(
      this.translate,
      this.facade.snapshot.hasBrokenUrl,
      this.facade.snapshot.entryUrl,
      this.videoCatalogForm.get('entryUrl').value
    );
    if (!!hasBrokenUrlValidatorcos) {
      return hasBrokenUrlValidatorcos.urlBrokenValidation;
    }

    return this.i18n.MediaFormCtrl_UrlRequired;
  }

  public onFormControlUpdate(field: string, value: any): void {
    onFormControlUpdate(this.videoCatalogForm, field, value);
  }

  /**
   * Loads recorded video modal and update the form's mediaUrl
   */
  public loadRecordedVideoModal(isRerecording: boolean = false): void {
    this.modalService
      .show(AuthorVideoModalComponent, {
        backdropClickClose: true,
        windowClass: 'xlg-modal',
      })
      .subscribe((data: AzureUserAuthoredResult) => {
        if (!!data) {
          this.videoCatalogForm.get('entryUrl').setValidators(null);
          this.isRecordedVideo = true;
          this.facade.recordedVideoUploaded(
            this.videoCatalogForm,
            'entryUrl',
            data
          );
          if (!isRerecording) {
            this.onNext(null);
          }
        }
      });
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    let groupDefinition = {};
    let validators = ['entryUrl'];

    if (this.facade.snapshot.shouldShowContentUploader) {
      groupDefinition = { ['contentUploader']: ['', []] };
      validators.push('contentUploader');
    }

    this.videoCatalogForm = this.formBuilder.group(
      {
        entryUrl: ['', [Validators.pattern(HTTP_REQUIRED_URL_PATTERN)]],
        ...groupDefinition,
      },
      {
        validator: atLeastOneRequiredValidator(validators),
      }
    );
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm(): void {
    this.showExpandedForm = true;
    const entryUrlValidators =
      this.isRecordedVideo ||
      !!this.videoCatalogForm.get('contentUploader')?.value
        ? []
        : [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)];

    let inputEntry: any = {
      entryUrl: [
        this.videoCatalogForm.get('entryUrl').value,
        entryUrlValidators,
      ],
    };
    if (!!this.videoCatalogForm.get('contentUploader')?.value) {
      inputEntry = {
        ...inputEntry,
        contentUploader: [
          this.videoCatalogForm.get('contentUploader').value,
          Validators.required,
        ],
      };
    }
    this.videoCatalogForm = this.formBuilder.group({
      ...inputEntry,
      title: ['', [Validators.required, isEmptyValidator]],
      sourceName: [],
      format: [],
      description: [],
      owner: ['', Validators.required],
      image: [],
      skills: ['', maxFifteenSkillsValidator],
    });

    this.videoCatalogForm.patchValue({
      title: this.facade.snapshot.title,
      sourceName: this.facade.snapshot.sourceName,
      format: this.facade.snapshot.format,
      description: this.facade.snapshot.summary,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.highConfidenceInferredSkills ?? [],
    });

    // When loading the full view reset the focus on the url input if we do not have
    // hosted content, otherwise dgxautofocus will focus on the title.
    if (!this.facade.snapshot.hostedContentDetails) {
      this.entryUrlInput?.nativeElement.focus();
    }
    // Subscribe to value changes on the entryURL to reset the duplicates error
    if (
      !!this.videoCatalogForm.get('entryUrl')?.value &&
      !this.isRecordedVideo &&
      !this.videoCatalogForm.get('contentUploader')?.value
    ) {
      this.videoCatalogForm
        .get('entryUrl')
        ?.valueChanges.subscribe(() => this.facade.resetDuplicates());
    }
    this.cdr.detectChanges();
  }

  /**
   * On edit initialize the full form
   */
  private initializeExpandedEditForm() {
    this.showExpandedForm = true;
    this.isRecordedVideo = this.isAuthoredContent(
      this.facade.snapshot.entryUrl
    );
    const urlValidators =
      this.isRecordedVideo || !!this.facade.snapshot.hostedContentDetails
        ? []
        : [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)];

    let inputEntry: any = {
      entryUrl: [this.facade.snapshot.entryUrl, urlValidators],
    };
    if (!!this.facade.snapshot.hostedContentDetails && !this.isRecordedVideo) {
      inputEntry = {
        ...inputEntry,
        contentUploader: [
          this.facade.snapshot.hostedContentDetails,
          Validators.required,
        ],
      };
    }
    const createdByI18n = this.translate.instant('Core_CreatedBy', {
      authorName: this.facade.snapshot.createdBy,
    });

    const createdBy = !!this.facade.snapshot.createdBy ? createdByI18n : '';

    this.videoCatalogForm = this.formBuilder.group({
      ...inputEntry,
      createdBy: createdBy,
      title: [
        this.facade.snapshot.title ?? '',
        [Validators.required, isEmptyValidator],
      ],
      sourceName: this.facade.snapshot.sourceName ?? '',
      description: this.facade.snapshot.summary ?? '',
      format: this.facade.snapshot.format ?? '',
      owner: [
        this.facade.snapshot.owner ?? '',
        [Validators.required, contentOwnerIdValidator],
      ],
      image: this.facade.snapshot.imageUrl ?? '',
      skills: [this.facade.snapshot.tags ?? [], maxFifteenSkillsValidator],
    });
  }

  /**
   * On selecting next from the initial form
   */
  private async onEntryUrlNext(): Promise<void> {
    await this.facade.onNext(this.videoCatalogForm.get('entryUrl').value);

    // Check if the entry URL has duplicates, if it does the UI will display corresponding error
    if (this.facade.snapshot.duplicateCount > 0) {
      return;
    }
  }

  /**
   * Check to see if the content is a recorded video
   *
   * @param entryUrl
   * @returns true is entryUrl is recorded video
   */
  private isAuthoredContent(entryUrl: string) {
    return entryUrl?.indexOf('userauthoredvideo') > -1;
  }
}
