import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { tagHasRedactedRatings } from '@app/shared/utils/tag-helpers';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import {
  TagRatingTrackerService,
  TagRatingTrackingType,
} from '@app/tags/services/tag-rating-tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes, TagRatingMeta } from '@app/tags/tags';
import { UserProfile } from '@app/user/user-api.model';
import { NotificationType } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { CLICKABLE_TILE_CLASS, NEW_TILE_CLASS } from './tag-rating-button';

/**
 * Base class that provides common logic and interface for tag rating buttons.
 */
@Directive()
export abstract class TagRatingButtonBaseComponent {
  @Input() public tag: TagsApi.TagDetails;
  @Input() public tagRatingDetails?: TagsApi.UserTagRatingDetails[];
  @Input() public availableRatingTypes?: TagsApi.RatingType[];
  @Input() public ownerIsViewing?: boolean = false;
  @Input() public trackingLocation?: string;

  @ViewChild('target') public target: ElementRef;

  public abstract type: InternalTagRatingTypes | string;

  public readonly NotificationType = NotificationType;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef
  ) {}

  public get authUser(): AuthUser {
    return this.authService.authUser;
  }

  // Ratings
  public get allRatings(): TagsApi.UserTagRatingDetails[] {
    return this.ratingsForTypePipe.transform(this.tagRatingDetails, this.type);
  }

  public get completedRatings(): TagsApi.UserTagRatingDetails[] {
    return this.completedTagRatingsPipe.transform(this.allRatings);
  }

  public get incompleteRatings(): TagsApi.UserTagRatingDetails[] {
    return this.incompleteTagRatingsPipe.transform(this.allRatings);
  }

  public get level(): string {
    return this.completedRatings?.[0]?.level;
  }

  public get hasRatings(): boolean {
    return !!this.allRatings?.length;
  }

  public get hasIncompleteRatings(): boolean {
    return !!this.incompleteRatings?.length;
  }

  public get hasCompletedRatings(): boolean {
    return !!this.completedRatings?.length;
  }

  public get hasRedactedRatings(): boolean {
    return tagHasRedactedRatings(this.completedRatings);
  }

  public get ratingType(): TagsApi.RatingType {
    return this.availableRatingTypes?.find((type) => type.name === this.type);
  }

  // Endorsers

  public get endorsers(): UserProfile[] {
    return this.tagRatingEndorsersPipe.transform(
      this.allRatings
    ) as unknown as UserProfile[];
  }

  public get endorser(): UserProfile {
    return this.endorsers?.[0];
  }

  public get hasSingleEndorser(): boolean {
    return false;
  }

  public get hasMultipleEndorsers(): boolean {
    return false;
  }

  public get providerName(): string {
    // If no ratings provided, fall back to Degreed
    return this.allRatings?.[0]?.providerName || 'Degreed';
  }

  public get privacyId(): number {
    return this.completedRatings?.[0]?.privacyId;
  }

  public get endorsedByLabel(): string {
    // Linter Error
    // placeholder to prevent build errors
    return '';
  }

  // Rating type label

  /** Rating type label: e.g. "Skill Certification" or "Codecademy" */
  public get ratingTypeLabel(): string {
    return this.ratingTypePipe.transform(
      this.allRatings?.[0] || {
        type: this.type,
        ...this.ratingType,
      }
    );
  }

  // Description label

  /** Description label: e.g. "Rate yourself" */
  public get ratingDescriptionLabel(): string {
    return '';
  }

  // Rating level label

  /** In-progress level label: e.g. "In progress" */
  public get inProgressRatingLevelLabel(): string {
    return this.translateService.instant('dgTagRating_InProgress');
  }

  /** Completed level label: e.g. "Level 5" */
  public get completedRatingLevelLabel(): string {
    const params = { Level: this.level };
    return this.translateService.instant('dgTagRating_Level', params);
  }

  /** The appropriate level label for the current rating state */
  public get ratingLevelLabel(): string {
    return this.hasCompletedRatings
      ? this.completedRatingLevelLabel
      : this.hasIncompleteRatings
      ? this.inProgressRatingLevelLabel
      : '';
  }

  // Call to action

  /** Call to action label for new ratings: e.g. "Add Rating" */
  public get noRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_AddRating');
  }

  /** Call to action label for in progress ratings: e.g. "Cancel Request" */
  public get inProgressRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ContinueProcess');
  }

  /** Call to action label for new tag ratings: e.g. "View Rating" */
  public get completedRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ViewRating');
  }

  /** The appropriate call to action label for the current rating state */
  public get CTALabel(): string {
    return this.hasCompletedRatings
      ? this.completedRatingCTAlabel
      : this.hasIncompleteRatings
      ? this.inProgressRatingCTAlabel
      : this.noRatingCTAlabel;
  }

  public get showCTA(): boolean {
    return this.ownerIsViewing;
  }

  public get showCTAPlus(): boolean {
    return !this.hasRatings;
  }

  // Pending rating notifiation

  public get pendingRatingNotificationLabel(): string {
    return this.translateService.instant('dgTagRating_RatingRequested');
  }

  public get showPendingRatingNotification(): boolean {
    return this.hasIncompleteRatings && this.ownerIsViewing;
  }

  // Action menu

  public get menuConfig(): MenuViewModel[] {
    return undefined;
  }

  public get showActionMenu(): boolean {
    return this.ownerIsViewing && !!this.menuConfig && this.hasRatings;
  }

  // Rating meta

  public get ratingMeta(): TagRatingMeta {
    return this.ratingType
      ? {
          duration:
            this.ratingType.durationUnitType === 'hours'
              ? this.ratingType.durationUnits * 60 // transform to minutes
              : this.ratingType.durationUnits,
          visibility: this.translateService.instant(
            'dgTagRating_EvalVisibility'
          ),
        }
      : undefined;
  }

  public get showRatingMeta(): boolean {
    return this.ownerIsViewing && !this.hasRatings && !!this.ratingMeta;
  }

  // Provider logo path

  public get imgSrc(): string {
    const provider = this.providerName.toLowerCase();
    const image = this.hasCompletedRatings ? provider : `grayscale-${provider}`;
    return `/content/img/app/tag-ratings/${image}.svg`;
  }

  public get tileClasses(): { [key: string]: boolean } {
    return {
      [CLICKABLE_TILE_CLASS]: this.showCTA,
      [NEW_TILE_CLASS]: !this.hasRatings && this.ownerIsViewing,
    };
  }

  public get showPrivacyDropdown(): boolean {
    return this.ownerIsViewing && this.hasCompletedRatings;
  }

  // base dgat value based on rating type

  public get dgatType(): string {
    return `${this.type.toLowerCase()}-rating-button`;
  }

  /**
   * Handle all click/touch events
   *
   * @param event
   */
  public abstract handleClick(event: Event): void;

  /**
   * A11y: allow mouse click area to be larger than the keyboard
   * focus area so that screen readers get correct header text
   *
   * @param event
   */
  public captureTileClick(event: Event) {
    event.stopImmediatePropagation();
    this.target?.nativeElement.click();
  }

  /**
   * Change rating privacy
   *
   * @param privacyId
   */
  public setPrivacy(privacyId: number): void {
    this.tagRatingTrackerService.trackRatingVisibilityChange(
      this.type as TagRatingTrackingType,
      this.completedRatings[0].privacyId,
      privacyId,
      this.tag
    );
    this.tagsService
      .updateTagRatingPrivacy(
        this.completedRatings[0].userTagRatingId,
        privacyId
      )
      .subscribe();
  }
}
