import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { twJoin, twMerge } from '../../../../utils';
import { LayoutFeature, LayoutNavigationItem } from '../../layout.model';
import { isActive } from '../../layout.utils';

@Component({
  selector: 'da-nav-popover',
  template: `
    <button
      type="button"
      [class]="
        twJoin(
          'tw-relative tw-flex tw-w-full tw-items-center tw-py-3 tw-pl-7 tw-pr-3 tw-text-left tw-text-xs rtl:tw-pl-3 rtl:tw-pr-7 rtl:tw-text-right',
          'hover:tw-bg-[rgb(from_var(--apollo-layout-highlight,theme(colors.neutral.800))_r_g_b_/_15%)] hover:tw-text-[var(--apollo-layout-highlight,theme(colors.neutral.800))]',
          'focus:tw-bg-[rgb(from_var(--apollo-layout-highlight,theme(colors.neutral.800))_r_g_b_/_15%)] focus:tw-text-[var(--apollo-layout-highlight,theme(colors.neutral.800))] focus:tw-outline-none',
          'active:tw-bg-[rgb(from_var(--apollo-layout-highlight,theme(colors.neutral.900))_r_g_b_/_30%)] active:tw-text-[var(--apollo-layout-highlight,theme(colors.neutral.900))]',
          active
            ? 'tw-font-semibold tw-text-[var(--apollo-layout-highlight)]'
            : '',
          open
            ? 'tw-bg-[rgb(from_var(--apollo-layout-highlight,theme(colors.neutral.800))_r_g_b_/_15%)]'
            : ''
        )
      "
      [title]="item.text"
      [attr.data-dgat]="item.dgat"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="handleToggleExpanded(item)"
    >
      <da-nav-icon
        [item]="item"
        [active]="active"
        [className]="
          twJoin(
            'tw-size-6 tw-shrink-0 tw-min-w-6',
            iconOnly
              ? 'tw-mr-1 rtl:tw-mr-0 rtl:tw-ml-1'
              : 'tw-mr-2 rtl:tw-mr-0 rtl:tw-ml-2',
            item.rtlMirrorIcon ? 'rtl:tw-scale-x-[-1]' : ''
          )
        "
      />

      <span
        [class]="
          twJoin(
            iconOnly ? 'tw-sr-only' : 'tw-truncate',
            beta?.enabled && item.isBeta ? '' : 'tw-grow'
          )
        "
        >{{ item.text }}</span
      >

      <div
        *ngIf="beta?.enabled && item.isBeta"
        [class]="
          twJoin(
            'tw-grow',
            iconOnly
              ? 'tw-absolute tw-right-2 tw-top-1 rtl:tw-left-2 rtl:tw-right-auto'
              : 'tw--mt-2 tw-flex tw-self-start'
          )
        "
      >
        <div
          [class]="
            twJoin(
              'tw-rounded-2xl tw-border tw-border-purple-300 tw-bg-purple-100 tw-px-1 tw-py-0.5 tw-text-[8px] tw-font-extrabold tw-uppercase tw-leading-3 tw-text-purple-800'
            )
          "
        >
          {{ beta.text }}
        </div>
      </div>

      <da-icon
        icon="chevron-right"
        [className]="
          twJoin(
            'tw-size-3 rtl:tw-rotate-180',
            iconOnly ? '' : 'tw-ml-2 rtl:tw-ml-0 rtl:tw-mr-2'
          )
        "
      />

      <div
        *ngIf="active"
        [class]="
          twJoin(
            'tw-absolute tw-inset-y-0 tw-right-0 tw-w-1 tw-rounded-l tw-bg-[var(--apollo-layout-highlight)] rtl:tw-left-0 rtl:tw-right-auto rtl:tw-rounded-l-none rtl:tw-rounded-r'
          )
        "
      ></div>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="open"
      [cdkConnectedOverlayPositions]="[
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom'
        }
      ]"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayPanelClass="tw-reset"
      (detach)="open = false"
      (backdropClick)="open = false"
    >
      <div
        @PopoverTrigger
        class="tw-reset tw-ml-2 tw-min-w-60 tw-origin-left tw-rounded-lg tw-bg-white tw-py-2 tw-shadow-xl tw-ring-1 tw-ring-neutral-200 tw-transition rtl:tw-ml-0 rtl:tw-mr-2 rtl:tw-origin-right"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
      >
        <header
          class="tw-border-b tw-border-neutral-300 tw-px-4 tw-pb-4 tw-pt-2"
        >
          <h4 class="tw-font-extrabold">{{ item.text }}</h4>
        </header>
        <ul class="tw-flex tw-flex-col">
          <li
            *ngFor="let subItem of item.subItems; let i = index"
            [class]="
              i > 0 && subItem?.includeSeparator
                ? 'tw-border-t tw-border-neutral-300'
                : ''
            "
          >
            <ng-container *ngIf="subItem?.routerLink; else externalLink">
              <a
                [routerLink]="subItem.routerLink"
                [class]="
                  twMerge(
                    'tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-neutral-100 focus:tw-bg-neutral-100 focus-visible:tw-outline-none',
                    isSubItemActive(subItem) ? 'tw-font-semibold' : ''
                  )
                "
                [attr.data-dgat]="subItem.dgat"
                (click)="handleClick(subItem)"
              >
                <da-icon
                  *ngIf="subItem.icon"
                  [icon]="subItem.icon"
                  type="solid"
                  className="tw-mr-2 rtl:tw-mr-0 rtl:tw-ml-2 tw-size-6 tw-shrink-0"
                />

                <span class="tw-grow">{{ subItem.text }}</span>

                <da-icon
                  *ngIf="isSubItemActive(subItem)"
                  icon="check-circle"
                  type="solid"
                  className="tw-ml-4 rtl:tw-ml-0 rtl:tw-mr-4 tw-text-green-600 tw-size-6 tw-shrink-0"
                />
              </a>
            </ng-container>

            <ng-template #externalLink>
              <ng-container *ngIf="!!subItem">
                <a
                  [href]="subItem.href"
                  [attr.target]="subItem.target"
                  [class]="
                    twMerge(
                      'tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-neutral-100 focus:tw-bg-neutral-100 focus-visible:tw-outline-none',
                      isSubItemActive(subItem) ? 'tw-font-semibold' : ''
                    )
                  "
                  [attr.data-dgat]="subItem.dgat"
                  (click)="handleClick(subItem)"
                >
                  <da-icon
                    *ngIf="subItem.icon"
                    [icon]="subItem.icon"
                    type="solid"
                    className="tw-mr-2 rtl:tw-mr-0 rtl:tw-ml-2 tw-size-6 tw-shrink-0"
                  />

                  <span class="tw-grow">{{ subItem.text }}</span>

                  <da-icon
                    *ngIf="isSubItemActive(subItem)"
                    icon="check-circle"
                    type="solid"
                    className="tw-ml-4 rtl:tw-ml-0 rtl:tw-mr-4 tw-text-green-600 tw-size-6 tw-shrink-0"
                  />
                </a>
              </ng-container>
            </ng-template>
          </li>
        </ul>
      </div>
    </ng-template>
  `,
  animations: [
    trigger('PopoverTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(95%)' }),
        animate(
          '200ms ease-out',
          style({ opacity: 1, transform: 'scale(100%)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(100%)' }),
        animate(
          '100ms ease-in',
          style({ opacity: 0, transform: 'scale(95%)' })
        ),
      ]),
    ]),
  ],
})
export class NavPopoverComponent implements OnInit, OnDestroy {
  @Input() item!: LayoutNavigationItem;
  @Input() iconOnly?: boolean = false;
  @Input() beta?: LayoutFeature;

  open = false;
  active = false;
  twMerge = twMerge;
  twJoin = twJoin;

  private subscription: Subscription;

  constructor(private router: Router) {
    this.subscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).urlAfterRedirects)
      )
      .subscribe(this.updateActive.bind(this));
  }

  ngOnInit(): void {
    this.updateActive();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleToggleExpanded(item: LayoutNavigationItem) {
    item.trackEvent?.();
    this.open = !this.open;
  }

  handleClick(subItem: LayoutNavigationItem) {
    subItem.trackEvent?.();
    this.open = false;
  }

  updateActive(urlAfterRedirects?: string): void {
    const findItem = (subItem) =>
      this.isSubItemActive(subItem, urlAfterRedirects);
    this.active = this.item.subItems?.some(findItem) ?? false;
  }

  isSubItemActive(subItem: LayoutNavigationItem, url?: string) {
    if (!subItem) return false;
    if (!subItem.href && !subItem.routerLink) return false;

    url = url ?? this.router.url;
    return isActive(url, subItem.href || subItem.routerLink, subItem.end);
  }
}
