import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DfIconChevronDown12, DfIconRegistry } from '@lib/fresco';
import { filter as _filter, forEach as _forEach } from 'lodash-es';
import { SearchFilter, SearchFilterFilter } from '@app/search/search-api.model';
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { A11yService } from '@app/shared/services/a11y.service';
import { AuthService } from '@app/shared/services/auth.service';
import { CareerPathNamingService } from '@app/shared/services/career-path-naming.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit {
  @Input() public showCount: boolean = true;

  @Output() public filtersChange = new EventEmitter<SearchFilter>();

  public isPopoverOpen: boolean = false;
  public selectedFilterItemIds: Set<string> = new Set<string>();
  public hasCareerPathing: boolean = false;
  // the specific types of the filters selected (i.e. 'Language' even if it's grouped under the 'More Filters' filter)
  public selectedFilterTypes: Set<string> = new Set<string>();
  // the specific types of the filters changed (i.e. 'Language' even if it's grouped under the 'More Filters' filter)
  // this is needed because we can start with default filters selected
  public changedFilterTypes: Set<string> = new Set<string>();

  public placementOffsetLeftPixels: number;

  private _searchFilter: SearchFilter;
  private shouldApplyFilter: boolean = false;

  @ViewChild('scroller') private scroller: ElementRef<HTMLElement>;

  constructor(
    private cdr: ChangeDetectorRef,
    private a11yService: A11yService,
    private translateService: TranslateService,
    private searchTrackerService: SearchTrackerService,
    private authService: AuthService,
    private iconRegistry: DfIconRegistry,
    public careerPathNamingService: CareerPathNamingService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.iconRegistry.registerIcons([DfIconChevronDown12]);
  }

  @Input() public set searchFilter(filter: SearchFilter) {
    this._searchFilter = filter;
    this.selectFilterItemsFromFilter();
  }

  public get searchFilter(): SearchFilter {
    return this._searchFilter;
  }

  public get numSelectedFilterItems(): number {
    return this.selectedFilterItemIds.size;
  }

  public get hasFilterItems(): boolean {
    return this.filtersWithItems?.length > 0;
  }

  public get filtersWithItems(): SearchFilterFilter[] {
    return this.searchFilter?.filters?.filter(
      (filter) => filter?.subitems?.length > 0
    );
  }

  public ngOnInit(): void {
    const authUser = this.authService.authUser;
    this.hasCareerPathing = authUser?.hasCareerPathing;
  }

  public applyFilter(): void {
    this.shouldApplyFilter = false;
    this.popoverToggled(false);
    this.selectFilterItemsInFilter();

    this.filtersChange.emit(this.searchFilter);
  }

  public clearSelectedFilterItems(): void {
    this.selectedFilterItemIds.clear();
    this.selectFilterItemsInFilter();
  }

  public isFilterItemSelected(id: string): boolean {
    return this.selectedFilterItemIds.has(id);
  }

  /**
   * Used to segment out a single filter with many items into multiple,
   * toggle-hidden sections, without turning it into multiple filters.
   * (For some reason, this component doesn't think `group` is a valid
   * child of our type, so we're just leaving the type off here.)
   *
   * @param subitems - Subitems array on a filter.
   * @param groupId - Current group ID.
   * @returns
   */
  public filterBySegment(subitems, groupId: number) {
    return subitems.filter((item) => item.group === groupId);
  }

  public popoverToggled(popoverState: boolean, filter?: SearchFilter): void {
    this.isPopoverOpen = popoverState;

    // When filter popover is opened, track initiation
    if (popoverState) {
      const filterTitle = this.translateService.instant(filter.title);
      this.searchTrackerService.setSearchData({
        latestFilterChanged: filterTitle,
      });
      this.searchTrackerService.searchResultViewChangeInitiated(filterTitle);
    }

    if (popoverState && this.searchFilter.isFilterCollection) {
      this.repositionFilterCollection();
      return;
    }

    if (!popoverState && this.shouldApplyFilter) {
      this.applyFilter();
    }
  }

  public resetSelectedFilterItems(): void {
    this.clearSelectedFilterItems();

    this.filtersChange.emit(this.searchFilter);
  }

  public toggleSelectedFilterItem({
    filterItemId,
    filterName,
  }: {
    filterItemId: string;
    filterName: string;
  }): void {
    this.changedFilterTypes.add(filterName);
    if (this.searchFilter?.isCheckboxFilter) {
      this.searchTrackerService.setSearchData({
        latestFilterChanged: 'Endorsed',
      });
    }

    this.shouldApplyFilter = true;

    if (this.selectedFilterItemIds.has(filterItemId)) {
      this.selectedFilterItemIds.delete(filterItemId);
    } else {
      this.selectedFilterItemIds.add(filterItemId);
    }

    // Announce number of selected items
    this.a11yService.announceAssertive(
      this.translateService.instant('Core_ItemsSelectedFormat', {
        count: this.numSelectedFilterItems,
      })
    );
  }

  private selectFilterItemsInFilter(): void {
    const selectItems = (items) => {
      return items.map((item) => ({
        ...item,
        isSelected: this.selectedFilterItemIds.has(item.model.id),
      }));
    };
    this.searchFilter.filters = this.searchFilter.filters.map((filter) => ({
      ...filter,
      subitems: selectItems(filter.subitems),
    }));
    this.cdr.markForCheck();
  }

  private selectFilterItemsFromFilter(): void {
    if (!this.searchFilter.filters) {
      return;
    }

    this.selectedFilterItemIds.clear();

    _forEach(this.searchFilter.filters, (filter) =>
      _forEach(_filter(filter.subitems, 'isSelected'), (item) => {
        this.selectedFilterItemIds.add(item.model.id);
        this.selectedFilterTypes.add(filter.title);
      })
    );
  }

  private repositionFilterCollection(): void {
    // Check if popover is outside viewport and, if so, adjust accordingly
    setTimeout(() => {
      const windowWidth = this.document.body.clientWidth;
      const adjustedForWidthCheck = windowWidth - 72; // Provide 3rem margin on each side
      const adjustedForRightCheck = windowWidth - 36; // Provide 3rem margin on right side

      // Use the popover's host element for comparisons to account for border sizes
      const popover = this.scroller.nativeElement.closest(
        'dgx-popover'
      ) as HTMLElement;
      let popoverRect = popover.getBoundingClientRect();

      // Constrain popover to viewport
      // Math.floor is needed for IE 11 to properly compare values
      if (Math.floor(popoverRect.width) > adjustedForWidthCheck) {
        this.scroller.nativeElement.style.width = `${adjustedForWidthCheck}px`;
        this.scroller.nativeElement.classList.add('l_flex-wrap');
        popoverRect = popover.getBoundingClientRect();
      }

      // Move popover left into viewport
      if (Math.floor(popoverRect.right) > adjustedForRightCheck) {
        const popoverOffsetLeft =
          (popoverRect.right - adjustedForRightCheck) * -1;
        // Can't use the popover's placementAdjustLeftRem property since it only adjusts the inner popover_window element,
        // which still has the popover's host element pushing outside the viewport
        // The placementOffsetLeftPixels property moves the popover's host element in the positioning service
        this.placementOffsetLeftPixels = popoverOffsetLeft;
      }

      this.cdr.markForCheck();
    });
  }
}
