import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
import { UserProfileSummary } from '@app/user/user-api.model';
import { MentionUserMatch } from '../models/comment-api.model';

@Injectable({
  providedIn: 'root',
})
export class MentionService {
  constructor(
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // This captures the userProfileKey and replaces the key reference to an a tag element
  // that's clickable. Seen when getting comments, adding comments, and adding replies
  // example: hello @[0987890] -> hello Victor Osuyak
  public createMentionsFormat(comment: string, mentions?: MentionUserMatch[]) {
    if (!mentions) {
      return comment;
    }
    for (const mention of mentions) {
      const key = new RegExp('@\\[' + mention.userProfileKey + '\\]');
      comment = comment.replace(key, () => {
        return this.createUserMentionFormatLink(mention);
      });
    }
    return comment;
  }

  // This takes each mention object and defines a new a tag element
  // example: { name: 'Victor Osuyak'} -> <a>Victor Osuyak</a>
  public createUserMentionFormatLink(mention: MentionUserMatch): string {
    const newComment = `<a contenteditable="false" href="/${
      mention?.vanityUrl
    }" class="color-blue js-mention" data-item="${encodeURIComponent(
      JSON.stringify(mention)
    )}">${this.encodeToEntitiesPipe.transform(mention?.name)}</a>`;
    return newComment;
  }

  // This consumes a comment and breaks down each mention into an <a> tag
  // and in turn it displays a new comment replacing tags with userProfileKey
  // example: hello <a data-item="">Victor Osuyak</a> -> hello @[0987890]
  public getCommentWithMentionKeys(comment: string): string {
    const dom = new DOMParser().parseFromString(comment, 'text/html');
    const mentions: any = dom.children[0].children[1].children;
    let replaceMentionWithKey: any;
    for (const $match of mentions) {
      replaceMentionWithKey = (match: any) => {
        const m = this.document.createElement('div');
        m.innerHTML = match;
        const $mention = m.children[0] as any;
        if ($mention?.className.includes('js-mention')) {
          const mention: MentionUserMatch = JSON.parse(
            decodeURIComponent($mention.dataset.item)
          );
          return match.replace(match, '@[' + mention.userProfileKey + ']');
        } else {
          return match;
        }
      };
    }
    return comment.replace(/<a\s.*?>*.?<\/a>/gi, replaceMentionWithKey);
  }

  // This retrieves a comments <a> tags for all mentioned items and convert
  // the JSON mention object and stores it into mentions array
  // example: <a data-item="%name:victor%" -> mentions[{name:'victor'}]
  public getMentions(containerEl: any): MentionUserMatch[] {
    const mentions: MentionUserMatch[] = [];
    for (const mentionEl of containerEl) {
      mentions.push(JSON.parse(decodeURIComponent(mentionEl.dataset.item)));
    }
    return mentions;
  }

  // This returns an HTML collection of mentions in the text area
  public findMentionItemsInTextArea(
    textAreaId: string = 'textarea-for-mentions'
  ): HTMLCollectionOf<any> {
    return document
      .getElementById(textAreaId)
      .getElementsByClassName('js-mention');
  }
}
