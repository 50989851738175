import {
  BusinessRule,
  Predicate,
  Outcome,
  StaticOutcomes,
  Trigger,
  TriggerType,
  DueDateType,
} from '../model';
import { isValidSchedule } from './schedule-util';

export const validatePredicates = (predicates?: Predicate[]) => {
  return !predicates?.some(
    (p) =>
      p.fieldName === '' ||
      p.comparisonOperator === '' ||
      p.comparisonValue?.length === 0 ||
      p.comparisonValue === undefined ||
      p.comparisonValue === null
  );
};

export const validateOutcome = (
  outcome: Outcome,
  isEmailTemplatePopulated = true
) => {
  if (outcome?.hasError) {
    return false;
  }

  switch (outcome?.updateTypeResourceId) {
    case StaticOutcomes.SEND_EMAIL:
      return isEmailTemplatePopulated
        ? outcome.emailTemplate?.emailSubject &&
            outcome.emailTemplate?.emailContent
        : true; // assume email template contains subject/body since we don't allow saving without
    case StaticOutcomes.NOTIFY_USER:
      return outcome.parameters?.Details && outcome.parameters?.Subject;
    case StaticOutcomes.DELETE_USER:
      return true;
    case StaticOutcomes.ACM_DELETE_USER:
    case StaticOutcomes.ACM_ADD_USER:
      return (
        outcome.parameters?.DevelopPermissionRole &&
        outcome.parameters?.IsDelete !== undefined &&
        outcome.parameters?.IsDisableLogin !== undefined
      );
    case StaticOutcomes.SHARE_RESOURCE:
    case StaticOutcomes.ASSIGN_RESOURCE:
      const hasBasics =
        outcome.parameters?.Id &&
        outcome.parameters?.ResourceType &&
        outcome.parameters?.RecommendationType;

      if (outcome.updateTypeResourceId === StaticOutcomes.ASSIGN_RESOURCE) {
        switch (outcome.parameters?.DueDateType) {
          case DueDateType.None:
            return hasBasics;
          case DueDateType.Specific:
            return hasBasics && outcome.parameters?.DueDate;
          case DueDateType.Relative:
            return (
              hasBasics &&
              outcome.parameters?.DateUnit &&
              outcome.parameters?.DateUnitType
            );
        }
      } else {
        return hasBasics;
      }
    default:
      return outcome.updateValue && outcome.updateTypeResourceId;
  }
};

export const validateTrigger = (trigger?: Trigger): boolean => {
  if (trigger?.resourceFieldName && !trigger.resourceValue) {
    return false;
  } else if (
    trigger?.triggerType === TriggerType.Scheduled &&
    !isValidSchedule(trigger.schedule)
  ) {
    return false;
  } else {
    return !!trigger?.triggerType;
  }
};

export const validateAutomation = (
  automation: BusinessRule,
  isEmailTemplatesPopulated = true
) => {
  const { predicates, outcomes, trigger } = automation;

  const isValidPredicates = validatePredicates(predicates);
  const isValidOutcomes =
    outcomes?.length > 0 &&
    outcomes?.filter((o) => !validateOutcome(o, isEmailTemplatesPopulated))
      .length === 0;
  const isValidTrigger = validateTrigger(trigger);

  return isValidPredicates && isValidOutcomes && isValidTrigger;
};

export const bucketValidAutomations = (
  automations: BusinessRule[],
  isEmailTemplatesPopulated = true
) => {
  return automations.reduce(
    (prev, curr) => {
      if (validateAutomation(curr, isEmailTemplatesPopulated)) {
        prev.valid.push(curr);
      } else {
        prev.invalid.push(curr);
      }
      return prev;
    },
    { valid: [], invalid: [] }
  );
};
