import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  ElementRef,
  Input,
  OnChanges,
  Output,
  ViewChild,
  HostListener,
  SimpleChanges,
  Renderer2,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { MentionService } from '@app/comments/services/mention.service';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { AuthService } from '@app/shared/services/auth.service';
import { ContentEditableService } from '@app/shared/services/content-editable.service';
import { ModalService } from '@app/shared/services/modal.service';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { CommentSubmission, Resource } from '../../comments.model';
import { DOCUMENT } from '@angular/common';
import {
  MarkdownService,
  MarkdownToHtmlOptions,
} from '@app/markdown/services/markdown.service';

export type Parent = Resource;

@Component({
  selector: 'dgx-comment-field',
  templateUrl: './comment-field.component.html',
  styleUrls: ['./comment-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentFieldComponent
  implements AfterViewChecked, OnChanges, AfterViewInit
{
  /** Optional value to initialize the input field with */
  @Input() public initialValue?: string;
  /** Optional value for setting the SDET data-dgat field */
  @Input() public dataDgat?: string;
  /** A new comment is being submitted to the server */
  @Input() public isSubmitting = false;
  /** Determines if the submit button is shown */
  @Input() public showControls? = true;
  /** Determines if the field should autofocus. Default: true */
  @Input() public autoFocus? = true;
  /** Determines if the form should cancel and reset to initial value */
  @Input() public cancel? = false;
  /** Passed to the mention directive */
  @Input() public groupId?: number;
  /** Passed to the mention directive */
  @Input() public groupPrivacy?: GroupPrivacyLevel;
  /** Determines if the field should read-only. Default: false */
  @Input() public isReadOnly? = false;
  /** To be used with resetComment output */
  @Input() public forceReset? = false;
  /** Force the trigger of the submission output */
  @Input() public forceSubmit? = false;
  /** Force the trigger of the submission output */
  @Input() public markdownToHtmlOptions?: Partial<MarkdownToHtmlOptions> = {};
  /** Fires when the user triggers a submit action */
  @Output() public submitComment = new EventEmitter<CommentSubmission>();
  /** Fires when the user triggers a submit action */
  @Output() public resetComment = new EventEmitter<null>();

  /** The <div contenteditable="true" /> element */
  @ViewChild('input') public inputElement: ElementRef<HTMLDivElement | any>;
  // @ViewChild('submitButton') public submitButtonElement: ElementRef;

  public i18n = this.translateService.instant([
    'A11y_AddComment',
    'CommentsCtrl_PublicComment',
    'CommentsCtrl_PublicCommentDesc',
    'Core_Comment',
    'Core_YesSure',
    'dgComments_ShareSomethingAbout',
  ]);
  public mentionEvent: Event;

  constructor(
    private cdr: ChangeDetectorRef,
    private contentEditableService: ContentEditableService,
    private translateService: TranslateService,
    private authService: AuthService,
    private mentionService: MentionService,
    private modalService: ModalService,
    private renderer: Renderer2,
    private markdownService: MarkdownService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * The contenteditable inputs don't play well with ngModel by default so we just use these
   * accessors to read and set the model value. The innerHTML property seems plenty fast when
   * dealing with elements that only contain text nodes.
   */
  public get value(): string {
    const _value = this.inputElement?.nativeElement?.innerHTML ?? '';
    return RegExp(/^[&nbsp;\s]+$/).test(_value) ? '' : _value; // regex checks if the comment is only spaces
  }

  public set value(val: string) {
    if (this.inputElement?.nativeElement) {
      const options = {
        sanitize: true,
        allowImages: true,
        stripSourceHtml: true,
        openLinksInNewWindow: true,
        ...this.markdownToHtmlOptions,
      };
      const value = this.markdownService.markdownToHtml(val, options);
      this.inputElement.nativeElement.innerHTML = value;
    } else {
      throw new Error('DOM not ready to set the value');
    }
  }

  @HostListener('keypress', ['$event'])
  public onKeypress(event: KeyboardEvent) {
    // Submit on enter
    if (!this.isSubmitting && event.code === 'Enter') {
      return this.onSubmit(event);
    }
  }

  @HostListener('submit', ['$event'])
  public onSubmit(event?: Event) {
    if (!this.value || this.isSubmitting) {
      return;
    }
    // Prevent form submission
    event?.preventDefault();
    this.isSubmitting = true;
    this.clearListItems();
    if (this.value !== undefined && this.value.length !== 0) {
      if (this.authService.authUser.isPrivateProfile) {
        this.showPrivacyModal();
      } else {
        this.submitComment.emit({
          value: this.value,
          mentions: this.getMentions(),
        });
      }
      this.isSubmitting = false;
    }
  }

  public ngAfterViewInit() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }

  public ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges) {
    // eslint-disable-next-line no-console
    if (changes?.autoFocus?.currentValue) {
      this.autoFocus = changes.autoFocus.currentValue;
      // Breathe a tick to make DOM access available
      setTimeout(() => {
        this.setFocus();
        this.contentEditableService.setCaretToEnd(
          this.inputElement?.nativeElement
        );
      });
    }
    if (changes?.forceReset?.currentValue) {
      // Breathe a tick to avoid any NG0100 on the relay back
      setTimeout(() => {
        this.resetValue();
      });
    }
    if (changes?.forceSubmit?.currentValue) {
      // Breathe a tick to avoid any NG0100 on the relay back
      setTimeout(() => {
        this.onSubmit();
      });
    }
  }

  // remove the mention-options dropdown when invoked
  public clearListItems(): void {
    const options = this.document.getElementById('mention-options');

    if (options) {
      [options.children].forEach((child: any) => {
        if (child.item(0).id === 'mention-list') {
          this.renderer.removeChild(options, child.item(0));
        }
      });
      this.cdr.detectChanges();
    }
  }

  public getMentions(): UserProfileSummary[] {
    const mentionEls = this.mentionService.findMentionItemsInTextArea(
      'comment-textarea-for-mentions'
    );
    const mentions = this.mentionService.getMentions(
      mentionEls
    ) as UserProfileSummary[];
    return mentions;
  }

  public onInput(event: Event) {
    this.mentionEvent = event;
    this.cdr.markForCheck();
  }

  public cancelEdit() {
    this.resetValue();
    this.forceReset = true; // will be flipped via resetComment()
    this.cdr.detectChanges();
  }

  public setFocus() {
    if (this.autoFocus && !this.isReadOnly) {
      this.inputElement?.nativeElement?.focus();
    }
  }

  public resetValue() {
    this.value = this.initialValue ?? '';
    this.resetComment.emit();
    this.cdr.markForCheck();
  }

  private showPrivacyModal() {
    const inputs: SimpleModalInputBindings = {
      headerText: this.i18n.CommentsCtrl_PublicComment,
      bodyText: this.i18n.CommentsCtrl_PublicCommentDesc,
      submitButtonText: this.i18n.Core_YesSure,
      canCancel: true,
    };
    this.modalService
      .show<any>(SimpleModalComponent, { inputs })
      .subscribe(() => {
        this.submitComment.emit({
          value: this.value,
          mentions: this.getMentions(),
        });
      });
  }
}
