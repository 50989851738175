import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthUser } from '@app/account/account-api.model';
import { EmbeddedLearningTag } from '@app/embedded-learning/embedded-learning-api.model';
import {
  InputDetails,
  InputId,
  InputStatistics,
} from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputRatingsService } from '@app/inputs/services/input-ratings.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { InputType } from '@app/shared/models/core-api.model';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { QueueService } from '@app/shared/services/queue.service';
import { TagsService } from '@app/tags/services/tags.service';
import { WebEnvironmentService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'dgx-embedded-learning',
  templateUrl: './embedded-learning.component.html',
  styleUrls: ['./embedded-learning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbeddedLearningComponent implements OnInit {
  @Input() inputId: InputId;
  @Input() inputType: InputType;
  @Input() embedSrc: string;

  private readonly iframeResizerScriptUrl: string =
    '/scripts/libs/iframeResizer.min.js';

  public i18n = this.translate.instant([
    'QueueSvc_ItemAddedFormat',
    'Core_ActionError',
    'Core_SaveForLater',
    'Core_Unsave',
    'Core_Recommend',
  ]);

  public trustedEmbedSrc: SafeHtml;
  public canRecommend: boolean;
  public input: LearningResourceViewModel;
  public tags: EmbeddedLearningTag[];
  public loading: boolean = true;
  public authUser: AuthUser;
  public socialCountsModel;
  public showThumbsComponent: boolean;

  public relatedInputTypes: string[] = ['video', 'article', 'pathway'];
  public isClientProvider: boolean;

  get ownerId(): number {
    return this.inputId;
  }

  // linter fix - TODO: possible refactor to handle this casting better
  get inputDetails(): InputDetails {
    return this.input as unknown as InputDetails;
  }

  constructor(
    private authService: AuthService,
    private inputsService: InputsService,
    private tagService: TagsService,
    private queueService: QueueService,
    private translate: TranslateService,
    private notifierService: NotifierService,
    private inputRatingsService: InputRatingsService,
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private inputToLearningResourcePipe: InputToLearningResourcePipe,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private recommendationsModalService: RecommendationsModalService,
    private enviroService: WebEnvironmentService
  ) {}

  ngOnInit(): void {
    this.loadResizerScript()
      .pipe(
        mergeMap(() =>
          this.inputsService.getHMMEmbeddedContentUrl(
            this.inputId,
            this.inputType
          )
        )
      )
      .subscribe((url) => {
        this.trustedEmbedSrc =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.authUser = this.authService.authUser;
        this.isClientProvider =
          this.authService.authUser?.defaultOrgInfo?.settings.isClientProvider;
        this.canRecommend =
          this.authService.authUser?.canRecommendItems || false;

        this.getInputAndStatistics().subscribe(() => {
          this.loading = false;
          this.cdr.detectChanges();
        });
      });
  }

  public takeAction(action, $event) {
    const actions = {
      toggleQueue: () => {
        if (!this.input.isQueued) {
          this.input.isQueued = true;
          this.queueService
            .post(
              this.input.resourceType,
              this.input.resourceId,
              null,
              this.input,
              'Browse'
            )
            .subscribe((response) => {
              const data = response.data;
              this.input.queueItemId = data;
              this.notifierService.showSuccess(
                this.translate.instant('QueueSvc_ItemAddedFormat', {
                  item: this.encodeToEntitiesPipe.transform(this.input.title),
                })
              );
            });
        } else {
          this.queueService
            .deleteItem(
              this.input.queueItemId,
              this.input.resourceType,
              this.input.resourceId,
              null
            )
            .subscribe(() => {
              this.input.isQueued = false;
              this.input.queueItemId = null;
              // this.input.relatedGoal = false;
            });
        }
      },
      recommend: () => {
        this.recommendationsModalService
          // linter error - Argument of type 'ProviderLearningResource' is not assignable to parameter of type 'RecommendingItem'
          .showShareModal(this.input.model as any)
          .subscribe();
      },
    };

    if (typeof actions[action] !== 'function') {
      this.notifierService.showError(this.i18n.Core_ActionError);
    }

    return actions[action]();
  }

  public rate(event) {
    const newRating = event.rating;

    // not all items have statistics
    this.input.statistics ??= {} as InputStatistics;

    this.inputRatingsService.rateItem(this.input, newRating);

    this.input.requestingUserRating = newRating;
  }

  public completionComponentUpdate(updateOccurred: boolean) {
    if (updateOccurred) {
      this.showThumbsComponent = !this.showThumbsComponent;
      this.cdr.detectChanges();
    }
  }

  private getInputAndStatistics() {
    return this.inputsService
      .getInputAndStatistics({
        inputType: this.inputType,
        inputId: this.inputId,
      })
      .pipe(
        tap((response) => {
          this.input = this.inputToLearningResourcePipe.transform(
            response.input
          );

          this.inputsService.mapInputStatistics(
            [this.input],
            response.statistics
          );
          if (response.input.tags) {
            // If tags, add to statistics
            this.input.statistics = this.input.statistics;

            if (this.input.statistics) {
              // force conversion
              (this.input.statistics.tags as any) =
                this.tagService.objectifyTags(response.input.tags);
              this.tags = this.input.statistics.tags;
            } else {
              // force conversion
              (this.tags as any) = this.tagService.objectifyTags(
                response.input.tags
              );

              // force conversion
              this.input.statistics = { tags: this.tags } as any;
              this.showThumbsComponent = this.input.model.isCompleted;
            }
          }
        })
      );
  }

  /**
   * This is a library that we're using to resize the iframe so that content within the iframe isn't being cut off.
   *
   */
  private loadResizerScript(): Observable<void> {
    return new Observable<void>((observer) => {
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute(
        'src',
        this.enviroService.getBlobUrl(this.iframeResizerScriptUrl)
      );

      scriptTag.addEventListener('load', () => {
        // Script loaded and executed, resolve the observable
        observer.next();
        observer.complete();
      });

      document.body.appendChild(scriptTag);

      // Clean up if the observable is unsubscribed before script load
      return () => {
        scriptTag.removeEventListener('load', () => {});
        document.body.removeChild(scriptTag);
      };
    });
  }
}
