import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { twMerge } from '../utils';

export interface DropdownRowItem {
  id: string | number;
  name: string;
  secondaryName?: string;
  badgeLabel?: string;
}

@Component({
  selector: 'da-dropdown',
  template: `
    <button
      type="button"
      [class]="twMerge('tw-btn-secondary-outline', className)"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="open = !open"
    >
      <span
        >{{ selectedItem?.name }}
        <span *ngIf="selectedItem?.secondaryName">
          &bull; {{ selectedItem?.secondaryName }}
        </span>
      </span>
      <da-icon icon="chevron-down" class="tw-size-4"></da-icon>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="open"
      [cdkConnectedOverlayPositions]="[
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayPanelClass="tw-reset"
      (detach)="open = false"
      (backdropClick)="open = false"
    >
      <div
        @PopoverTrigger
        class="tw-reset tw-mt-8 tw-min-w-60 tw-origin-top tw-rounded-lg tw-bg-white tw-py-2 tw-shadow-xl tw-ring-1 tw-ring-neutral-200 tw-transition"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
      >
        <ul class="tw-flex tw-flex-col">
          <li *ngFor="let item of items">
            <button
              [class]="
                twMerge(
                  'tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-px-4 tw-py-2 tw-text-left hover:tw-bg-neutral-100  focus-visible:tw-outline-none'
                )
              "
              (click)="handleItemSelect(item)"
            >
              <div
                class="pr-10 text-left tw-leading-5"
                [ngClass]="{ 'tw-font-semibold': selectedItem?.id === item.id }"
              >
                <span
                  class="tw-block tw-max-w-48 tw-truncate"
                  title="{{ item.name }}"
                >
                  {{ item.name }}
                </span>
                <span class="tw-text-xs tw-text-neutral-600">
                  {{ item?.secondaryName }}
                </span>
              </div>

              <div class="tw-flex tw-items-center tw-gap-3">
                <span
                  *ngIf="selectedItem?.id === item.id"
                  class="tw-rounded-2xl tw-border tw-border-purple-300 tw-bg-purple-50 tw-px-2 tw-py-1 tw-text-xs tw-font-extrabold tw-uppercase tw-text-purple-800"
                >
                  {{ item?.badgeLabel }}
                </span>
                <da-icon
                  *ngIf="selectedItem?.id === item.id"
                  icon="check-circle"
                  type="solid"
                  class="tw-size-5 tw-text-green-600"
                ></da-icon>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </ng-template>
  `,
  animations: [
    trigger('PopoverTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(95%)' }),
        animate(
          '200ms ease-out',
          style({ opacity: 1, transform: 'scale(100%)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(100%)' }),
        animate(
          '100ms ease-in',
          style({ opacity: 0, transform: 'scale(95%)' })
        ),
      ]),
    ]),
  ],
})
export class DropdownComponent {
  @Input() items!: DropdownRowItem[];
  @Input() className?: string;
  @Input() selectedItem: DropdownRowItem | null = null;
  @Output() itemSelect = new EventEmitter<DropdownRowItem>();

  open = false;
  twMerge = twMerge;

  handleItemSelect(item: DropdownRowItem) {
    this.selectedItem = item;
    this.itemSelect.emit(item);
    this.open = false;
  }
}
