<div aria-live="polite">
  <form class="form-wrap" (submit)="submit($event)">
    <dgx-modal
      [modalHeaderTitle]="i18n.addToTargetModal_AddToTarget"
      [canCancel]="true"
      [cancelButtonText]="cancelButtonText"
      [isLoading]="isModalLoading"
      [submitButtonText]="submitButtonText"
      [useDefaultForm]="false"
      [useDefaultSubmitButton]="!!submitButtonText"
      [isSubmitDisabled]="!canSubmit"
    >
      <!-- Modal Body -->
      <ng-container class="modal-body">
        <dgx-org-managed-notification
          class="guts-m-t-1 guts-m-b-2 block"
          *ngIf="showOrgManagedNotification"
          resourceType="Target"
        ></dgx-org-managed-notification>
        <div class="guts-m-full-half">
          <div class="guts-p-b-1">
            <ng-container *ngIf="targets.length; else createATarget">
              <ng-container *ngIf="!isAdded; else addedToPlan">
                <label for="plan-input">{{ i18n.Core_SkillPlan }}</label>
                <dgx-combobox
                  *ngIf="!loadingTargets; else comboboxSkeleton"
                  class="full-width"
                  (selection)="onSelectTargetOption($event)"
                  [options]="targetOptions"
                  labelKey="name"
                  [placeholder]="i18n.addToTargetModal_SelectTargetPlaceHolder"
                  [hasNoResultsText]="i18n.Core_NoResultsFound"
                  dgatInput="addToPlan-4d1"
                ></dgx-combobox>
                <div class="guts-p-t-1" *ngIf="!loadingSections">
                  <div *ngIf="sectionOptions?.length > 0; else noSectionsFound">
                    <label for="plan-input-section">{{
                      i18n.addToTargetModal_Section
                    }}</label>
                    <dgx-combobox
                      class="full-width"
                      (selection)="onSelectSectionOption($event)"
                      [options]="sectionOptions"
                      labelKey="name"
                      [placeholder]="
                        i18n.addToTargetModal_SelectSectionPlaceHolder
                      "
                      [hasNoResultsText]="i18n.Core_NoResultsFound"
                      dgatInput="addToPlan-4d1"
                    ></dgx-combobox>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </dgx-modal>
  </form>
</div>

<ng-template #comboboxSkeleton>
  <dgx-skeleton type="formfield"></dgx-skeleton>
</ng-template>

<ng-template #noSectionsFound>
  <df-local-notification
    [text]="i18n.addToTargetModal_NoSectionsFound"
    size="small"
    [type]="NotificationType.info"
  ></df-local-notification>
</ng-template>

<ng-template #addedToPlan>
  <div class="center-text break">
    <h3
      [innerHtml]="
        isAdded ? successfullAddText : i18n.addToTargetModal_AddingError
      "
    ></h3>
  </div>
</ng-template>

<!-- when there are no targets associated with the orgId -->
<ng-template #createATarget>
  <h3 class="h3">
    {{ i18n.addToTargetModal_CreateYourTarget }}
  </h3>
  <p class="zeta explanation">
    {{ i18n.addToTargetModal_CreateJobRoleExplanation }}
  </p>
</ng-template>
