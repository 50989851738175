import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VendorIntegrationsService {
  constructor() {}

  /**
   * Loads the osano cookie tracker script.
   *
   * Osano managers whether or not a user of the site has chosen to accept cookies or not. It is the primary
   * method which degreed manages this data. There is a degreed fallback if for some reason a client doesn't
   * allow us to use third party vendors based on the 'enableOsanoCookieBannerAuthed' flag on the authUser object.
   */

  public loadOsanoCookieHandler(offsetIcon: boolean = false) {
    var scriptTag = document.createElement('script');
    scriptTag.setAttribute(
      'src',
      'https://cmp.osano.com/16CHyLSGtDs7brYI/e2e2db52-5767-400f-b9fc-e4fab3cab972/osano.js'
    );
    document.body.appendChild(scriptTag);

    // this is required so the icon doesn't conflict with the get help widget on certain pages
    if (offsetIcon) {
      document.body.classList.add('osano-icon-offset');
    }
  }

  /**
   * Loads the qualtrics script into the dom.
   *
   * Qualtrics is an external service that pops up a feedback survey for end users and it relies on the variables set on "window" to validate
   * whether or not the popover should show
   *
   * @param orgId - Maps to __dg_qualtrics_org_id
   * @param isRestricted - Maps to __dg_qualtrics_is_restricted
   * @param onboardDate - Maps to __dg_qualtrics_onboarded_30d_ago
   *
   */
  public loadQualtrics({
    orgId,
    isRestricted,
    onboardDate,
    lastVisitDate,
  }: {
    orgId: number;
    isRestricted: boolean;
    onboardDate: string;
    lastVisitDate: string;
  }) {
    const w: any = window;
    w.__dg_qualtrics_is_restricted = isRestricted;
    w.__dg_qualtrics_org_id = orgId;

    let hasOnboarded30dAgo = false;
    const ago30d = new Date();
    ago30d.setDate(-30);

    // if the user has logged in before, look at the onboard date for the account age
    // this is an extra failsafe check to try to prevent the survey from showing to
    // users whose accounts were created previous but this is their first login
    // TODO: once we have added a stored 'first login' date, we can use that instead
    // see PD-70736 for more information
    if (lastVisitDate) {
      hasOnboarded30dAgo = onboardDate ? new Date(onboardDate) < ago30d : false;
    }

    w.__dg_qualtrics_onboarded_30d_ago = hasOnboarded30dAgo;

    const div = document.createElement('div');
    div.id = 'ZN_3dPyydxNQIcCHIy';
    document.body.appendChild(div);

    // Formatted Qualtrics snippet follows with minor changes to make it compile
    /* eslint-disable */
    (function () {
      var g = function (e, h, f, g) {
        this.get = function (a) {
          for (
            // @ts-ignore
            var a = a + '=',
              c = document.cookie.split(';'),
              b = 0,
              e = c.length;
            b < e;
            b++
          ) {
            for (var d = c[b]; ' ' == d.charAt(0); )
              d = d.substring(1, d.length);
            if (0 == d.indexOf(a)) return d.substring(a.length, d.length);
          }
          return null;
        };
        this.set = function (a, c) {
          var b: any = new Date();
          b.setTime(b.getTime() + 6048e5);
          b = '; expires=' + b.toGMTString();
          document.cookie = a + '=' + c + b + '; path=/; ';
        };
        this.check = function () {
          var a = this.get(f);
          if (a) a = a.split(':');
          else if (100 != e)
            'v' == h && (e = Math.random() >= e / 100 ? 0 : 100),
              (a = [h, e, 0]),
              this.set(f, a.join(':'));
          else return !0;
          var c = a[1];
          if (100 == c) return !0;
          switch (a[0]) {
            case 'v':
              return !1;
            case 'r':
              return (
                (c = a[2] % Math.floor(100 / c)),
                a[2]++,
                this.set(f, a.join(':')),
                !c
              );
          }
          return !0;
        };
        this.go = function () {
          if (this.check()) {
            var a = document.createElement('script');
            a.type = 'text/javascript';
            a.src = g;
            document.body && document.body.appendChild(a);
          }
        };
        this.start = function () {
          var t = this;
          'complete' !== document.readyState
            ? window.addEventListener(
                'load',
                function () {
                  t.go();
                },
                !1
              )
            : t.go();
        };
      };
      try {
        new g(
          100,
          'r',
          'QSI_S_ZN_3dPyydxNQIcCHIy',
          'https://zn3dpyydxnqicchiy-degreed.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_3dPyydxNQIcCHIy'
        ).start();
      } catch (i) {}
    })();
  }
}
