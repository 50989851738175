import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PathwayDetailsModel,
  PathwaySummaryConfig,
  PathwaySummaryModel,
} from '@app/pathways/pathway-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PathwayService } from '@app/pathways/services/pathway.service';
import { LDFlagsService } from '@dg/shared-services';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { catchAndSurfaceError } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class PathwaySettingsService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private tracker: TrackerService,
    private resourceImageService: ResourceImageService,
    private pathwayService: PathwayService,
    private lDFlagsService: LDFlagsService
  ) {}

  /**
   * Get a list of possible pathway primary Contacts from a term
   * @param term
   * @param count: number of recommendations wanted, default is ten.
   * @returns
   */
  public getPrimaryContactSuggestions(
    term: string,
    count?: number
  ): Observable<AnyRecommendee[]> {
    if (term.length === 0) {
      return of([]);
    }
    return this.http
      .get<AnyRecommendee[]>('/search/findUserGroupResources', {
        params: {
          term,
          count,
        },
      })
      .pipe(
        map((item: any) => {
          if (item.resourceType === 'User') {
            return {
              ...item,
              userProfileKey: item.resourceId,
            };
          }
          return item;
        })
      );
  }

  public getPathSummary(pathId): Observable<PathwaySummaryModel> {
    return this.http
      .get<PathwaySummaryModel>('/pathways/getpathwaysummary', {
        params: {
          pathId,
          useResourceImages: true,
        },
      })
      .pipe(
        switchMap((data) => {
          const result = data;
          if (!!result.imageUrl) {
            let imageUrl = result.imageUrl;
            if (this.resourceImageService.isResourceImage(result.imageUrl)) {
              const { legacyPictureUrl } =
                this.resourceImageService.parseImageUrl(result.imageUrl);
              imageUrl = legacyPictureUrl;
            }
            result.displayImage = result.imageUrl;

            // We only want to send up the name of the file because the location is assumed.
            const imgPath = imageUrl.split('/');
            result.imageUrl = imgPath[imgPath.length - 1].split('?')[0];

            // store to know if user selected different image since loading
            result.prevImageFilename = result.imageUrl;
          }
          return of(data);
        }),
        map((p) => {
          if (!p.publicUrl?.endsWith('/pathway')) {
            return {
              ...p,
              publicUrl: `${p.publicUrl}/pathway`,
            };
          } else {
            return p;
          }
        }),
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant('Pathways_PathwayInfoAccessError'),
              error
            )
          );
        })
      );
  }

  public getPathwayAuthors = (
    pathId?: number
  ): Observable<UserSearchItem[]> => {
    if (!!pathId) {
      return this.http
        .get<UserSearchItem[]>('/pathways/getPathwayAuthors', {
          params: { pathId: pathId },
        })
        .pipe(
          catchError((error) => {
            return throwError(
              new DgError(
                this.translate.instant('Pathways_AuthorAccessError'),
                error
              )
            );
          })
        );
    } else {
      return this.http.get<UserSearchItem>('/pathways/getDefaultAuthor').pipe(
        switchMap((data) => {
          const response: UserSearchItem[] = [data];
          return of(response);
        }),
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant('Pathways_AuthorAccessError'),
              error
            )
          );
        })
      );
    }
  };

  public savePathwaySummary(
    pathway: PathwaySummaryConfig,
    endorsementAdded?: boolean,
    trackingArea?: string
  ): Observable<PathwayDetailsModel['id']> {
    let imageUrl = pathway.imageUrl;
    if (imageUrl) {
      if (pathway.legacyPictureUrl) {
        // new resource image was added
        imageUrl = pathway.legacyPictureUrl;
      } else if (this.resourceImageService.isResourceImage(imageUrl)) {
        const { legacyPictureUrl } =
          this.resourceImageService.parseImageUrl(imageUrl);
        imageUrl = legacyPictureUrl;
      }
      pathway.imageUrl = imageUrl.split('?')[0];
    }

    let payload: any = {
      pathId: pathway.resourceId,
      name: pathway.title,
      description: pathway.description ? pathway.description : '',
      tags: pathway.tags,
      imageUrl: imageUrl,
      organizationId: pathway.organizationId,
      authors: pathway.authors,
      userIsOnlyAuthor: pathway.userIsOnlyAuthor,
      clonedFromPathId: pathway.clonedFromPathId,
      imageHasChanged:
        pathway.prevImageFilename !== null &&
        pathway.imageUrl !== null &&
        pathway.prevImageFilename !== pathway.imageUrl,
      shareAuthorPermission: pathway.shareAuthorPermission,
      headerImageDisabled: pathway.headerImageDisabled,
      durationDisplayDisabled: pathway.durationDisplayDisabled,
      isEndorsed: pathway.isEndorsed,
      metadata: pathway.metadata,
      resourceImageId: pathway.resourceImageId || '',
    };

    if (this.lDFlagsService.pathwayNotificationOwnership) {
      payload = {
        ...payload,
        visibility: pathway.visibility,
        groupIds: pathway.visibilityGroupIds,
        primaryContactResourceId: pathway.primaryContactResourceId,
        primaryContactResourceType: pathway.primaryContactResourceType,
      };
    }

    return this.http
      .post<
        PathwayDetailsModel['id']
      >('/pathways/AddUpdatePathwaySummary', payload)
      .pipe(
        tap((data) => {
          this.pathwayService.updateVersion(pathway.id);
          // default privacy level if not set because it gets defaulted server side if it is not set.
          const trackingProperties = {
            pathwayId: data,
            pathwayName: pathway.title,
            skillTagCount: pathway.tags?.length,
            isEndorsed: pathway.isEndorsed,
            endorsementAdded:
              endorsementAdded !== undefined ? endorsementAdded : undefined,
            endorsementRemoved:
              endorsementAdded !== undefined ? !endorsementAdded : undefined,
            actionLocation: trackingArea,
          };
          this.tracker.trackEventData({
            action:
              pathway.resourceId !== undefined && pathway.resourceId !== 0
                ? 'Pathway Updated'
                : 'Pathway Created',
            properties: trackingProperties,
          });
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409) {
            throw new DgError(
              this.translate.instant('Pathways_SetPathwayNameError'),
              error
            );
          } else if (error.status === 403 && pathway.organizationId) {
            throw new DgError(
              this.translate.instant('Pathways_EditPermissionError')
            );
          }
          throw new DgError(
            this.translate.instant('Pathways_SaveError'),
            error
          );
        })
      );
  }
}
