<label *ngIf="!hideLabel" for="tag-search-input">{{
  i18n.ContentTabs_RelatedSkills
}}</label>

<div class="field-group" data-dgat="searchTags-e1a">
  <dgx-search-field-wrapper
    *ngIf="useSearchStyle; else useInputStyle"
    class="full-width"
    [ngClass]="{
      'bg-color-ebony-a03': ngDisabled
    }"
  >
    <ng-container *ngTemplateOutlet="searchField"></ng-container>
  </dgx-search-field-wrapper>

  <ng-template #useInputStyle>
    <span
      class="field-group__input field-group__input--tag guts-p-full-0"
      [ngClass]="{
        'bg-color-ebony-a03': ngDisabled,
        'suggested-skills-view-container': useSuggestedSkillsView
      }"
    >
      <ng-container *ngTemplateOutlet="searchField"></ng-container>
    </span>
  </ng-template>
</div>

<div *ngIf="!hideTopTags" class="top-tags">
  <div *ngIf="hint" class="micro explanation guts-m-v-half">
    {{ hint }}
  </div>

  <p class="hidden" id="tag-search-top-tags-desc-{{ instanceId }}">
    {{ i18n.TagsSearch_A11yTopTagsHint }}
  </p>

  <ul [ngClass]="{ 'guts-m-t-half': !hint }">
    <li
      *ngFor="let tag of topTags; trackBy: trackByTitle"
      class="ib"
      data-dgat="tagsEdit-f54"
    >
      <button
        type="button"
        (click)="selected(tag)"
        class="guts-m-r-half break guts-m-b-half btn btn-passive btn-sm"
        data-dgat="tagsEdit-fb2"
        [attr.aria-label]="
          'dgTagsEdit_AddTagFormat' | translate: { tag: tag.title }
        "
      >
        <div class="l_flexbar gap-full-half">
          <df-icon
            *ngIf="tag.isAIInferredSkill"
            size="small"
            icon="plus"
          ></df-icon>
          <svg
            class="color-ebony-a61"
            *ngIf="tag.isAIInferredSkill"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 4.5C9.33486 4.5 9.62915 4.72198 9.72114 5.04396L10.5343 7.89015C10.8903 9.13593 11.8641 10.1097 13.1099 10.4657L15.956 11.2789C16.278 11.3709 16.5 11.6651 16.5 12C16.5 12.3349 16.278 12.6291 15.956 12.7211L13.1098 13.5343C11.8641 13.8903 10.8903 14.8641 10.5343 16.1099L9.72114 18.956C9.62915 19.278 9.33486 19.5 9 19.5C8.66514 19.5 8.37085 19.278 8.27886 18.956L7.46566 16.1099C7.10972 14.8641 6.13593 13.8903 4.89015 13.5343L2.04396 12.7211C1.72198 12.6291 1.5 12.3349 1.5 12C1.5 11.6651 1.72198 11.3709 2.04396 11.2789L4.89015 10.4657C6.13593 10.1097 7.10972 9.13593 7.46566 7.89015L8.27886 5.04396C8.37085 4.72198 8.66514 4.5 9 4.5Z"
              fill="#0f1f2c9c"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 1.5C18.3442 1.5 18.6441 1.73422 18.7276 2.0681L18.9865 3.10356C19.2216 4.04406 19.9559 4.7784 20.8964 5.01353L21.9319 5.27239C22.2658 5.35586 22.5 5.65585 22.5 6C22.5 6.34415 22.2658 6.64414 21.9319 6.72761L20.8964 6.98647C19.9559 7.2216 19.2216 7.95594 18.9865 8.89644L18.7276 9.9319C18.6441 10.2658 18.3442 10.5 18 10.5C17.6558 10.5 17.3559 10.2658 17.2724 9.9319L17.0135 8.89644C16.7784 7.95594 16.0441 7.2216 15.1036 6.98647L14.0681 6.72761C13.7342 6.64414 13.5 6.34415 13.5 6C13.5 5.65585 13.7342 5.35586 14.0681 5.27239L15.1036 5.01353C16.0441 4.7784 16.7784 4.04406 17.0135 3.10356L17.2724 2.0681C17.3559 1.73422 17.6558 1.5 18 1.5Z"
              fill="#0f1f2c9c"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5 15C16.8228 15 17.1094 15.2066 17.2115 15.5128L17.6058 16.6956C17.7551 17.1435 18.1065 17.4949 18.5544 17.6442L19.7372 18.0385C20.0434 18.1406 20.25 18.4272 20.25 18.75C20.25 19.0728 20.0434 19.3594 19.7372 19.4615L18.5544 19.8558C18.1065 20.0051 17.7551 20.3565 17.6058 20.8044L17.2115 21.9872C17.1094 22.2934 16.8228 22.5 16.5 22.5C16.1772 22.5 15.8906 22.2934 15.7885 21.9872L15.3942 20.8044C15.2449 20.3565 14.8935 20.0051 14.4456 19.8558L13.2628 19.4615C12.9566 19.3594 12.75 19.0728 12.75 18.75C12.75 18.4272 12.9566 18.1406 13.2628 18.0385L14.4456 17.6442C14.8935 17.4949 15.2449 17.1435 15.3942 16.6956L15.7885 15.5128C15.8906 15.2066 16.1772 15 16.5 15Z"
              fill="#0f1f2c9c"
            />
          </svg>
          <div>{{ tag.title | titlecase }}</div>
        </div>
      </button>
    </li>
  </ul>
</div>

<div
  *ngIf="hideTopTags && showHintAlone"
  class="micro explanation guts-m-v-half"
>
  {{ hint }}
</div>

<p class="hidden" id="tag-search-input-desc-{{ instanceId }}">
  {{ i18n.dgGlobalSearch_A11ySearchDescription }}
</p>

<ng-template #searchField>
  <div class="guts-p-l-1 l_flex">
    <div *ngIf="!useNewTagsDesign" class="guts-p-t-1">
      <df-icon
        [icon]="loadingJobRoleAISkillSuggestions ? 'arrow-circular-16' : 'tag'"
        *ngIf="!useSearchStyle && !useSuggestedSkillsView"
        class="guts-m-r-1 color-ebony-a61"
      ></df-icon>
    </div>
    <div
      class="guts-m-v-quart"
      [ngClass]="{
        'field-group__input--full-width': useNewTagsDesign
      }"
    >
      <button
        type="button"
        *ngFor="let tag of tags; trackBy: trackByTitle"
        class="btn btn-sm break bg-color-ebony-a08 color-ebony guts-m-r-half guts-m-v-quart"
        [attr.aria-label]="
          'dgTagsEdit_RemoveTagFormat' | translate: { tag: tag.title }
        "
        [ngClass]="{ 'selected-suggested-tag': useSuggestedSkillsView }"
        (click)="remove(tag)"
        data-dgat="editDetailsModal-a93"
      >
        <div class="l_flexbar gap-full-half">
          <svg
            class="color-ebony-a61"
            *ngIf="tag.isAIInferredSkill"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 4.5C9.33486 4.5 9.62915 4.72198 9.72114 5.04396L10.5343 7.89015C10.8903 9.13593 11.8641 10.1097 13.1099 10.4657L15.956 11.2789C16.278 11.3709 16.5 11.6651 16.5 12C16.5 12.3349 16.278 12.6291 15.956 12.7211L13.1098 13.5343C11.8641 13.8903 10.8903 14.8641 10.5343 16.1099L9.72114 18.956C9.62915 19.278 9.33486 19.5 9 19.5C8.66514 19.5 8.37085 19.278 8.27886 18.956L7.46566 16.1099C7.10972 14.8641 6.13593 13.8903 4.89015 13.5343L2.04396 12.7211C1.72198 12.6291 1.5 12.3349 1.5 12C1.5 11.6651 1.72198 11.3709 2.04396 11.2789L4.89015 10.4657C6.13593 10.1097 7.10972 9.13593 7.46566 7.89015L8.27886 5.04396C8.37085 4.72198 8.66514 4.5 9 4.5Z"
              fill="#0f1f2c9c"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 1.5C18.3442 1.5 18.6441 1.73422 18.7276 2.0681L18.9865 3.10356C19.2216 4.04406 19.9559 4.7784 20.8964 5.01353L21.9319 5.27239C22.2658 5.35586 22.5 5.65585 22.5 6C22.5 6.34415 22.2658 6.64414 21.9319 6.72761L20.8964 6.98647C19.9559 7.2216 19.2216 7.95594 18.9865 8.89644L18.7276 9.9319C18.6441 10.2658 18.3442 10.5 18 10.5C17.6558 10.5 17.3559 10.2658 17.2724 9.9319L17.0135 8.89644C16.7784 7.95594 16.0441 7.2216 15.1036 6.98647L14.0681 6.72761C13.7342 6.64414 13.5 6.34415 13.5 6C13.5 5.65585 13.7342 5.35586 14.0681 5.27239L15.1036 5.01353C16.0441 4.7784 16.7784 4.04406 17.0135 3.10356L17.2724 2.0681C17.3559 1.73422 17.6558 1.5 18 1.5Z"
              fill="#0f1f2c9c"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5 15C16.8228 15 17.1094 15.2066 17.2115 15.5128L17.6058 16.6956C17.7551 17.1435 18.1065 17.4949 18.5544 17.6442L19.7372 18.0385C20.0434 18.1406 20.25 18.4272 20.25 18.75C20.25 19.0728 20.0434 19.3594 19.7372 19.4615L18.5544 19.8558C18.1065 20.0051 17.7551 20.3565 17.6058 20.8044L17.2115 21.9872C17.1094 22.2934 16.8228 22.5 16.5 22.5C16.1772 22.5 15.8906 22.2934 15.7885 21.9872L15.3942 20.8044C15.2449 20.3565 14.8935 20.0051 14.4456 19.8558L13.2628 19.4615C12.9566 19.3594 12.75 19.0728 12.75 18.75C12.75 18.4272 12.9566 18.1406 13.2628 18.0385L14.4456 17.6442C14.8935 17.4949 15.2449 17.1435 15.3942 16.6956L15.7885 15.5128C15.8906 15.2066 16.1772 15 16.5 15Z"
              fill="#0f1f2c9c"
            />
          </svg>
          <span [innerText]="tag.title | titlecase"></span>
          <df-icon
            icon="cross"
            size="small"
            class="guts-m-l-quart color-ebony-a61"
            [ngClass]="{ 'color-white': useSuggestedSkillsView }"
          ></df-icon>
        </div>
      </button>
      <span
        [ngClass]="{
          'field-group__input-wrapper': useNewTagsDesign
        }"
      >
        <df-icon
          *ngIf="useNewTagsDesign"
          icon="magnifying-glass"
          class="guts-m-r-half color-ebony-a61"
        ></df-icon>
        <input
          #tagsInput
          attr.aria-describedby="
            tag-search-input-desc-{{ instanceId }}
            tag-search-top-tags-desc-{{ instanceId }}
          "
          attr.aria-owns="tag-search-popover-{{ instanceId }}"
          autocomplete="off"
          id="tag-search-input"
          [ngbTooltip]="
            loadingJobRoleAISkillSuggestions
              ? i18n.OrgSkills_GeneratingSkills
              : placeholderText || i18n.Core_CategoryName
          "
          [disableTooltip]="!isTagsInputOverflowing"
          placement="bottom"
          [ngClass]="{
            'field-group__input--full-width': useNewTagsDesign,
            'bg-color-white': loadingJobRoleAISkillSuggestions
          }"
          name="tagSearch"
          role="combobox"
          type="text"
          [(ngModel)]="searchTerm"
          (input)="onInputChange($event)"
          (keypress)="inputKeyPress($event)"
          maxlength="255"
          [attr.aria-label]="
            searchInputLabel
              ? undefined
              : i18n.TargetResourcesForm_SearchPlaceHolder
          "
          [disabled]="loadingJobRoleAISkillSuggestions || ngDisabled"
          [placeholder]="
            loadingJobRoleAISkillSuggestions
              ? i18n.OrgSkills_GeneratingSkills
              : placeholderText || i18n.Core_CategoryName
          "
          data-dgat="searchTags-d9b"
        />
      </span>
      <df-popover
        #popoverComponent
        id="tag-search-popover-{{ instanceId }}"
        (isOpenChange)="onIsPopoverOpenChange($event)"
        [(isOpen)]="shouldShowSuggestions"
        [popoverTrigger]="tagsInputRef"
        [isFocusFirstItem]="false"
        placement="bottom-left"
        [ngClass]="{ 'full-width-popover': useSuggestedSkillsView }"
      >
        <!--Search input auto suggest menu contents. role=menu and role=menuitem are both a11y attributes AND custom directives that enable keyboard navigation-->
        <div role="menu">
          <p
            *ngIf="suggestSkills"
            class="guts-p-l-1 guts-p-v-1 color-ebony-a61 par--small font-medium"
            [innerText]="
              'SkillRegistrySearch_SkillRegistrySuggestions' | translate
            "
          ></p>
          <ng-container
            *ngFor="
              let suggestion of suggestions;
              let first;
              let last;
              trackBy: trackByTitle
            "
            data-dgat="tags-search-622"
          >
            <button
              class="l_flexbar guts-p-v-1 guts-p-l-0 guts-p-r-1 listbox--option"
              data-dgat="tags-search-fe6"
              role="menuitem"
              (click)="selected(suggestion, $event)"
            >
              <div
                *ngIf="!useSuggestedSkillsView"
                class="icon-spot bg-color-ebony-a08 center-item guts-m-h-1"
              >
                <df-icon icon="tag" class="color-ebony-a61"></df-icon>
              </div>
              <div
                class="par par--small l_flexbar left-text"
                [ngClass]="{ 'guts-m-l-2': useSuggestedSkillsView }"
              >
                <span
                  [innerHtml]="
                    suggestion.title
                      | encodeToEntities
                      | highlightSearchText: searchTerm
                  "
                ></span>
                <img
                  class="tag-endorsed-img v-bottom guts-p-l-quart"
                  *ngIf="suggestion.endorsedSrc"
                  [attr.alt]="i18n.Core_Endorsed"
                  src="{{ suggestion.endorsedSrc }}?brandlogo"
                />
              </div>
            </button>
          </ng-container>
        </div>
      </df-popover>
      <df-popover
        #popoverComponentCustomTag
        id="tag-search-popover-{{ instanceId }}"
        (isOpenChange)="onIsPopoverOpenChange($event)"
        [(isOpen)]="shouldShowCustomTag"
        [popoverTrigger]="tagsInputRef"
        [isFocusFirstItem]="false"
        placement="bottom-left"
        [ngClass]="{ 'full-width-popover': useSuggestedSkillsView }"
      >
        <div role="menu">
          <ul class="typeahead-list js-suggestion-list">
            <li class="typeahead-item guts-p-b-half">
              <p
                class="guts-p-l-1 guts-p-v-1 color-ebony-a61 par--small font-medium"
                [innerText]="i18n.TargetResourcesForm_NoResults"
              ></p>
              <button
                role="menuitem"
                class="guts-p-v-0 guts-p-l-0 guts-p-r-1 listbox--option"
                (click)="selected(customItem, $event)"
                data-dgat="searchTags-d22"
              >
                <div class="l_flexbar guts-p-v-1 guts-p-h-0">
                  <div
                    *ngIf="!useSuggestedSkillsView"
                    class="icon-spot bg-color-ebony-a08 center-item guts-m-h-1"
                  >
                    <df-icon icon="tag" class="color-ebony-a61"></df-icon>
                  </div>
                  <div
                    class="l_flex-grow"
                    [ngClass]="{ 'guts-m-l-1': useSuggestedSkillsView }"
                  >
                    <h3
                      class="par par--small font-bold"
                      [innerText]="searchTerm"
                    ></h3>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </df-popover>
    </div>
  </div>
</ng-template>
