import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { useApolloLayout } from '@degreed/apollo-angular';

import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { OrgBaseGuard } from './org-base.guard';
import { LDFlagsService } from '@dg/shared-services';

@Injectable({
  providedIn: 'root',
})
export class SettingsLinkGuard extends OrgBaseGuard {
  constructor(
    authService: AuthService,
    @Inject(WindowToken) windowRef: Window,
    router: Router,
    private ldFlagsService: LDFlagsService
  ) {
    super(authService, windowRef, router);
  }

  override get hasPermission(): boolean {
    return this.orgInfo?.permissions.editSettings ?? false;
  }

  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const personalizedLearning = this.ldFlagsService.personalizedLearning;
    const layoutRefresh = useApolloLayout();

    return this.hasPermission &&
      (!personalizedLearning || (personalizedLearning && !layoutRefresh))
      ? this.hasPermission
      : this.hasPermission && personalizedLearning && layoutRefresh
        ? this.router.parseUrl(`/orgs/${this.orgId}/settings/home`)
        : this.router.parseUrl(this.resolveOrgUrl(''));
  }

  protected resolveOrgUrl(routePath: string) {
    const basePath = `/orgs/${this.orgId}${this.routeDirectory}`;
    return `${basePath}${routePath}`;
  }
}
