import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ReportingService,
  ScoreType,
} from '@app/insights/services/reporting.service';
import { ReportingContentService } from '@app/shared/services/reporting-content/reporting-content.service';
import { SubscriptionQueueService } from '@app/shared/services/subscription-queue.service';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-top-groups',
  templateUrl: './top-groups.component.html',
  styleUrls: ['./top-groups.component.scss'],
  providers: [SubscriptionQueueService],
})
export class TopGroupsComponent implements OnInit, OnChanges {
  @Input() public orgId: number;
  @Input() public startDate: any;
  @Input() public endDate: any;
  @Input() public context?: string;
  @Input() public location: string;

  public topGroups: any;
  public chartName: string;

  public i18n = this.translate.instant([
    'dgTopGroups_LinkToGroups',
    'dgTopGroups_ClosedGroup',
    'dgTopGroups_PrivateGroup',
    'dgTopGroups_AdministrativeGroup',
    'dgTopGroups_Empty',
    'ReportingContentSvc_Name',
    'ReportingContentSvc_Points',
    'OrgReportingCtrl_Members',
    'Core_Completions',
  ]);

  public scoreType: number;
  public metricStr: string;

  constructor(
    private translate: TranslateService,
    private reportingContentService: ReportingContentService,
    private reportingService: ReportingService,
    private subscriptionQueueService: SubscriptionQueueService,
    private ldFlagService: LDFlagsService
  ) {
    const pointsDecomission = this.ldFlagService.profile.pointsDecomission;
    this.scoreType = pointsDecomission
      ? ScoreType.itemsCompleted
      : ScoreType.points;
    this.metricStr = pointsDecomission
      ? 'Core_Completions'
      : 'ReportingContentSvc_Points';
  }

  public ngOnInit(): void {
    this.chartName = this.reportingContentService.getChartName('topGroups');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate || changes.endDate || changes.orgId) {
      this.getTopGroups(this.orgId, this.startDate, this.endDate);
    }
  }

  public getTopGroups(orgId, startDate, endDate) {
    const count = 5;

    const subscription = this.reportingService
      .getTopActiveGroups(orgId, startDate, endDate, count, this.scoreType)
      .subscribe((data) => {
        this.topGroups = {
          headers: [
            this.i18n.ReportingContentSvc_Name,
            this.i18n[this.metricStr],
            this.i18n.OrgReportingCtrl_Members,
          ],
          groups: data,
        };
      });
    this.subscriptionQueueService.persist(subscription, 'getTopActiveGroups');
  }
}
