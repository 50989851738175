// This file is shared with the bookmarklet/extensions

import { Injectable } from '@angular/core';
import {
  Group,
  GroupInfo,
  GroupIdentifier,
  GroupMemberCount,
  GroupPrivacyLevel,
  TypeaheadGroupSearchFunction,
  Permission,
  GroupRole,
  GroupMember,
  PaginatedGroupMembers,
} from '@app/groups/group-api';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NgxHttpClient } from '../ngx-http-client';
import { TrackerService } from './tracker.service';
import { OrgNewGroupSettingsComponent } from '@app/groups/components/org-new-group-settings/org-new-group-settings.component';
import { OrgMemberSummary } from '@app/orgs/services/orgs.model';
import { ActivitiesModel } from '@app/groups/group-activity.model';
import { ApiServiceBase } from './api-service-base';
import { GroupPermissions } from '@app/profile/components/profile-groups/profile-groups.component';
import { PredicateType } from '@app/automations/model';

export interface OrgNewGroupSettingsInfo {
  canCreateOpenGroups?: boolean;
  canCreateClosedGroups?: boolean;
  canGreatPrivateGroups?: boolean;
  canCreateAdministrativeGroups?: boolean;
  canCreatePrivateGroups?: boolean;
  isRestrictedProfile?: any;
  createGroupTitle?: any;
  sourceTarget?: any;
  groupId?: any;
  saveGroup?: any;
  isRegulated?: boolean;
  orgId?: any;
}
export interface GroupCountChange {
  delta?: number;
  totalCount?: number;
}

@Injectable({ providedIn: 'root' })
export class GroupService extends ApiServiceBase {
  public isNotFound$ = new Subject<boolean>();
  private groupCountChangeSubject$ = new Subject<GroupCountChange>();
  private pendingCountAddSubject$ = new Subject<number>();
  private pendingCountRefreshSubject$ = new Subject<number>();
  private groupInfoCache: GroupInfo;

  private _groupModified = new Subject<number>();

  /** Subscribe to be notified of updates to a group */
  public readonly groupModified = this._groupModified.asObservable();

  constructor(
    public httpService: NgxHttpClient,
    private translateService: TranslateService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    protected authService: AuthService,
    private trackerService: TrackerService
  ) {
    super(httpService, translateService.instant('GroupSvc_ErrorNotification'));
  }

  /** Notify `groupModified` listeners that a users tags have been udpated */
  public notifyGroupModified(groupId: number): void {
    return this._groupModified.next(groupId);
  }

  public get groupInfo() {
    return this.groupInfoCache;
  }

  public set groupInfo(groupInfo) {
    // kind of odd we have to do this, better done in the BE?
    groupInfo.group.topActiveUsers =
      groupInfo.group.topActiveUsers ?? groupInfo.topActiveUsers;
    this.groupInfoCache = groupInfo;
  }

  public sendGroupCountChange({ delta, totalCount }: GroupCountChange) {
    this.groupCountChangeSubject$.next({ delta, totalCount });
  }

  public getGroupCountChange(): Observable<GroupCountChange> {
    return this.groupCountChangeSubject$.asObservable();
  }

  public sendPendingCountAdd(count: number) {
    this.pendingCountAddSubject$.next(count);
  }

  public getPendingCountAdd(): Observable<number> {
    return this.pendingCountAddSubject$.asObservable();
  }

  public sendPendingCountRefresh(count: number) {
    this.pendingCountRefreshSubject$.next(count);
  }

  public getPendingCountRefresh(): Observable<number> {
    return this.pendingCountRefreshSubject$.asObservable();
  }

  public add(group: Partial<GroupInfo>): Observable<{ groupId: number }> {
    if (group.predicates) {
      group.predicates = group.predicates.map((predicate) => ({
        ...predicate,
        comparisonValue: JSON.stringify(predicate.comparisonValue) as any,
      }));
    }
    return this.post<{ groupId: number }>('/groups/post', group).pipe(
      tap(({ groupId }) => {
        this.trackerService.trackEventData({
          action: 'Group Created',
          properties: {
            ...group,
            groupId,
          },
        });
      })
    );
  }

  /**
   * Add Group Modal
   *
   * @param orgNewGroupSettingsInfo - for the group to be added
   */
  public addGroup(
    orgNewGroupSettingsInfo: OrgNewGroupSettingsInfo
  ): Observable<OrgNewGroupSettingsComponent> {
    orgNewGroupSettingsInfo.createGroupTitle = this.translateService.instant(
      'GroupSvc_GroupSettingsTitle'
    );
    return this.modalService.show(OrgNewGroupSettingsComponent, {
      inputs: { orgNewGroupSettingsInfo },
    });
  }

  public addMembers(
    userProfileKeys: string[],
    groupId: number
  ): Observable<{ groupMemberCount: number }> {
    return this.put<{ groupMemberCount: number }>(
      '/groups/addmembers',
      {
        userProfileKeys,
        groupId,
      },
      this.translateService.instant('GroupSvc_GroupInvitesNotificationError')
    ).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Administrative Group Member Added',
          category: `${groupId}`,
        });
      })
    );
  }

  public checkName(
    name: string,
    groupId: number
  ): Observable<{ alreadyExists: string }> {
    return this.get('/groups/checkgroupname', {
      name,
      groupId,
    });
  }

  public bulkDeleteGroups(
    organizationId: number,
    groupIds: number[]
  ): Observable<void> {
    return this.delete<void>('/groups/bulkDelete', {
      organizationId,
      groupIds,
    }).pipe(
      tap(() => {
        this.notifierService.showSuccess(
          this.translateService.instant('GroupSvc_BulkDeleteGroupsSuccess')
        );
      })
    );
  }

  public deleteGroup(
    organizationId: number,
    groupId: number
  ): Observable<void> {
    return this.put('/groups/deletegroup', {
      organizationId,
      groupId,
    });
  }

  public editComment(
    groupActivityId: number,
    comment: string
  ): Observable<void> {
    return this.put('/groups/editComment', {
      groupActivityId,
      comment,
    });
  }

  /**
   * The Group search function
   *
   * @param term An observable that emits characters as they are typed.
   * @param visibility an optional visibility specifier (defaults to public)
   * @returns An observable of results.
   */
  public search: TypeaheadGroupSearchFunction<
    string | number,
    GroupPrivacyLevel,
    GroupIdentifier
  > = (
    term: Observable<string>,
    groupPrivacyLevel: GroupPrivacyLevel = GroupPrivacyLevel.Closed
  ): Observable<readonly GroupIdentifier[]> => {
    return term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      withLatestFrom(this.authService.authUser$),
      switchMap(([term, authUser]) => {
        return this.searchGroups(
          authUser.orgInfo[0].organizationId,
          groupPrivacyLevel,
          term
        );
      }),
      map((results: Group[]) => {
        results.length > 0
          ? this.isNotFound$.next(false)
          : this.isNotFound$.next(true);

        return results.map((group) => this.toGroupSearchItem(group));
      }),
      catchError((error) => {
        // for now, swallow and return no results
        return of([] as GroupIdentifier[]);
      })
    );
  };

  public searchGroups(
    orgId: number,
    groupPrivacyLevel: GroupPrivacyLevel,
    term: string,
    excludePrivateUsers: boolean = false
  ): Observable<Group[]> {
    return this.get('/organizations/searchgroups', {
      orgId,
      searchGroupPreset: groupPrivacyLevel,
      term,
      excludePrivateUsers,
    });
  }

  public getGroup(groupId: any): Observable<GroupInfo> {
    return this.get('/groups/get', { groupId }).pipe(
      map((group: GroupInfo) => {
        if (group.predicates) {
          group.predicates = group.predicates.map((predicate) => ({
            ...predicate,
            comparisonValue: JSON.parse(predicate.comparisonValue as any),
          }));
        }
        return group;
      })
    );
  }

  public getSummary(groupId: number): Observable<GroupInfo> {
    return this.get(`/groups/summary`, { groupId });
  }

  public getActivities(
    id: number,
    count: number,
    sinceDateUtc: Date,
    localeId: string
  ): Observable<ActivitiesModel> {
    return this.get('/groups/activities', {
      id,
      count,
      sinceDateUtc,
      localeId,
    });
  }

  public searchMembersForGroup(
    orgId: number,
    excludedGroupId: number,
    nameFilter: string,
    count?: number
  ): Observable<OrgMemberSummary[]> {
    return this.get('/groups/searchmembersforgroup', {
      orgId,
      excludedGroupId,
      nameFilter,
      count,
    });
  }

  public getMember(groupId: number, userId: any): Observable<GroupMember> {
    // use http.get not this.get as we need the whole error response to get returned to the component
    // as the status code needs to be checked, this.get just surfaces the error message
    return this.http.get('/groups/member', {
      params: {
        id: groupId,
        userId,
      },
      cache: false,
    });
  }

  public getMemberCount(id: number): Observable<GroupMemberCount> {
    return this.get('/groups/membercount', { id });
  }

  public getMemberDetail(
    groupId: number,
    userId: number,
    isPending: boolean
  ): Observable<Partial<GroupMember>> {
    return this.get('/groups/memberdetail', {
      groupId,
      userId,
      isPending,
    });
  }

  public getMemberPermissions(groupId: number): Observable<string[]> {
    return this.get('/groups/memberpermissions', { groupId });
  }

  public getUserGroupPermissions(): Observable<GroupPermissions> {
    return this.get('/groups/getProfileGroupPermissions');
  }

  public getMembers(
    id: number,
    nameFilter: any,
    count: number,
    skip: any,
    orderBy: any,
    sortDescending: boolean
  ): Observable<PaginatedGroupMembers> {
    return this.get('/groups/members', {
      id,
      nameFilter,
      count,
      skip,
      orderBy,
      sortDescending,
    });
  }

  public getInvitedOrRequestingMembers(
    groupId: number
  ): Observable<GroupMember[]> {
    return this.get('/groups/invitedOrRequestingMembers', { groupId });
  }

  public getInvitedOrRequestingMembersCount(
    groupId: number
  ): Observable<number> {
    return this.get('/groups/invitedOrRequestingMembersCount', { groupId });
  }

  public getPendingInvite(
    groupId: number,
    userId: number
  ): Observable<GroupMember> {
    return this.get('/groups/pendingInvite', { id: groupId, userId });
  }

  public getRoles(groupId: number): Observable<Partial<GroupRole[]>> {
    return this.get('/groups/roles', { groupId });
  }

  public join(
    {
      groupId,
      name,
      privacyLevel,
      userCount,
      interestNames,
    }: Partial<GroupInfo>,
    referId?: any,
    showSuccess = true
  ) {
    return this.post('/groups/join', {
      groupId,
      referId,
    }).pipe(
      tap(() => {
        if (showSuccess) {
          this.notifierService.showSuccess(
            this.translateService.instant('GroupSvc_GroupJoinNotification')
          );
        }

        this.trackerService.trackEventData({
          action: 'Group Joined',
          category: name,
          label: `${privacyLevel}`,
          properties: {
            name,
            groupId,
            MemberCount: userCount,
            Type: privacyLevel,
            Topics: interestNames,
          },
        });
      })
    );
  }

  public leave(
    {
      groupId,
      name,
      privacyLevel,
      userCount,
      interestNames,
    }: Partial<GroupInfo>,
    showSuccess = true
  ): Observable<void> {
    return this.post<void>('/groups/leave', {
      groupId,
    }).pipe(
      tap(() => {
        if (showSuccess) {
          this.notifierService.showSuccess(
            this.translateService.instant('GroupSvc_GroupLeaveNotification')
          );
        }

        this.trackerService.trackEventData({
          action: 'Group Left',
          category: name,
          label: `${privacyLevel}`,
          properties: {
            name,
            groupId,
            MemberCount: userCount,
            Type: privacyLevel,
            Topics: interestNames,
          },
        });
      })
    );
  }

  public postComment(groupId: number, comment: string): Observable<number> {
    return this.post('/groups/postcomment', {
      groupId,
      comment,
    });
  }

  public removeFromFeed(feedItemId: number): Observable<void> {
    return this.post('/groups/removeFeedItem', {
      feedItemId,
    });
  }

  public removeMember(
    groupId: number,
    userId: string,
    group: {
      name: string;
      privacyLevel: GroupPrivacyLevel;
    }
  ): Observable<void> {
    return this.put<void>('/groups/removemember', {
      groupId,
      userId,
    }).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Group User Removed',
          category: group?.name || '',
          label: group?.privacyLevel.toString() || '',
          properties: {
            GroupId: groupId,
            UserId: userId,
            Name: group.name,
            Type: group.privacyLevel,
          },
        });
      })
    );
  }

  public removePendingInvite(
    groupId: number,
    userId: number
  ): Observable<void> {
    return this.put<void>('/groups/removependinginvite', {
      groupId,
      userId,
    }).pipe(
      tap(() =>
        this.trackerService.trackEventData({ action: 'Pending Invite Removed' })
      )
    );
  }

  public requestMembership(
    {
      groupId,
      name,
      privacyLevel,
      userCount,
      interestNames,
    }: Partial<GroupInfo>,
    referId?: number
  ): Observable<{ addedToGroup: boolean }> {
    return this.post('/groups/requestmembership', {
      groupId,
      referId,
    }).pipe(
      tap(({ addedToGroup }: any) => {
        const notification = addedToGroup
          ? 'GroupSvc_GroupJoinNotification'
          : 'GroupSvc_GroupMembershipNotification';
        const action = addedToGroup
          ? 'Group Joined'
          : 'Group Membership Requested';
        this.notifierService.showSuccess(
          this.translateService.instant(notification)
        );
        this.trackerService.trackEventData({
          action: action,
          category: name,
          label: privacyLevel as unknown as string,
          properties: {
            Name: name,
            GroupId: groupId,
            MemberCount: userCount,
            Type: privacyLevel,
            Topics: interestNames,
          },
        });
      })
    );
  }

  public respondToMembershipRequest(
    groupId: number,
    requestedUserId: number,
    isApproved: boolean
  ): Observable<void> {
    return this.post<void>('/groups/respondtomembershiprequest', {
      groupId,
      requestedUserId,
      isApproved,
    }).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: `Group Membership ${isApproved ? 'Approved' : 'Denied'}`,
          properties: { groupId },
        });
      })
    );
  }

  public sendInvite(
    group: {
      groupId: any;
      name: any;
    },
    userProfileKeys: number[],
    customMessage: string,
    resendPendingInvites: boolean
  ): Observable<void> {
    const invite = {
      groupId: group.groupId,
      userProfileKeys,
      resendPendingInvites,
      customMessage,
    };
    return this.post<void>(
      '/groups/invite',
      invite,
      this.translateService.instant('GroupSvc_GroupInvitesNotificationError')
    ).pipe(
      tap(() => {
        this.notifierService.showSuccess(
          this.translateService.instant('GroupSvc_GroupInvitesNotification')
        );
        this.trackerService.trackEventData({
          action: 'Group Member Invited',
          category: group.name,
          label: 'Email Sent',
          properties: group,
        });
      })
    );
  }

  public setMemberRole(
    groupId: number,
    userId: string,
    roleId: number,
    group: {
      organizationId: number;
      name: string;
    },
    role: {
      name: string;
    }
  ): Observable<any> {
    return this.put('/groups/setmemberrole', {
      groupId,
      userId,
      roleId,
    }).pipe(
      tap(() => {
        const properties = {
          Role: role?.name || '',
          OrganizationId: group.organizationId,
        };
        this.trackerService.trackEventData({
          action: 'Group Updated',
          category: group?.name || '',
          label: 'Membership Role',
          properties,
        });
      })
    );
  }

  public setPendingMemberRole(
    groupId: number,
    userId: string,
    roleId: number
  ): Observable<void> {
    return this.put<void>('/groups/setpendingmemberrole', {
      groupId,
      userId,
      roleId,
    }).pipe(
      tap(() =>
        this.trackerService.trackEventData({
          action: 'Pending Invite Group Role Changed',
        })
      )
    );
  }

  public update(group: Partial<GroupInfo>): Observable<void> {
    if (group.predicates) {
      group.predicates = group.predicates.map((predicate) => ({
        ...predicate,
        comparisonValue: JSON.stringify(predicate.comparisonValue) as any,
      }));
    }
    return this.put<void>('/groups/put', group).pipe(
      tap(() =>
        this.trackerService.trackEventData({
          action: 'Group Settings Updated',
          category: group.name,
          label: '',
          properties: group,
        })
      )
    );
  }

  public updateRoles(groupId: number, roles: GroupRole[]): Observable<void> {
    return this.put<void>('/groups/updateroles', {
      groupId,
      roles,
    });
  }

  private toGroupSearchItem(group: Partial<Group>): GroupIdentifier {
    return {
      ...group,
      isRestricted: true,
    } as GroupIdentifier;
  }
}
