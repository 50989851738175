import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { GroupService } from '@app/shared/services/group.service';
import { AuthUser } from '@app/account/account-api.model';
import { GroupInfoCore } from '@app/groups/group-api';
import { AuthService } from '@app/shared/services/auth.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { AnyLearningResource } from '@app/inputs/models/learning-resource.view-model';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { lazySearch } from '@dg/shared-rxjs';
import { RecommendationGroupModel } from '@app/recommendations/recommendations.api';
import {
  AllRecommendees,
  AnyRecommendee,
} from '@app/recommendations/recommendations.model';
import { isGroup } from '@app/recommendations/recommendations.type-guards';
import { UserSearchComponent } from '../user-search/user-search.component';

/** Uses the {@link UserSearchComponent} to provide the ability to search and select users and groups for
 * sharing and collaboration.
 */
// TODO: This could benefit from refactoring to use the SimpleItemViewModel so we can stop caring about whether we have users or groups
@Component({
  selector: 'dgx-invite-user-search',
  templateUrl: './invite-user-search.component.html',
  styleUrls: ['./invite-user-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUserSearchComponent implements OnInit {
  /**
   * Optional: Restrict search to users only
   */
  @Input() public usersOnly?: boolean = false;
  /** Whether to initially populate and show the drop-down initially when focused. *Defaults to false.* */
  @Input() public showSuggestions?: boolean = false;

  /** Sets the ID on our input field, connecting it to an external label. */
  @Input() public labelKey = '';

  /**
   * Parent resource: Used to determine search users and/or groups.
   */
  @Input() public parentResource: AnyLearningResource | GroupInfoCore;

  // TODO: Currently expects and handles pascalCase input,
  // refactor once parent/sibling components have been migrated
  @Input() public selected: AnyRecommendee[] = [];
  @Output() public selectedChanged = new EventEmitter<AnyRecommendee[]>();

  @ViewChild(UserSearchComponent, { static: true })
  public userSearchRef: UserSearchComponent;

  public i18n = this.translate.instant([
    'Core_To',
    'recommendationForm_NoResults',
    'recommendationForm_SearchByName',
  ]);
  public noResults: boolean;
  public isLoading: boolean = false;
  private authUser: AuthUser;
  private organizationId: number;

  constructor(
    private translate: TranslateService,
    private groupService: GroupService,
    private recommendationsService: RecommendationsService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  public get parentIsGroup(): boolean {
    return isGroup(this.parentResource);
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.organizationId = this.authUser?.defaultOrgInfo?.organizationId;
  }

  public asUserSearchItem(item: AnyRecommendee): UserSearchItem {
    return item as UserSearchItem;
  }

  public searchGroupsOrUsers: TypeaheadSearchFunction<string, AnyRecommendee> =
    (searchTerm: Observable<string>): Observable<AnyRecommendee[]> =>
      searchTerm.pipe(
        lazySearch<AnyRecommendee>((searchTerm: string) => {
          this.isLoading = true;
          this.noResults = false;
          this.cdr.detectChanges(); // make sure spinner shows immediately
          if (this.parentIsGroup) {
            return this.searchMemberInvitees(searchTerm);
          }
          return this.searchInvitees(searchTerm);
        }),
        tap(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
        // exclude invitees already selected
        map((invitees) => invitees.filter((i) => !this.inviteeIsSelected(i)))
      );

  public addInvitee(item: AnyRecommendee): void {
    const exists = this.inviteeIsSelected(item);
    if (!exists) {
      // TODO: We shouldn't modify the original bound array
      this.selected.push(item);
      this.selectedChanged.emit(this.selected);
    }
    this.cdr.markForCheck();
  }

  public removeInvitee(item: AnyRecommendee): void {
    const index = this.selected.indexOf(item);
    if (index !== -1) {
      // TODO: We shouldn't modify the original bound array
      this.selected.splice(index, 1);
      this.selectedChanged.emit(this.selected);
      this.userSearchRef.focus(); // refocus search input after item button clicked
    }
    this.cdr.markForCheck();
  }

  private searchMemberInvitees(
    searchTerm: string
  ): Observable<UserSearchItem[]> {
    const memberCount = 100;
    const parent = this.parentResource as AnyLearningResource & GroupInfoCore;
    return this.groupService
      .searchMembersForGroup(
        parent.organizationId || this.organizationId,
        parent.groupId,
        searchTerm,
        memberCount
      )
      .pipe(
        tap((members) => {
          if (members.length === 0) {
            this.noResults = true;
          }
        })
      );
  }

  private searchInvitees(searchTerm: string): Observable<AnyRecommendee[]> {
    const parent = this.parentResource as AnyLearningResource & GroupInfoCore;
    const users = this.recommendationsService.findMembers({
      nameFilter: searchTerm,
      resourceId: parent.resourceId,
      resourceType: parent.resourceType,
      isOrgContent: !!parent.organizationId,
    });

    const groups = !this.usersOnly
      ? this.recommendationsService.findGroups({
          nameFilter: searchTerm,
          resourceId: parent.resourceId,
          resourceType: parent.resourceType,
        })
      : of([] as RecommendationGroupModel[]);

    return forkJoin([users, groups]).pipe(
      finalize(() => (this.isLoading = false)),
      map(([users, groups]) => {
        if (!users.length && !groups.length) {
          this.noResults = true;
        }

        const results = [...users, ...groups];
        return results.map((item) => item);
      })
    );
  }

  private inviteeIsSelected(item: AnyRecommendee): boolean {
    const mergedItem = item as AllRecommendees;
    const exists = this.selected.some((selectedItem: AllRecommendees) => {
      if (mergedItem?.userProfileKey) {
        return selectedItem.userProfileKey === mergedItem.userProfileKey;
      } else if (mergedItem?.groupId) {
        return selectedItem.groupId === mergedItem.groupId;
      }
    });
    return exists;
  }
}
