import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

export type ConfirmProps = {
  disableClose: boolean; // do not allow auto-close on backdrop clicks
  title: string;
  content: string;
  primaryBtnLabel: string;
  secondaryBtnLabel?: string;
  learnMoreLabel?: string;
  learnMoreUrl?: string;
  acknowledgeLabel?: string;
  onClose?: (confirmed: boolean) => void;
};

@Component({
  selector: 'da-confirm',
  template: `
    <div
      class="tw-max-w-72 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
      role="dialog"
      aria-labelledby="title"
      aria-describedby="description"
    >
      <div
        class="tw-mb-8 tw-flex tw-flex-col tw-items-start tw-gap-6 md:tw-flex-row"
      >
        <div class="tw-flex tw-flex-col">
          <h4 class="tw-mb-2 tw-font-extrabold tw-text-neutral-900" id="title">
            {{ title }}
          </h4>
          <p id="description" class="tw-text-neutral-700">
            {{ content }}
            <a
              *ngIf="learnMoreLabel"
              href="{{ learnMoreUrl }}"
              target="_blank"
              class="tw-font-semibold tw-text-blue-800"
            >
              {{ learnMoreLabel }}
            </a>
          </p>
          <label
            *ngIf="checkboxControl"
            class="tw-mt-4 tw-inline-flex tw-items-center tw-gap-2 tw-text-neutral-700"
          >
            <input
              class="tw-cursor-pointer"
              type="checkbox"
              [formControl]="checkboxControl"
            />
            {{ acknowledgeLabel }}
          </label>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
      >
        <button
          class="tw-btn-medium tw-btn-secondary-filled"
          (click)="handleClose(false)"
        >
          {{ secondaryBtnLabel }}
        </button>
        <button
          class="tw-btn-primary tw-btn-medium"
          [disabled]="!checkboxControl.value"
          (click)="handleClose(true)"
        >
          {{ primaryBtnLabel }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmComponent {
  @Input() public title: string = '';
  @Input() public content: string = '';
  @Input() public primaryBtnLabel: string = '';
  @Input() public secondaryBtnLabel?: string = '';
  @Input() public learnMoreLabel?: string = '';
  @Input() public learnMoreUrl?: string = '';
  @Input() public acknowledgeLabel?: string = '';
  @Output() public close: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkboxControl = new FormControl(false);

  constructor(
    @Inject(DIALOG_DATA)
    public data: ConfirmProps
  ) {
    this.title = data.title;
    this.content = data.content;
    this.primaryBtnLabel = data.primaryBtnLabel;
    this.secondaryBtnLabel = data.secondaryBtnLabel;
    this.learnMoreLabel = data.learnMoreLabel;
    this.learnMoreUrl = data.learnMoreUrl;
    this.acknowledgeLabel = data.acknowledgeLabel;
  }

  handleClose(confirmed: boolean): void {
    if (!!this.data.onClose) {
      this.data.onClose(confirmed);
    }
    this.close.emit(confirmed);
  }
}
