import { ModalFooterComponent } from './modal-footer/modal-footer.component';
// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalComponent } from './modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DfSpinnerModule,
  DfSpinnerButtonModule,
  DfButtonsModule,
  DfPopoverModule,
  DfLocalNotificationModule,
  DfIconModule,
  DfLabelMarkerModule,
  DfFormlyModule,
} from '@lib/fresco';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteModalComponent } from './delete-confirmation-modal/delete-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { DeleteMultiConfirmationModalComponent } from './delete-multi-confirmation-modal/delete-multi-confirmation-modal.component';

@NgModule({
  declarations: [
    /* components */
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    AlertModalComponent,
    DeleteModalComponent,
    DeleteMultiConfirmationModalComponent,
    SimpleModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    DfIconModule,
    DfSpinnerModule,
    DfSpinnerButtonModule,
    DfButtonsModule,
    DfFormlyModule, // used for DeleteMultiConfirm
    DfLocalNotificationModule,
    DfLabelMarkerModule,
    DfPopoverModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
  ],
  providers: [],
  exports: [
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    AlertModalComponent,
    DeleteModalComponent,
    DeleteMultiConfirmationModalComponent,
    SimpleModalComponent,
  ],
})
export class ModalModule {}
