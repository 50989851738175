import { A11yModule } from '@angular/cdk/a11y';
import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  FooterComponent_v2,
  HeaderComponent_v2,
  SidebarComponent_v2,
  LayoutComponent_v2,
  SwitcherDisclosureComponent,
  SwitcherItemComponent,
  SwitcherPopoverComponent,
} from './components/layout-v2';

import {
  ConfirmComponent,
  DropdownComponent,
  FooterComponent,
  HeaderComponent,
  LayoutComponent,
  NavDisclosureComponent,
  NavIconComponent,
  NavItemComponent,
  NavPopoverComponent,
  SidebarComponent,
} from './components';

import { IconsModule } from './icons';
import { DialogService } from './services';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    DialogModule,
    IconsModule,
    OverlayModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  declarations: [
    // Version 2 components (deprecated)

    FooterComponent_v2,
    HeaderComponent_v2,
    SidebarComponent_v2,
    LayoutComponent_v2,

    SwitcherDisclosureComponent,
    SwitcherItemComponent,
    SwitcherPopoverComponent,

    // Version 3 components

    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,

    NavDisclosureComponent,
    NavIconComponent,
    NavItemComponent,
    NavPopoverComponent,

    ConfirmComponent,
    DropdownComponent,
  ],
  exports: [
    DialogModule,
    IconsModule,
    OverlayModule,
    FooterComponent,
    ConfirmComponent,
    DropdownComponent,
    LayoutComponent,
    LayoutComponent_v2,
  ],
  providers: [DialogService],
})
export class ApolloAngularModule {}
