import {
  CourseApiInputEdit,
  CourseEntryApi,
  CourseMappingToApi,
  CourseTypeId,
  CourseModel,
} from './../course.model';
import { Injectable } from '@angular/core';
import { ScormInfo } from '@app/content-catalog/services/scorm.service';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { HostType } from '@app/content-hosting';
import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';
import { parseImageDataForBE } from '@app/user-content/user-input-v2/utils/image-data-utils';
import { getMappedInferredSkills } from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { PathwayStep } from '@dg/pathways-rsm';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CourseMapper {
  constructor(
    private imageUrlService: ImageUrlService,
    private datePipe: DatePipe
  ) {}

  public toViewModel(
    source: CourseEntryApi,
    model: CourseModel
  ): Partial<CourseModel> {
    // Keep original 'groups' available on the model, so that we don't lose it
    // when saving with Advanced Settings closed.
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => ({
          ...g,
          groupId: g.id || g.groupId,
          id: g.id || g.groupId,
        }))
      : [];
    const isScorm = source.hostedType === 'SCORM';
    const isAccredited = !!source.isAccredited;
    const isCatalogContent = !(
      source.orgContentMetadata?.hideFromCatalog ?? !model.addToCatalog
    );

    const course = {
      addToCatalog: isCatalogContent,
      isAccredited,
      accessible: !!source.accessible ?? !!model.orgContentMetadata,
      // TODO: This *should* align with the "I taught this class" checkbox, which should be present
      // on the Global Add form. It's currently missing from the form redesign.
      authored: source.authored ?? model.authored,
      courseId: source.courseId ?? source.courseNumber, // CourseId/CourseNumber/InputId are all the same, but different properties come from different calls.
      courseNumber: source.courseNumber ?? source.courseId, // CourseId/CourseNumber/InputId are all the same, but different properties come from different calls.
      courseUrl: source.courseUrl ?? model.courseUrl,
      courseTypeId: source.isAccredited
        ? CourseTypeId.Accredited
        : CourseTypeId.Informal, // Informal by default ( radio button )
      createdBy: source.createdByName ?? source.createdBy,
      createdByName: source.createdByName,
      createdByUserProfileKey: source.createdByUserProfileKey,
      description: source.description ?? model.description,
      durationHours: source.durationHours ?? model.durationHours,
      durationMinutes: source.durationMinutes ?? model.durationMinutes,
      extent: {
        dateCompleted: source.dateCompleted,
        verificationUrl: source.verificationUrl,
        courseLevel: source.levelId,
        courseGrade: source.gradeId,
      },
      externalId: source.externalId ?? model.externalId,
      file: source.hostedContentDetails
        ? {
            name: source.hostedContentDetails.fileName,
            size: source.hostedContentDetails.fileSize,
          }
        : undefined,
      fileManaged: source.fileManaged ?? model.fileManaged,
      hasBrokenUrl: source.hasBrokenUrl,
      highConfidenceInferredSkills: source.highConfidenceInferredSkills?.length
        ? getMappedInferredSkills(source.highConfidenceInferredSkills)
        : model?.highConfidenceInferredSkills?.length
          ? model?.highConfidenceInferredSkills
          : [],
      hostedContentDetails: source.hostedContentDetails,
      imageUrl: source.imageUrl,
      inputId: source.inputId, // CourseId/CourseNumber/InputId are all the same, but different properties come from different calls.
      inputType: source.inputType,
      institutionId: source.institutionId,
      institutionName: source.institutionName,
      language: source.language,
      name: source.name,
      orgContentMetadata: {
        groupIds: groups,
        hideFromCatalog: !isCatalogContent,
      }, // for On edit visibility
      groupIds: groups,
      organizationId: source.organizationId,
      owner: source.owner ? source.owner : model.owner,
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      publishDate: source.publishDate,
      sessions: source.sessions,
      sessionDetails:
        source.sessions?.length > 0 ? { ...source?.sessions[0] } : undefined,
      tags: source.tags,
      scormInfo: isScorm
        ? {
            scormFileName: source.hostedContentDetails.fileName,
            scormCourseId: source.hostedContentDetails.fileId,
          }
        : null,
      isScorm,
    };

    // Add input to course, which reuses some of the calculated properties above,
    // and return complete object.
    return {
      ...course,
      input: {
        isAccredited,
        accessible: course.accessible,
        orgContentMetadata: { ...course.orgContentMetadata },
        institutionId: course.institutionId,
        name: course.name,
        userInputId: source.userInputId,
        inputType: course.inputType,
        inputId: course.inputId,
        title: course.name,
        courseUrl: course.courseUrl,
        institutionName: course.institutionName,
        externalId: course.externalId,
        description: course.description,
        groupIds: source.groupIds, // This is a number?? What?
        dateCompleted: source.dateCompleted,
        gradeId: source.gradeId,
        tags: course.tags,
        verificationUrl: source.verificationUrl,
        authored: course.authored,
        levelId: source.levelId,
        organizationId: course.organizationId,
        courseNumber: course.courseNumber,
        creditHours: source.creditHours,
        imageUrl: course.imageUrl,
      },
    };
  }

  public toApiParameters(source: CourseModel): CourseMappingToApi {
    const isCatalogContent = !(
      source.orgContentMetadata?.hideFromCatalog ?? !source.addToCatalog
    );

    // The BE expects this to be a string.
    let dateCompleted = undefined;
    if (source.extent?.dateCompleted) {
      dateCompleted = source.extent.dateCompleted;
      // If it's not a string, we have to make it one.
      if (typeof dateCompleted !== 'string') {
        // If it's not a date but it IS an object, assume NgbDateStruct and convert.
        if (
          !(dateCompleted instanceof Date) &&
          dateCompleted.hasOwnProperty('year')
        ) {
          dateCompleted = new Date(
            dateCompleted.year,
            dateCompleted.month - 1,
            dateCompleted.day
          );
        }
        // *Now* our object should be a Date...
        dateCompleted = this.datePipe.transform(dateCompleted, 'yyyy-MM-dd');
      }
    }

    const courseEntity: CourseMappingToApi = {
      ...(source.input || {}),
      authored: source.authored,
      // can be manually added on global add form, otherwise potentially comes from source.input
      courseNumber: source.courseNumber
        ? source.courseNumber
        : source.courseId
          ? source.courseId
          : source.input?.courseId,
      courseUrl: source.courseUrl,
      // can be manually added on global add form, otherwise comes from source.input
      creditHours: source.creditHours
        ? source.creditHours
        : source.input?.creditHours,
      description: source.description,
      dateCompleted,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      externalId: source.externalId
        ? source.externalId
        : source.input?.externalId,
      gradeId: source.extent?.courseGrade,
      hostedContentDetails:
        !!source.scormInfo && !source.courseUrl
          ? this.getScormHostedDetails(source.scormInfo)
          : source.hostedContentDetails,
      inputId: source.inputId,
      institutionId: source.institutionId
        ? source.institutionId
        : source.institution?.institutionId,
      language: source.language,
      levelId: source.extent?.courseLevel,
      name: source.name, // Title
      organizationId: !isCatalogContent ? null : source.organizationId,
      orgContentMetadata: {
        hideFromCatalog: !isCatalogContent,
        groupIds: source.orgContentMetadata?.groupIds?.length
          ? source.orgContentMetadata.groupIds
          : source.groupIds?.length
            ? source.groupIds
            : [],
      },
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      publishDate: source.publishDate,
      sessions: source.sessions,
      tags: source.tags,
      units: source.units,
      unitType: source.unitType || 'Hours',
      verificationUrl: source.extent?.verificationUrl,
      ...parseImageDataForBE(source, this.imageUrlService),

      // !!! TODO: Remove everything below this line, we're only adding it to make the Cypress tests pass.
      // The BE doesn't expect or use these properties. !!!
      institutionName: source.institutionName
        ? source.institutionName
        : source.institution?.name,
      inputType: 'Course',
      isAccredited: source.isAccredited,
    };

    return courseEntity;
  }

  /**
   * Called both when editing a fresh, never-before-edited local step
   * and when editing an already-edited step. Prefer properties from
   * the top-level where they exist (as top-level props will be the
   * non-internal step, if there's both a local and internal version,
   * and internal edits will never call this method), falling back to
   * the reference as necessary.
   *
   * @param reference - Reference parameter of our step.
   * @param step - All other step properties.
   * @param model - ViewModel.
   */
  public fromStepToViewModel(
    { reference, ...step }: PathwayStep,
    model: CourseModel
  ): CourseModel {
    const tags = Array.isArray(reference.tags)
      ? reference.tags
      : getMappedInferredSkills([reference.tags]);

    const editEntry: CourseApiInputEdit = {
      accessible: reference.accessible,
      courseId: reference.inputId,
      courseUrl: reference.url,
      createdByName: reference.createdByName ?? (reference.createdBy as string),
      description: step.description ?? reference.summary,
      durationHours: step.durationHours ?? reference.durationHours,
      durationMinutes: step.durationMinutes ?? reference.durationMinutes,
      externalId: reference.externalId,
      hasBrokenUrl: reference.hasBrokenUrl,
      imageUrl: step.imageUrl ?? reference.imageUrl,
      inputId: reference.inputId,
      inputType: reference.inputType,
      language: reference.language,
      name: step.title ?? reference.title,
      obsolete: reference.obsolete,
      organizationId: reference.organizationId,
      sourceName: reference.source,
      primaryContactResourceId: reference.primaryContactResourceId,
      primaryContactResourceType: reference.primaryContactResourceType,
      publishDate: reference.publishDate,
      tags: tags?.length ? tags : [],
    } as unknown as CourseApiInputEdit;

    // Map response to view model
    return this.toViewModel(editEntry, model) as CourseModel;
  }

  /**
   * Only called on detail edit. Update top-level step properties.
   *
   * @param source
   * @param step
   */
  public toStep(source: CourseModel, step: PathwayStep): CourseModel {
    return {
      ...source,
      ...step,
      title: source.name,
      description: source.description,
      ...parseImageDataForBE(source, this.imageUrlService),
    } as unknown as CourseModel;
  }

  // TODO: Update the uploadResponse from UploadSection to return an UploadFile similar to Content Hosting files and use getContentHostedDetails instead
  private getScormHostedDetails(scormInfo: ScormInfo) {
    const details: HostedContentMetadata = {
      fileId: scormInfo.scormCourseId,
      fileName: scormInfo.scormFileName,
      fileSize: 0,
      fileType: '.zip',
      hostType: HostType.Scorm,
    };
    return details;
  }
}
