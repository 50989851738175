import { Injectable } from '@angular/core';
import { InputType } from '@app/shared/models/core-api.model';

import { RenderMode } from '../user-input.model';
import { ContentCatalogEventMapper } from '../event-modal/mapper/content-catalog-event.mapper';
import { GlobalAddEventMapper } from '../event-modal/mapper/global-add-event.mapper';
import { PathwaysEventMapper } from '../event-modal/mapper/pathways-event.mapper';
import { Mapper } from '../mapper.model';
import { MediaMapper } from '../media-modal/media.mapper';
import { GlobalAddCourseMapper } from '../course-form/mapper/global-add-course.mapper';
import { ContentCatalogCourseMapper } from '../course-form/mapper/content-catalog-course.mapper';
import { PathwayCourseMapper } from '../course-form/mapper/pathway-course.mapper';
import { UserProfileEpisodeMapper } from '../episode-forms/mapper/user-profile-episode.mapper';
import { UserProfileBookMapper } from '../book-forms/mapper/user-profile-book.mapper';
import { UserProfileAssessmentMapper } from '../assessment-forms/mapper/user-profile-assessment.mapper';
import { ContentCatalogAssessmentMapper } from '../assessment-forms/mapper/content-catalog-assessment.mapper';
import { PathwayAssessmentMapper } from '../assessment-forms/mapper/pathway-assessment.mapper';
import { UserProfileExperienceMapper } from '../experience-modal/mapper/user-profile-experience.mapper';
import { ContentCatalogEpisodeMapper } from '../episode-forms/mapper/content-catalog-episode.mapper';

interface MapperSelector {
  renderMode: RenderMode;
  mapper: Mapper;
}
@Injectable({ providedIn: 'root' })
export class MapperFactoryService {
  private readonly mappersLookup: Record<InputType, MapperSelector[]> = {
    Event: [
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.contentCatalogEventMapper,
      },
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.globalAddEventMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.pathwayEventMapper,
      },
    ],
    Article: [
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.mediaMapper,
      },
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.mediaMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.mediaMapper,
      },
    ],
    Video: [
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.mediaMapper,
      },
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.mediaMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.mediaMapper,
      },
    ],
    Book: [
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.userProfileBookMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.userProfileBookMapper,
      },
    ],
    None: null,
    Assessment: [
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.userProfileAssessmentMapper,
      },
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.contentCatalogAssessmentMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.pathwayAssessmentMapper,
      },
    ],
    Course: [
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.globalAddCourseMapper,
      },
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.contentCatalogCourseMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.pathwayCourseMapper,
      },
    ],
    Episode: [
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.userProfileEpisodeMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.contentCatalogEpisodeMapper,
      },
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.contentCatalogEpisodeMapper,
      },
    ],
    Position: [
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.userProfileExperienceMapper,
      },
    ],
    Task: null,
    Post: null,
    Academy: null,
    Coach: [
      {
        renderMode: RenderMode.ContentCatalog,
        mapper: this.mediaMapper,
      },
      {
        renderMode: RenderMode.UserProfile,
        mapper: this.mediaMapper,
      },
      {
        renderMode: RenderMode.Pathways,
        mapper: this.mediaMapper,
      },
    ],
  };
  constructor(
    // Event
    private globalAddEventMapper: GlobalAddEventMapper,
    private contentCatalogEventMapper: ContentCatalogEventMapper,
    private pathwayEventMapper: PathwaysEventMapper,
    // Article & Video
    private mediaMapper: MediaMapper,
    //Course
    private globalAddCourseMapper: GlobalAddCourseMapper,
    private contentCatalogCourseMapper: ContentCatalogCourseMapper,
    private pathwayCourseMapper: PathwayCourseMapper,
    // Episode
    private userProfileEpisodeMapper: UserProfileEpisodeMapper,
    private contentCatalogEpisodeMapper: ContentCatalogEpisodeMapper,
    // Book
    private userProfileBookMapper: UserProfileBookMapper,
    // Assessment
    private userProfileAssessmentMapper: UserProfileAssessmentMapper,
    private contentCatalogAssessmentMapper: ContentCatalogAssessmentMapper,
    private pathwayAssessmentMapper: PathwayAssessmentMapper,
    //Position-Experience
    private userProfileExperienceMapper: UserProfileExperienceMapper
  ) {}

  public getMapper<TViewModel = unknown, TEntity = unknown>(
    forInputType: InputType,
    inRenderMode: RenderMode
  ) {
    const mapper = this.mappersLookup[forInputType].find(
      (item) => item.renderMode === inRenderMode
    ).mapper as Mapper<TViewModel, TEntity>;
    if (!mapper) {
      throw new Error(
        `Mapper was not found for ${forInputType} in ${inRenderMode}`
      );
    }
    return mapper;
  }
}
