import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EbbUtilityService } from '@app/browser-extension/services/ebb-utility.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowToken } from '@app/shared/window.token';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';
import { UserData } from '../models/user-data.model';
import { pascalCaseKeys } from '@app/shared/utils';
import { EbbAuthUserService } from './ebb-auth-user.service';
import { EbbConfigService } from './ebb-config.service';
import { isEmpty } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class EbbAuthService {
  private loading$ = new BehaviorSubject<boolean>(true);
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private authCheckResponse = new BehaviorSubject<false | UserData>(false);

  private accessToken: string;
  private forceEbbLogout: boolean = false;
  public readonly isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor(
    private http: NgxHttpClient,
    private config: EbbConfigService,
    private router: Router,
    private tracker: TrackerService,
    private cookieService: CookieService,
    private authUserService: EbbAuthUserService,
    private utilityService: EbbUtilityService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.authUserService.userData
      .pipe(filter((userData) => !!userData))
      .subscribe((userData: any) => {
        if (userData.AnalyticsEnabled) {
          this.utilityService.setAnalytics();
        }
        this.tracker.trackEventData({
          action: 'Extension Viewed',
        });
      });
    this.checkIfShouldForceLogout();
  }

  private checkIfShouldForceLogout() {
    this.forceEbbLogout = localStorage.getItem('forceEbbLogout') === 'true';
  }

  get loading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  public get isLoggedIn(): boolean {
    return this.isLoggedInSubject.value;
  }

  public set isLoggedIn(value: boolean) {
    this.isLoggedInSubject.next(value);
  }


  public logOut(): Observable<void> {
    return this.logOff().pipe(
      finalize(() => {
        this.clearStorage();
        this.clearAuthStatus();
        if (typeof chrome !== 'undefined' && chrome.runtime) {
          // Available and non-interceptable: manifest's externally_connectable
          chrome.runtime.sendMessage(this.config.EXTENSION_ID, {
            message: this.config.EVENT_CLEAR_SERVICE_HOST,
          });
        }
        this.router.navigate(['/degreed-button/app/auth'], {
          queryParams: { forceNewLogin: true },
          replaceUrl: true,
        });
        this.tracker.trackEventData({
          action: 'Extension Logged Out',
        });
      })
    );
  }

  public initMessageListener(): void {
    const onMessage = (e) => {
      let data, stateData, responseData;

      if (!e.data) {
        this.router.navigate(['/degreed-button/app/auth'], {
          replaceUrl: true,
        });
        return;
      }

      data = e.data;

      if (typeof e.data === 'string') {
        try {
          data = JSON.parse(e.data);
        } catch (e) {
          // if this fails, keep data as-is
        }
      }

      if (data.name === 'unauthorized') {
        this.logOff().subscribe(() => {
          stateData = {
            msg: data.data, // url
            status: 401,
            data: undefined,
          };
          this.router.navigate(['/degreed-button/app/auth'], {
            queryParams: { stateData },
            replaceUrl: true,
          });
        });
      } else if (data.name === this.config.EVENT_CHECK_AUTH) {
        responseData = data.data ? data.data : data;
        this.onSuccess(responseData);
      }
    };

    this.windowRef.addEventListener('message', (e) => {
      onMessage(e);
    });
  }

  public authCheckWithCommonHandlers(): Observable<boolean> {
    return this.authCheck().pipe(
      map((response: UserData | false) => {
        if (response === false) {
          this.onError(response);
          this.loading$.next(false);
          return this.isLoggedIn;
        }
        response = pascalCaseKeys(response);
        this.onSuccess(response);
        this.loading$.next(false);
        return this.isLoggedIn;
      })
    );
  }

  public setAccessToken(value): void {
    this.accessToken = value;
    localStorage.setItem(this.config.TOKEN_KEY, value);

    if (typeof chrome !== 'undefined' && chrome.runtime) {
      // Available and non-interceptable: manifest's externally_connectable
      chrome.runtime.sendMessage(this.config.EXTENSION_ID, {
        message: this.config.EVENT_SET_ACCESS_TOKEN,
        token: value,
      });
    }
  }

  public setLoading(status: boolean) {
    this.loading$.next(status);
  }

  public getAccessToken(): string {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem(this.config.TOKEN_KEY);
      try {
        this.accessToken = JSON.parse(this.accessToken); // i.e. for "null" => null
      } catch (e) {
        // leave as-is since it's not parseable
      }
    }
    return this.accessToken;
  }

  // The promise nature of this call isn't required by our code anymore,
  // but it continues to work as-so and is more future-proof.
  public getAccessTokenAsync(): Promise<string> {
    this.accessToken = localStorage.getItem(this.config.TOKEN_KEY);

    return new Promise((resolve) => {
      if (typeof chrome !== 'undefined' && chrome.runtime) {
        if (this.accessToken) {
          // sync localStorage <> chromeStorage securely
          // Available and non-interceptable: manifest's externally_connectable
          chrome.runtime.sendMessage(this.config.EXTENSION_ID, {
            message: this.config.EVENT_SET_ACCESS_TOKEN,
            token: this.accessToken,
          });
          resolve(this.accessToken);
          this.setAccessToken(this.accessToken);
        } else {
          // Available and non-interceptable: manifest's externally_connectable
          chrome.runtime.sendMessage(
            this.config.EXTENSION_ID,
            {
              message: this.config.EVENT_GET_ACCESS_TOKEN,
            },
            (response) => {
              if (!isEmpty(response)) {
                resolve(response);
                this.setAccessToken(response);
              } else {
                resolve('');
              }
            }
          );
        }
      } else {
        resolve(this.accessToken);
        this.setAccessToken(this.accessToken);
      }
    });
  }

  public clearStorage(): void {
    this.clearToken();
    this.clearHostInfo();
    this.authUserService.isLoggedIn = false;
    this.isLoggedIn = false;
    localStorage.removeItem('forceEbbLogout');
  }

  public authCheck(): Observable<UserData | boolean> {
    this.checkIfShouldForceLogout();
    if (this.forceEbbLogout) {
      this.logOut();
      return of(false);
    } else {
      if (this.authCheckResponse.value) {
        return of(this.authCheckResponse.value);
      }
      return this.http
        .get<UserData>('/extension/util/authcheck', {
          headers: {
            // Ensure that this call is ignored by the auth.intercept service
            'Dg-Skip-Intercept': 'true',
          },
        })
        .pipe(
          catchError((_) => of(false)),
          tap((response: false | UserData) => this.authCheckResponse.next(response))
        );
    }
  }

  public logOff(): Observable<void> {
    return this.http.post('/extension/util/account/logoff', {});
  }

  private storeAuthStatus(): void {
    localStorage.setItem('isLoggedIn', new Date().toJSON());
    // reset the ngx client-side cache busting token
    localStorage.removeItem('authUser:v');
  }

  private clearAuthStatus(): void {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('authUser:v');
  }

  private clearToken(): void {
    if (typeof chrome !== 'undefined' && chrome.runtime) {
      chrome.runtime.sendMessage(this.config.EXTENSION_ID, {
        message: this.config.EVENT_CLEAR_ACCESS_TOKEN,
      });
    }
    localStorage.removeItem(this.config.TOKEN_KEY);
    this.cookieService.delete(this.config.TOKEN_KEY);
  }

  private clearHostInfo(): void {
    localStorage.removeItem(this.config.HOST_KEY);
    this.cookieService.delete(this.config.HOST_KEY);
  }

  private onSuccess(response): void {
    this.checkIfShouldForceLogout();
    if (this.forceEbbLogout) {
      localStorage.removeItem('forceEbbLogout');
      this.logOut();
    } else {
      this.isLoggedIn = !!response;
      this.storeAuthStatus();
      this.authUserService.setUserData(response);
    }
  }

  private onError(err): void {
    this.clearStorage();
  }
}
