import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NUMERIC_DATE_OPERATORS } from '@app/automations/components/form-components/condition-form/condition-form/condition-form.component';
import {
  Attribute,
  AttributeDataType,
  Predicate,
  PredicateOption,
  PredicateType,
} from '@app/automations/model';
import { AutomationsApiService } from '@app/automations/services/automations-api.service';
import {
  getFollowingLabelKey,
  getOperatorFromPredicate,
  getPredicateValueMin,
  predicateIdToAdjustedType,
} from '@app/automations/utils';
import { ComboboxComponent } from '@app/shared/components/combobox/combobox.component';
import { ComboboxOption } from '@app/shared/components/combobox/combobox.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'dgx-dynamic-group-definition',
  templateUrl: './dynamic-group-definition.component.html',
  styleUrl: './dynamic-group-definition.component.scss',
})
export class DynamicGroupDefinitionComponent implements OnInit {
  @Input() public predicates: FormArray<FormGroup>;
  @Input() public orgId: number;
  @ViewChildren('attributesField')
  public attributesFields: QueryList<ComboboxComponent>;

  public readonly AttributeDataType = AttributeDataType;

  public i18n = this.translate.instant([
    'BusinessRules_AttributeName',
    'Core_NoResults',
    'BusinessRules_Equals',
    'BusinessRules_DoesNotEqual',
    'BusinessRules_Contains',
    'Automation_IsOneOf',
    'Automation_IsNotOneOf',
    'BusinessRules_GreaterThan',
    'BusinessRules_GreaterOrEqual',
    'BusinessRules_LessThan',
    'BusinessRules_LesserOrEqual',
    'BusinessRules_WithinDaysAgo',
    'BusinessRules_BeyondDaysAgo',
    'BusinessRules_AttributeValue',
    'Automation_AttributeValue_HelperText',
    'RecommendationCtrl_SelectADate',
    'BusinessRules_Value',
    'BusinessRules_And',
    'BusinessRules_AddCondition',
    'Automation_RemoveEmptyCondition',
    'Core_Remove',
    'BusinessRules_Operator',
    'BusinessRules_Condition',
    'Core_FieldRequired',
  ]);
  public attributes$: Observable<{ payload: Attribute[] }>;
  public attributes: Attribute[];
  public attributeOptions: PredicateOption[];
  public readonly booleanOptions = [
    { name: 'True', value: 'True' },
    { name: 'False', value: 'False' },
  ];

  public readonly allOperatorOptions = {
    '=': {
      name: this.i18n.BusinessRules_Equals,
      value: '=',
    },
    '!=': {
      name: this.i18n.BusinessRules_DoesNotEqual,
      value: '!=',
    },
    contains: {
      name: this.i18n.BusinessRules_Contains,
      value: 'contains',
    },
    isOneOf: {
      name: this.i18n.Automation_IsOneOf,
      value: 'isoneof',
    },
    isNotOneOf: {
      name: this.i18n.Automation_IsNotOneOf,
      value: 'isnotoneof',
    },
    '>': {
      name: this.i18n.BusinessRules_GreaterThan,
      value: '>',
    },
    '>=': {
      name: this.i18n.BusinessRules_GreaterOrEqual,
      value: '>=',
    },
    '<': {
      name: this.i18n.BusinessRules_LessThan,
      value: '<',
    },
    '<=': {
      name: this.i18n.BusinessRules_LesserOrEqual,
      value: '<=',
    },
    '<ago': {
      name: this.i18n.BusinessRules_WithinDaysAgo,
      value: '<ago',
    },
    '>ago': {
      name: this.i18n.BusinessRules_BeyondDaysAgo,
      value: '>ago',
    },
  };

  public readonly operatorOptionsByType = {
    // These properties should match the values allowed in OrganizationAttribute.attributeDataType
    String: [
      this.allOperatorOptions['='],
      this.allOperatorOptions['!='],
      this.allOperatorOptions.contains,
      this.allOperatorOptions.isOneOf,
      this.allOperatorOptions.isNotOneOf,
    ],
    Number: [
      this.allOperatorOptions['='],
      this.allOperatorOptions['!='],
      this.allOperatorOptions.isOneOf,
      this.allOperatorOptions.isNotOneOf,
      this.allOperatorOptions['>'],
      this.allOperatorOptions['>='],
      this.allOperatorOptions['<'],
      this.allOperatorOptions['<='],
    ],
    DateTime: [
      this.allOperatorOptions['='],
      this.allOperatorOptions['!='],
      this.allOperatorOptions['>'],
      this.allOperatorOptions['>='],
      this.allOperatorOptions['<'],
      this.allOperatorOptions['<='],
      this.allOperatorOptions['<ago'],
      this.allOperatorOptions['>ago'],
    ],
    Bool: [this.allOperatorOptions['=']],
  };

  public readonly operatorsInfo = {
    allOperatorOptions: this.allOperatorOptions,
    operatorOptionsByType: this.operatorOptionsByType,
  };

  constructor(
    public translate: TranslateService,
    public automationsApi: AutomationsApiService,
    public formBuilder: FormBuilder
  ) {}

  public ngOnInit() {
    this.attributes$ = this.automationsApi
      .getAttributes(this.orgId, 'Segment')
      .pipe(
        tap((response) => {
          this.attributeOptions = this.makeConditionOptions(response.payload);
        })
      );
  }

  public makeConditionOptions(attributes: Attribute[]) {
    return attributes.map((a) => ({
      attributeDataType: a.attributeDataType,
      id: a.attributeName,
      title: a.attributeName,
    }));
  }

  public getOperatorOptions(predicateControl: FormGroup) {
    const { fieldName, comparisonOperator } = predicateControl.value;
    return getOperatorFromPredicate(
      this.operatorsInfo,
      this.attributeOptions,
      fieldName,
      comparisonOperator
    );
  }

  public setFieldName(event: ComboboxOption, predicate: FormGroup) {
    predicate.patchValue({
      fieldName: event.id,
      comparisonValue: [],
      comparisonOperator: '=',
    });
  }

  public setComparisonValue(event, predicateControl: FormGroup) {
    const value = event.target.value;
    predicateControl.get('comparisonValue').setValue([value]);
  }

  public updateAttributes(
    attributes: (string | number | Date)[],
    predicateControl: FormGroup
  ) {
    predicateControl.get('comparisonValue').setValue(attributes);
  }

  public getAttributeDataType(predicateControl: FormGroup) {
    const { fieldName, comparisonOperator } = predicateControl.value;

    return predicateIdToAdjustedType(
      this.attributeOptions as PredicateOption[],
      fieldName,
      NUMERIC_DATE_OPERATORS,
      comparisonOperator
    );
  }

  public getFollowingLabel(predicateControl: FormGroup): string | boolean {
    const { comparisonOperator, fieldName } = predicateControl.value;
    const labelKey = getFollowingLabelKey(
      this.attributeOptions as PredicateOption[],
      fieldName,
      NUMERIC_DATE_OPERATORS,
      comparisonOperator
    );
    return labelKey ? this.translate.instant(labelKey) : labelKey;
  }

  public getMin = (predicateControl: FormGroup): number | null => {
    const { comparisonOperator, fieldName } = predicateControl.value;
    return getPredicateValueMin(
      this.attributeOptions as PredicateOption[],
      fieldName,
      NUMERIC_DATE_OPERATORS,
      comparisonOperator
    );
  };

  public setDateValue(event, predicateControl: FormGroup) {
    const day = event.getDate();
    const month =
      event.getMonth() > 8
        ? event.getMonth() + 1
        : '0' + (event.getMonth() + 1);
    const year = event.getFullYear();
    predicateControl
      .get('comparisonValue')
      .setValue([`${month}/${day}/${year}`]);
  }

  public addPredicate() {
    this.predicates.push(
      this.formBuilder.group({
        fieldName: ['', Validators.required],
        comparisonOperator: ['', Validators.required],
        comparisonValue: ['', Validators.required],
        predicateType: [PredicateType.Attribute, Validators.required],
      })
    );
    setTimeout(() => {
      this.attributesFields
        .get(this.predicates.length - 1)
        .inputElement.nativeElement.focus();
    });
  }

  public removePredicate(index: number) {
    this.predicates.removeAt(index);
    setTimeout(() => {
      this.attributesFields.get(index - 1).inputElement.nativeElement.focus();
    });
  }

  public getFormattedRemoveConditionString(
    predicateControl: FormGroup
  ): string {
    const { fieldName, comparisonOperator, comparisonValue } =
      predicateControl?.value;
    return this.translate.instant('Automation_RemoveCondition_Format', {
      value: `${fieldName} ${comparisonOperator} ${comparisonValue}`,
    });
  }

  public setComparisonOperator(operator: string, predicateControl: FormGroup) {
    predicateControl.get('comparisonOperator').setValue(operator);
    if (
      predicateControl.get('comparisonValue').value?.length > 1 &&
      !(operator === 'isoneof' || operator === 'isnotoneof')
    ) {
      predicateControl
        .get('comparisonValue')
        .setValue([predicateControl.get('comparisonValue').value[0]]);
    }
  }
}
