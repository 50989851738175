import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isPlainObject } from 'lodash-es';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CachedHttpRequest } from './caching';
import { NgxHttpParamUriEncoder } from './ngx-http-param-encoder';
import { isUrlAbsolute } from './utils/common-utils';

// TODO: Ideally, tighten the put/post data type to exclude any containing JsonObject props if MS ever provides a viable solution for https://github.com/microsoft/TypeScript/issues/15300

/**
 * @description
 * Extends wrapped HttpClient functionality to allow caching, camel cased API models,
 * optional forced URI encoding, and relative URLs based on '/api'
 *
 * Note that you may pass `forceUriEncoding: true` to force `encodeURIComponent` to be
 * applied to keys/values without character exceptions...
 *
 * @example
 * // A string with problematic characters
 * const searchTerm = 'C++';
 * this.http.get('/search/findlearningresources', {
 *   params: { terms: searchTerm },
 *   forceUriEncoding: true // forces `C++` to `C%2B%2B`
 * })
 *
 * @see NgxHttpParamUriEncoder
 */
@Injectable({ providedIn: 'root' })
export class NgxHttpClient {
  private static readonly globalQueryOptions = { 'dg-casing': 'camel' };

  private static buildQueryParams(
    source: object = {},
    forceUriEncoding = false
  ): HttpParams {
    let target: HttpParams = forceUriEncoding
      ? new HttpParams({ encoder: new NgxHttpParamUriEncoder() }) // Prevent Angular from unescaping special characters
      : new HttpParams();
    Object.keys(source).forEach((key: string) => {
      let value: any = source[key];
      // eslint-disable-next-line eqeqeq
      if (value == undefined) {
        return;
      }
      if (isPlainObject(value)) {
        value = JSON.stringify(value);
      } else {
        value = value.toString();
      }
      target = target.append(key, value);
    });
    return target;
  }

  private static normalizeUrl(url: string) {
    return isUrlAbsolute(url) ? url : '/api' + url;
  }

  constructor(private http: HttpClient) {}

  public delete<TResult = unknown>(
    url: string,
    options: {
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    } = {}
  ): Observable<any> {
    url = NgxHttpClient.normalizeUrl(url);
    options.params = { ...options.params, ...NgxHttpClient.globalQueryOptions };
    const requestOptions = {
      ...options,
      params: NgxHttpClient.buildQueryParams(
        options.params || {},
        options.forceUriEncoding
      ),
      headers: { ...options.headers },
    };
    return this.http.delete<TResult>(url, requestOptions);
  }

  public get<T>(
    url: string,
    options?: {
      observe?: 'body';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      cache?: boolean;
      forceUriEncoding?: boolean;
    }
  ): Observable<T>;

  public get<T>(
    url: string,
    options?: {
      observe?: 'response';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      cache?: boolean;
      forceUriEncoding?: boolean;
    }
  ): Observable<HttpResponse<T>>;

  public get<T>(
    url: string,
    options: {
      observe?: 'body' & 'response';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      cache?: boolean;
      forceUriEncoding?: boolean;
    } = {}
  ): Observable<any> {
    url = NgxHttpClient.normalizeUrl(url);
    options.params = { ...options.params, ...NgxHttpClient.globalQueryOptions };
    const requestOptions = {
      observe: options.observe || 'body',
      params: NgxHttpClient.buildQueryParams(
        options.params || {},
        options.forceUriEncoding
      ),
      headers: new HttpHeaders({ ...options.headers }),
    };
    let req: HttpRequest<T>;
    if (options.cache) {
      req = new CachedHttpRequest<T>('GET', url, requestOptions);
    } else {
      req = new HttpRequest<T>('GET', url, requestOptions);
    }
    return this.http.request<T>(req).pipe(
      filter((e) => e.type === HttpEventType.Response),
      map((r: HttpResponse<T>) =>
        requestOptions.observe === 'body' ? r.body : r
      )
    );
  }

  public patch<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'body';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    }
  ): Observable<T>;

  public patch<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'response';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    }
  ): Observable<HttpResponse<T>>;

  public patch<T>(
    url: string,
    body: any,
    options: {
      observe?: 'body' & 'response';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    } = {}
  ): Observable<any> {
    url = NgxHttpClient.normalizeUrl(url);
    options.params = { ...options.params, ...NgxHttpClient.globalQueryOptions };
    const requestOptions = {
      observe: options.observe,
      params: NgxHttpClient.buildQueryParams(
        options.params || {},
        options.forceUriEncoding
      ),
      headers: { ...options.headers },
    };
    return this.http.patch<T>(url, body, requestOptions);
  }

  public post<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'body';
      params?: {
        [param: string]: string | string[] | boolean;
      };
      headers?: {
        [header: string]: string | string[];
      };
      withCredentials?: boolean;
      forceUriEncoding?: boolean;
    }
  ): Observable<T>;

  public post<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'response';
      params?: {
        [param: string]: string | string[];
      };
      headers?: {
        [header: string]: string | string[];
      };
      withCredentials?: boolean;
      forceUriEncoding?: boolean;
    }
  ): Observable<HttpResponse<T>>;

  public post<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'events';
      reportProgress?: boolean;
      params?: {
        [param: string]: string | string[];
      };
      headers?: {
        [header: string]: string | string[];
      };
      withCredentials?: boolean;
      forceUriEncoding?: boolean;
    }
  ): Observable<HttpEvent<T>>;

  public post<T>(
    url: string,
    body: any,
    options: {
      observe?: 'body' & 'response' & 'events';
      reportProgress?: boolean;
      params?: {
        [param: string]: string | string[];
      };
      headers?: {
        [header: string]: string | string[];
      };
      withCredentials?: boolean;
      forceUriEncoding?: boolean;
    } = {}
  ): Observable<any> {
    url = NgxHttpClient.normalizeUrl(url);
    options.params = {
      ...options.params,
      ...NgxHttpClient.globalQueryOptions,
    };
    const requestOptions = {
      observe: options.observe,
      withCredentials: options.withCredentials,
      reportProgress: options.reportProgress,
      params: NgxHttpClient.buildQueryParams(
        options.params || {},
        options.forceUriEncoding
      ),
      headers: { ...options.headers },
    };
    return this.http.post<T>(url, body, requestOptions);
  }

  public put<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'body';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    }
  ): Observable<T>;

  public put<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'response';
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    }
  ): Observable<HttpResponse<T>>;

  public put<T>(
    url: string,
    body: any,
    options?: {
      observe?: 'events';
      reportProgress?: boolean;
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    }
  ): Observable<HttpEvent<T>>;

  public put<T>(
    url: string,
    body: any,
    options: {
      observe?: 'body' & 'response' & 'events';
      reportProgress?: boolean;
      params?: any;
      headers?: {
        [header: string]: string | string[];
      };
      forceUriEncoding?: boolean;
    } = {}
  ): Observable<any> {
    url = NgxHttpClient.normalizeUrl(url);
    options.params = { ...options.params, ...NgxHttpClient.globalQueryOptions };
    const requestOptions = {
      observe: options.observe,
      reportProgress: options.reportProgress,
      params: NgxHttpClient.buildQueryParams(
        options.params || {},
        options.forceUriEncoding
      ),
      headers: { ...options.headers },
    };
    return this.http.put<T>(url, body, requestOptions);
  }

  //// HTTP Verbs specific for SCORM - https://degreedjira.atlassian.net/wiki/spaces/TechDocs/pages/396460081/SCORM
  // The main reason we need methods specifically to Scorm is that the Scorm api returns xml which requires specifying
  // a response type, and that's not available with generic http calls in Angular.
  // Also having the global query params causes the requests to fail, so those are not included either.

  public postForScorm(
    url: string,
    body: any,
    options: {
      observe?;
      reportProgress?: boolean;
      params?: {
        [param: string]: string | string[];
      };
      headers?: {
        [header: string]: string | string[];
      };
      withCredentials?: boolean;
    } = {}
  ): Observable<any> {
    url = NgxHttpClient.normalizeUrl(url);
    options.params = {
      ...options.params,
    };
    const requestOptions = {
      observe: options.observe,
      withCredentials: options.withCredentials,
      reportProgress: options.reportProgress,
      params: NgxHttpClient.buildQueryParams(options.params),
      headers: { ...options.headers },
      responseType: 'text' as 'text',
    };
    return this.http.post(url, body, requestOptions);
  }

  public getForScorm(url?: string, courseInputId?: string): Observable<any> {
    if (!courseInputId) {
      const normalizedUrl = NgxHttpClient.normalizeUrl(url);
      return this.http.get(normalizedUrl, { responseType: 'text' as 'text' });
    } else {
      const params = { importId: courseInputId };
      const requestOptions = {
        observe: null,
        withCredentials: undefined,
        reportProgress: true,
        params: NgxHttpClient.buildQueryParams(params),
        headers: {},
        responseType: 'text' as 'text',
      };
      const normalUrl = NgxHttpClient.normalizeUrl(
        '/scorm/getcourseimportprogress'
      );
      return this.http.get(normalUrl, requestOptions);
    }
  }
}
