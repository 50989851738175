import { Observable } from 'rxjs';

import { FileUploadSetting } from './uploader-api.model';

/**
 * Interface to be implemented by file uploaders
 */
export interface Uploader<TUploadOptions, TResponse = unknown> {
  /**
   * A function that returns an Observable with image upload limits see {@link FileUploadSetting}
   * for the options that should be returned.
   */
  getUploadSettings(): Observable<FileUploadSetting>;

  /**
   * A function to save an image and crop settings that returns an Observable
   * @param file the selected file
   * @param options optional settings that can be passed in
   * @returns a tuple of Observables providing the API response and, optionally, fractional progress updates (0-1.0)
   */
  upload(
    file: File,
    options?: TUploadOptions
  ): Observable<UploadProgressEvent | UploadCompleteEvent<TResponse>>;
}

/** Upload event for reporting progress */
export interface UploadProgressEvent {
  type: UploadEventType.Progress;
  progress: number;
}

/** Upload event for reporting upload completion */
export interface UploadCompleteEvent<T> {
  type: UploadEventType.Complete;
  response: T;
}

export type UploadEvent<T = unknown> =
  | UploadProgressEvent
  | UploadCompleteEvent<T>;

/** Identifies the type of upload event */
export const enum UploadEventType {
  Progress = 0,
  Complete = 1,
}

/** Typeguard for file upload progress events */
export function isProgressEvent(
  event: UploadEvent
): event is UploadProgressEvent {
  return event.type === UploadEventType.Progress;
}

/** Typeguard for file upload completion events */
export function isCompletionEvent<T>(
  event: UploadEvent
): event is UploadCompleteEvent<T> {
  return event.type === UploadEventType.Complete;
}

/**
 * helper function to validate file size against an allowed maximum
 * @param file file to validate
 * @param maxSizeMB max size of file in MB
 */
export function isValidFileSize(file: File, maxSizeMB: number): boolean {
  const fileSizeMB = file.size / (1024 * 1024);
  const isValid = fileSizeMB < maxSizeMB;
  return isValid;
}

/** Helper function to validate a file extension against allowed extensions
 */
export function isValidFileExtension(
  file: File,
  allowedFileTypes: string[]
): boolean {
  const fileExtension = getFileExtension(file);
  return (
    fileExtension && allowedFileTypes.includes(fileExtension.toLowerCase())
  );
}

/** Helper function to get the extension from a file, which includes the '.' delimiter
 */
export function getFileExtension(file: File): string {
  const filenameParts = file.name.split('.');
  return filenameParts.length > 1
    ? `.${filenameParts[filenameParts.length - 1]}`
    : '';
}
