import { Injectable } from '@angular/core';
import { OpportunityGetResponse } from '@app/opportunities/services/browse-opportunities.service';
// services

// misc
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { QueueService } from '@app/shared/services/queue.service';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { OpportunityAsExperienceApiEntity } from '@app/user-content/user-input/experience-modal/model/experience-form.model';
// third-party
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { map, mapTo, mergeMap, tap } from 'rxjs/operators';
// types
import { Opportunity, MarketplaceSection } from '../opportunities-api.model';
import { formatMarketplaceOpportunities } from '../utils';

/**
 * This service will house *all* opportunity-related API calls.
 * It does not care about and should not include modal-showing wrappers
 * for those API calls, only the API calls themselves.
 *
 * This service must *not* implement OpportunityServiceBase; rather, it
 * is intended to provide a shared Source of Truth for services based on
 * that, as well as services *not* based on it (like OpportunityModalsService).
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunityApiService {
  public i18n = this.translateService.instant([
    'Opportunities_Apply',
    'Opportunities_Error_Get',
    'Opportunities_Interested',
    'Opportunities_RemoveInterest',
    'Opportunities_ShowInterest',
    'Opportunities_Success_AddExperience',
    'OrgManage_Error_Get',
    'OrgManage_Opportunities_Create',
    'OrgManage_Opportunities_DeletePrompt_Title',
    'OrgManage_Opportunities_DeletePrompt_Description',
    'OrgManage_Opportunities_Edit',
    'OrgManage_Opportunities_VisibleCollaborators',
    'OrgManage_Opportunities_VisibleGroups',
    'OrgManage_Opportunities_VisibleOrganization',
    'QueueSvc_InternalError',
    'TargetCtrl_ConfirmDeleteButton',
  ]);
  private readonly baseUrl: string = '/opportunities';

  constructor(
    private http: NgxHttpClient,
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private queueService: QueueService
  ) {}

  /**
   * Add an opportunity to the user's queue.
   *
   * @param opportunity - The current opportunity.
   * @param element - The clicked element.
   */
  public addToQueue({
    element,
    opportunity,
  }: {
    element?: HTMLElement;
    opportunity: Opportunity;
  }): Observable<number> {
    return this.queueService
      .post('Opportunity', opportunity.opportunityId, null, null, null, element)
      .pipe(
        // TODO: Also, add unique opportunity specific tracking here.
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant('QueueSvc_ItemAddedFormat', {
              item: opportunity.title,
            })
          );
        }),
        catchAndSurfaceError<number>(this.i18n.QueueSvc_InternalError)
      );
  }

  /**
   * Get sections of opportunities for the Marketplace page.
   *
   * @param organizationId - ID for the current organization.
   */
  public getMarketplaceSections(
    organizationId: number,
    sections: MarketplaceSection<Opportunity>[]
  ): Observable<MarketplaceSection<Opportunity>> {
    return from(sections).pipe(
      mergeMap((section) =>
        this.getMarketplaceOpportunities({
          organizationId,
          section,
        })
      ),
      catchAndSurfaceError<MarketplaceSection<Opportunity>>(
        this.i18n.Opportunities_Error_Get
      )
    );
  }

  /**
   * Remove an opportunity from the user's queue.
   *
   * @param opportunity - The current opportunity.
   * @param element - The clicked element.
   */
  public removeFromQueue({
    element,
    opportunity,
  }: {
    element?: HTMLElement;
    opportunity: Opportunity;
  }): Observable<void> {
    return this.queueService
      .deleteItem(
        opportunity.userQueueId,
        'Opportunity',
        opportunity.opportunityId,
        null,
        null,
        element
      )
      .pipe(
        // TODO: Also, add unique opportunity tracking here.
        catchAndSurfaceError(this.i18n.QueueSvc_InternalError)
      );
  }

  /**
   * Associate an experience that has been added to a user's collection
   * with the opportunity, thus allowing us to show the opportunity in
   * the state of having been added as an experience, with MasteryPoints.
   *
   * @param opportunityId - ID of the opportunity added.
   * @param response - Full payload, including the userInputId.
   */
  public setOpportunityExperience(
    opportunityId: number,
    response: OpportunityAsExperienceApiEntity
  ): Observable<OpportunityAsExperienceApiEntity> {
    return this.http
      .put<void>(`${this.baseUrl}/setopportunityexperience`, {
        opportunityId,
        userInputId: response.userInputId,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.i18n.Opportunities_Success_AddExperience
          );
        }),
        // Manually pass the original response back, so that our
        // components will have access to masteryPoints.
        mapTo(response)
        // No error-catching here, as that will be handled by
        // OpportunityModalsService itself.
      );
  }

  // Used as the 'base' call for any call to `orgopportunities`
  private getBaseOrgOpportunities({
    term = '',
    count = 10,
    facets = [],
    orderBy = 'title',
    organizationId = 1,
    skip = 0,
    sortDescending = true,
    takeNum = 30,
    isManaging = false,
    minTagsToMatch = 0,
  }): Observable<OpportunityGetResponse> {
    return this.http.get<OpportunityGetResponse>(
      `${this.baseUrl}/orgopportunities`,
      {
        params: {
          term,
          count,
          orderBy,
          organizationId,
          skip,
          take: takeNum,
          sortDescending,
          isManaging,
          minTagsToMatch,
          facets: JSON.stringify(facets),
        },
        forceUriEncoding: true,
      }
    );
  }

  private getMarketplaceOpportunities({
    organizationId = 1,
    count = 3,
    section,
  }): Observable<MarketplaceSection<Opportunity>> {
    return this.getBaseOrgOpportunities({
      organizationId,
      count,
      facets: [{ id: 'Status', name: 'Status', values: ['open'] }],
      orderBy: section.orderBy,
      minTagsToMatch: section.minTagsToMatch,
    }).pipe(map((data) => formatMarketplaceOpportunities(data, section)));
  }
}
