import { Injectable } from '@angular/core';
import { ProfileFilter } from '@app/profile/components/profile-filter-bar/profile-filter-bar.component';
import { TagsApi } from '@app/tags/tag-api.model';
import { isEmpty, isEqual } from 'lodash-es';
import { FilteredTag } from '@app/profile/components/profile-skills-list/profile-skills.model';
import { InternalTagRatingTypes, OwnerTag } from '@app/tags/tags';
import { TrackerService } from '@app/shared/services/tracker.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { AuthService } from '@app/shared/services/auth.service';
import { RecommendeeType } from '@app/recommendations/recommendations.model';

@Injectable({
  providedIn: 'root',
})
export class TagRatingTrackerService {
  constructor(
    private trackerService: TrackerService,
    private authService: AuthService
  ) {}

  /**
   * Track completion of a rating step
   * @param element HTMLElement
   * @param tag Tag
   * @param RatingType TagRatingTrackingType
   * @param ItemClicked string
   * @param StepCompleted string
   * @param InitiationLocation string
   */
  public trackRatingUpdateStepCompleted(
    element: HTMLElement,
    tag: TagsApi.TagDetails,
    type: InternalTagRatingTypes,
    ItemClicked?: string,
    StepCompleted?: string,
    InitiationLocation?: string
  ): void {
    tag = camelCaseKeys(tag);
    this.trackerService.trackEventData({
      action: 'Skill Rating Updating Step Completed',
      element,
      properties: {
        InitiationLocation,
        RatingType: TagRatingTypes[type],
        ItemClicked,
        StepCompleted,
        SkillId: tag?.tagId,
        SkillName: tag?.name,
        IsFocusSkill: tag?.isFocused,
        IsOnProfile: tag?.isFollowing,
      },
    });
  }

  /**
   * Track initiation of rating process for given rating type
   * @param element HTMLElement
   * @param tag Tag
   * @param ratingType TagRatingTrackingType
   * @param initiationLocation string
   */
  public trackRatingUpdateInitiated(
    element: HTMLElement,
    tag: TagsApi.Tag,
    RatingType: TagRatingTrackingType,
    SkillOwnerUserId?: number
  ): void {
    this.trackerService.trackEventData({
      action: 'Skill Rating Updating Initiated',
      element,
      properties: {
        SkillOwnerUserId,
        RatingType,
        SkillId: tag?.tagId,
        SkillName: tag?.name,
        IsFocusSkill: tag?.isFocused,
        IsOnProfile: tag?.isFollowing,
      },
    });
  }

  /**
   * Track the request/cancellation of manager/peer ratings
   * @param element HTMLElement
   * @param tag Tag
   * @param RatingType TagRatingTrackingTypePeer
   * @param RequestAction requested/cancelled
   * @param AssociateUserId string
   * @param InitiationLocation string
   * @param PeerUserCount number
   * @param PeerGroupCount number
   */
  public trackRatingRequested(
    element: HTMLElement,
    tag: TagsApi.TagDetails,
    RatingType: TagRatingTrackingTypePeer,
    RequestAction: 'requested' | 'cancelled',
    AssociateUserId?: number,
    SkillOwnerUserId?: number,
    InitiationLocation?: string,
    PeerUserCount?: number,
    PeerGroupCount?: number
  ): void {
    this.trackerService.trackEventData({
      action: 'Skill Rating Requested',
      element,
      properties: {
        InitiationLocation,
        RatingType,
        RequestAction,
        ManagerUserId:
          RatingType === InternalTagRatingTypes.manager
            ? AssociateUserId
            : undefined,
        PeerUserId:
          RatingType === InternalTagRatingTypes.peer
            ? AssociateUserId
            : undefined,
        SkillOwnerUserId,
        PeerUserCount,
        PeerGroupCount,
        SkillId: tag?.tagId,
        SkillName: tag?.name,
        IsFocusSkill: tag?.isFocused,
        IsOnProfile: tag?.isFollowing,
      },
    });
  }

  /**
   * Send tracking data to amplitude for a privacy change to the given rating type
   * @param type TagRatingTrackingType
   * @param changedFrom 0 | 1
   * @param changedTo 0 | 1
   * @param tag Tag
   */
  public trackRatingVisibilityChange(
    type: TagRatingTrackingType,
    changedFrom: number,
    changedTo: number,
    tag: TagsApi.TagDetails
  ): void {
    const privacyLabels = ['private', 'public'];
    this.trackerService.trackEventData({
      action: 'Skill Rating Visibility Updated',
      properties: {
        // NOTE: currently, privacy can only be toggled from the skill modal,
        // if that changes `InitiationLocation` will need to be set in the proper context
        InitiationLocation: 'Skill Modal',
        RatingType: type,
        VisibilityChangedFrom: privacyLabels[changedFrom],
        VisibilityChangedTo: privacyLabels[changedTo],
        SkillId: tag?.tagId,
        SkillName: tag?.name,
        IsFocusSkill: tag?.isFocused,
        IsOnProfile: tag?.isFollowing,
      },
    });
  }

  /**
   * Send tracking data to amplitude for skill engagement updates
   * @param type TagRatingSkillEngagementType
   * @param changedTo boolean
   * @param tag Tag
   */
  public trackSkillEngagementUpdated(
    type: TagRatingSkillEngagementType,
    changedTo: boolean,
    tag: TagsApi.TagDetails | TagsApi.Tag,
    location?: string
  ): void {
    let properties: SkillEngagementUpdatedProperties = {
      EngagementType: type,
      ChangedTo: changedTo,
      SkillId: tag?.tagId,
      SkillName: tag?.name,
      IsFocusSkill: tag?.isFocused,
      IsOnProfile: tag?.isFollowing,
    };

    // need to add location this way; otherwise it overwrites any locations that were set in the tracker service already
    if (location) {
      properties = { ...properties, Location: location };
    }

    this.trackerService.trackEventData({
      action: 'Skill Engagement Updated',
      properties,
    });
  }

  /**
   * Send tracking data to amplitude for a rating change to the given rating type
   */
  public trackSkillRatingUpdated(
    type: InternalTagRatingTypes,
    action: TagRatingUpdatedActions,
    levelChangedFrom: number,
    levelChangedTo: number,
    tag: TagsApi.Tag,
    ownerUserId?: number,
    location?: string,
    element?: HTMLElement,
    isExternal?: boolean
  ): void {
    let properties: SkillRatingUpdatedProperties = {
      RatingScale: this.authService.authUser?.orgRatingScale?.anchorHigh,
      RatingType: TagRatingTypes[type],
      RatingLevelChangedFrom: levelChangedFrom,
      RatingLevelChangedTo: levelChangedTo,
      SkillRatingAction: action,
      SkillId: tag?.tagId,
      SkillName: tag?.name,
      IsExternal: isExternal || false,
      // If owns skill
      IsFocusSkill: !ownerUserId ? tag?.isFocused : null,
      IsOnProfile: !ownerUserId ? tag?.isFollowing : null,
      // If manager or peer
      SkillOwnerUserId: ownerUserId,
    };

    // need to add location this way; otherwise it overwrites any locations that were set in the tracker service already
    if (location) {
      properties = { ...properties, Location: location };
    }

    this.trackerService.trackEventData({
      action: 'Skill Rating Updated',
      properties,
      element,
    });
  }

  /**
   * Send tracking data to amplitude for any skills view change
   */
  public trackSkillsViewChange(
    renderMethod: string,
    tags,
    changeObject?: SkillsLayout
  ): void {
    let properties = {} as SkillsViewChange;

    // search term & rating type filters
    if (changeObject.filter) {
      properties = this.constructFilterChange(
        changeObject.filter,
        changeObject.previousFilter,
        properties
      );
    }

    // render method
    if (!changeObject.previousRenderMethod) {
      properties.RenderMethod = renderMethod;
    } else {
      properties = {
        ...properties,
        RenderMethodChangedFrom: changeObject.previousRenderMethod,
        RenderMethodChangedTo: renderMethod,
      };
    }

    // pagination
    if (!changeObject.previousPagination) {
      properties.Pagination = changeObject.pagination;
    } else {
      properties = {
        ...properties,
        PaginationChangedFrom: changeObject.previousPagination,
        PaginationChangedTo: changeObject.pagination,
      };
    }

    // selected results per page count
    if (!changeObject.previousSelectedResultsPerPageCount) {
      properties.SelectedResultsPerPageCount =
        changeObject.selectedResultsPerPageCount;
    } else {
      properties = {
        ...properties,
        SelectedResultsPerPageCountChangedFrom:
          changeObject.previousSelectedResultsPerPageCount,
        SelectedResultsPerPageCountChangedTo:
          changeObject.selectedResultsPerPageCount,
      };
    }

    // column configuration
    if (!changeObject.previousColumnConfiguration) {
      properties.ColumnConfiguration = changeObject.columnConfiguration?.map(
        (column) => TagRatingTypes[column]
      );
    } else {
      properties = {
        ...properties,
        ColumnConfigurationChangedFrom:
          changeObject.previousColumnConfiguration.map(
            (column) => TagRatingTypes[column]
          ),
        ColumnConfigurationChangedTo: changeObject.columnConfiguration.map(
          (column) => TagRatingTypes[column]
        ),
      };
    }

    // sorting direction
    if (changeObject.previousSortingColumn === undefined) {
      properties = {
        ...properties,
        SortingColumn: !isEmpty(changeObject.sortingColumn)
          ? changeObject.sortingColumn
          : undefined,
        SortingDirection: !isEmpty(changeObject.sortingDirection)
          ? changeObject.sortingDirection
          : undefined,
      };
    } else {
      if (changeObject.sortingColumn !== changeObject.previousSortingColumn) {
        properties = {
          ...properties,
          SortingColumnChangedFrom: changeObject.previousSortingColumn,
          SortingColumnChangedTo: changeObject.sortingColumn,
        };
      } else {
        properties = {
          ...properties,
          SortingColumn: changeObject.sortingColumn,
        };
      }

      if (
        changeObject.sortingDirection !== changeObject.previousSortingDirection
      ) {
        properties = {
          ...properties,
          SortingDirectionChangedFrom: changeObject.previousSortingDirection,
          SortingDirectionChangedTo: changeObject.sortingDirection,
        };
      } else {
        properties = {
          ...properties,
          SortingDirection: changeObject.sortingDirection,
        };
      }
    }

    this.trackerService.trackEventData({
      action: 'Skills View Changed',
      properties: {
        ...properties,
        ...this.setRatingTypeCounts(tags),
      },
    });
  }

  /**
   * Send tracking data to amplitude for any recommended skill ratings
   */
  public trackSkillRecommended(
    type: InternalTagRatingTypes,
    tag: TagsApi.TagDetails,
    isAssigned: boolean,
    recommendedTo: RecommendeeType,
    assignmentDueDate?: string,
    element?: HTMLElement
  ) {
    tag = camelCaseKeys(tag);
    const properties: SkillRecommendedProperties = {
      RatingType: TagRatingTypes[type],
      SkillId: tag?.tagId,
      SkillName: tag?.name,
      IsAssigned: isAssigned,
      RecommendedTo: recommendedTo,
      IsFocusSkill: tag.isFocused,
      IsEndorsed: tag.isEndorsed,
      IsOnProfileforOwner: (tag as OwnerTag).isOnProfileForOwner,
    };
    if (isAssigned) {
      properties.AssignmentDueDate = assignmentDueDate;
    }
    this.trackerService.trackEventData({
      action: 'Skill Recommended',
      properties,
      element,
    });
  }

  private setRatingTypeCounts(tags: FilteredTag[]): TagRatingTypeCount {
    const tagRatingTypeCounts = {
      [InternalTagRatingTypes.self]: 0,
      [InternalTagRatingTypes.evaluation]: 0,
      [InternalTagRatingTypes.manager]: 0,
      [InternalTagRatingTypes.peer]: 0,
      [InternalTagRatingTypes.credential]: 0,
      [InternalTagRatingTypes.target]: 0,
      external: 0,
      skillsWithRatings: 0,
    };

    let ratings;
    tags?.forEach((tag) => {
      ratings = 0;
      if (tag.ratings?.some((rating) => !rating.isInternal)) {
        tagRatingTypeCounts.external++;
        ratings++;
      }
      for (const [ratingType] of Object.entries(tagRatingTypeCounts)) {
        if (
          ratingType !== 'external' &&
          tag.ratings?.some((rating) => rating.type === ratingType)
        ) {
          tagRatingTypeCounts[ratingType]++;
          ratings++;
        }
      }

      if (ratings > 0) {
        tagRatingTypeCounts.skillsWithRatings++;
      }
    });

    return {
      SelfRatingsCount: tagRatingTypeCounts[InternalTagRatingTypes.self],
      ManagerRatingsCount: tagRatingTypeCounts[InternalTagRatingTypes.manager],
      PeerRatingsCount: tagRatingTypeCounts[InternalTagRatingTypes.peer],
      SkillReviewCount: tagRatingTypeCounts[InternalTagRatingTypes.evaluation],
      SkillTargetCount: tagRatingTypeCounts[InternalTagRatingTypes.target],
      SkillsWithExternalRatingsCount: tagRatingTypeCounts.external,
      SkillsWithRatingsCount: tagRatingTypeCounts.skillsWithRatings,
      SkillsCount: tags?.length || 0,
    };
  }

  private constructFilterChange(
    filter,
    previousFilter,
    properties
  ): Partial<SkillsViewChange> {
    // search term
    if (
      !isEmpty(previousFilter) &&
      (filter?.searchTerm || previousFilter?.searchTerm) &&
      filter?.searchTerm !== previousFilter?.searchTerm
    ) {
      properties = {
        ...properties,
        KeywordChangedTo: filter?.searchTerm || '',
        KeywordChangedFrom: previousFilter?.searchTerm || '',
      };
    } else {
      properties = {
        ...properties,
        Keyword: !isEmpty(filter?.searchTerm) ? filter?.searchTerm : undefined,
      };
    }

    // rating type filter
    const filterChangedFrom = !isEmpty(previousFilter?.facets)
      ? previousFilter.facets
          .find((facet) => facet.id === 'RatingType')
          ?.values.filter((value) => value.filter)
          .map((value) => value.name)
      : [];
    const filterChangedTo = !isEmpty(filter?.facets)
      ? filter.facets
          .find((facet) => facet.id === 'RatingType')
          ?.values.filter((value) => value.filter)
          .map((value) => value.name)
      : [];

    if (
      !isEmpty(previousFilter) &&
      !isEqual(filterChangedFrom, filterChangedTo)
    ) {
      properties = {
        ...properties,
        RatingTypeFilterChangedFrom: filterChangedFrom,
        RatingTypeFilterChangedTo: filterChangedTo,
      };
    } else {
      properties = {
        ...properties,
        RatingTypeFilter: !isEmpty(filterChangedTo)
          ? filterChangedTo
          : undefined,
      };
    }

    return properties;
  }
}

export type TagRatingTrackingTypeSelf =
  | InternalTagRatingTypes.credential
  | InternalTagRatingTypes.evaluation
  | InternalTagRatingTypes.self
  | 'external'
  | 'checkpoint';
export type TagRatingTrackingTypePeer =
  | InternalTagRatingTypes.manager
  | InternalTagRatingTypes.peer
  | InternalTagRatingTypes.self;
export type TagRatingTrackingType =
  | TagRatingTrackingTypeSelf
  | TagRatingTrackingTypePeer;
export interface SkillsLayout {
  previousRenderMethod?: string;
  filter?: ProfileFilter;
  previousFilter?: ProfileFilter;
  columnConfiguration?: InternalTagRatingTypes[];
  previousColumnConfiguration?: InternalTagRatingTypes[];
  sortingColumn?: string;
  previousSortingColumn?: string;
  sortingDirection?: string;
  previousSortingDirection?: string;
  pagination?: number;
  previousPagination?: number;
  selectedResultsPerPageCount?: number;
  previousSelectedResultsPerPageCount?: number;
}
export interface SkillsViewChange {
  RenderMethod?: string;
  RenderMethodChangedFrom?: string;
  RenderMethodChangedTo?: string;
  RatingTypeFilter?: string[];
  RatingTypeFilterChangedFrom?: string[];
  RatingTypeFilterChangedTo?: string[];
  Keyword?: string;
  KeywordChangedFrom?: string;
  KeywordChangedTo?: string;
  ColumnConfiguration?: string[];
  ColumnConfigurationChangedFrom?: string[];
  ColumnConfigurationChangedTo?: string[];
  SortingColumn?: string;
  SortingColumnChangedFrom?: string;
  SortingColumnChangedTo?: string;
  SortingDirection?: string;
  SortingDirectionChangedFrom?: string;
  SortingDirectionChangedTo?: string;
  Pagination?: number;
  PaginationChangedFrom?: number;
  PaginationChangedTo?: number;
  SelectedResultsPerPageCount?: number;
  SelectedResultsPerPageCountChangedFrom?: number;
  SelectedResultsPerPageCountChangedTo?: number;
}
export interface SkillRatingUpdatedProperties {
  RatingScale: number;
  RatingType: string;
  RatingLevelChangedFrom: number;
  RatingLevelChangedTo: number;
  SkillRatingAction: string;
  SkillId: number;
  SkillName: string;
  IsExternal: boolean;
  IsFocusSkill?: boolean;
  IsOnProfile?: boolean;
  SkillOwnerUserId?: number;
  Location?: string;
  RenderMethod?: string;
}
export interface SkillEngagementUpdatedProperties {
  EngagementType: TagRatingSkillEngagementType;
  ChangedTo: boolean;
  SkillId: number;
  SkillName: string;
  IsFocusSkill: boolean;
  IsOnProfile: boolean;
  Location?: string;
}
export interface SkillRecommendedProperties {
  RatingType: TagRatingTypes;
  SkillId: number;
  SkillName: string;
  IsAssigned: boolean;
  RecommendedTo: 'User' | 'Group';
  IsFocusSkill: boolean;
  IsEndorsed: boolean;
  IsOnProfileforOwner: boolean;
  AssignmentDueDate?: string;
}
export interface TagRatingTypeCount {
  SelfRatingsCount: number;
  ManagerRatingsCount: number;
  PeerRatingsCount: number;
  SkillReviewCount: number;
  SkillTargetCount: number;
  SkillsWithRatingsCount: number;
  SkillsWithExternalRatingsCount: number;
  SkillsCount: number;
}

export enum TagRatingTypes {
  Credential = 'Skill Certification',
  Evaluation = 'Skill Review',
  Manager = 'Manager Rating',
  Peer = 'Peer Ratings',
  Self = 'Self Rating',
  Goal = 'Skill Target',
  Signals = 'Skill Signals', // technically not a rating type, but required here for show / hide of the Skill Signal column in the skills table
  Total = 'Total Ratings',
}

export enum TagRatingUpdatedActions {
  added = 'Added',
  removed = 'Removed',
  cancelled = 'Cancelled',
}

export enum TagRatingSkillEngagementType {
  focus = 'Added as Focus skill',
  follow = 'Added as Skill',
  removed = 'Removed',
}
