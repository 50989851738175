import { TrackerService } from '@app/shared/services/tracker.service';
import { LiveEventsService } from '@app/shared/components/content/live-events/live-events.service';
import { InputRatingsService } from '@app/inputs/services/input-ratings.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, forkJoin, of, switchMap } from 'rxjs';
import { AuthUser } from '@app/account/account-api.model';
import { CommentsService } from '@app/comments/comments.service';
import { InputSession, InputStatistics } from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { ResourceCardMenuService } from '@app/shared/components/content/learning-resource-card/resource-card-menu.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { UserRecommendationHeaderItem } from '@app/shared/components/user-recommendation-header/user-recommendation-header.component';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { InputDetailsService } from '../input-details/input-details.service';
import { InputType } from '@app/shared/models/core-api.model';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { ProfileService } from '@app/profile/services/profile.service';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { EndorseContentService } from '@app/shared/components/content/services/endorse-content.service';
import { SearchNavigationService } from '@dg/shared-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { CatalogSource } from '@app/shared/utils/tracker-helper';
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { SearchResultTrackingConfig } from '@app/search';
import { UserInputModalService } from '@app/inputs/services/user-input-modal.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { take } from 'rxjs/operators';

type PreviewType = 'image' | 'video';

@Component({
  selector: 'dgx-input-card',
  templateUrl: './input-card.component.html',
  styleUrls: ['./input-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputCardComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public resource: LearningResourceViewModel;
  @Input() public userRecHeaderItem?: UserRecommendationHeaderItem;
  @Input() public disableSocialClick?: boolean = false;
  @Input() public displayMenu: boolean = false;
  @Input() public isDetailModal: boolean;
  @Input() public isPreview?: boolean;
  @Input() public customMenuConfig?: MenuViewModel[];
  @Input() public expandComments: boolean = false;
  @Input() public isMarketplaceProgram: boolean = false;
  // this is used when a card is displayed in a modal for a marketplace search result item, when tracking the click events for the title,
  // open button and the image, we also need to send the location, searchTerm, currentPage, resultPosition and totalContentResults
  @Input()
  public searchResultTrackingConfig: SearchResultTrackingConfig;

  @ViewChild('link') public link: ElementRef<HTMLElement>;

  public menuConfig: MenuViewModel[];
  public authUser: AuthUser;
  public previewType: PreviewType = 'image';
  public socialCountsModel;
  public showMoreDetails: boolean;
  public showComments: boolean;
  public isLoading: boolean = true;
  public hasMenu: boolean;
  // this is used to display the full Post summary
  public summary: string;
  public liveSessions: InputSession[];
  public liveEventsEnabled: boolean;
  public sessionLabel = '';
  public commentThreadExpanded = false;
  public viewerInput: LearningResourceViewModel;
  public i18n = this.translateService.instant([
    'Core_ViewDetails',
    'Core_MoreOptions',
    'Core_Recommend',
    'Core_SaveForLater',
    'Core_Save',
    'Core_Unsave',
    'Core_AddTags',
    'Core_MoreDetails',
    'Core_MoreOptions',
    'Core_Endorsed',
    'Core_EndorsedTooltip',
    'Core_Open',
    'Core_Edit',
    'Core_Like',
    'Core_Pathway',
    'Core_SkillPlan',
    'Core_AddTo',
    'LiveEvents_SessionsCardLabel',
    'dgThumbs_Dislike',
    'Core_Skills',
  ]);
  public userTags: string[] = [];
  public orgTags: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private inputDetailsService: InputDetailsService,
    private inputRatingsService: InputRatingsService,
    private resourceCardMenuService: ResourceCardMenuService,
    private authService: AuthService,
    private translateService: TranslateService,
    private commentService: CommentsService,
    private inputsService: InputsService,
    private markdownService: MarkdownService,
    private liveEventsService: LiveEventsService,
    private trackerService: TrackerService,
    private inputToLearningResourcePipe: InputToLearningResourcePipe,
    private profileService: ProfileService,
    private orgEndorsedService: OrgEndorsedService,
    private endorseContentService: EndorseContentService,
    private searchNavigationService: SearchNavigationService,
    private activeModal: NgbActiveModal,
    private searchTrackerService: SearchTrackerService,
    private userInputModalService: UserInputModalService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
  }

  public get saveSessionCheckboxLabel(): string {
    return this.translateService.instant('LiveEvents_RegisteredCheckboxLabel', {
      inputType: this.resource.resourceType.toLowerCase(),
    });
  }

  public get saveSessionCheckboxTooltip(): string {
    return this.translateService.instant(
      'LiveEvents_RegisteredCheckboxTooltip',
      {
        inputType: this.resource.resourceType.toLowerCase(),
      }
    );
  }

  public get saveIconTooltip(): string {
    return this.resource.isQueued
      ? this.i18n.Core_Unsave
      : this.i18n.Core_SaveForLater;
  }

  public get isCompleted(): boolean {
    // the viewerInput is only available when viewing another user's profile and represents the input with the viewing user's completion info
    return (
      (this.ownerIsViewing
        ? this.resource.completionInfo?.isCompleted
        : this.viewerInput?.model?.isCompleted) && !this.isMarketplaceProgram
    );
  }

  public get ownerIsViewing(): boolean {
    // the owner is only relevant within the profile context, where we need to show the completion info for the owner of
    // the input - eg if you're looking at someone else's profile, you see their completion info, everywhere else you would see your own
    return this.profileService.isProfilePage
      ? this.authUser.viewerProfile.userProfileKey ===
          (this.resource.model as any).userProfileKey
      : true;
  }

  public get url(): string {
    return this.resource?.model?.marketplaceInputId
      ? this.resource.url
      : this.resource.externalUrl || this.resource.url;
  }

  public get isPost(): boolean {
    return this.resource.resourceType === 'Post';
  }

  public get isTask() {
    return this.resource.resourceType === 'Task';
  }

  public get isTarget() {
    return this.resource.resourceType === 'Target';
  }

  public get canAddSkills() {
    const taggableInputTypes = [
      'Article',
      'Book',
      'Assessment',
      'Episode',
      'Video',
      'Event',
      'Course',
    ];

    return (
      taggableInputTypes.includes(this.resource.resourceType) &&
      !this.isMarketplaceProgram &&
      !this.isPreview &&
      this.isCompleted &&
      this.ownerIsViewing
    );
  }

  public get isClickable(): boolean {
    if (!this.resource.url || !this.resource.internalUrl) {
      return false;
    }

    // Only youtube videos should be clickable due to dissalowing cookies for youtube videos
    // which made it so private videos can't be viewed within the degreed system. (PD-82722)
    // Note: previously we disabled videos because ted videos would download (PD-70122)

    // What REALLY needs to happen is two things
    // 1. Video providers need a flag that indicates they can be externally opened (false for ted, true for everyone else...)
    // 2. We have a org opt-in/opt-out for the cookie flag that allows youtube to work within degreed

    if (
      this.resource.videoInfo?.videoUrl &&
      !this.resource.videoInfo.videoUrl.includes('youtube')
    ) {
      // embedded videos should not have a clickable title.
      return false;
    }
    if (
      this.isDetailModal &&
      (this.resource.videoInfo?.videoType === 'degreed' ||
        this.resource.resourceType === 'Post' ||
        this.resource.resourceType === 'Task')
    ) {
      // Degreed hosted videos in a modal/Input page have nowhere else to go
      return false;
    }
    if (this.resource.resourceType === 'Task') {
      return false;
    }
    if (this.isPreview) {
      return false;
    }
    return true;
  }

  public get showSocialCounts() {
    return !this.isPreview && !!this.socialCountsModel;
  }

  public get showCompletion() {
    return (
      !this.resource.isAcademy &&
      !this.resource.isv2Academy &&
      !this.isMarketplaceProgram
    );
  }

  public get showShare() {
    return this.authUser?.canRecommendItems && !this.isMarketplaceProgram;
  }

  public get endorsedSrc() {
    return this.orgEndorsedService.getEndorsedSrc(
      this.authUser?.defaultOrgInfo?.organizationId
    );
  }

  public get canAddToQueue() {
    return (
      !this.resource.isTarget && !this.isCompleted && !this.isMarketplaceProgram
    );
  }

  // TODO: Currently, our "I've registered for this event" checkbox is an exact duplicate of the save-for-later
  // button, except we also check whether the "is registration available" box was checked when the course/event was
  // created. We should also add a `register()` method here (and on the BE) to actually update the `resource.isRegistered`
  // property. (NOTE: As of 2024, there is still only one live session supported per course/event, despite the code
  // technically expecting an array.)
  public get canRegister() {
    return (
      this.canAddToQueue &&
      this.resource.liveSessions?.[0].isRegistrationAvailable
    );
  }

  public get currentTags() {
    return this.orgTags.join(',');
  }

  // If the org and a user both assign the same skill to content
  // we want the user skill to take precedence in the UI and ignore the
  // duplicate org skill
  public get uniqueOrgTags() {
    return this.orgTags?.filter((tag) => !this.userTags.includes(tag)) || [];
  }

  public get hasTags() {
    return [...this.orgTags, ...this.userTags].length > 0;
  }

  public get showAddButton() {
    return (
      (this.resourceCardMenuService.mayAddToPathway(this.resource) ||
        this.resourceCardMenuService.mayAddToTarget(this.resource)) &&
      !this.isMarketplaceProgram
    );
  }

  public get showOpenButton() {
    return (
      !this.resource.isAcademy &&
      !this.showMoreDetails &&
      !this.isTask &&
      !this.isPost &&
      !!this.url
    );
  }

  public get addToMenuConfig(): MenuViewModel[] {
    return [
      {
        title: this.i18n.Core_Pathway,
        preventRefocus: true,
        isHidden: () =>
          !this.resourceCardMenuService.mayAddToPathway(this.resource),
        defaultAction: (event, popoverTrigger) =>
          this.resourceCardMenuService.addToPathway(
            event,
            this.resource,
            popoverTrigger
          ),
      },
      {
        title: this.i18n.Core_SkillPlan,
        preventRefocus: true,
        isHidden: () =>
          !this.resourceCardMenuService.mayAddToTarget(this.resource),
        defaultAction: (_, { nativeElement }) =>
          this.resourceCardMenuService.addToTarget(
            'JobRole',
            this.resource,
            nativeElement
          ),
      },
    ];
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;

    this.endorseContentService.onUpdate$
      .pipe(this.takeUntilDestroyed())
      .subscribe((resourceUpdated: LearningResourceViewModel) => {
        if (
          resourceUpdated.resourceId === this.resource.resourceId &&
          resourceUpdated.resourceType === this.resource.resourceType
        )
          this.cdr.detectChanges();
      });

    const { inputType, userInputId, inputId } = this.resource.model;

    const input$ =
      !this.isPreview && !this.isMarketplaceProgram
        ? // We can't count on the `resource` input having all the properties we need in every context
          // so we go back to the server for it...
          this.inputsService.getInput(inputId, inputType as InputType)
        : // We don't needs tags for preview mode and marketplace items have tags on the resource
          of(this.resource);

    // if there are no statistics - eg the input has never been completed / shared / saved etc - then we don't need to load
    // the input within the context of the viewing user. If there are statistics, we get the input details within the context
    // of the viewing user so that the completion button reflects the viewer's completion status for the input
    const loadViewerInput = !!this.resource.statistics && !this.ownerIsViewing;
    const viewerInput$ = loadViewerInput
      ? this.inputsService.getInputAndStatistics({
          inputType: inputType as InputType,
          inputId,
        })
      : of(null);

    // Get user added Skills if allowed
    const userTags$: Observable<TagsApi.Tag[]> = this.canAddSkills
      ? this.userInputModalService.getUserInputTags({
          inputType,
          userInputId,
          inputId,
        })
      : of([]);

    forkJoin([input$, viewerInput$, userTags$]).subscribe(
      ([input, viewerInput, userTags]) => {
        this.userTags = this.getTagNames(userTags);
        this.orgTags = this.getTagNames(input?.tags);
        const summary =
          this.isPost && !this.isPreview
            ? // Set `summary` for `Post` inputs
              this.addPublishDateToSummary({
                ...this.resource,
                summary: (input as any).details?.body,
              } as LearningResourceViewModel)
            : // Set `summary` for all other inputs
              this.addPublishDateToSummary(
                this.resource as LearningResourceViewModel
              );

        this.summary = this.markdownService.markdownToHtml(summary, {
          allowImages: true,
          openLinksInNewWindow: true,
        });

        if (!!viewerInput) {
          this.viewerInput = this.inputToLearningResourcePipe.transform(
            viewerInput.input
          );
        }

        this.setupCard();

        if (this.expandComments) {
          this.toggleCommentThread();
        }

        this.isLoading = false;

        this.cdr.markForCheck();
      }
    );
  }

  public setupCard() {
    this.liveSessions =
      this.resource.liveSessions ?? this.resource.model?.liveSessions?.$values;

    if (this.liveSessions) {
      this.sessionLabel = this.translateService.instant(
        'LiveEvents_SessionsCardLabel',
        { count: this.liveSessions.length }
      );
    }

    this.showMoreDetails =
      this.resource.completionInfo?.externalCompletionOnly &&
      !this.isMarketplaceProgram;
    this.showComments =
      this.commentService.displayCommentsSection(this.resource) &&
      !this.isPost &&
      !this.isPreview &&
      !this.isTarget &&
      this.authUser?.canComment &&
      !this.isMarketplaceProgram;
    this.hasMenu = this.isDetailModal || this.displayMenu;
    this.menuConfig = this.applyMenuConfigs();

    this.authUser = this.authService.authUser;
    this.updateSocialCounts();
    if (this.resource.videoInfo?.videoUrl) {
      this.previewType = 'video';
    }
  }

  public toggleLike() {
    // not all items have statistics
    this.resource.statistics ??= {} as InputStatistics;

    const oldRating = this.resource.requestingUserRating;
    const newRating = oldRating !== 1 ? 1 : 0;

    const { likeCount } = this.inputRatingsService.rateItem(
      this.resource,
      newRating,
      this.currentTags
    );

    this.resource.requestingUserRating = newRating;

    // update thumbs up / down social count
    this.resource.statistics.likeCount = likeCount;
  }

  public dislike() {
    // not all items have statistics
    this.resource.statistics ??= {} as InputStatistics;

    const newRating = 2;

    const { likeCount, dislikeCount } = this.inputRatingsService.rateItem(
      this.resource,
      newRating,
      this.currentTags
    );

    this.resource.requestingUserRating = newRating;

    this.resource.statistics.likeCount = likeCount;
    this.resource.statistics.dislikeCount = dislikeCount;
  }

  public searchTag(tagName: string) {
    this.searchNavigationService.searchLearnings(tagName);
    this.activeModal.dismiss();
  }

  public rate(event) {
    const newRating = event.rating;

    // not all items have statistics
    this.resource.statistics ??= {} as InputStatistics;

    const { likeCount, dislikeCount } = this.inputRatingsService.rateItem(
      this.resource,
      newRating,
      this.currentTags
    );

    this.resource.requestingUserRating = newRating;

    // update thumbs up / down social count
    this.resource.statistics.likeCount = likeCount;
    this.resource.statistics.dislikeCount = dislikeCount;

    // We used to call this.updateSocialCounts() here to immediately reflect the user's rating
    // but due to the issues outlined in the comment below we decided to remove it as it was causing
    // too much confusion.

    // Note: this.resource.statistics doesn't come back immediately with the
    // dislikeCount and likeCount set. The database aggregates updates for the
    // input statistics on a 15-minute job, for performance reasons.
    // Therefore, if the user immediately refreshes after liking/disliking, the
    // counts won't immediately show the change reflecting the user's action.
    // This is related to shared underlying reasons of PD-71181 (completions).
  }

  public goToUrl() {
    if (this.url) {
      if (this.isMarketplaceProgram) {
        this.searchTrackerService
          .contentOpened(this.resource, this.searchResultTrackingConfig)
          .pipe(take(1))
          .subscribe({
            complete: () => {
              this.windowRef.open(this.url, '_blank');
            },
          });
      } else {
        this.windowRef.open(this.url, '_blank');
      }
    }
  }

  public openInputPage() {
    // hosted content is currently managed with the external url
    // longer term this makes more sense as internal url if we can
    // conclusively say external url is only for content that links out of degreed
    let urlToOpen = this.resource.isHostedContent
      ? this.resource.externalUrl
      : this.resource.internalUrl;

    if (
      this.previewType === 'video' &&
      this.resource.videoInfo.videoType === 'degreed'
    ) {
      urlToOpen = this.url;
    }

    this.windowRef.open(urlToOpen, '_blank');
  }

  public recommend(event: MouseEvent) {
    this.inputDetailsService.showShareModel(
      this.resource,
      event.target as HTMLElement
    );
  }

  public queue() {
    if (this.resource.isQueued) {
      this.inputDetailsService
        .removeFromMyQueue(this.resource)
        .subscribe(() => {
          this.resource.queueItemId = null;
          this.resource.isQueued = false;
        });
    } else {
      this.inputDetailsService
        .addToMyQueue(this.resource)
        .subscribe((result) => {
          this.resource.queueItemId = result;
          this.resource.isQueued = true;
        });
    }
  }

  public tagResource(event: MouseEvent) {
    this.inputDetailsService
      .tagResource(this.resource, event)
      .pipe(
        switchMap(() => {
          const { inputType, userInputId, inputId } = this.resource.model;

          return this.userInputModalService.getUserInputTags({
            inputType,
            userInputId,
            inputId,
          });
        })
      )
      .subscribe((tags: any) => {
        this.updateSocialCounts();
        this.userTags = this.getTagNames(tags);
        this.cdr.markForCheck();
      });
  }

  public onImageClick(e: MouseEvent) {
    if (this.url) {
      e.stopPropagation();
      this.link.nativeElement.click();
    }
  }

  public increaseCommentCount() {
    if (!this.resource.statistics) {
      return;
    }

    this.resource.statistics.commentCount += 1;
    this.updateSocialCounts();
  }

  public decreaseCommentCount() {
    if (!this.resource.statistics) {
      return;
    }

    this.resource.statistics.commentCount -= 1;
    this.updateSocialCounts();
  }

  public updateSocialCounts() {
    // Create an object to pass to the social-count component because it requires the `model` to avoid
    // circular dependency issues with the getter/setters on the LearningResourceModel but
    // the `model` does not always have the statistics on it and is an immutable object so we have to create a new object
    // with the statistics here!
    const model = this.viewerInput
      ? this.viewerInput.model
      : this.resource.model;

    this.socialCountsModel = {
      ...model,
      statistics: this.resource.statistics,
      isModal: this.isDetailModal,
    };
  }

  public onLinkClick(event?: { trackAction: string; session: any }) {
    const trackingProperties = this.liveEventsService.getTrackingProperties(
      this.resource,
      event?.session
    );
    this.trackerService.trackEventData({
      action: event?.trackAction,
      properties: trackingProperties,
    });
  }

  public toggleCommentThread() {
    this.commentThreadExpanded = !this.commentThreadExpanded;
  }

  public onContentClicked() {
    if (
      this.resource.resourceType === 'Article' &&
      this.resource.model.hostedType
    ) {
      this.trackerService.trackEventData({
        action: 'Content Clicked',
        properties: {
          catalogSource: this.resource.internalUrl
            ? CatalogSource.Internal
            : CatalogSource.External,
          contentId: this.resource.resourceId,
          contentName: this.resource.title,
          contentType: this.resource.resourceType,
          hasImage: !!this.resource.imageUrl,
          isEndorsed: this.resource.isEndorsed,
          organizationId: this.resource.organizationId,
          hostedType: this.resource.model.hostedType,
          providerId: this.resource.providerSummary?.id,
        },
      });
    } else if (this.isMarketplaceProgram) {
      this.searchTrackerService.contentOpened(
        this.resource,
        this.searchResultTrackingConfig
      );
    }
  }

  public addPublishDateToSummary({
    publishDate,
    summary,
  }: LearningResourceViewModel) {
    return publishDate && summary ? `${publishDate} - ${summary}` : summary;
  }

  private applyMenuConfigs(): MenuViewModel[] {
    const config =
      this.customMenuConfig ??
      this.resourceCardMenuService.getResourceMenu(this.resource, true);

    const moreDetails = {
      title: this.i18n.Core_MoreDetails,
      isHidden: () => !this.resource.internalUrl,
      defaultAction: () => this.openInputPage(),
    };

    const dislike = {
      title: this.i18n.dgThumbs_Dislike,
      isHidden: () => !this.isCompleted,
      defaultAction: () => this.dislike(),
    };

    return [...config, moreDetails, dislike];
  }

  private getTagNames(tags?: string | string[] | TagsApi.Tag[]): string[] {
    if (!tags) {
      return [];
    }

    if (typeof tags === 'string') {
      return tags.split(',');
    }

    return Array.isArray(tags)
      ? tags.map((tag) => (typeof tag === 'string' ? tag : tag.title))
      : [];
  }
}
