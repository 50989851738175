import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { WebEnvironmentService } from '@dg/shared-services';
import { catchError, map, Observable, of, shareReplay, Subject } from 'rxjs';
import { COACH_CONSTANTS } from '../shared/constants';

export interface CoachAction {
  action: string;
  value: string;
}

export interface ConversationMetadata {
  coachId: string;
  skillId?: string;
  skillName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  private dataService = inject(NgxHttpClient);
  private http = inject(HttpClient);
  private webEnvironmentService = inject(WebEnvironmentService);
  private chatActionSubject = new Subject<CoachAction>();
  public chatActionSubject$ = this.chatActionSubject.asObservable();
  private summaySubject = new Subject<any>();
  public summary$ = this.summaySubject.asObservable();
  private skillSubject = new Subject<any>();
  public skill$ = this.skillSubject.asObservable();

  public get fallBackAvatar(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/branding/degreed-logo-glasses.svg',
      true
    );
  }

  public setSkill(skill: any) {
    this.skillSubject.next(skill);
  }

  public setSummary(summary: any) {
    this.summaySubject.next(summary);
  }

  public startNewChat(metadata: CoachAction) {
    this.chatActionSubject.next(metadata);
  }

  getConversationById(conversationId: number): Observable<any> {
    return this.dataService.get(`/Coach/Conversations/${conversationId}`);
  }

  getTopNConversation(coachId: string, topN: string = '4'): Observable<any> {
    return this.dataService.get(`/Coach/Conversations`, {
      params: { topN, coachId },
    });
  }

  getRecentConversations(coachId: string): Observable<any> {
    return this.dataService.get(`/Coach/Conversations/${coachId}?topN=3`);
  }

  createNewConversation(payload?: any): Observable<any> {
    return this.dataService.post('/Coach/Conversations/Create', payload);
  }

  public getSkillReport(conversationId: number): Observable<any> {
    return this.dataService.get(`/Coach/Inferences/SkillReview`, {
      params: { conversationId },
    });
  }

  public exportSummaryAsPDF(
    conversationId: number
  ): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `/api/Coach/Summaries/Conversation/${conversationId}/pdf`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  public getRecommendations(
    coachId?: string,
    conversationId?: number
  ): Observable<any> {
    return this.dataService.get(
      `/Coach/Recommendations/Conversation/${conversationId}`,
      {
        params: { coachId },
      }
    );
  }

  updateConversation(payload: any, retryTimes: number = 0): Observable<any> {
    return this.dataService.put('/Coach/Conversations/Update', payload);
  }

  getCoachById(coachId: string): Observable<any> {
    return this.dataService.get(`/Coach/Get/${coachId}`);
  }

  getConversations(params: any): Observable<any> {
    return this.dataService.get(`/Coach/Conversations`, { params });
  }

  public getCoachList(params?: any): Observable<any> {
    return this.dataService.get('/Coach/List', { params }).pipe(
      shareReplay(1),
      catchError((err: any) => of(err))
    );
  }

  public sendFeedback(payload: any): Observable<any> {
    return this.dataService.post('/Coach/Feedbacks/Create', payload);
  }

  public getMasterCoach(): Observable<any> {
    return this.dataService
      .get('/Coach/List', { params: { coachType: 'Master' } })
      .pipe(
        map((data: any) => data?.[0]),
        shareReplay(1),
        catchError((err: any) => of(err))
      );
  }

  deleteCoachData(params: any): Observable<any> {
    return this.dataService.delete(
      `${COACH_CONSTANTS.apiBaseUrl}/api/coach/reset_coach_chat`,
      {
        params,
        headers: { 'ngrok-skip-browser-warning': 'dsd' },
      }
    );
  }

  deleteConversation(conversationId: any): Observable<any> {
    return this.dataService.delete(
      `/Coach/Conversations/Delete/${conversationId}`
    );
  }
}
