import { Component } from '@angular/core';

import { PathwayUserBaseDirective } from '@app/pathways/directives/pathway-user-base.directive';
import { ColorService } from '@app/shared/services/color.service';
import {
  PathwayStep,
  PathwaySubsection,
  shouldShowSubsection,
} from '@dg/pathways-rsm';
import { EventBus, TrackerService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

/**
 * This is a presentation component that displays a list of pathway sections
 * and will auto scroll to the in-progress section if specified
 *
 * NOTE: all input from container component are read-only
 */
@Component({
  selector: 'dgx-pathway-subsections',
  templateUrl: './pathway-subsections.component.html',
  styleUrls: ['./pathway-subsections.component.scss'],
})
export class PathwaySubsectionsComponent extends PathwayUserBaseDirective {
  public readonly i18n = this.translate.instant([
    'ProfilePathways_Completed',
    'Pathways_LessonItemsAllOptional',
  ]);
  public progressCircleInProgress = 'var(--apollo-color-yellow-700)';

  constructor(
    private translate: TranslateService,
    private trackerService: TrackerService,
    eventBus: EventBus
  ) {
    super(eventBus);
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public shouldShowSubsection = shouldShowSubsection;

  // ***************************
  // Methods
  // ***************************

  /**
   * Formatted text listing completed items.
   *
   * @param subsection
   */
  public getCompletedItems({
    completedSteps,
    totalSteps,
    optionalSteps,
  }: PathwaySubsection) {
    return this.translate.instant('Pathways_CompletedItemsFormat', {
      totalCompletedItems: completedSteps,
      totalItems: totalSteps - optionalSteps,
    });
  }

  public inProgressAriaLabel(
    completedSteps: number,
    totalSteps: number
  ): string {
    return this.translate.instant('A11y_CompletionProgressDetails', {
      numberCompleted: completedSteps,
      total: totalSteps,
    });
  }

  /**
   * Whether or not to show the optional-subsection notice above a subsection.
   *
   * @param subsection
   */
  public showOptionalText({
    complete,
    lessonItemCount,
    optionalSteps,
  }: PathwaySubsection) {
    return !complete && lessonItemCount === optionalSteps;
  }

  /**
   * Track steps being viewed.
   *
   * Only works on browsers that support interesection observers.
   *
   * @param step
   * @param stepIndex
   */
  public onStepIsVisible({ reference }: PathwayStep, stepIndex: number): void {
    this.trackerService.trackEventData({
      action: 'Content Impressed',
      properties: {
        location: 'Pathway', // Maps to ActionLocation in Amplitude
        inputType: reference.inputType, // Maps to ContentType in Amplitude
        contentId: reference.inputId,
        title: reference.title,
        providerId: reference.providerId,
        providerName: reference.providerName,
        externalId: reference.externalId,
        position: stepIndex,
        dateCreated: reference.dateCreated,
      },
    });
  }
}
