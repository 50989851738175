import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  InputCount,
  InputPoints,
  UserInsightsService,
} from '@app/insights/services/user-insights.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { forkJoin } from 'rxjs';
import { LocalizationService } from '@app/shared/localization.service';

@Component({
  selector: 'dgx-profile-input-counts-by-type',
  templateUrl: './profile-input-counts-by-type.component.html',
  styleUrls: ['./profile-input-counts-by-type.component.scss'],
  providers: [TitleCasePipe],
})
export class ProfileInputCountsByTypeComponent
  extends SubscriberBaseDirective
  implements OnInit, OnDestroy
{
  @Input() public ownerKey: number;

  public isMobile: boolean = this.windowLayoutService.isMobile;
  public totalPoints = 0;
  public counts = [];

  public userLocale: string;

  constructor(
    private userInsightsService: UserInsightsService,
    private windowLayoutService: WindowLayoutService,
    private localizationService: LocalizationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadCounts();
    this.windowLayoutService.windowResize$
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.isMobile = this.windowLayoutService.isMobile;
      });
    // use navigator.language when there is no authUser - ie unauthed view on public profiles
    this.userLocale = this.localizationService.userLocale;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private loadCounts(): void {
    forkJoin([
      this.userInsightsService.getInputPoints(this.ownerKey),
      this.userInsightsService.getInputCounts(this.ownerKey),
    ]).subscribe(
      ([inputPoints, inputCounts]: [InputPoints[], InputCount[]]) => {
        let sum = 0;
        for (let i = 0; inputPoints && i < inputPoints.length; i++) {
          sum += inputPoints[i].points;
        }
        this.totalPoints = sum;
        this.counts = inputCounts;
      }
    );
  }
}
