import { Injectable, Injector, Type } from '@angular/core';

import { InputType } from '@app/shared/models/core-api.model';
import { EventModalFacade } from '../event-modal/event-modal.facade';
import { AnyInputApiEntity, InputContext } from '../user-input.model';
import {
  INPUT_CONTEXT,
  INPUT_ENTITY_MODEL,
} from '@app/user-content/user-input/user-input.tokens';
import { InputsFacadeBase } from '@app/user-content/services/inputs-facade-base';
import { CourseFacade } from '../course-form/course.facade';
import { ArticleModalFacade } from '../article-modal/article-modal.facade';
import { VideoModalFacade } from '../video-modal/video-modal.facade';
import { AssessmentFacade } from '../assessment-forms/assessment.facade';
import { BookFacade } from '../book-forms/book.facade';
import { ExperienceFacade } from '../experience-modal/experience-facade';
import { EpisodeFacade } from '../episode-forms/episode.facade';
import { CoachFacade } from '../coach-forms/coach.facade';

/** Creates an Input modal service for a given input type and usage context, which
 * can then be used to create a specific modal.
 */
@Injectable({ providedIn: 'root' })
export class InputModalFactoryService {
  public static readonly modalTypes: Record<InputType, Type<InputsFacadeBase>> =
    {
      Event: EventModalFacade,
      Course: CourseFacade,
      Article: ArticleModalFacade,
      Video: VideoModalFacade,
      Episode: EpisodeFacade,
      Book: BookFacade,
      Assessment: AssessmentFacade,
      Position: ExperienceFacade,
      Coach: CoachFacade,
    } as any as Record<InputType, Type<InputsFacadeBase>>;
  constructor(private injector: Injector) {}

  public getInputModalService(
    context: InputContext,
    initialModel?: Partial<AnyInputApiEntity>
  ): InputsFacadeBase {
    const type = InputModalFactoryService.modalTypes[context.inputType];
    if (!type) {
      throw new Error(
        `A modal service wasn't specified for ${context.inputType}`
      );
    }
    // Create a new injector so we can create a stateful instance of the Input-type-specific modal facade initialized
    // with the context and initial model
    const i = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: INPUT_CONTEXT,
          useValue: context,
        },
        {
          provide: INPUT_ENTITY_MODEL,
          useValue: initialModel,
        },
      ],
    });
    return i.get(type);
  }
}
