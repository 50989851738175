import { Injectable } from '@angular/core';
import { generateGuid } from '@app/shared/utils/uuid';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { UploaderService } from '@app/uploader/uploader.service';
import { catchError, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';

export interface ScormInfo {
  /** The url to upload the Scorm file too */
  scormCourseInputUrl?: string;
  /** The url to check the status of an uploaded file */
  scormCourseProcessingUrl?: string;
  /** Our generated GUID */
  scormCourseId?: string;
  /** Scorm Token - Used to query scorm processing status */
  scormToken?: string;
  /** The requested scorm URL is only good for 15 minutes. Need to keep track in case we need to get a new one */
  scormUrlCreatedDate?: Date;
  /** Name of uploaded file */
  scormFileName?: string;
  /** Indicates if scorm was edited and should be updated */
  scormIsEdited?: boolean;
  /** If there was an error during the process */
  scormHasError?: boolean;
}

export enum ScormXMLResultStatusType {
  UploadResult,
  Processing,
  // this is used to return a failed status from scorn instead of their status
  Fail = 'error',
}

// 15 minutes
const SCORM_STALE_MILLISECONDS = 15 * 60 * 1000;

/**
 * Service for managing anything SCORM related
 */
@Injectable({
  providedIn: 'root',
})
export class ScormService {
  static MAX_UPLOAD_FILE_SIZE_MB: number = 1024;

  public cachedScormInfo: ScormInfo;
  public cachedScormInfoForEdit: ScormInfo;

  private courseErrorMessage: string = this.translate.instant(
    'ContentCatalogSvc.CoursesError'
  );

  constructor(
    private http: NgxHttpClient,
    private uploader: UploaderService,
    private translate: TranslateService
  ) {}

  /**
   * Initialize the scorm object: generates a guid for the scormCourseId, and sets the created date.
   * @returns ScormInfo
   */
  public initialize(updateCourse: boolean, courseUrl: string): ScormInfo {
    const originalGuid = courseUrl?.substring(
      courseUrl?.lastIndexOf('/') + 1,
      courseUrl?.length
    );
    const scormCourseId = updateCourse ? originalGuid : generateGuid();

    return {
      scormCourseId,
      scormUrlCreatedDate: new Date(),
    };
  }

  /**
   * Checks if the scorm info passed in is stale (scormUrlCreatedDate is over 15 minutes old)
   * @param info
   * @returns boolean
   */
  public isStale(info: ScormInfo): boolean {
    const now = new Date();
    return (
      now.getTime() - info.scormUrlCreatedDate.getTime() >
      SCORM_STALE_MILLISECONDS
    );
  }

  public getXMLResponseType(xml: string): ScormXMLResultStatusType {
    if (xml.includes('token')) {
      return ScormXMLResultStatusType.UploadResult;
    } else {
      return ScormXMLResultStatusType.Processing;
    }
  }

  public getTokenFromXmlResponse(xml: string): string {
    return xml.match(/<id>(.*)<\/id>/)[1];
  }

  public getStatusFromXmlResponse(xml: string): string {
    // if this fails then we received an error
    try {
      return xml.match(/<status>(.*)<\/status>/)[1];
    } catch (error) {
      console.warn('scorm failed', xml);
      return ScormXMLResultStatusType.Fail.toString();
    }
  }

  public getProgressFromXmlResponse(xml: string): number {
    return parseInt(xml.match(/<progress>(.*)<\/progress>/)[1], 10);
  }

  public getTitleFromXmlResponse(xml: string): string {
    return xml.match(/<title><\!\[CDATA\[(.*)\]\]><\/title>/)[1];
  }

  public getScormCourseImportUrl(): Observable<string> {
    return of('/scorm/uploadcourse');
  }

  public getScormCourseImportUrString(): string {
    return '/scorm/uploadcourse';
  }

  public getScormCourseImportResultUrl(token, orgId): Observable<string> {
    return this.http
      .get('/organizations/scormcourseimportresulturl', {
        params: {
          token: token,
          orgId: orgId,
        },
      })
      .pipe(
        map((result: { url: string }) => {
          return result.url;
        }),
        catchError((error) =>
          throwError(new DgError(this.courseErrorMessage, error))
        )
      );
  }

  public getScormImportStatus = (
    url?: string,
    courseInputId?: string
  ): Observable<string> =>
    this.http
      .getForScorm(url, courseInputId)
      .pipe(
        catchError((error) =>
          throwError(new DgError(this.courseErrorMessage, error))
        )
      );

  public uploadScormFile(file, url: string): Observable<any> {
    const reportProgress = true;
    const queryParams = { courseId: this.cachedScormInfo.scormCourseId };
    const response = this.uploader.scormUpload(
      this.uploader.prepRawFile(file),
      url,
      queryParams,
      reportProgress
    );

    return response;
  }
}
