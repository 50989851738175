import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NormalizedRecommendation } from '@app/recommendations/recommendations.api';
import { InputDetails as PascalCaseInputDetails } from '@app/shared/ajs/pascal-cased-types.model';
import { TranslateService } from '@ngx-translate/core';
import { EncodeToEntitiesPipe } from '../pipes/encode-to-entities.pipe';
import { NotifierService } from './notifier.service';
import { QueueService } from '@app/shared/services/queue.service';
import { ModalService } from './modal.service';

/** Provides helpers that can be used with action options */
@Injectable({ providedIn: 'root' })
export class ActionOptionHelpersService {
  constructor(
    private notifierService: NotifierService,
    private translate: TranslateService,
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private queueService: QueueService,
    private modalService: ModalService
  ) {}

  public removeFromMyQueue(
    resource: PascalCaseInputDetails,
    element?: HTMLElement
  ) {
    return this.queueService
      .deleteItem(
        resource.QueueItemId,
        resource.ResourceType,
        resource.ResourceId,
        null,
        resource as any,
        element
      )
      .pipe(
        tap(() => {
          resource.QueueItemId = null;
          resource.IsQueued = false;
          return resource;
        })
      );
  }

  public addToMyQueue(
    resource: PascalCaseInputDetails,
    trackingArea: string,
    element?: HTMLElement
  ) {
    return this.queueService
      .post(
        resource.ResourceType,
        resource.ResourceId,
        null,
        resource,
        trackingArea,
        element
      )
      .pipe(
        tap((response) => {
          resource.QueueItemId = response.data.result;
          resource.IsQueued = true;

          this.notifierService.showSuccess(
            this.translate.instant('Core_AddedToQueueNotifierFormat', {
              itemTitle: this.encodeToEntitiesPipe.transform(resource.Title),
            })
          );

          return resource;
        })
      );
  }

  public deleteRecommendation(
    recommendation: NormalizedRecommendation,
    $event,
    onConfirm: () => any
  ) {
    if (!$event.type) {
      // ignore secondary events that aren't triggered by the user
      // (prevents duplicate modals)
      return;
    }

    if (recommendation.recommendationType === 'RequiredLearning') {
      const i18n = this.translate.instant([
        'Core_DismissDialogHeader',
        'RecommendationsOverviewCtrl_DismissDialogDescription',
        'Core_YesSure',
      ]);

      this.modalService
        .showAlert({
          title: i18n.Core_DismissDialogHeader,
          description:
            i18n.RecommendationsOverviewCtrl_DismissDialogDescription,
          confirmButtonText: i18n.Core_YesSure,
        })
        .subscribe(() => {
          onConfirm();
        });
    } else {
      onConfirm();
    }
  }
}
