import { LDFlagsService, WebEnvironmentService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { switchMap } from 'rxjs/operators';
import { EbbAuthService } from '@app/browser-extension/services/ebb-auth.service';
import { ExtensionLocalizationService } from '@app/browser-extension/services/extension-localization.service';

export function initExtensionAppState(
  enviroService: WebEnvironmentService,
  authService: EbbAuthService,
  translate: TranslateService,
  configService: ConfigurationService,
  localizationService: ExtensionLocalizationService,
  launchDarklyClientService: LDFlagsService
) {
  return () => {
    // Initialize web environment then dependent auth service sequentially
    return enviroService
      .initialize()
      .pipe(
        switchMap(() => configService.init()),
        switchMap(() => authService.authCheck()),
        switchMap(() => initTranslations(translate, localizationService)),
        switchMap(() => launchDarklyClientService.initialize())
      )
      .toPromise();
  };
}

/**
 * Initializes the translation service which handles the bulk of our translations
 * but does not cover the initialization of the app locale which will affect
 * certain settings like dates using pipe formatters.
 *
 * Localization is set in app-browser-extension-bootstrap.module.ts
 *
 * @param translate - translation service to initialize with locales
 * @param localizationService - Only used for the constants
 */
function initTranslations(
  translate: TranslateService,
  localizationService: ExtensionLocalizationService
) {
  translate.setDefaultLang(localizationService.defaultLocale);
  return localizationService.userExtensionLocale
    .pipe(switchMap((locale) => translate.use(locale)));
}