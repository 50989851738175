import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';

@Injectable({ providedIn: 'root' })
export class CoachInfoRendererService implements FormRenderer {
  public readonly i18n = this.translate.instant([
    'BookFormCtrl_BookLength',
    'BookFormCtrl_BookTitle',
    'BookFormCtrl_AddCategory',
    'BookFormCtrl_SelectBook',
    'BookFormCtrl_SaveBook',
    'Core_AddGroups',
    'Core_AddGroupsInfo',
    'Core_OpenDatePicker',
  ]);

  constructor(
    private formBuilder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private translate: TranslateService // private domainService: BookFormDomainService, // private authService: AuthService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(context: RendererContext): DfFormFieldConfig[] {
    const state = context.state();

    return [
      this.formBuilder
        .customField('title', '', context.templates.coachInfo, {
          book: state.book,
          isEditing: context.inputContext.isEditing,
        })
        .build(),
      this.inputFieldBuilder.buildSkillsField(
        context.state().authUser.viewerInterests,
        false
      ),
    ];
  }
}
