import { Injectable } from '@angular/core';
import { Input } from '@app/inputs/inputs-api.model';
import { SanitizationService } from '@app/shared/services/sanitization.service';
import { camelCaseKeys } from '@app/shared/utils/property';

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  private commentViewModel = {};
  private urlExp =
    /(\b(https?):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;()]*[\-A-Z0-9+&@#\/%=~_|()])(=~_|!:,.;)?/gi;

  constructor(private sanitization: SanitizationService) {}

  public cleanHtml(text: string): string {
    return this.sanitization.cleanHtml(text);
  }

  public setCommentViewModel(item): void {
    this.commentViewModel = item;
  }

  public getCommentViewModel(): any {
    return this.commentViewModel;
  }

  public getUrl(text: string): string {
    const match = text.match(this.urlExp);
    return match ? match[0] : undefined;
  }

  public debounce(fn, time) {
    let timeout;
    return (...args) => {
      const functionCall = () => fn.apply(this, args);
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  }

  public displayCommentsSection(
    inputComments,
    input?: Input,
    isDetailed?,
    context?,
    displayOverride?
  ): boolean {
    if (displayOverride) {
      return false;
    }
    inputComments = camelCaseKeys(inputComments);
    input = camelCaseKeys(input);
    const { inputType, contentType, resourceType } =
      input || inputComments?.model || {};
    const type = inputType || contentType || resourceType;

    let displayComments = true;
    if (inputComments?.feed?.length > 0) {
      for (const i of inputComments.feed) {
        // if at least one comment is appropriate it will show the comment section
        if (!i.isInappropriate) {
          displayComments = true;
        }
      }
    }

    // TODO: This check doesn't work when `input` is null, e.g. in input-card.component
    if (!!input?.pathwayStepDetails) {
      // We don't show comments on pathways.
      // However, the modal detail from a pathway item does show comments unless it's a Post
      if (isDetailed && type !== 'Post') {
        displayComments = true;
      } else {
        displayComments = false;
      }
    }

    if (
      context?.recommendationType?.recommendationType === 'RequiredLearning' &&
      !context.creator
    ) {
      displayComments = false;
    }

    if (type === 'Post' || type === 'Opportunity') {
      displayComments = false;
    }

    return displayComments;
  }

  public mapObjectComments(targets, comments, comparerFn): any {
    return targets.map((target) =>
      comments.map((comment) => {
        if (comparerFn(target, comment)) {
          target.comments = comment; // each is actually a comment collection
        }
      })
    );
  }
}
