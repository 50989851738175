import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { InlineInputGetter } from '@app/inputs/inputs.model';
import { CoachInput, PostInput } from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { PathwaySettingsService } from '@app/pathways/services/pathway-settings.service';
import { PathwaySummaryModel } from '@app/pathways/pathway-api.model';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { FileUploadSetting } from '@app/uploader/uploader-api.model';
import { UploaderService } from '@app/uploader/uploader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { truncate, words } from 'lodash-es';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  from,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
} from 'rxjs';
import { InputValidationService } from '../services/input-validation.service';
import { NotificationType } from '@lib/fresco';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { CoachService } from '@app/degreed-coach/services/coach.service';
import { lazySearch } from '@dg/shared-rxjs';

@Component({
  selector: 'dgx-coach-form',
  templateUrl: './coach-form.component.html',
  styleUrls: ['./coach-form.component.scss'],
  providers: [CoachService],
})
export class CoachFormComponent implements OnInit {
  @Input() public pathwayId: number;
  @Input() public inputId: number;
  @Input() public sourceTarget;
  @Input() public organizationId: number;
  @Input() public load: InlineInputGetter;
  private coachService = inject(CoachService);

  public fileTypesAllowed: string[];
  public titleValidationMessage: string = '';
  public editMessage: string = '';
  public initialBody: string = '';
  public initialTitle: string = '';
  public contentValidationMessage: string = '';
  public disableForm: boolean = false;
  public input: CoachInput;
  public clonedEditMessage: string;
  public isSubmitPending = false;
  public titleMaxLength = 255;
  public contentMaxLength = 15000;
  public contentTouched = false;
  public durationMinutesValidationError: string = null;
  public durationHoursValidationError: string = null;
  public selectedCoach: any;
  public readonly NotificationType = NotificationType;

  public i18n = this.translate.instant([
    'Core_Hours',
    'Core_Minutes',
    'Core_Preview',
    'PostFormCtrl_CreatePost',
    'PostFormCtrl_Title',
    'PostFormCtrl_TitleRequired',
    'PostFormCtrl_Content',
    'PostFormCtrl_EditPost',
    'PostFormCtrl_EditPostSave',
    'PostFormCtrl_CreatePostSave',
    'PostFormCtrl_EditingDescription',
    'PostFormCtrl_TitleLength',
    'PostFormCtrl_TitleRequired',
    'PostFormCtrl_ClonedEditMessage',
    'PostFormCtrl_AreYouSure',
    'deleteModal_Delete',
    'Core_FieldRequired',
    'Markdown_ContentLength',
    'Core_YesSure',
    'PostFormCtrl_AnotherPathway',
    'Core_Loading',
  ]);

  public postForm = new FormGroup({
    coachName: new FormControl(undefined, [
      isEmptyValidator,
      Validators.required,
      Validators.maxLength(this.titleMaxLength - 1),
    ]),
  });

  public isLoading = true;
  public search = this.searchCoach.bind(this);
  public onSelectCoach = this.coachSelect.bind(this);

  constructor(
    private inputsService: InputsService,
    private pathwaySettingsService: PathwaySettingsService,
    private translate: TranslateService,
    private detailModalService: DetailsModalService,
    private modalService: ModalService,
    private markdownService: MarkdownService,
    private trackerService: TrackerService,
    private activeModal: NgbActiveModal,
    private focusStackService: FocusStackService,
    private inputToLearningPipe: InputToLearningResourcePipe,
    private uploadService: UploaderService,
    private inputValidationService: InputValidationService
  ) {}

  public get coachName() {
    return this.postForm.get('coachName');
  }

  public get showTitleErrors() {
    return (
      this.coachName.errors && (this.coachName.dirty || this.coachName.touched)
    );
  }

  public get titleErrorMsg() {
    return 'Coach Name is required';
  }

  // TODO: Remove after ngx migration of postForm

  public get isSubmitDisabled() {
    return !this.postForm.valid || this.disableForm;
  }

  public get editing() {
    return !!this.inputId;
  }

  public get header() {
    return this.editing ? 'Edit Coach' : 'Add Coach';
  }

  public ngOnInit(): void {
    // Feature flags only allow orgs that have opted in to edit
    this.disableForm = !this.inputsService.showCustomPostType();

    if (!this.disableForm && this.editing) {
      this.load(this.inputId, 'Post').subscribe((response: any) => {
        this.initEditForm(response);
        this.isLoading = false;
      });
    } else {
      // Initialize values for new posts
      this.input = {
        ...this.input,
        inputId: -1,
        inputType: 'Post',
        title: '',
        description: '',
        details: {
          body: '',
          originalPathwayId: this.pathwayId,
        },
        imageUrl: '',
        organizationId: this.organizationId,
      };
      this.isLoading = false;
    }

    this.uploadService
      .getUploadLimit('image')
      .subscribe((response: FileUploadSetting) => {
        this.fileTypesAllowed = response.allowedFileTypes;
      });
  }

  public disable() {
    this.disableForm = true;
  }

  public enable() {
    this.disableForm = false;
  }

  public isClonedPost(): boolean {
    return (
      this.editing &&
      this.input.details &&
      this.input.details.originalPathwayId &&
      this.pathwayId &&
      this.input.details.originalPathwayId !== this.pathwayId
    );
  }

  public preview() {
    // overwrite description in case user tried submitting unsuccessfuly
    // otherwise the stripped version gets displayed in the preview
    const previewInput = {
      ...this.input,
      title: this.coachName.value,
      description: '',
      resourceType: 'Coach',
      resourceId: this.inputId,
      details: { ...this.input.details, body: this.selectedCoach },
    };

    // TODO: ideally we create a Post component instead of rehashing the input details for posts
    const learningResource = this.inputToLearningPipe.transform(previewInput);
    this.detailModalService.openDetails(learningResource, {
      preview: true,
    });
  }

  // TODO: Remove after ngx migration of postForm
  public onContentChange(content: string) {
    this.contentTouched = true;
  }

  public confirmationModal() {
    // Show confirmation modal on-dismiss if content was updated
    if (!this.coachName.dirty) {
      this.activeModal.dismiss();
      return;
    }

    const inputs = {
      canCancel: true,
      bodyClasses: 'h3 center-text',
      bodyText: this.i18n.PostFormCtrl_AreYouSure,
      submitButtonText: this.i18n.Core_YesSure,
    };
    this.focusStackService.push(this.sourceTarget);
    return this.modalService
      .show<any>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: false,
      })
      .subscribe({
        next: () => {
          this.activeModal.dismiss();
        },
      });
  }

  public onSubmit() {
    this.input.Title = this.selectedCoach.title; // Get form value
    this.input.Description = this.selectedCoach.shortDescription; // Get form value
    this.input.Url = '/assistant/chat/' + this.selectedCoach.coachId;
    this.input.InputType = 'Coach';
    this.input.title = this.selectedCoach.title;
    this.input.description = this.selectedCoach.shortDescription;
    const scraperMaxChars = 2000; // match this with scraper's max
    this.isSubmitPending = true;
    this.input.imageUrl = this.selectedCoach.avatar;

    from(of(this.input)).subscribe({
      next: () => {
        this.activeModal.close(this.input);

        const postLocation = this.editing
          ? 'Edit Pathway Post Modal'
          : 'Create Pathway Post Modal';

        this.trackerService.trackEventData({
          action: 'Create Pathway Post Saved',
          properties: {
            Location: postLocation,
          },
        });
      },
      error: () => {
        this.isSubmitPending = false;
      },
    });
  }

  // Creates an alert message that Post can not be edited with link back to parent Post.
  public getClonedEditMessage() {
    this.pathwaySettingsService
      .getPathSummary(this.input.details.originalPathwayId)
      .subscribe((response: PathwaySummaryModel) => {
        let pathwayTitle: string;
        if (response.title && response.internalUrl) {
          pathwayTitle = `<a href="${response.internalUrl}">${response.title}</a>`;
        } else {
          pathwayTitle = this.i18n.PostFormCtrl_AnotherPathway;
        }
        this.clonedEditMessage = this.translate.instant(
          'PostFormCtrl_ClonedEditMessage',
          {
            pathwayName: pathwayTitle,
          }
        );
      });
  }

  public get postFormValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const durationHours = form.controls['durationHours']?.value;
      const durationMinutes = form.controls['durationMinutes']?.value;
      const durationHoursAndMinutes: {
        durationHoursValidationError: string;
        durationMinutesValidationError: string;
      } = this.inputValidationService.getDurationValidationErrors(
        durationHours,
        durationMinutes
      );

      this.durationHoursValidationError =
        durationHoursAndMinutes.durationHoursValidationError;
      this.durationMinutesValidationError =
        durationHoursAndMinutes.durationMinutesValidationError;

      const durationHoursError = this.durationHoursValidationError
        ? {
            durationHoursValidationError: this.durationHoursValidationError,
          }
        : null;
      const durationMinutesError = this.durationMinutesValidationError
        ? {
            durationMinutesValidationError: this.durationMinutesValidationError,
          }
        : null;

      form.controls['durationHours'].setErrors(durationHoursError);
      form.controls['durationMinutes'].setErrors(durationMinutesError);

      return null;
    };
  }

  private initEditForm(_input) {
    this.input = _input;
    this.initialBody =
      _input.details && _input.details.body ? _input.details.body : '';
    this.initialTitle = _input.coachName ? _input.coachName : '';

    this.coachName.setValue(this.initialTitle);

    // Check if pathway is cloned as Post of cloned pathways can not be edited.
    if (this.isClonedPost()) {
      this.disableForm = true;
      this.getClonedEditMessage();
    } else {
      // Default edit message
      this.editMessage = this.i18n.PostFormCtrl_EditingDescription;
    }
  }

  public searchCoach(term: Observable<string>): any {
    return term.pipe(
      lazySearch((t) =>
        this.coachService.getCoachList({ searchTitle: t }).pipe(
          map((coaches) =>
            coaches.map((coach: any) => ({
              title: coach.coachName,
              imageUrl: coach.avatar,
              subtitle: coach.shortDescription,
              description: coach.coachDescription,
              ...coach,
            }))
          )
        )
      )
    );
  }

  public formatBookResult(item) {
    return item.title;
  }

  public coachSelect(item) {
    this.selectedCoach = item;
  }
}
