import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { A11yService } from '@app/shared/services/a11y.service';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * For use with Angular Router and the TagNavigationService.
 *
 * @example
 * ```
 * <dgx-tab-navigation
 *   [tabList]="tabs"
 *   wrapperClasses="guts-p-t-0"
 * ></dgx-tab-navigation>
 * ```
 */
@Component({
  selector: 'dgx-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavigationComponent
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit
{
  // input bindings
  @Input() public tabList: TabNavigationItem[];
  @Input() public updateA11yTitle = true;
  @Input() public wrapperClasses: string;
  @Input() public queryParamsHandling: 'merge' | 'preserve';
  @Input() public underlineSelectedTabOnly = false;

  /** Note this emits the translated tab `label`, so any conditional logic will need to match the translated value */
  @Output() public tabSwitch = new EventEmitter<string>();

  @ViewChild('scrollElement', { static: true })
  public scrollElement!: ElementRef;
  @ViewChildren('rla', { read: ElementRef })
  public tabListElementsQL!: QueryList<ElementRef>;
  // local bindings
  public i18n = this.translate.instant(['A11y_SecondaryPagesNavType']);
  public showLeftArrow = false;
  public showRightArrow = false;
  public tabListElements: ElementRef[] = [];
  // local (private) bindings
  private arrowWidth = 5;

  constructor(
    private a11yService: A11yService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => this.checkShowArrows());
    fromEvent(this.scrollElement.nativeElement, 'scroll')
      .pipe(debounceTime(50), this.takeUntilDestroyed())
      .subscribe((_) => this.checkShowArrows());
  }

  public ngAfterViewInit(): void {
    // Create a real array from the tabListElementsQL.
    // (AfterViewInit is where ViewChild/Chilren are populated.)
    this.tabListElements = this.tabListElementsQL.toArray();
    // Check whether to show arrows immediately.
    this.checkShowArrows();
  }

  /**
   * Calculates position of scroll element in relation to viewport,
   * and determines whether to show our left and/or right navigation
   * arrows.
   */
  public checkShowArrows(): void {
    // Calculate the outer bounds of the scrolling element, relative to its viewport.
    const scrollElementBoundaries =
      this.scrollElement.nativeElement.getBoundingClientRect();
    // Determine whether to show the left button
    this.showLeftArrow =
      this.tabListElements[0].nativeElement.getBoundingClientRect().left +
        this.arrowWidth <
      scrollElementBoundaries.left;
    // Determine whether to show the right button
    this.showRightArrow =
      this.tabListElements[
        this.tabListElements.length - 1
      ].nativeElement.getBoundingClientRect().right -
        this.arrowWidth >
      scrollElementBoundaries.right;
    // Explicitly check the view for updates
    this.cdr.markForCheck();
  }

  /**
   * When tab is switched, use a11yService to announce it.
   */
  public onTabSwitch(event: Event, tabTitle: string): void {
    this.tabSwitch.emit(tabTitle);

    this.a11yService.announcePolite(
      this.translate.instant('A11y_TabChangeAnnouncement', {
        newPageTitle: tabTitle,
      })
    );

    event.stopPropagation();
  }

  /**
   * when the arrow is clicked, scroll the next hidden tab into view
   * @param e Event
   * @param direction 'left'|'right'
   */
  public scrollTabs({ target }: Event, direction: 'left' | 'right'): void {
    // since the arrows cover up the sides of the parent div, this is the border you need to scroll past
    const arrow = (target as HTMLElement).getBoundingClientRect();
    // cycle through tabs to find the first one in view
    const tabs =
      direction === 'left'
        ? this.tabListElements
        : Array.from(this.tabListElements).reverse();
    let prev: ElementRef;
    for (const tab of tabs) {
      // find the first tab that's showing, then scroll the prev tab into view
      if (
        (direction === 'left' &&
          tab.nativeElement.getBoundingClientRect()[direction] >
            arrow[direction]) ||
        (direction === 'right' &&
          tab.nativeElement.getBoundingClientRect()[direction] <
            arrow[direction])
      ) {
        // the 2x is meant to scroll a little past the tab to show a preview of the next one
        this.scrollElement.nativeElement.scrollTo({
          top: 0,
          left:
            this.scrollElement.nativeElement.scrollLeft +
            2 *
              (prev?.nativeElement?.getBoundingClientRect()[direction] -
                arrow[direction]),
          behavior: 'smooth',
        });
        break;
      } else {
        prev = tab;
      }
    }
  }
}
