/**
 * Build out a filter config object for the `searchFilters.set` method.
 *
 * @param filter - The original filter.
 * @param filterConfig - An optional configuration object. All properties have default values.
 */
import {
  SearchFacet,
  SearchFacetValue,
  SearchFilterConfig,
  SearchFilterFilter,
} from '@app/search';
import {
  identity as _identity,
  map as _map,
  pickBy as _pickBy,
} from 'lodash-es';
import { SearchFilterId } from '@app/search/components/search-filters/search-filters.component';
import { getDeepCopy } from '@app/shared/utils';

export const buildFilterConfig = (
  filter: SearchFilterFilter,
  // typed as any to allow all these values to fall through
  // to their default values
  {
    id = '',
    title = '',
    checkboxFilterId = '',
    isCheckboxFilter = false,
    isFilterCollection = false,
    organizationName = '',
    filterGroup = '',
    isRangeFilter = false,
    isMinFilter = false,
    isMaxFilter = false,
  }: Partial<SearchFilterConfig> = {}
): SearchFilterConfig => {
  // return an object with only truthy values
  // false, empty strings, and null values will be stripped
  return _pickBy(
    {
      // required fields
      id: id ? id : filter.title,
      title: title ? title : `Core_${filter?.title?.replace(/\s+/g, '')}`,
      filters: [filter],
      // optional fields
      checkboxFilterId,
      isCheckboxFilter,
      isFilterCollection,
      organizationName,
      filterGroup,
      isRangeFilter,
      isMinFilter,
      isMaxFilter,
    },
    _identity
  ) as SearchFilterConfig;
};

/**
 * Create/update search filter configs
 *
 * @param facetId id of facet you want to create/update a config for
 * @param facets all facets
 * @param configPatch patch object
 * @param configs optionally provide existing config array to patch
 * @returns config array
 */
export const patchFacetConfig = (
  facetId: SearchFilterId,
  facets: SearchFacet[],
  configPatch: Partial<SearchFilterConfig>,
  configs: Partial<SearchFilterConfig>[] = []
): Partial<SearchFilterConfig>[] => {
  facets.forEach((facet, index) => {
    configs = getDeepCopy(configs);
    if (!configs[index]) {
      configs[index] = {};
    }
    if (facet.id === facetId) {
      configs[index] = {
        ...configs[index],
        ...configPatch,
      };
    }
  });

  return configs;
};

/**
 * Coerce facets into filters for use with our search filters.
 *
 * @param facets - An array of facets received from the back-end.
 * @param notSpecifiedId - A string to use for empty facets
 * @param notSpecifiedValue - A value to use for empty facets
 * @param config - An optional array of config objects. Will ultimately be `SearchFilterConfig`
 * type, after buildFilterConfig has been called.
 */
export const facetsToFilters = (
  facets: SearchFacet[],
  notSpecifiedId: string,
  notSpecifiedValue: string,
  config: any[] = []
): SearchFilterFilter[] => {
  return _map(facets, (facet: SearchFacet, index: number) => {
    const options = config[index]?.showEmptyOptions
      ? facet.values
      : facet.values?.filter((opt) => opt.count > 0);
    const subitems = options?.map((value: SearchFacetValue) => {
      // If empty facets are part of the result, catch them
      if (!value.id) {
        value.id = notSpecifiedId;
        // Translate the name, since it is what is used for display
        value.name = notSpecifiedValue;
      }
      return {
        title: value.name,
        model: value,
        isSelected: value.filter,
      };
    });
    const filter = {
      title: facet.name,
      model: facet,
      subitems,
    };
    return {
      ...filter,
      config: buildFilterConfig(filter, config[index]),
    };
  });
};

/**
 * Check if any filters have options with a count greater than 0
 *
 * @param filters - An array of filters.
 */
export const hasFiltersWithResults = (
  filters: SearchFilterFilter[]
): boolean => {
  return !!filters?.some((filter) =>
    filter.subitems?.some((subitem) => subitem.model.count > 0)
  );
};
