import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import {
  MentionListItem,
  ValidSearchTerm,
} from '@app/comments/models/comment.model';
import { MentionUserMatch } from '@app/comments/models/comment-api.model';
import { MentionService } from '@app/comments/services/mention.service';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import { OrgService } from '@app/orgs/services/org.service';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SubscriberBaseDirective } from '../components/subscriber-base/subscriber-base.directive';
import { isKey, Key } from '../key';
import { AuthService } from '../services/auth.service';
import { ContentEditableService } from '../services/content-editable.service';

/**
 * This directive is used to delay the rendering of an element until it scrolls into view.
 * Confluence: https://degreedjira.atlassian.net/wiki/spaces/TechDocs/pages/4868800602/Mention+a+user+directive
 * on KeyUp check if element includes '@'
 * if comment includes '@'
 * - read all values after '@' and capture it as a searchTerm, later pass the searchTerm
 *   into the getMentionUserMatches(searchTerm) function
 * - The getMentionUserMatches(searchTerm)
 *   creates a new nativeElement of list items
 * - attach the newElement of list items onto the element when searchTerm
 *   is !='' and list is greater than 0
 * - selectMentionedUser invoked
 *   *replaces the current comment, with a new comment that includes the <a>name</a>
 *
 * @example
 *   <div
 *     dgxMention
 *     [mentionEvent]="mentionEvent"
 *   </div>"
 */
@Directive({
  selector: '[dgxMention]',
  host: {
    id: 'textarea-for-mentions',
    class: 'textarea-for-mentions',
    tabindex: '0',
  },
})
export class MentionDirective extends SubscriberBaseDirective {
  @Input() public mentionEvent: any;
  @Input() public groupId?: number;
  @Input() public groupPrivacy?: GroupPrivacyLevel;
  @Output() public createMentionsFormat = new EventEmitter<any>();

  public mentions: MentionUserMatch[] = [];
  public searchTerm: string;
  public comment: string;
  public distanceApart: string;
  public orgId: number;

  constructor(
    private contentEditableService: ContentEditableService,
    private el: ElementRef,
    private renderer: Renderer2,
    private mentionService: MentionService,
    private orgService: OrgService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  public ngOnInit(): void {
    this.orgId = this.authService.authUser.orgInfo[0].organizationId;
    this.validateDirectiveDependencies();
  }

  @HostListener('click', ['$event.target.className', '$event.target.id'])
  public selectMentionedUser(className: string, id: any) {
    if (this.isClassName(className)) {
      const mention: MentionUserMatch = this.mentions.find(
        (m: any) => m.userProfileKey.toString() === id.toString()
      );

      if (mention) {
        const aTag = this.mentionService.createUserMentionFormatLink(mention);
        const newComment = this.comment.replace(
          `@${this.searchTerm}`,
          `${aTag}`
        );
        this.renderer.setProperty(
          this.el.nativeElement,
          'innerHTML',
          newComment
        );
      }
      this.getNewComment(this.mentionEvent);
      this.clearListItems();
    }
  }

  @HostListener('keyup', ['$event']) public searchUser(e: any) {
    this.comment = e.target.innerHTML;
    const isValidSearchTerm = this.isValidSearchTerm(this.comment, e);
    if (!isValidSearchTerm.isLetterOnly) {
      return true;
    }
    this.searchTerm = isValidSearchTerm.searchTerm;
    if (this.invalidSearchTerm(this.searchTerm) || this.comment.endsWith('@')) {
      this.clearListItems();
    } else if (
      isValidSearchTerm.check &&
      this.searchTerm !== '' &&
      this.searchTerm.length > 1
    ) {
      of(this.searchTerm)
        .pipe(
          distinctUntilChanged(),
          switchMap((searchTerm) => this.getMentionUserMatches(searchTerm)),
          finalize(() => this.initializeMentionsForKeyboardEvents()),
          this.takeUntilDestroyed()
        )
        .subscribe();
    }
  }

  public getMentionUserMatches(searchTerm: string): Observable<any> {
    this.clearListItems();
    const listItems = this.renderer.createElement('ul');
    this.renderer.addClass(listItems, 'mention-list');
    this.renderer.setAttribute(listItems, 'id', 'mention-list');
    const span = this.renderer.createElement('span');
    this.renderer.setAttribute(span, 'id', 'mention-options');
    const groupId =
      this.groupPrivacy === GroupPrivacyLevel.Open ? undefined : this.groupId;
    return this.orgService
      .getMentionUserMatches(
        this.orgId,
        searchTerm.trim(),
        5,
        0, // the original code was false, but the property skip is defined as integer by the API
        groupId
      )
      .pipe(
        map((resp: any) => resp.items),
        tap((mentions: MentionUserMatch[]) => {
          if (mentions.length > 0) {
            this.mentions = mentions;
            for (const mention of mentions) {
              // list item
              const listItem = this.renderer.createElement('li');
              this.renderer.addClass(listItem, 'mention-list-item');
              this.renderer.addClass(listItem, 'border-bottom');
              this.renderer.addClass(listItem, 'guts-p-v-half');
              this.renderer.addClass(listItem, 'guts-p-h-1');
              this.renderer.setAttribute(
                listItem,
                'id',
                mention.userProfileKey.toString()
              );

              // for flexbar or row
              const listItemDiv = this.renderer.createElement('div');
              this.renderer.addClass(listItemDiv, 'l_flexbar');
              this.renderer.setAttribute(
                listItemDiv,
                'id',
                mention.userProfileKey.toString()
              );

              // guts for image div col3
              const picItemDiv = this.renderer.createElement('div');
              this.renderer.setStyle(picItemDiv, 'width', '3rem');
              this.renderer.setStyle(picItemDiv, 'height', '3rem');

              const imgItemDiv = this.renderer.createElement('div');
              this.renderer.addClass(imgItemDiv, 'guts-m-r-1');
              this.renderer.addClass(imgItemDiv, 'profile-pic');
              this.renderer.addClass(imgItemDiv, 'profile-pic--fallback');

              // name div col9
              const nameItemDiv = this.renderer.createElement('div');
              this.renderer.addClass(nameItemDiv, 'no-wrap');
              this.renderer.addClass(nameItemDiv, 'font-semibold');
              this.renderer.addClass(nameItemDiv, 'par');
              this.renderer.addClass(nameItemDiv, 'par--small');
              this.renderer.setAttribute(
                nameItemDiv,
                'id',
                mention.userProfileKey.toString()
              );

              const img = this.renderer.createElement('img');
              this.renderer.setProperty(img, 'src', mention.picture);
              this.renderer.addClass(img, 'profile-pic__image');
              this.renderer.addClass(img, 'small');
              this.renderer.setAttribute(
                img,
                'id',
                mention.userProfileKey.toString()
              );

              const name = this.renderer.createText(mention.name);

              this.renderer.appendChild(picItemDiv, img);
              this.renderer.appendChild(imgItemDiv, picItemDiv);
              this.renderer.appendChild(listItemDiv, imgItemDiv);
              this.renderer.appendChild(nameItemDiv, name);
              this.renderer.appendChild(listItemDiv, nameItemDiv);
              this.renderer.appendChild(listItem, listItemDiv);
              this.renderer.appendChild(listItems, listItem);
            }
            if (this.document.getElementById('mention-options') === null) {
              this.renderer.appendChild(span, listItems);
              this.renderer.appendChild(this.el.nativeElement, span);
              this.positionMentionItems();
              this.renderer.setStyle(
                listItems,
                'left',
                `${this.distanceApart}px`
              );
              // select first list item and add class 'active'
              const liSelected = this.document
                .getElementById('mention-list')
                .getElementsByTagName('li')[0];
              this.addClass(liSelected, 'active');
              // add the ability to click select the first onload
              this.renderer.listen(
                this.el.nativeElement,
                'keydown',
                (event) => {
                  if (event.key === 'Enter') {
                    const selectedListItem: MentionListItem = {
                      el: liSelected,
                      classes: ['active'],
                      index: 0,
                    };
                    this.selectEnter(selectedListItem, event);
                  }
                }
              );
            }
          }
        })
      );
  }

  // once the element for mentions is displayed select a user by keyboard events
  public initializeMentionsForKeyboardEvents() {
    const ul = this.document.getElementById('mention-list');
    let liSelected;
    let index = -1;
    let selectedListItem: MentionListItem;
    this.renderer.listen(this.el.nativeElement, 'keydown', (event) => {
      const len = ul?.getElementsByTagName('li').length - 1;
      // Up Arrow selected
      if (isKey(event, Key.Up)) {
        event.preventDefault();
        if (liSelected) {
          index--;
          this.removeClass(liSelected, 'active');
          const next = ul.getElementsByTagName('li')[index];
          if (typeof next !== undefined && index >= 0) {
            liSelected = next;
          } else {
            index = len;
            liSelected = ul.getElementsByTagName('li')[len];
          }
          selectedListItem = {
            el: liSelected,
            classes: ['active'],
            index,
          };
          this.addClass(liSelected, 'active');
        } else {
          index = 0;
          liSelected = ul?.getElementsByTagName('li')[len];
          selectedListItem = {
            el: liSelected,
            classes: ['active'],
            index,
          };
          this.addClass(liSelected, 'active');
        }
      }
      // Down Arrow selected
      else if (isKey(event, Key.Down)) {
        event.preventDefault();
        index++;
        if (liSelected) {
          this.removeClass(liSelected, 'active');
          const next = ul.getElementsByTagName('li')[index];
          if (typeof next !== undefined && index <= len) {
            liSelected = next;
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName('li')[0];
          }
          selectedListItem = {
            el: liSelected,
            classes: ['active'],
            index,
          };
          this.addClass(liSelected, 'active');
        } else {
          index = 0;
          liSelected = ul?.getElementsByTagName('li')[0];
          selectedListItem = {
            el: liSelected,
            classes: ['active'],
            index,
          };
          this.addClass(liSelected, 'active');
        }
      }
      // Enter selected
      else if (isKey(event, Key.Enter)) {
        this.selectEnter(selectedListItem, event);
      }
    });
  }

  public removeClass(el, className) {
    if (el?.classList) {
      el.classList.remove(className);
    }
  }

  public addClass(el, className) {
    if (el?.classList) {
      el.classList.add(className);
    }
  }

  // remove the mention-options dropdown when invoked
  public clearListItems() {
    Array.from(this.el.nativeElement.children).forEach((child: any) => {
      if (child.id === 'mention-options') {
        this.renderer.removeChild(this.el.nativeElement, child);
      }
    });
  }

  // check is comment is a valid search item
  // check if @A-Z and only return searchTerm if true
  // set last instance of userMention
  // comment must include @ or return ''
  public isValidSearchTerm(comment, e?: any): ValidSearchTerm {
    const atSymbolLedChar = comment.lastIndexOf('@') + 1;
    const instancesOfAtIndexOf = (comment.match(/@/g) || []).length;
    if (comment.includes('<span')) {
      comment = comment.replace(/<span.*span>/, '');
    }
    const searchTerm = comment.includes('@')
      ? comment
          .split('@')
          [instancesOfAtIndexOf].split(' ')
          .slice(0, 2)
          .join(' ')
          .trim()
      : '';
    const check =
      comment.includes('@') &&
      comment.charAt(atSymbolLedChar).toLowerCase() !==
        comment.charAt(atSymbolLedChar).toUpperCase();
    const isLetterOnly = this.isLetterOnly(e);
    return {
      check,
      searchTerm,
      isLetterOnly,
    };
  }

  // determines the distance of the textarea and the @symbol
  public positionMentionItems() {
    this.distanceApart = (
      this.document.getElementById('mention-options').getBoundingClientRect()
        .left -
      this.document
        .getElementById('textarea-for-mentions')
        .getBoundingClientRect().left
    ).toString();
  }

  // if search term includes or equal invalid characters return false
  public invalidSearchTerm(searchTerm: string): boolean {
    const opts =
      searchTerm === undefined ||
      searchTerm === '' ||
      searchTerm.includes('id="mention-options"') ||
      searchTerm.includes('<span') ||
      searchTerm.includes('<ul');
    return opts;
  }

  // if className of list-item is valid
  public isClassName(className: string): boolean {
    return (
      className.includes('mention-list-item') ||
      className.includes('profile-pic__image') ||
      className.includes('par') ||
      className.includes('l_flexbar')
    );
  }

  // take keyboard event and replace comment with newComment
  public getNewComment(event: KeyboardEvent) {
    event.preventDefault();
    const innerHtml = (event.target as HTMLInputElement).innerHTML;
    (event.target as HTMLInputElement).innerHTML = innerHtml.replace(
      this.comment,
      this.comment
    );
    this.contentEditableService.setCaretToEnd(event.target);
  }

  // should return true if keyboard event is only A-Z/a-z
  public isLetterOnly(e: any): boolean {
    return (
      (e?.keyCode > 64 && e?.keyCode < 91) ||
      (e?.keyCode > 96 && e?.keyCode < 123) ||
      e?.keyCode === 8
    );
  }

  // when pressing Enter add mention if no mentions is presented comment; else: submit form
  public selectEnter(selectedListItem: MentionListItem, event: any) {
    if (this.document.getElementById('mention-options') === null) {
      if (selectedListItem) {
        this.selectMentionedUser('mention-list-item', selectedListItem.el?.id);
      }
    } else {
      if (selectedListItem) {
        this.selectMentionedUser('mention-list-item', selectedListItem.el?.id);
      }
      event.preventDefault();
    }
  }
  // validate if the directive required variables are included on the element
  public validateDirectiveDependencies() {
    if (this.orgId === undefined) {
      throw new Error(`[dgxMention] : requires 'orgId'`);
    }
    if (!this.hasOwnProperty('mentionEvent')) {
      throw new Error(`[dgxMention] : requires @Input() of 'mentionEvent'`);
    }
  }
}
