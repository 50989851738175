import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';

import { useApolloLayout } from '@degreed/apollo-angular';

import {
  ResourceType,
  TargetSuggestionType,
  TargetType,
} from '@app/shared/models/core-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { TargetsService } from '@app/shared/services/targets.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { WindowToken } from '@app/shared/window.token';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { Target } from '@app/target/target-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize } from 'rxjs/operators';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { ComboboxOption } from '@app/shared/components/combobox/combobox.model';
import { FlexService } from '@app/flex-framework/services/flex-framework.service';
import { HtmlTranslatedPipe } from '@app/shared/pipes/html-translated.pipe';
import { HtmlPipe } from '@app/shared/pipes/html.pipe';
import { SafeHtml } from '@angular/platform-browser';
import { FlexRowSummary } from '@app/flex-framework/flex-api.model';
import { NotificationType } from '@lib/fresco';

interface targetOption extends ComboboxOption {
  name?: string;
  node?: string;
}

@Component({
  selector: 'dgx-add-to-target-modal',
  templateUrl: './add-to-target-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToTargetModalComponent implements OnInit {
  // inputs
  @Input() public type: TargetType | TargetSuggestionType;
  @Input() public resource;

  public readonly NotificationType = NotificationType;

  // local
  public i18n = this.translate.instant([
    'addToTargetModal_AddedSuccessfully',
    'addToTargetModal_AddingError',
    'addToTargetModal_AddItemDescription',
    'addToTargetModal_AddToTarget',
    'addToTargetModal_CreateJobRoleExplanation',
    'addToTargetModal_CreateTarget',
    'addToTargetModal_CreateYourTarget',
    'Core_Done',
    'addToTargetModal_EditTarget',
    'addToTargetModal_MostRecentTargets',
    'addToTargetModal_SearchAuthoringTarget',
    'addToTargetModal_SearchYourTargets',
    'addToTargetModal_VisitTargetEditPage',
    'addToTargetModal_WhichTarget',
    'addToTargetModal_YourTargets',
    'Core_SkillPlan',
    'addToTargetModal_SelectTargetPlaceHolder',
    'addToTargetModal_Section',
    'addToTargetModal_SelectSectionPlaceHolder',
    'addToTargetModal_NoSectionsFound',
    'addToTargetModal_GoToTarget',
    'Core_NoResultsFound',
  ]);
  public isAdding = false;
  public isAdded = false;
  public selectedTarget: Target;
  public selectedSectionOption: targetOption;
  public resourceAsTarget: any;
  public targets: Target[] = [];
  public typeDisplayName: string;
  public typeDisplayNamePlural: string;
  public user = this.authService.authUser;
  public organizationId: number;
  public resourceType: string;
  public targetOptions: targetOption[];
  public sectionOptions: targetOption[] = [];
  public canSubmit = false;
  public isSubmitting = false;
  public loadingTargets = true;
  public loadingSections = true;

  public isLoading = true;

  private orgManagedNotificationFlag: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private sharedTargetService: SharedTargetService,
    private targetsService: TargetsService,
    private translate: TranslateService,
    private windowLayoutService: WindowLayoutService,
    private ldFlagService: LDFlagsService,
    private flexService: FlexService,
    private htmlTranslatedPipe: HtmlTranslatedPipe,
    private htmlPipe: HtmlPipe,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.canSubmit = false;

    this.orgManagedNotificationFlag = useApolloLayout();
  }
  public get isModalLoading(): boolean {
    if (this.isAdding) {
      return true;
    }

    // otherwise, fall back to the default ('Cancel')
    return this.isLoading;
  }

  public get successfullAddText(): string | SafeHtml {
    const title = this.resource.name || this.resource.title;
    if (title) {
      return this.htmlTranslatedPipe.transform(
        'addToTargetModal_AddedItemSuccessfully',
        {
          resourceTitleHtml: this.htmlPipe
            .transform`<span class="font-semibold">${title}</span>`,
          targetTitle: this.selectedTarget.title,
        }
      );
    }

    return this.i18n.addToTargetModal_AddedSuccessfully;
  }

  public get cancelButtonText(): string {
    if (this.isAdded) {
      return this.i18n.Core_Done;
    }
    // otherwise, fall back to the default ('Cancel')
    return undefined;
  }

  public get submitButtonText(): string {
    if (!this.targets.length) {
      return this.i18n.addToTargetModal_CreateTarget;
    }

    if (this.isAdded) {
      return this.i18n.addToTargetModal_GoToTarget;
    }

    // provide submit button text so that the content can be added to the plan and section
    return this.i18n.addToTargetModal_AddToTarget;
  }

  public get showOrgManagedNotification() {
    return (
      this.orgManagedNotificationFlag &&
      this.resource?.organizationId &&
      !this.selectedTarget
    );
  }

  public ngOnInit(): void {
    // populate the organizationId and type properties
    this.organizationId =
      this.resource.organizationId ?? this.user.defaultOrgId;
    this.resourceType = this.resource.resourceType ?? this.type;

    // set the type display names
    this.typeDisplayName = this.sharedTargetService.getTypeDisplayName(
      this.type
    );
    this.typeDisplayNamePlural = this.sharedTargetService.getTypeDisplayName(
      this.type,
      true
    );
    // get targets
    this.loadTargets();
  }

  /**
   * Dismiss the current modal.
   */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /**
   * Close the current modal.
   */
  public close(): void {
    this.activeModal.close();
  }

  /**
   * Create a new target? How is this triggered?
   */
  public createTarget(): void {
    this.targetsService.showTargetCreateModal({
      targetDefaults: {
        targetType: 'Target',
        organizationId: this.organizationId,
      },
      initialResource: this.resource,
      showAutoPopulateOption: !this.ldFlagService.hideTargetAutoPopulate,
    });
    // close *this* modal
    this.close();
  }

  /**
   * Navigate to the edit view of a given target.
   */
  public editTarget(editMode = true): void {
    const route =
      this.selectedTarget.targetType === 'Directory' ? 'explore' : 'plan';
    const targetUrl = `/${route}/${this.selectedTarget?.targetId}/?editMode=${editMode}`;

    // open the plan, either in a new window (extension) or in the current one
    if (this.windowLayoutService.isIframe) {
      this.windowRef.open(targetUrl, '_blank');
    } else {
      this.windowRef.location.href = targetUrl;
    }
    // close the modal
    this.close();
  }

  /**
   * When a target is selected from the drop down, this will set the
   * selected target and make a request to load the associated sections
   */
  public onSelectTargetOption(selectedTargetOption: targetOption): void {
    const excludedSectionTypes = ['opportunity', 'linktext', 'freeformtext'];

    this.selectedTarget = this.targets.find(
      (target) => target.targetId === selectedTargetOption.id
    );
    // reset things for the secondary sections options and submit button
    this.sectionOptions = [];
    this.canSubmit = false;
    this.isAdded = false;
    this.loadingSections = true;
    this.selectedSectionOption = null;

    this.targetsService
      .getTargetSections(selectedTargetOption.id as number)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((sections: FlexRowSummary[]) => {
        this.sectionOptions = sections
          .filter(
            (s) =>
              excludedSectionTypes.indexOf(s.resourceType.toLowerCase()) === -1
          )
          .map((s) => {
            return {
              name: s.name || this.flexService.getSectionName(s),
              id: s.targetId,
              node: s.parentNode,
            } as targetOption;
          });

        this.loadingSections = false;
        // if no sections, mark as valid which will display info text to the user
        // indicating that a new Mixed Resources section will be created for them
        if (!this.sectionOptions || this.sectionOptions.length < 1) {
          this.canSubmit = true;
        }
      });
  }

  /**
   * When a targets section is selected save it and prepare for submit()
   */
  public onSelectSectionOption(selectedSectionOption: targetOption): void {
    this.selectedSectionOption = selectedSectionOption;
    this.canSubmit = true;
    this.cdr.markForCheck();
  }

  /**
   * When a search selection is clicked.
   *
   * @param titleSuggestion - The title suggestion returned by plan-title-search.
   */
  public onSelectTarget({ targetId }: Target): void {
    // get the matching target from our targets array
    this.selectTarget(
      this.targets.find((target) => target.targetId === targetId)
    );
  }

  /**
   * Select a given target.
   *
   * @param target - The selected target.
   */
  public selectTarget(target: Target, sectionNode: string = null): void {
    // sanity check
    if (!target) {
      return;
    }
    // set loading state
    this.isAdding = true;
    // set target to selected
    this.selectedTarget = target;
    // add resource to selected target
    this.targetsService
      .addTargetResources(target, sectionNode, [
        {
          ...this.resource,
          referenceType: this.resourceType,
          referenceId: this.resource.resourceId,
        },
      ])
      .pipe(
        catchError((err) => {
          // Close the modal.
          this.cancel();
          // Returning the error here will throw it?
          return err;
        }),
        finalize(() => {
          this.isAdding = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(() => {
        this.isAdded = true;
        this.canSubmit = true;
      });
  }

  /**
   * Either create a target or edit an existing target.
   *
   * @param event - The submit event.
   */
  public submit(event: Event): void {
    // prevent default
    event.preventDefault();

    // if there are targets...
    if (this.targets.length) {
      if (this.isAdded) {
        return this.editTarget(false);
      }

      if (!this.selectedSectionOption && this.sectionOptions?.length < 1) {
        // when no sections exist yet on the plan, add a Mixed Resources section
        this.isAdding = true;
        this.targetsService
          .addTargetRow(
            this.selectedTarget.targetId,
            null,
            null,
            '/0/',
            'Any',
            null
          )
          .subscribe((f) => {
            // now add the item to the newly section within the plan
            this.selectTarget(this.selectedTarget, f.parentNode);
          });
        return;
      }

      return this.selectTarget(
        this.selectedTarget,
        this.selectedSectionOption?.node
      );
    }
    // create a new target if no targets have been set
    return this.createTarget();
  }

  /**
   * Populate the list of targets to add to the current target.
   */
  private loadTargets(): void {
    this.targetsService
      .getAuthoredTargets()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((targets: Target[]) => {
        // keep track of the actual target details
        this.targets = targets;

        // if no targets are returned set this to true which
        // enables the submit button and allows the user to create a target
        this.canSubmit = this.targets?.length < 1;

        this.targetOptions = this.targets.map((t) => {
          return {
            name: t.name,
            id: t.targetId,
          } as targetOption;
        });

        this.loadingTargets = false;
      });
  }
}
