import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'displayType' })
export class DisplayTypePipe implements PipeTransform {
  // These should match the similar code in our server code
  private skillRatingTypes = [
    'TagCredential',
    'TagEvaluation',
    'TagSelf',
    'TagManager',
  ];

  private facetTypes = [
    'Subscription',
    'Free',
    'Paid',
    'Minutes',
    'Hours',
    'Days',
    'Weeks',
    'LessThan30Days',
    'LessThan6Months',
    'LessThanOneYear',
    'PublishDate',
    'Cost',
    'InPerson',
    'Hybrid',
    'Unknown',
    'Today',
    'Next7Days',
    'Next30Days',
    'Next60Days',
    'NextYear',
    'Duration',
    'LessThan5',
    'LessThan10',
    'LessThan30',
    'LessThan1Hour',
    'LessThan4Hours',
    'LessThan1Day',
    'GreaterThan1Day',
    'CEU',
    'CEUEligible',
    'Tag',
    'Beginner',
    'Basic',
    'Intermediate',
    'Advanced',
    'Expert',
    'Developing',
    'Proficient',
    'Online',
    'Instructor',
    'Virtual',
    'Accredited',
    'Documentary',
    'Print',
    'Electronic',
    'Audiobook',
    'Summary',
    'Future',
    'NearStart',
    'RecentStart',
    'Past',
    'CaseStudy',
    'LearningPath',
    'LiveOnlineTraining',
    'Live',
    'Text',
    'Location',
    'People',
    'Provider',
  ];

  private miscTypes = [
    'Pathway',
    'SkillPlan',
    'Target',
    'JobRole',
    'Group',
    'User',
    'Episode',
    'Recommendation',
    'Article',
    'Assessment',
    'Book',
    'Course',
    'Event',
    'Video',
    'Directory',
    'Accomplishment',
    'Award',
    'Certificate',
    'Badge',
    'Degree',
    'Self-study',
    'Workshop',
    'Coaching',
    'Journey',
    'Academy',
  ];

  // Any type included in `genericTypes` will be used for
  // generic string interpolation to create the i18n ID
  private genericTypes = [
    ...this.skillRatingTypes,
    ...this.facetTypes,
    ...this.miscTypes,
  ];
  constructor(private translate: TranslateService) {}

  public transform<T = string>(contentType: string, isPlural?: boolean): T {
    const resourceId = this.hasGenericResourceId(contentType)
      ? this.getGenericResourceId(contentType, isPlural)
      : this.getCustomResourceId(contentType, isPlural);

    return this.translate.instant(resourceId) as unknown as T;
  }

  // Check if type will work with generic interpolated i18n ID
  private hasGenericResourceId(contentType: string): boolean {
    let hasGenericI18nId = false;
    for (let i = 0, len = this.genericTypes.length; i < len; i++) {
      if (this.genericTypes[i] === contentType) {
        hasGenericI18nId = true;
        break;
      }
    }
    return hasGenericI18nId;
  }

  // Get generic interpolated i18n ID
  private getGenericResourceId(contentType: string, isPlural: boolean): string {
    return isPlural ? `Core_${contentType}sDisplayName` : `Core_${contentType}`;
  }

  // Get custom i18n id for type that doesn't work with generic interpolation
  // If you're adding a new type and it doesn't work with generic interpolation, add it here
  private getCustomResourceId(contentType: string, isPlural: boolean): string {
    let type = '';
    switch (contentType) {
      case 'Top':
        return 'LearningSearch_Top';
      case 'Opportunity':
        type = isPlural ? 'Opportunities' : contentType;
        return `Core_${type}`;
      case 'Role':
        return isPlural ? 'TargetsSvc_TypeRoles' : `Core_${contentType}`; // Plural for "Role" is "Role Plans"
      case 'Position':
        type = isPlural ? 'Experiences' : 'Experience';
        return `Core_${type}DisplayName`;
      case 'Skill':
        type = isPlural ? 'Skills' : 'Skill';
        return `Core_${type}DisplayName`; // Returns "Skill Behavior" / "Skill Behaviors"
      case 'Content':
        type = isPlural ? 'ArticlesVideos' : 'ArticleVideo';
        return `Core_${type}DisplayName`;
      default:
        return contentType;
    }
  }
}
