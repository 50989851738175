import { LocalizationService } from '@app/shared/localization.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs/operators';
import { LDFlagsService } from './shared/services/ld-flags.service';
import { configureApolloWithFeatureFlags } from '../apollo/src';
import { firstValueFrom } from 'rxjs';

const mustFetchUser = (authService: AuthService) =>
  authService.isLoggedIn && !authService.authUser;

export function initUser(authService: AuthService) {
  return () => {
    if (mustFetchUser(authService)) {
      return authService.fetchAuthenticatedUser().toPromise();
    }
  };
}

export function initAppState(
  envService: WebEnvironmentService,
  authService: AuthService,
  translate: TranslateService,
  configService: ConfigurationService,
  l10nService: LocalizationService,
  ldService: LDFlagsService
) {
  return () => {
    // Initialize web environment then dependent auth service sequentially
    return firstValueFrom(
      envService.initialize().pipe(
        switchMap(() => configService.init()),
        switchMap(() => authService.fetchAuthenticatedUser(false, true)),
        switchMap(() => initTranslations(translate, l10nService)),
        switchMap(() => ldService.initialize()),
        tap(() => {
          // Cache Apollo feature flags for router, and other uses
          // since we can't inject LDFlagsService into the router loadChildren() function
          const lookup = ldService.isEnabled.bind(ldService);
          configureApolloWithFeatureFlags(lookup);
        })
      )
    );
  };
}

/**
 * Initializes the translation service which handles the bulk of our translations
 * but does not cover the initialization of the app locale which will affect
 * certain settings like dates using pipe formatters.
 *
 * Localization is set in app.module.
 *
 * @param translate - translation service to initialize with locales
 * @param localizationService - Only used for the constants
 */
export function initTranslations(
  translate: TranslateService,
  { defaultLocale, userLocale }: LocalizationService
) {
  translate.setDefaultLang(defaultLocale);
  return translate.use(userLocale);
}
