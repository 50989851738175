import { Injectable } from '@angular/core';
import { SanitizationService } from '@app/shared/services/sanitization.service';
import { ProxyImageLinkConfig } from '@app/thumbnails/thumbnail.model';
import { ThumbnailService } from '@app/thumbnails/thumbnail.service';
import { createShowdownConverter } from '../showdown-converter';

export interface MarkdownToHtmlOptions {
  /** Removes javascript from the markdown text */
  sanitize?: boolean;
  /** Strips any initial html in the text prior to markdown to html conversion */
  stripSourceHtml?: boolean;
  /** Allows processing of MD image syntax */
  allowImages?: boolean;
  /** Substitutes proxy images in the output html via thumbnail service. Only effective when allowImages is also enabled. */
  proxyImages?: boolean;
  /** Formats outbound links to open securely in a new tab */
  openLinksInNewWindow?: boolean;

  encodeEmails?: boolean;
}

@Injectable({ providedIn: 'root' })
export class MarkdownService {
  /**
   * Matches Markdown images.
   *
   * @example
   * ![ALT text](http://my.image.url.jpg)
   * ![ALT text](http://my.image.url.jpg "Title text.")
   */
  private static readonly imageRegEx = /!\[([^[])*\]\(([^)]*)\)/g;

  constructor(
    private thumbnailService: ThumbnailService,
    private sanitizationService: SanitizationService
  ) {}

  public markdownToHtml(
    markdownText: string,
    opts: MarkdownToHtmlOptions = {}
  ) {
    if (!markdownText) {
      return;
    }
    const converter = createShowdownConverter({
      allowImages: opts.allowImages,
      openLinksInNewWindow: opts.openLinksInNewWindow,
      encodeEmails: opts.encodeEmails,
    }) as any;
    if (opts.stripSourceHtml) {
      markdownText = this.sanitizationService.removeHtml(markdownText, true);
    }
    if (opts.sanitize) {
      // TODO: Check if this is necessary when used in conjunction with stripSourceHtml
      markdownText = this.sanitizeMarkdown(markdownText);
    }

    if (opts.allowImages === false) {
      // When explicity set to false remove markdown image tag
      markdownText = markdownText.replace(MarkdownService.imageRegEx, '');
    }

    let html = converter.makeHtml(markdownText);
    if (opts.proxyImages) {
      html = this.proxyImages(html);
    }
    return html;
  }

  /**
   * Strips both markdown *and* HTML from the text.
   * TODO: Rename this method to be clearer.
   *
   * @param markdown The markdown string to strip
   * @param preserveLineBreaksAsHtml Whether or not to preserve line breaks.
   */
  public markdownToPlaintext(
    markdownText: string,
    preserveLineBreaksAsHtml = false
  ) {
    const html = this.markdownToHtml(
      // strip images first so we don't leave the image name around after strippipng
      markdownText.replace(MarkdownService.imageRegEx, '')
    );
    const plainText = this.sanitizationService.htmlToPlaintext(
      html,
      preserveLineBreaksAsHtml
    );
    return plainText;
  }

  /**
   * Returns the URL for the first image provided in the markdown if any.
   * @param markdown The markdown string to pull the image from
   */
  public getFirstImage(markdown: string) {
    let firstImage: string;
    const imageUrlRegex = '(!\\[[^\\]]*?]\\()([^\\)]+)\\)';
    const imageMatches = markdown.match(imageUrlRegex);
    if (imageMatches) {
      firstImage = imageMatches[2];
    }
    return firstImage;
  }

  private proxyImages(htmlFromMarkdown: string) {
    const imageConfig: ProxyImageLinkConfig = {
      width: '/w_1128',
      crop: ',c_lfill',
      src: '',
    };
    htmlFromMarkdown = htmlFromMarkdown.replace(
      /<img src="([^"]*)/gi,
      `<img src="${this.thumbnailService.getProxyImageLink(imageConfig)}$1`
    );
    return htmlFromMarkdown;
  }

  private sanitizeMarkdown(markdownText: string) {
    if (markdownText && markdownText.length) {
      return markdownText.replace(/\]\s*\(\s*javascript\s*:/, '](');
    }
    return markdownText;
  }
}
