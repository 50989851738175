<div class="grid guts-m-t-3">
  <div class="grid__col-md-8">
    <div *ngIf="loading">
      <dgx-skeleton type="embeddedLearning"></dgx-skeleton>
    </div>
    <div class="guts-p-h-half" *ngIf="!loading">
      <h1>{{ input.title }}</h1>
      <div *ngIf="inputType === 'Course'">
        <iframe
          class="embedded-iframe"
          dgxIframeTracker
          [src]="trustedEmbedSrc"
          allowfullscreen
          scrolling="no"
          [title]="input.title"
          data-dgat="embeddedLearning-iframe"
        ></iframe>
      </div>
      <div class="guts-m-t-1 l_flexbar par par--small par--light font-semibold">
        <span *ngIf="!isClientProvider" class="guts-m-r-1-half">{{
          input.providerSummary.name
        }}</span>
        <dgx-social-counts
          *ngIf="input.resourceId"
          [item]="input"
          [config]="{}"
          class="social-counts guts-p-r-half guts-p-b-half"
        ></dgx-social-counts>
      </div>

      <div class="guts-m-t-2 guts-m-b-2 l_flex-grow l_flexbar" *ngIf="!loading">
        <dgx-input-completion
          *ngIf="input.resourceType !== 'Pathway'"
          class="guts-m-r-1"
          [isCompleted]="input.model.isCompleted"
          [input]="input"
          (updated)="completionComponentUpdate($event)"
          size="sm"
        ></dgx-input-completion>
        <dgx-thumbs
          *ngIf="showThumbsComponent"
          class="guts-m-r-1"
          (rate)="rate($event)"
          [currentRating]="input.requestingUserRating"
          [inputTitle]="input.title"
        ></dgx-thumbs>
        <dgx-action-button
          *ngIf="authUser?.canRecommendItems"
          class="guts-m-r-1"
          (click)="takeAction('recommend', $event)"
          [autoToggleState]="false"
          iconSize="medium"
          [isIconOnly]="true"
          [tooltip]="i18n.Core_Recommend"
          dgatInput="input-detail-modal-505"
          icon="arrow-forward"
          size="sm"
        ></dgx-action-button>
        <dgx-action-button
          (click)="takeAction('toggleQueue', $event)"
          [isActive]="input.isQueued"
          iconSize="medium"
          [isIconOnly]="true"
          [tooltip]="input.isQueued ? i18n.Core_Unsave : i18n.Core_SaveForLater"
          icon="bookmark"
          size="sm"
          dgatInput="input-detail-modal-7fc"
        ></dgx-action-button>
      </div>
      <div *ngIf="inputType !== 'Course'">
        <iframe
          class="embedded-iframe"
          dgxIframeTracker
          [src]="trustedEmbedSrc"
          allowfullscreen
          scrolling="no"
          [title]="input.title"
          data-dgat="embeddedLearning-iframe"
        ></iframe>
      </div>
      <div class="guts-m-t-3">
        <dgx-comment-thread
          [resource]="{
            resourceId: input.resourceId,
            resourceType: input.resourceType,
            title: input.title
          }"
          [showAllComments]="true"
          [parentReferenceId]="ownerId"
          [userHasCommented]="input.model.userHasCommented"
          [showUserProfilePic]="true"
          context="Embedded"
        ></dgx-comment-thread>
      </div>
    </div>
  </div>
  <div class="grid__col-md-4">
    <div *ngIf="loading">
      <ul>
        <li *ngFor="let i of [1, 2, 3]" data-dgat="embeddedLearning-b65">
          <dgx-skeleton type="dashboardNav"></dgx-skeleton>
        </li>
      </ul>
    </div>
    <div *ngIf="!loading">
      <div
        *ngFor="let inputType of relatedInputTypes"
        data-dgat="embeddedLearning-563"
      >
        <dgx-related-learning
          [inputType]="inputType"
          [tags]="tags"
          [input]="inputDetails"
          [orgId]="authUser.defaultOrgId"
        ></dgx-related-learning>
      </div>
    </div>
  </div>
</div>
