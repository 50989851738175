import { Directive, HostListener, Inject, Input } from '@angular/core';
import { HtmlToPlaintextPipe } from '../pipes/htmlToPlaintext.pipe';
import { WindowToken } from '../window.token';

@Directive({
  selector: '[dgxPasteAsPlainText]',
})
export class PasteAsPlainTextDirective {
  @Input() public preserveHtmlLineBreaks: boolean = false;
  constructor(
    @Inject(WindowToken) private windowRef: Window,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe
  ) {}

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent) {
    // https://stackoverflow.com/questions/2176861/javascript-get-clipboard-data-on-paste-event-cross-browser/6804718#6804718

    // Stop data actually being pasted into div
    event.stopPropagation();
    event.preventDefault();

    // Get pasted data via clipboard API
    let clipboardData =
      event.clipboardData || (this.windowRef as any).clipboardData;
    let pastedData = clipboardData.getData('text/plain');
    let cleanData = this.htmlToPlaintextPipe.transform(pastedData);

    // now paste the stripped down text
    this.pasteHtmlAtCaret(cleanData);
  }

  private pasteHtmlAtCaret(html: string) {
    // see http://stackoverflow.com/a/6691294/441894
    let sel: Selection;
    let range: Range;
    if (this.windowRef.getSelection) {
      sel = this.windowRef.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        const el = document.createElement('div');
        el.innerHTML = html;
        let frag = document.createDocumentFragment();
        let node;
        let lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }
  }
}
