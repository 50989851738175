<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!certificateForm"
    class="reactive-forms"
    [class.input-success-kickstart]="vm.shouldShowResults$ | async"
    [formGroup]="certificateForm"
    (ngSubmit)="onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="submitButtonText"
      [canCancel]="true"
      [modalHeaderTitle]="heading"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [useDefaultSubmitButton]="false"
      [canCancel]="!(vm.shouldShowResults$ | async)"
      [bodyClasses]="{
        'guts-p-full-2': true,
        'input-success-kickstart': (vm.shouldShowResults$ | async),
        first: true,
        second: true,
        third: false
      }"
      (dismiss)="onDismiss($event)"
    >
      <ng-container class="modal-body">
        <div class="input-success-wrap">
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [placeholder]="i18n.Cred_Certificate_CertificateTitle"
              (blur)="loadInferredSkills()"
              data-dgat="certificate-modal-v2-bbf"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                certificateForm.get('title').touched &&
                certificateForm.get('title').invalid
              "
              [message]="i18n.MediaFormCtrl_TitleRequired"
            ></dgx-validate-field>
          </div>

          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="issuer" [isRequired]="true">
              {{ i18n.dgProfileCollectionItem_CertIssuedBy }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="issuer"
              formControlName="issuer"
              [placeholder]="i18n.CertificateFormCtrl_IssuerName"
              (blur)="loadInferredSkills()"
              data-dgat="certificate-modal-v2-0f9"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                certificateForm.get('issuer').touched &&
                certificateForm.get('issuer').invalid
              "
              [message]="i18n.CertificateFormCtrl_IssuerNameRequired"
            ></dgx-validate-field>
          </div>

          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="issuerUrl">
              {{ i18n.CertificateFormCtrl_IssuerURL }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="issuerUrl"
              formControlName="issuerUrl"
              placeholder="https://"
              data-dgat="certificate-modal-v2-a5e"
            />
          </div>

          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="certNumber">
              {{ i18n.CertificateFormCtrl_CertificateNumber }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="certNumber"
              formControlName="certNumber"
              [placeholder]="i18n.CertificateFormCtrl_CertificateNumber"
              data-dgat="certificate-modal-v2-8cc"
            />
          </div>

          <div class="reactive-forms__control">
            <label for="noExpiration" class="reactive-forms__label-checkbox">
              <input
                id="noExpiration"
                df-checkbox
                type="checkbox"
                name="noExpiration"
                [checked]="vm.noExpiration"
                formControlName="noExpiration"
                data-dgat="certificate-modal-v2-ea8"
              />
              <span class="guts-m-l-half">{{
                i18n.dgUserOutcomeEditForm_DoesCertExpire
              }}</span></label
            >
          </div>

          <div class="reactive-forms__control">
            <dgx-date-range
              [formModel]="{
                startDate: vm.dateRangeForm.startDate,
                endDate: vm.dateRangeForm.endDate
              }"
              [isTouched]="markSubFormsAsTouched"
              [showEndDate]="!certificateForm.get('noExpiration').value"
              [startDateRequired]="true"
              [endDateRequired]="true"
              [startDateLabel]="i18n.CertificateFormCtrl_IssueDate"
              [endDateLabel]="i18n.CertificateFormCtrl_ExpiryDate"
            ></dgx-date-range>
          </div>

          <div class="reactive-forms__control">
            <dgx-upload-section
              [useCropper]="false"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [fileInfo]="vm.file"
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.uploadAdapter"
              [isHostImageUrl]="true"
              [labels]="{ header: i18n.dgContentHosting_DragAndDrop }"
              icon="arrow-up-tray"
              (fileChangedEvent)="vm.contentUpload.onContentFileChange($event)"
              (uploadSuccessEvent)="
                vm.contentUpload.onContentUploadSuccess(
                  certificateForm,
                  'imageUrl',
                  $event.url
                )
              "
              (errorEvent)="vm.contentUpload.onContentUploadFailure()"
            >
            </dgx-upload-section>
          </div>

          <div class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.inferredSkills$ | async"
              [hideTopTags]="!(vm.inferredSkills$ | async).length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="i18n.dgOrgInternalContent_SkillsPlaceholderText"
              [allowExistingOnly]="false"
              [tags]="certificateForm.get('skills').value || []"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                certificateForm.get('skills').touched &&
                certificateForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>
        </div>
      </ng-container>

      <ng-container class="modal-footer">
        <dgx-global-add-results
          [class.full-width]="vm.shouldShowResults$ | async"
          [buttonText]="submitButtonText"
          [isEditing]="false"
          [isCompleting]="true"
          [isNewbUser]="vm.isNewbUser$ | async"
          [submissionStatus]="vm.submissionStatus$ | async"
          [classifier]="'achievements'"
          [showCancelButton]="false"
          [addSpacing]="false"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </ng-container>
    </dgx-modal>
  </form>
</ng-container>
