<div class="l_flex l_flex-justify l_flex-center-children">
  <form
    id="comment-field-form"
    class="__input-container l_flex-grow guts-m-r-1"
  >
    <div
      #input
      [id]="isReadOnly ? '' : 'comment-textarea-for-mentions'"
      (input)="onInput($event)"
      dgxMention
      dgxPasteAsPlainText
      [mentionEvent]="mentionEvent"
      [groupId]="groupId"
      [groupPrivacy]="groupPrivacy"
      [attr.contenteditable]="!(isReadOnly || isSubmitting)"
      class="__input contenteditable markdown"
      [attr.placeholder]="i18n.dgComments_ShareSomethingAbout"
      [attr.data-dgat]="dataDgat ? dataDgat : 'comment-field-74c'"
      [attr.aria-label]="i18n.dgComments_ShareSomethingAbout"
      role="textbox"
    ></div>
  </form>
  <button
    #submitButton
    *ngIf="showControls"
    df-button
    dfButtonType="primary"
    form="comment-field-form"
    type="submit"
    class="__submit"
    [attr.aria-disabled]="!value || isSubmitting"
    [attr.aria-label]="i18n.A11y_AddComment"
    data-dgat="comment-field-934j"
  >
    <df-spinner-button [isSpinning]="isSubmitting">
      {{ i18n.Core_Comment }}
    </df-spinner-button>
  </button>
</div>
