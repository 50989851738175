import { Injectable } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { from, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { WebEnvironmentService } from './web-environment.service';
import { ChartsLDFlags } from './ld-flags/charts-ldflags';
import { SearchLDFlags } from './ld-flags/search-ldflags';
import { ProfileLDFlags } from './ld-flags/profile-ldflags';
import { SkillsLDFlags } from './ld-flags/skills-ldflags';
import { OrgManagementLDFlags } from './ld-flags/org-management-ldflags';
import { InputsLDFlags } from './ld-flags/inputs-ldflags';
import { MentoringLDFlags } from './ld-flags/mentoring-ldflags';
import { InsightsLDFlags } from './ld-flags/insights-ldflags';
import { PathwaysLDFLags } from './ld-flags/pathway-ldflags';
import { ReportingLDFlags } from './ld-flags/reporting-ldflags';

@Injectable({
  providedIn: 'root',
})
export class LDFlagsService {
  public charts: ChartsLDFlags = new ChartsLDFlags(this);
  public inputs: InputsLDFlags = new InputsLDFlags(this);
  public mentoring: MentoringLDFlags = new MentoringLDFlags(this);
  public insights: InsightsLDFlags = new InsightsLDFlags(this);
  public orgManagement: OrgManagementLDFlags = new OrgManagementLDFlags(this);
  public pathway: PathwaysLDFLags = new PathwaysLDFLags(this);
  public profile: ProfileLDFlags = new ProfileLDFlags(this);
  public search: SearchLDFlags = new SearchLDFlags(this);
  public skills: SkillsLDFlags = new SkillsLDFlags(this);
  public reporting: ReportingLDFlags = new ReportingLDFlags(this);

  public isEnabled(flag: string): boolean {
    return this.getFlag(flag, false);
  }

  /***
   *  Team: Content, Curation, and Opportunities
   *  Created Date: 5/17/2024 [PD-97615]
   *  Planned Cleanup: pending [TODO]
   *  Visibility:
   *    - Group: "Pre-release: Personalized Sections Cards"
   *
   *  Shows the personalized settings for learner hub
   */
  public get personalizedLearning(): boolean {
    return this.getFlag('personalized-learning-sections-cards', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 5/19/2024 [PD-101247]
   *  Planned Cleanup: pending [TODO]
   *  Visibility:
   *    - Group: "Pre-Release: Learner Hub Personalized Learning"
   *
   *  Shows the personalized tab in the learning summaryn in the learner hub
   */
  public get personalizedLearningTab(): boolean {
    return this.getFlag('learner-hub-personalized-learning', false);
  }

  /***
   *  Team: Platform Services
   *  Created Date: 2/05/2022 [PD-94025]
   *  Planned Cleanup: TBD
   *  Visibility:
   *    - Group: "Pre-Release: Degreed Assistant"
   *
   *  Shows the degreed Assistant within the UI refresh layout.
   */
  public get lxpDegreedAssistant(): boolean {
    return this.getFlag('lxp-degreed-assistant', false);
  }

  /***
   *  Team: Platform Services
   *  Created Date: 8/7/2024 [AIDATASCI-2832]
   *  Planned Cleanup: TBD
   *  Visibility:
   *    - Group: "Pre-Release: Degreed Assistant"
   *
   *  Shows the degreed Assistant voice experiments within the UI refresh layout.
   */
  public get showDegreedExperiments(): boolean {
    return this.getFlag('lxp-degreed-assistant-experiments', false);
  }

  /***
   *  Team: Content, Curation, and Opportunities
   *  Created Date: 8/18/2023 [PD-88784]
   *  Planned Cleanup: pending [PD-91054]
   *  Visibility:
   *    - Group: "Pre-release: Pathway Notify Own"
   *
   *  Includes
   * - Marking new items in a pathway as "new"
   * - pathway visibility updates
   * - required pathway ownership
   */
  public get pathwayNotificationOwnership(): boolean {
    return this.getFlag('pathway-notification-ownership', false);
  }

  /***
   *  Team: Content, Curation, and Opportunities
   *  Created Date: 11/14/2023 [PD-88791]
   *  Planned Cleanup: pending [TODO]
   *  Visibility:
   *    - Group: "Pre-release: Inferred Skills Content"
   *
   *  Includes
   * - Inferred Skills Selection in input types
   * - Clean up of input forms
   */
  public get inferredSkillsContent(): boolean {
    return this.getFlag('inferred-skills-content', false);
  }

  /***
   *  Team: Content, Curation, and Opportunities
   *  Created Date: 4/1/2024 [PD-95575]
   *  Planned Cleanup: pending [TODO]
   *  Visibility:
   *    - Group: "Pre-release: Inferred Skills Content JUL"
   *
   *  Includes
   * - Inferred Skills Selection in input types Courses & Events
   * - Clean up of input forms
   */
  public get inferredSkillsCourseEvent(): boolean {
    return this.getFlag('inferred-skills-course-event', false);
  }

  /**
   * Team: Content, Curation, and Opportunities
   * Created Date: Sep 2022 [PD-54897]
   * Planned Cleanup: pending [PD-85840]
   * Visibility:
   *  - Group: "Pre-release: Pathway Visibility Enhancement"
   *
   * Notes
   * - Feature has gone live, flag serving true all env.
   */
  public get showPathwayVisibilityEnhancements(): boolean {
    return this.getFlag('pathway-visibility-enhancements-20220419', false);
  }

  /***
   *  Team: Admin Experience
   *  Created Date: 1/18/2024 [PD-93316]
   *  Planned Cleanup: 2024 (TBD) [PD-93484]
   *  Visibility:
   *    - Group: "Pre-release: Automations V2"
   *
   *  Includes
   * - New Automations UI
   */
  public get automationsV2(): boolean {
    return this.getFlag('manage-automations-april24', false);
  }

  /**
   * Team: Admin Experience
   * Created Date: 3/27/2024 [PD-94949]
   * Planned Cleanup: July/August 2024 [PD-96257]
   * Visibility:
   *   -Group: "Pre-release: Pre-release: Automations July24"
   *
   * Includes
   * - New Automations UI
   */
  public get automationsJuly24(): boolean {
    return this.getFlag('manage-automations-july24', false);
  }

  /**
   * Team: Admin Experience
   * Created Date: 6/27/2024 [PD-102765]
   * Planned Cleanup: October 2024 [PD-102852]
   * Visibility:
   *   -Group: "Pre-release: Automations Audit Log"
   */
  public get automationsAuditLog(): boolean {
    return this.getFlag('automations-audit-log', false);
  }

  /*********** Clean Up ********/

  /** Whether the Phase 2 Plan Simplification changes should show.  */
  public get showPlanSimplification(): boolean {
    return this.getFlag('plan-simplification-041123', false);
  }

  /** Determine if the AutoPopulate option should be hidden in the target (Plan) Add modal */
  public get hideTargetAutoPopulate(): boolean {
    return this.getFlag('hide_autopopulate_plan_simplification_011723', false);
  }

  /** Should we show insights per section (legacy) or new combined insights sections  */
  public get showCombinedPlanInsights(): boolean {
    return this.getFlag('plan-insights-page-2023', false);
  }

  /** Whether we should show the broken links management in the content catalog.
   * Pre-release: Broken Links Scan
   */
  public get showBrokenLinksManagement(): boolean {
    return this.getFlag('broken-links-management', false);
  }

  public get durationForCoursesEventsOther(): boolean {
    return this.getFlag('content_duration_course_event_other', false);
  }

  public get newDurationForArticlesAndAssessments(): boolean {
    return this.getFlag('add-edit-content-duration', false);
  }

  public get limitNotificationBadgeCount(): boolean {
    return this.getFlag('limit-notification-badge-count', false);
  }

  public get enableAllRapTicketsForOrg(): boolean {
    return this.getFlag('enable-all-rap-tickets-for-org', false);
  }

  public get showAcademies(): boolean {
    return this.getFlag('learnin-academies', false);
  }

  public get showv2Academies(): boolean {
    return this.getFlag('academies-in-lxp-oct-2024', false);
  }

  public get customizeAcademyHeader(): boolean {
    return this.getFlag('customize-academy-banner', false);
  }

  public get showLearnInProductSwitcher(): boolean {
    return this.getFlag('learnin-integration-product-switcher-v1', false);
  }

  public get degreedSkillsOctober2024(): boolean {
    return this.getFlag('skills-degreedskills-october2024', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 01/15/2024 [PD-93075]
   *  Planned Cleanup: TBA
   *  Visibility:
   *    - Group: "Pre-Release: Profile Experience Types"
   *
   *  Includes:
   *    - Shows the experience types in the 'Add Experience' menu on the profile overview
   *    - Shows the optional Experience Type dropdown and from functionality for different experience types in the
   *      add experience form
   */
  public get showExperienceTypes(): boolean {
    return this.getFlag('profile-experience-types', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 11/28/2023 [PD-91963]
   *  Planned Cleanup: 01/07/2024
   *  Visibility:
   *    - Group: "Pre-Release: Profile collection facade RSM update"
   *
   *  Includes:
   *    - Shows the new profile collection page with RSM update
   */
  public get showProfileCollectionV2(): boolean {
    return this.getFlag('profile-collection-facade-rsm-update', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 02/05/2024 [PD-93319]
   *  Planned Cleanup: 01/07/2024
   *  Visibility:
   *    - Group: "Pre-Release: Premium CM Experience"
   *
   *  Includes:
   *    - Shows the learning budget on the homepage
   *    - Determines the redirection to LearnIn
   */
  public get isPaidCMUser(): boolean {
    return this.getFlag('paid-content-marketplace-user', false);
  }

  public get saveSessionForLater(): boolean {
    return this.getFlag('sessions-saved-for-later', false);
  }

  public get showMarketplace(): boolean {
    return this.getFlag('degreed-marketplace', false);
  }

  public get hideExternalCatalog(): boolean {
    return this.getFlag('remove-external-search', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 02/26/2024 [PD-83809]
   *  Planned Cleanup: 01/04/2024
   *  Visibility:
   *    - Group: "Pre-Release: Profile Overview v2 Dev"
   *
   *  Includes:
   *    - Shows the V2 of the Profile Overview implemented with RSM
   */
  public get showProfileOverviewV2(): boolean {
    return this.getFlag('profile-overview-v-2-dev', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 07/19/2020 [PD-47654]
   *  Planned Cleanup: TBC - Unilever need to remove custom config
   *  Visibility:
   *    - Group: "Pre-release: Learner Home" - Will check
   *
   *  Includes:
   *    - Shows Profile overview tab
   */
  public get showProfileOverviewTab(): boolean {
    return this.getFlag('profile-overview-tab', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 19/06/2023 [PD-88625]
   *  Planned Cleanup: TBC pending tech strategy to track date complete
   *  Visibility:
   *    - Group: "Pre-Release: Show assignment completed date"
   *
   *  Includes:
   *    - Shows the completed date column in the assignments table
   */
  public get showAssignmentsCompletedDate(): boolean {
    return this.getFlag('show-assignment-completed-date', false);
  }

  /***
   *  Team: Profile
   *  Created Date: 20/09/2023 [PD-89283]
   *  Planned Cleanup: After the April 2024 MR
   *  Visibility:
   *    - Group: "Pre-release: Multiple preferred content languages"
   *
   *  Includes:
   *    - Allows multiple content languages to be set from profile settings
   */
  public get showAdditionalContentLanguages(): boolean {
    return this.getFlag('multiple-preferred-content-languages', false);
  }

  /***
   * Team: Profile
   * Created Date: 20/03/2024 [PD-95589]
   * Planned Cleanup: After the July MR
   * Visibility:
   *    - Group: "Pre-Release: Updated Achievement and Experience Modals"
   *
   * Includes:
   *    - Enables bespoke family feature for BOA
   */
  public get showDegreedFamily(): boolean {
    return this.getFlag('degreed-family-08122020', false);
  }

  /***
   * Team: Profile
   * Created Date: 29/02/2024 [PD-91377]
   * Planned Cleanup: TBC - Once it is converted to an org setting
   * Visibility:
   *    - Group: "Pre-Release: Updated Achievement and Experience Modals"
   *
   * Includes:
   *    - Enables the new achievement modals - new designs
   */
  public get showUpdatedAchievementModals(): boolean {
    return this.getFlag('updated-achievement-and-experience-modals', false);
  }

  /***
   * Team: Profile
   * Created Date: 05/03/2024 [PD-93925]
   * Planned Cleanup: TBC
   * Visibility:
   *    - Group: "Pre-Release: Similar Skills API v5"
   *
   * Includes:
   *    - Uses version 5 data science API
   */
  public get useV5SkillsAPI(): boolean {
    return this.getFlag('similar-skills-api-v-5', false);
  }

  /***
   * Team: Profile
   * Created Date: 05/07/2024 [PD-99574]
   * Planned Cleanup: TBC
   * Visibility:
   *    - Group: "Pre-Release: New Home Learning Hub"
   *
   * Includes:
   *    - Turns on the new (in progress) learner hub functionality
   */
  public get useLearnerHub(): boolean {
    return true; // this.getFlag('new-home-learner-hub', false);
  }

  /***
   * Team: Profile
   * Created Date: 07/16/2024 [PD-102339]
   * Planned Cleanup: TBC
   * Visibility:
   *    - Group: "Pre-Release: Learner Hub Skills Section"
   *
   * Includes:
   *    - enables the skills section in the learner hub
   */
  public get showLearnerHubSkills(): boolean {
    return this.getFlag('learner-hub-skills-section', false);
  }

  /***
   * Team: Profile
   * Created Date: 08/21/2024 [PD-103780]
   * Planned Cleanup: TBC
   * Visibility:
   *    - Group: "Pre-Release: New Learner Assignments Page"
   *
   * Includes:
   *    - enables the new assignments experience
   */
  public get useNewLearnerAssignments(): boolean {
    return this.getFlag('new-learner-assignments-page', false);
  }

  private client: LaunchDarkly.LDClient;

  constructor(
    private webEnvironmentService: WebEnvironmentService,
    private authService: AuthService
  ) {}

  /**
   * Initializes the LaunchDarkly client with the provided configuration and user information.
   *
   * @returns {Observable<void>} An observable that resolves when the client initialization is complete.
   *
   * @example
   * service.initialize().subscribe(() => {
   *   console.log('LaunchDarkly client initialized');
   * });
   */
  public initialize(): Observable<void> {
    // If for some reason we don't have this, we don't want the site to error out
    if (!this.webEnvironmentService.clientSideFeatureFlagSdkKey) {
      console.warn('Feature flags not available');
      return of(null);
    }

    let context = this.buildLDContext();

    this.client = LaunchDarkly.initialize(
      this.webEnvironmentService.clientSideFeatureFlagSdkKey,
      context
    );

    return from(this.client.waitForInitialization());
  }

  /**
   * Initializes the context for the LaunchDarkly client.
   *
   * This method creates an `LDContext` object which contains the current user's information
   * or marks them as anonymous if not logged in. This context is used by LaunchDarkly to
   * determine feature flag values for the user.
   *
   * @returns {LaunchDarkly.LDContext} The LaunchDarkly context object for the current user.
   *
   * Custom attributes include:
   * - `UserKey`: A unique key representing the user.
   * - `Org`: The ID of the user's default organization, or -1 if none is set.
   * - `Role`: The user's role within the organization.
   * - `PreReleaseGroups`: An array of pre-release group names that the user is a part of.
   */
  private buildLDContext(): LaunchDarkly.LDContext {
    if (!this.authService.isLoggedIn) {
      return {
        anonymous: true,
        kind: 'anonymous',
      };
    }

    const authUser = this.authService.authUser;
    const envString = this.webEnvironmentService.isProduction
      ? ''
      : `-${this.webEnvironmentService.environment}`;

    return {
      kind: 'user',
      key: `${authUser.viewerProfile.userProfileKey}${envString}`,

      // custom attributes
      UserKey: authUser.viewerProfile.userProfileKey,
      Org: authUser.defaultOrgId || -1,
      Role: authUser.defaultOrgInfo?.orgRole,
      PreReleaseGroups: this.getGroupNames(),
    };
  }

  /**
   * Determines the variation of a feature flag for the current user.
   *
   * In the client-side JavaScript SDKs, this is always a fast synchronous operation because
   * all of the feature flag values for the current user have already been loaded into memory.
   *
   * @param {string} key - The unique key of the feature flag.
   * @param {boolean} defaultValue - The default value of the flag, to be used if the value is not available from LaunchDarkly.
   * @returns {boolean} The flag's value.
   */
  public getFlag(ldFlag: string, defaultValue: boolean): boolean {
    // Get the LD flag via the client API if not already cached
    if (this[ldFlag] === undefined) {
      return (this[ldFlag] = this.client.variation(ldFlag, defaultValue));
    }
    // Else return the cached value
    return this[ldFlag];
  }

  /**
   * Retrieves an array of group names from the `authService` that contain the string 'pre-release'.
   *
   * @returns {string[]} An array of group names that match the criteria.
   */
  private getGroupNames(): string[] {
    // null or empty, return []
    if (
      !this.authService.authUser.viewerGroups ||
      this.authService.authUser.viewerGroups.length === 0
    ) {
      return [];
    }

    return this.authService.authUser.viewerGroups
      .filter((viewerGroup) =>
        viewerGroup.group.name.toLowerCase().includes('pre-release')
      )
      .map((viewerGroup) => viewerGroup.group.name);
  }
}
