import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import {
  GlobalAddInputAction,
  GlobalAddOutcomeAction,
} from '@app/global-add/global-add.model';
import { AuthService } from '@app/shared/services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class GlobalAddService {
  public readonly targetAddResourcesTypes: Partial<GlobalAddInputAction>[] = [
    { type: 'Article' },
    { type: 'Episode' }, // podcast
    { type: 'Video' },
    { type: 'Book' },
    { type: 'Course' },
    { type: 'Event' },
    { type: 'Assessment' },
  ];
  private readonly IconOverrideMap = {
    position: 'experience',
    post: 'article',
    coach: 'speech-bubble',
  };

  private readonly globalAddInputsBase: Partial<GlobalAddInputAction>[] = [
    { type: 'Article' },
    { type: 'Episode' },
    { type: 'Video' },
    { type: 'Book' },
    { type: 'Course' },
    { type: 'Event' },
    { type: 'Position' },
    { type: 'Assessment' },
    { type: 'Task' },
    { type: 'Coach' },
  ];

  private readonly globalAddOutcomesBase: Partial<GlobalAddOutcomeAction>[] = [
    {
      type: 'Accomplishment',
      limit: 8,
      count: 0,
    },
    { type: 'Award' },
    { type: 'Certificate' },
    { type: 'Badge' },
    { type: 'Degree' },
  ];

  constructor(private authService: AuthService) {
    if (this.authUser?.isRestrictedProfile) {
      this.globalAddInputsBase = this.globalAddInputsBase.filter(
        ({ type }) => type !== 'Position'
      );
    }
  }

  public get globalAddInputs(): Partial<GlobalAddInputAction>[] {
    return this.addIconsToActions(this.globalAddInputsBase);
  }

  public get globalAddOutcomes(): Partial<GlobalAddOutcomeAction>[] {
    return this.addIconsToActions(this.globalAddOutcomesBase);
  }
  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public getIcon(name: string): string {
    const lowercaseName = name.toLowerCase();
    return this.IconOverrideMap[lowercaseName] || lowercaseName;
  }

  public addIconsToActions(actions: any[]) {
    return actions.map((action) => {
      return {
        ...action,
        ...{
          icon: this.getIcon(action.type),
        },
      };
    });
  }

  public getTargetAddInputs(): Partial<GlobalAddInputAction>[] {
    return this.addIconsToActions(this.targetAddResourcesTypes);
  }

  public getPathwayAddInputs(hasOrg): Partial<GlobalAddInputAction>[] {
    const pathwayAddInputs = this.globalAddInputsBase.filter(({ type }) =>
      hasOrg ? type !== 'Position' : type !== 'Position' && type !== 'Task'
    );

    pathwayAddInputs.push({ type: 'Post' });

    return this.addIconsToActions(pathwayAddInputs);
  }
}
