import { Observable } from 'rxjs';
import { TagsApi } from '@app/tags/tag-api.model';
import { TypeaheadSearchTerm } from '@app/shared/shared-api.model';
import { UserProfileSummary } from '@app/user/user-api.model';
import { RecommendeeType } from '@app/recommendations/recommendations.model';
import { Predicate } from '@app/automations/model';

export interface Group {
  description: string;
  groupId: number;
  isMutualGroup: boolean;
  isPendingMember: boolean;
  name: string;
  /**
   * Fixing linter errors
   * Privacy level in the spec files is a string, but in the profile > groups, it's a number.
   * TODO: Is it accurate that this can be both?
   */
  privacyLevel: number | string;
  userProfileKey: number;
}

export interface GroupRole {
  isDefault: boolean;
  labelKey: string;
  name: string;
  permissionNames: string;
  permissions: AvailableGroupPermissions[];
  roleId: number;
}

export interface GroupItem {
  id: number;
  name: string;
  isRestricted?: boolean;
}

export interface GroupIdentifier {
  groupId?: number;
  id?: number;
  name: string;
  isRestricted?: boolean;
  resourceId?: number; // This will be the UserProfileKey value if the resource is a 'User' or is the GroupId if the resource is a 'Group'
  resourceType?: RecommendeeType | string; // TODO: this should be recommendeeType // This will be either 'User' or 'Group' depending upon what type of resource it is
}

// Note: also see enum equivalent GroupPrivacyLevel
// aka. SearchGroupPreset sometimes used on the BE
// see Degreed.Common.Standard/Enums/SearchGroupPreset.cs
// and
// See Degreed.Web/Controllers/Api/Mobile/OrganizationsController.cs#L151 for notes about types
export type GroupPrivacy = 'Open' | 'Closed' | 'Private' | 'Administrative';

export interface Groupable {
  groupIds: GroupIdentifier[];
}

export interface PaginatedGroupMembers {
  hasMoreItems: boolean;
  items: GroupMember[];
  totalCount: number;
}
export interface GroupInterest {
  groupId: number;
  interestId: number;
  position?: number;
  tagId: number;
  name: string;
  internalUrl: string;
  title: string;
  resourceId: number;
  resourceType: string;
  rating: TagsApi.UserTagRating;
  ratings: TagsApi.UserTagRating[];
}

/** Synthetic intermediate interface for convenience with various group APIs  */
export interface GroupInfoCore extends GroupIdentifier {
  userCount?: number;
  organizationId?: number;
}

export interface GroupInfo extends GroupInfoCore {
  description: string;
  privacyLevel: GroupPrivacyLevel;
  organizationId: number;
  organizationName_Secure: string;
  organizationCode: string;
  anonymousUserCount?: number;
  interests: GroupInterest[];
  interestNames: string;
  organizationName: string;
  groupType: number;
  group: GroupInfo;
  groupId?: number;
  appLocation?: string;
  isAdministrativeGroup?: boolean;
  canRecommend?: boolean;
  canManageGroups?: boolean;
  canShareSocial?: boolean;
  context?: string;
  isMember?: boolean;
  isOrgMember?: boolean;
  isPendingMember?: boolean;
  referId?: number;
  inviteUrl?: string;
  topActiveUsers?: TopActiveUsers[];
  availableGroupPermissions?: AvailableGroupPermissions[];
  availablePermissionsJSON?: AvailableGroupPermissions[];
  userGroupPermissions?: string[];
  userOrgPermissions?: string[];
  groupConfigs?: {
    enabledOpportunitiesForGroup: boolean;
  };
  membershipType?: 'Static' | 'Dynamic';
  predicates?: Predicate[];
  segmentId?: number;
}

export interface GroupDetail extends GroupInfo {
  title: string;
  resourceId: number;
  resourceType: string;
  memberCount?: number;
  members: UserProfileSummary[];
  isMember: boolean;
  isPendingMember: boolean;
}

export enum GroupPrivacyLevel {
  Open = 0,
  Closed = 1,
  Private = 2,
  Administrative = 3,
}

export interface Interest {
  groupId: number;
  internalUrl: string;
  interestId: number;
  name: string;
  position: number;
  resourceId: number;
  resourceType: string;
  tagId: number;
  title: string;
}

export interface GroupMember {
  bio: string;
  categories: string[];
  dateJoinedGroup: Date;
  email: string;
  firstName: string;
  groupId: number;
  isGroupAdmin: boolean;
  lastActiveFormatted: string;
  ratedSkillsCount: number;
  jobSkillsCount: number;
  focusSkillsCount: number;
  lastName: string;
  lastLogin: Date;
  masteryPoints: number;
  name: string;
  organizationId: number;
  organizationEmail?: string;
  picture: string;
  privacyId: number;
  role: any; // TODO: update with Role definition
  roleId: number;
  userProfileId: string;
  userProfileKey: number;
  vanityUrl: string;
  href?: string;
  sourceType?: string;
}

// USER
// TODO: find a better home for this
export type UserSkillRating = {
  dateCompleted: string;
  dateRequested: string;
  externalId: string;
  isInternal: boolean;
  level: string;
  privacyId: number;
  provider: TagsApi.ProviderDetails;
  providerId: number;
  providerName: string;
  raterProfileKey: number;
  tagId: number;
  type: string;
  typeId: number;
  userProfileKey: number;
  userTagRatingId: number;
};

// TODO: find a better home for this
export interface UserSkillDetails {
  additionalProficiencyLevels: number;
  content: any[];
  isFocusSkill: boolean;
  isJobSkill: boolean;
  isProfileSkill?: boolean;
  isRequired: boolean;
  isTeamFocusSkill: boolean;
  ratings: UserSkillRating[];
  lastUpdated: string;
  skillSources: UserSkillSources[];
  skillTarget: string;
  sourceSkillTarget: string;
  tagId: number;
  tagTitle: string;
}

export interface UserSkillDetailsManagerRating {
  enableManagerRating: boolean;
  enablePeerRating: boolean;
  teamFocusSkillCount: number;
  userTagDetails: UserSkillDetails[];
}

export interface UserSkillSources {
  id: number;
  name: string;
  type: string;
}

export interface ProfileSkillStates {
  id: string;
  title: string;
}

export interface GetSkillDetails {
  title: string;
  averageRating: number;
  ratingCount: number;
  onProfileCount: number;
  focusSkillCount: number;
  teamFocusSkill: boolean;
  tagId: number;
}

export interface GetSkillDetailsManagerRating {
  enableManagerRating: boolean;
  items: GetSkillDetails[];
  totalCount: number;
}

export interface TopActiveUsers {
  groupId: number;
  userProfileKey: number;
  name: string;
  picture: string;
  activityCount: number;
  privacyId: number;
}

export interface AvailableGroupPermissions {
  permissionId: number;
  name: string;
  description?: string | null;
  isRequiredForAdmin: boolean;
  isOrgPermission: boolean;
  isGroupPermission: boolean;
  isMemberPermission: boolean;
  sortOrder?: number | null;
  isChannel: boolean;
  organizationRoles?: null[] | null;
  groupRoles?: null[] | null;

  // for state management only?
  nested?: boolean;
  id?: string;
  assigned?: boolean;
}

export interface Permission {
  isMember: boolean;
  isPendingMember: boolean;
  isOrgMember: boolean;
  isRestrictedProfile: boolean;
  isAdministrativeGroup: boolean;
  canComment: boolean;
  canEditSettings: boolean;
  canEditPermissions: boolean;
  canInviteMembers: boolean;
  canManageContent: boolean;
  canViewReports: boolean;
  canViewReporting: boolean;
  canRemoveMembers: boolean;
  canDeleteGroup: boolean;
  canCreateOpenGroups: boolean;
  canCreateClosedGroups: boolean;
  canCreatePrivateGroups: boolean;
  canCreateAdministrativeGroups: boolean;
  canViewMembers: boolean;
  canViewSettingsTab: boolean;
  canRemoveFeedItems: boolean;
  canRecommendInGroup: boolean;
  canManageGroups: boolean;
  canViewGroupReports?: boolean;
  canRecommendItems?: boolean;
}

export interface GroupMemberCount {
  anonymousUserCount: number;
  userCount: number;
}

/**
 * The Typeahead group search function type.
 *
 * @param term An observable that emits characters as they are typed.
 * @returns An observable collection of results.
 */
export type TypeaheadGroupSearchFunction<TTerm, TVisibility, TResult> = (
  term: TypeaheadSearchTerm<TTerm>,
  groupPrivacyLevel: TVisibility
) => Observable<readonly TResult[]>;

export interface MemberCounts {
  userCount: number;
  anonymousUserCount: number;
}
