import { AuthService } from '@app/shared/services/auth.service';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';
import { OrgBaseGuard } from './org-base.guard';
import { SkillsGuard } from './skills.guard';
import { SkillStandardsGuard } from './skill-standards.guard';

@Injectable({
  providedIn: 'root',
})
export class SkillsRedirectGuard extends OrgBaseGuard {
  constructor(
    @Inject(WindowToken) windowRef: Window,
    authService: AuthService,
    protected router: Router,
    private skillsGuard: SkillsGuard,
    private skillStandardsGuard: SkillStandardsGuard
  ) {
    super(authService, windowRef, router);
    this.routeDirectory = '/skills';
  }

  override get hasPermission() {
    return (
      this.skillsGuard.hasPermission || this.skillStandardsGuard.hasPermission
    );
  }

  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.skillsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/list'));
    }
    if (this.skillStandardsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/skillstandards'));
    }
    return this.router.parseUrl('/');
  }
}
