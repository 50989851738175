import { GroupService } from '@app/shared/services/group.service';
import { ManagedGroup } from '@app/orgs/services/org-groups.service';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { switchMap } from 'rxjs/operators';
import { OrgGroupsService } from '@app/orgs/services/org-groups.service';

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FtpFileUploadType } from '@app/ftp/ftp-api.model';

@Component({
  selector: 'dgx-org-groups-header',
  templateUrl: './org-groups-header.component.html',
})
export class OrgGroupsHeaderComponent implements OnInit {
  @Input() public groups: ManagedGroup[];
  @Input() public isLoading: boolean;
  @Input() public orgId: number;
  @Input() public showBulkActions: boolean;

  @Output() public selectNone = new EventEmitter();
  @Output() public updateQueries = new EventEmitter();

  public canUploadGroups: boolean;
  public searchTerm: string;
  public useNewGroupSettingsLDFlag: any;
  public readonly fileType = FtpFileUploadType.Groups;

  private authUser: AuthUser;
  private orgInfo: OrgInfo;

  constructor(
    private authService: AuthService,
    private orgGroupsService: OrgGroupsService,
    private groupService: GroupService
  ) {}

  public get selectedGroups() {
    return this.groups.filter((group) => group.isSelected);
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.orgInfo = this.authUser.orgInfo.find(
      (x) => x.organizationId === this.orgId
    );

    // This should eventually check against the same service the FTP guard
    //   uses HOWEVER currently issues with doubling this component in org
    //   and channel prevents us from doing this.
    this.canUploadGroups =
      this.authUser.canBulkUpload && this.orgInfo.permissions.uploadGroups;
  }

  public addGroup(event) {
    // load new group settings modal
    this.groupService
      .addGroup({
        orgId: this.orgId,
        canCreateOpenGroups:
          this.orgInfo?.permissions?.createOpenGroups ?? false,
        canCreateClosedGroups:
          this.orgInfo?.permissions?.createClosedGroups ?? false,
        canCreatePrivateGroups:
          this.orgInfo?.permissions?.createPrivateGroups ?? false,
        canCreateAdministrativeGroups:
          this.orgInfo?.permissions?.createAdministrativeGroups ?? false,
        isRegulated: this.authUser?.isRestrictedProfile ?? false,
      })
      .subscribe(() => {
        this.updateQueries.emit(this.searchTerm);
      });
  }

  public cancelBulkActions(): void {
    this.selectNone.emit();
  }

  public showBulkDeleteModal() {
    this.orgGroupsService
      .showBulkDeleteModal(this.selectedGroups?.length)
      .pipe(
        switchMap(() => {
          const groupIds = this.selectedGroups.map((group) => group.groupId);
          return this.groupService.bulkDeleteGroups(this.orgId, groupIds);
        })
      )
      .subscribe(() => {
        this.updateQueries.emit(this.searchTerm);
      });
  }

  public updateQuery(term: string) {
    this.searchTerm = term;
    this.updateQueries.emit(this.searchTerm);
  }
}
