<ng-container *ngFor="let warning of warnings">
  <div class="guts-m-b-1">
    <df-local-notification
      [type]="NotificationType.warning"
      [text]="warning"
      size="small"
    ></df-local-notification>
  </div>
</ng-container>

<div class="l_flex guts-p-b-half">
  <h2 #stepHeader tabindex="-1" class="h3">
    {{ i18n.BusinessRules_Conditions_Column }}
  </h2>
  <button
    *ngIf="conditions?.length && !isSaving"
    class="l_flex--align-right color-red"
    df-button
    dfButtonType="clear"
    (click)="deleteConditions()"
  >
    {{ i18n.Automation_DeleteConditions }}
  </button>
</div>
<p class="par--small guts-m-b-1">{{ i18n.Automation_ConditionHelp }}</p>

<div
  *ngFor="
    let condition of localConditions;
    index as index;
    first as first;
    last as last
  "
  class="grid grid--bleed"
>
  <!-- Logical operator -->
  <div class="grid__col-12">
    <div
      *ngIf="!first"
      class="center-text up-me guts-p-v-1 font-semibold rel condition-form_and-divider"
    >
      <span class="guts-p-full-half ib rel"> {{ i18n.BusinessRules_And }}</span>
    </div>
  </div>
  <div aria-live="polite">
    <div
      class="grid__col-12 guts-p-b-3"
      *ngIf="showAttributeWarning(condition)"
    >
      <df-local-notification
        icon="exclamation-circle"
        size="small"
        [text]="getAttributeWarning(condition)"
        [type]="NotificationType.warning"
      >
      </df-local-notification>
    </div>
  </div>
  <div class="grid__col-md-8 guts-m-b-1">
    <!-- Attribute to compare -->
    <label for="'attributeName-' + index" class="form-label guts-p-r-1"
      >{{ i18n.Automation_UserAttribute }}<df-label-marker></df-label-marker
    ></label>
    <dgx-combobox
      #attributesField
      [isFixedWidth]="false"
      class="full-width guts-p-r-1"
      [id]="'attributeName-' + index"
      [options]="conditionOptions"
      [labelKey]="'title'"
      [selectedOption]="condition.fieldName"
      (selection)="setFieldName($event, index)"
      [placeholder]="i18n.BusinessRules_AttributeName"
      [hasNoResultsText]="i18n.Core_NoResults"
    ></dgx-combobox>
  </div>
  <!-- Condition Operator -->
  <div class="grid__col-md-4 guts-m-b-1">
    <label [attr.id]="'operatorLabel-' + index" class="form-label"
      >{{ i18n.BusinessRules_Operator }}<df-label-marker></df-label-marker
    ></label>
    <dgx-select
      [ariaLabelledby]="'operatorLabel-' + index"
      class="full-width"
      [isRequired]="true"
      [options]="getOperatorOptions(condition)"
      labelKey="name"
      trackBy="value"
      (selection)="setOperatorValue($event, index)"
      [selectedItem]="condition.comparisonOperator"
    >
    </dgx-select>

    <dgx-validate-field
      *ngIf="isInvalidValue(condition)"
      [message]="i18n.Core_FieldRequired"
    ></dgx-validate-field>
  </div>
  <!-- Condition Value -->
  <div class="grid__col-12 guts-m-b-1">
    <label class="form-label" [id]="'attributeValue-' + index"
      >{{ i18n.BusinessRules_AttributeValue }}<df-label-marker></df-label-marker
    ></label>
    <!-- <ng-container [ngSwitch]="getConditionType()"> -->
    <!-- String value -->
    <ng-container [ngSwitch]="getConditionType(condition)">
      <div *ngSwitchCase="AttributeDataType.String">
        <ng-container
          *ngTemplateOutlet="
            condition.comparisonOperator !== 'isoneof' &&
            condition.comparisonOperator !== 'isnotoneof'
              ? textAttributeValue
              : multiAttributeValueSelection;
            context: { $implicit: condition, index: index, type: 'text' }
          "
        >
        </ng-container>
      </div>
      <!-- Number or relative date value -->
      <div *ngSwitchCase="AttributeDataType.Number">
        <div class="grid grid--bleed grid--align-end">
          <div
            [ngClass]="{
              'grid__col-8': getFollowingLabel(condition),
              'grid__col-12': !getFollowingLabel(condition),
            }"
          >
            <ng-container
              *ngTemplateOutlet="
                condition.comparisonOperator !== 'isoneof' &&
                condition.comparisonOperator !== 'isnotoneof'
                  ? numberAttributeValue
                  : multiAttributeValueSelection;
                context: { $implicit: condition, index: index, type: 'number' }
              "
            >
            </ng-container>
          </div>
          <div
            class="grid__col-4 guts-p-l-2 guts-p-b-half guts-m-b-quart"
            *ngIf="getFollowingLabel(condition) as trailingLabel"
          >
            {{ trailingLabel }}
          </div>
        </div>
      </div>
      <!-- Static date value -->
      <div *ngSwitchCase="AttributeDataType.DateTime">
        <dgx-date-picker
          [date]="castToDate(condition.comparisonValue?.[0])"
          [isMinDateToday]="false"
          [placeholder]="i18n.RecommendationCtrl_SelectADate"
          (dateSelect)="setDateValue($event, index)"
        >
        </dgx-date-picker>
      </div>
      <!-- True or False -->
      <div *ngSwitchCase="AttributeDataType.Bool">
        <dgx-select
          [id]="'attributeValue-' + index"
          class="full-width"
          [options]="booleanOptions"
          labelKey="name"
          trackBy="value"
          (selection)="setComparisonValue($event, index)"
          [selectedItem]="condition.comparisonValue?.[0]"
          [placeholder]="i18n.BusinessRules_Value"
          [isRequired]="true"
        ></dgx-select>
      </div>
      <!-- Field errors -->
      <dgx-validate-field
        *ngIf="isInvalidValue(condition)"
        [message]="i18n.Core_FieldRequired"
      ></dgx-validate-field>
    </ng-container>
  </div>

  <!-- Add predicate -->
  <div class="grid__col-6">
    <div *ngIf="last && localConditions.length < 20">
      <button
        class="btn btn-secondary guts-p-full-quart no-border"
        (click)="addPredicate()"
      >
        <df-icon icon="plus" size="small" [attr.aria-hidden]="'true'"></df-icon>
        {{ i18n.BusinessRules_AddCondition }}
      </button>
    </div>
  </div>
  <!-- Remove predicate -->
  <div class="grid__col-6">
    <div class="guts-p-l-2 right-text" *ngIf="localConditions.length > 1">
      <button
        class="btn btn-danger btn--ghost--no-border guts-p-full-quart"
        type="button"
        [attr.aria-label]="
          condition.fieldName
            ? getFormattedRemoveConditionString(condition)
            : i18n.Automation_RemoveEmptyCondition
        "
        (click)="removePredicate(index)"
      >
        {{ i18n.Core_Remove }}
      </button>
    </div>
  </div>
</div>

<div class="guts-m-t-2">
  <div class="l_flexbar l_flex-justify">
    <div>
      <button df-button dfButtonType="clear" (click)="onBack()">
        <span class="l_flexbar color-blue">
          <df-icon icon="chevron-left"></df-icon>
          <span [textContent]="i18n.Core_Back"></span>
        </span>
      </button>
    </div>
    <div>
      <button
        type="button"
        (click)="onCancel()"
        df-button
        dfButtonType="passive"
        class="guts-m-r-1"
      >
        {{ i18n.Core_Cancel }}
      </button>
      <button
        df-button
        dfButtonType="primary"
        type="button"
        (click)="onSave()"
        [class.is_disabled]="isSaving"
        [attr.aria-disabled]="isSaving"
        [attr.aria-label]="isSaving ? i18n.Core_Loading : i18n.Core_Save"
      >
        <df-spinner-button [isSpinning]="isSaving">
          {{ i18n.Core_Save }}
        </df-spinner-button>
      </button>
    </div>
  </div>
</div>

<ng-template
  #multiAttributeValueSelection
  let-condition
  let-index="index"
  let-type="type"
>
  <dgx-attribute-multi-select
    [attributes]="condition.comparisonValue"
    [index]="index"
    (attributesUpdated)="updateAttributes($event, condition)"
    [helpText]="i18n.Automation_AttributeValue_HelperText"
    [placeholderText]="i18n.BusinessRules_AttributeValue"
    [a11yLabelledBy]="'attributeValue-' + index"
    [inputType]="type"
  ></dgx-attribute-multi-select>
</ng-template>

<ng-template #textAttributeValue let-condition let-index="index">
  <input
    [attr.aria-labelledby]="'attributeValue-' + index"
    type="text"
    [placeholder]="i18n.BusinessRules_AttributeValue"
    title="{{ i18n.BusinessRules_AttributeValue }}"
    required="true"
    [ngModel]="condition.comparisonValue?.[0]"
    (input)="setComparisonValue($event, index)"
  />
</ng-template>

<ng-template #numberAttributeValue let-condition let-index="index">
  <input
    [attr.aria-labelledby]="'attributeValue-' + index"
    type="number"
    title="{{ i18n.BusinessRules_AttributeValue }}"
    [placeholder]="i18n.BusinessRules_AttributeValue"
    [attr.min]="getMin(condition)"
    [ngModel]="condition.comparisonValue?.[0]"
    (input)="setComparisonValue($event, index)"
    required="true"
  />
</ng-template>
