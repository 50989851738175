<ul class="guts-m-t-2 pathway-subsection__list">
  <li
    *ngFor="let subsection of subsections; trackBy: trackById"
    dgxIntersectionObserver
    [unObserve]="false"
    [useDefaultViewArea]="true"
    (onIntersection)="
      onItemIsVisible('subsection', subsection.levelNumber, subsection.number)
    "
    data-dgat="pathway-subsection-dd0"
  >
    <div
      class="pathway-subsection__container guts-m-t-2"
      *ngIf="shouldShowSubsection(subsection)"
    >
      <div
        class="pathway-subsection__heading"
        id="section-{{ subsection.levelNumber }}-{{ subsection.number }}"
        tabindex="-1"
        [ngClass]="{
          'pathway-subsection__heading--in-progress': !subsection.complete,
          'pathway-subsection__heading--completed': subsection.complete,
        }"
      >
        <span
          class="epsilon no-wrap badge badge-pill badge-success"
          *ngIf="subsection.complete"
        >
          {{ i18n.ProfilePathways_Completed }}
        </span>
        <span
          class="pathway-subsection__meta-text l_flexbar"
          *ngIf="
            !subsection.complete &&
            subsection.lessonItemCount != subsection.optionalSteps
          "
        >
          <dgx-progress-circle
            [percent]="subsection.progress"
            [config]="{ color: progressCircleInProgress }"
            [customAriaLabel]="
              inProgressAriaLabel(
                subsection.completedSteps,
                subsection.totalSteps
              )
            "
            class="guts-m-r-half"
          ></dgx-progress-circle>
          {{ getCompletedItems(subsection) }}
        </span>
        <span
          class="pathway-subsection__meta-text l_flexbar"
          *ngIf="showOptionalText(subsection)"
        >
          {{ i18n.Pathways_LessonItemsAllOptional }}
        </span>
      </div>
      <!-- subsection Title & Description-->
      <div
        class="pathway-subsection__description guts-p-full-2"
        *ngIf="subsection.title || subsection.description"
      >
        <h3
          class="pathway-subsection__title"
          *ngIf="subsection.title"
          [innerHTML]="subsection.title | stripMarkup"
        ></h3>
        <p class="guts-m-t-half" *ngIf="subsection.description">
          <dgx-text-expander
            [content]="subsection.description | markdownToHtml: markdownOptions"
            [maxCollapsedLines]="2"
            [useReadMore]="true"
            [isHtml]="true"
            class="pathway-subsection__meta-text markdown"
          ></dgx-text-expander>
        </p>
      </div>

      <!-- subsection Input Cards-->
      <ul>
        <li
          *ngFor="
            let step of subsection.steps;
            trackBy: trackByStepId;
            let stepIndex = index
          "
          dgxIntersectionObserver
          (onIntersection)="onStepIsVisible(step, stepIndex)"
          data-dgat="pathway-subsection-7a5"
        >
          <dgx-pathway-author-note *ngIf="step.note" [step]="step">
          </dgx-pathway-author-note>
          <dgx-learning-resource-card
            [isNewInput]="step.isNew"
            [referenceId]="step.referenceId"
            [resource]="step.reference | inputToLearningResource: 'Pathway'"
            [displayTitle]="
              (step.title ? step.title : step.reference.title) | stripMarkup
            "
            [displaySummary]="
              (step.description ? step.description : step.reference.summary)
                | stripMarkup
            "
            [displayImageUrl]="step.imageUrl"
            cardStyle="stack"
            [class.pathway-subsection__resource--has-note]="!!step.note"
          ></dgx-learning-resource-card>
        </li>
      </ul>
    </div>
  </li>
</ul>
