<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!degreeGlobalAddForm"
    class="reactive-forms"
    [class.input-success-kickstart]="vm.shouldShowResults$ | async"
    [formGroup]="degreeGlobalAddForm"
    (ngSubmit)="onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [useDefaultSubmitButton]="false"
      [submitButtonText]="submitButtonText"
      [canCancel]="!(vm.shouldShowResults$ | async)"
      [modalHeaderTitle]="heading"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [bodyClasses]="{
        'guts-p-full-2': true,
        'input-success-kickstart': (vm.shouldShowResults$ | async),
        first: true,
        second: true,
        third: false
      }"
      (dismiss)="onDismiss($event)"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <div class="input-success-wrap">
          <!-- isInternational -->
          <div class="reactive-forms__control">
            <label for="isInternational" class="reactive-forms__label-checkbox">
              <input
                id="isInternational"
                df-checkbox
                [checked]="vm.isInternational"
                type="checkbox"
                name="isInternational"
                formControlName="isInternational"
              />
              <span class="guts-m-l-half">{{
                i18n.dgUserOutcomeEditForm_OutsideUS
              }}</span></label
            >
          </div>

          <!-- Title -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [placeholder]="i18n.dgUserOutcomeEditForm_DegreeTitleExample"
              (blur)="loadInferredSkills()"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                degreeGlobalAddForm.get('title').touched &&
                degreeGlobalAddForm.get('title').invalid
              "
              message="{{
                'Core_FieldRequiredFormat'
                  | translate
                    : {
                        fieldName: i18n.Core_Title
                      }
              }}"
            ></dgx-validate-field>
          </div>

          <!-- College or University -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="college" [isRequired]="true">
              {{ i18n.dgUserOutcomeEditForm_CollegeUniversity }}
            </dgx-reactive-forms-label>
            <div class="l_flex l_flex-middle rel">
              <input
                type="text"
                id="college"
                formControlName="college"
                [ngbTypeahead]="loadColleges"
                [resultTemplate]="searchResultItem"
                (selectItem)="onFormControlUpdate('college', $event.item)"
                [placeholder]="i18n.dgUserOutcomeEditForm_UniversityName"
              />
              <df-spinner
                *ngIf="isLoadingColleges"
                class="loading-spinner"
                spinnerContext="textInput"
              ></df-spinner>
            </div>
            <dgx-validate-field
              class="block"
              *ngIf="
                degreeGlobalAddForm.get('college').touched &&
                degreeGlobalAddForm.get('college').invalid
              "
              message="{{
                'Core_FieldRequiredFormat'
                  | translate
                    : {
                        fieldName: i18n.dgUserOutcomeEditForm_CollegeUniversity
                      }
              }}"
            ></dgx-validate-field>
          </div>

          <!-- Country -->
          <div
            class="reactive-forms__control"
            *ngIf="degreeGlobalAddForm.get('isInternational').value"
          >
            <dgx-reactive-forms-label for="country">
              {{ i18n.dgUserOutcomeEditForm_Country }}
            </dgx-reactive-forms-label>
            <div class="l_flex l_flex-middle rel">
              <input
                type="text"
                id="country"
                formControlName="country"
                [ngbTypeahead]="loadCountries"
                [resultTemplate]="searchResultItem"
                (selectItem)="onFormControlUpdate('country', $event.item)"
                [placeholder]="i18n.dgUserOutcomeEditForm_UniversityCountry"
              />
              <df-spinner
                *ngIf="isLoadingCountries"
                class="loading-spinner"
                spinnerContext="textInput"
              ></df-spinner>
            </div>
          </div>

          <!-- Degree -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="degree">
              {{ i18n.Core_Degree }}
            </dgx-reactive-forms-label>
            <dgx-select
              id="degree"
              [options]="vm.degreeLevels"
              [selectedItem]="vm.degree"
              (selection)="onFormControlUpdate('degree', $event.id)"
              trackBy="id"
              labelKey="name"
              class="full-width"
              [ariaLabel]="i18n.Core_Degree"
              [placeholder]="i18n.dgUserOutcomeEditForm_DegreeLevelExample"
            ></dgx-select>
          </div>

          <!-- GPA and Date Graduated -->
          <div class="l_flex l_flex-justify gap-full-1-half">
            <div class="reactive-forms__control l_flex-one">
              <dgx-reactive-forms-label for="gpa">
                {{ i18n.dgUserOutcomeEditForm_GPA }}
              </dgx-reactive-forms-label>
              <input
                id="gpa"
                formControlName="gpa"
                [placeholder]="i18n.dgUserOutcomeEditForm_GPAExample"
              />
              <dgx-validate-field
                class="block"
                *ngIf="
                  degreeGlobalAddForm.get('gpa').touched &&
                  degreeGlobalAddForm.get('gpa').invalid
                "
                message="{{ i18n.DegreeFormCtrl_GradePointAvg }}"
              ></dgx-validate-field>
            </div>

            <div class="reactive-forms__control l_flex-one">
              <dgx-reactive-forms-label for="endDate">
                {{ i18n.dgUserOutcomeEditForm_DateGraduated }}
              </dgx-reactive-forms-label>
              <dgx-date-picker
                id="endDate"
                [date]="vm.endDate"
                [attr.aria-label]="i18n.dgUserOutcomeEditForm_DateGraduated"
                [placeholder]="i18n.Core_SelectDate"
                (dateSelect)="onFormControlUpdate('endDate', $event)"
              ></dgx-date-picker>
            </div>
          </div>

          <!-- Image Upload -->
          <div class="reactive-forms__control">
            <dgx-upload-section
              [useCropper]="false"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [fileInfo]="vm.file"
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.uploadAdapter"
              [isHostImageUrl]="true"
              [labels]="{ header: i18n.dgContentHosting_DragAndDrop }"
              icon="arrow-up-tray"
              (fileChangedEvent)="vm.contentUpload.onContentFileChange($event)"
              (uploadSuccessEvent)="
                vm.contentUpload.onContentUploadSuccess(
                  degreeGlobalAddForm,
                  'imageUrl',
                  $event.url
                )
              "
              (errorEvent)="vm.contentUpload.onContentUploadFailure()"
            >
            </dgx-upload-section>
          </div>

          <!-- Skills -->
          <div class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.inferredSkills$ | async"
              [hideTopTags]="!(vm.inferredSkills$ | async).length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="i18n.dgOrgInternalContent_SkillsPlaceholderText"
              [allowExistingOnly]="false"
              [tags]="degreeGlobalAddForm.get('skills').value || []"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                degreeGlobalAddForm.get('skills').touched &&
                degreeGlobalAddForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>
        </div>
      </ng-container>

      <!-- Modal footer -->
      <ng-container class="modal-footer">
        <dgx-global-add-results
          *ngIf="!vm.isInitialForm"
          [class.full-width]="vm.shouldShowResults$ | async"
          [buttonText]="submitButtonText"
          [isEditing]="false"
          [isCompleting]="true"
          [isNewbUser]="vm.isNewbUser$ | async"
          [submissionStatus]="vm.submissionStatus$ | async"
          [classifier]="'achievements'"
          [showCancelButton]="false"
          [addSpacing]="false"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </ng-container>
    </dgx-modal>
  </form>
</ng-container>

<ng-template #searchResultItem let-name="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="name"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
</ng-template>
