/**
 *  @Deprecated
 *
 *  Use the new facade pattern.
 *
 *  This file can't be deleted until it's usage in the onboarding work flow is cleaned up.
 *
 */

import { DatePipe } from '@angular/common';
import { FlexibleDate } from '@app/shared/models/core-api.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PositionLevel, UserPosition } from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { Skill } from '@app/opportunities/opportunities-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { DfIconCalendar16, DfIconRegistry } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserContentModalBaseDirective } from '../user-content-modal-base/user-content-modal-base.directive';
import { AnyUserPositionParametersViewModel } from '../user-input.model';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';

interface SeniorityOption {
  id: PositionLevel;
  title: string;
}
export interface ExperienceFormModel {
  title: string;
  description?: string;
  extent: {
    startDate?: FlexibleDate;
    endDate?: FlexibleDate;
    hoursPerWeek?: string;
  };
  level: PositionLevel;
  tags?: TagsApi.Tag[];
  organizationName: string;
  isCurrent?: boolean;
}

@Component({
  selector: 'dgx-experience-modal',
  templateUrl: './experience-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceModalComponent
  extends UserContentModalBaseDirective<
    UserPosition,
    ExperienceFormModel,
    AnyUserPositionParametersViewModel
  >
  implements OnInit
{
  private static readonly defaultHoursPerWeek = 40;
  private static readonly defaultSeniority: PositionLevel = 'Intermediate';
  public readonly i18n = this.translate.instant([
    'PositionFormCtrl_Title',
    'PositionFormCtrl_PositionTitle',
    'PositionFormCtrl_Organization',
    'PositionFormCtrl_CompanyOrg',
    'PositionFormCtrl_CurrentPosition',
    'PositionFormCtrl_StartDate',
    'PositionFormCtrl_EndDate',
    'PositionFormCtrl_HoursPerWeek',
    'PositionFormCtrl_Seniority',
    'PositionFormCtrl_Description',
    'TagsCtrl_SkillsPlaceholder',
    'PositionFormCtrl_SaveExperience',
    'PositionFormCtrl_JuniorPosition',
    'PositionFormCtrl_IntermediatePosition',
    'PositionFormCtrl_SeniorPosition',
    'PositionFormCtrl_StartDateError',
    'PositionFormCtrl_EndDateError',
    'PositionFormCtrl_EndAfterStart',
    'PositionFormCtrl_AverageHours',
    'PositionFormCtrl_AddCategory',
    'PositionFormCtrl_SaveExperience',
    'PositionFormCtrl_WholeWeekHours',
    'Core_SelectStartDate',
    'Core_SelectEndDate',
  ]);

  @ViewChild('readonlyField') public readonlyField: TemplateRef<any>;
  @ViewChild('skillsView')
  public skillsViewRef: TemplateRef<any>;
  @ViewChild('seniority')
  public seniority: TemplateRef<any>;
  public isNewb: boolean;
  public skills: Skill[] = [];

  public seniorityOptions: SeniorityOption[] = [
    {
      id: 'Junior',
      title: this.i18n.PositionFormCtrl_JuniorPosition,
    },
    {
      id: 'Intermediate',
      title: this.i18n.PositionFormCtrl_IntermediatePosition,
    },
    { id: 'Senior', title: this.i18n.PositionFormCtrl_SeniorPosition },
  ];

  public options = {};

  constructor(
    @Inject(WindowToken) windowRef: Window,
    cdr: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    authService: AuthService,
    tipService: TipService,
    inputsService: InputsService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private builder: DfFormFieldBuilder,
    private iconRegistry: DfIconRegistry
  ) {
    super(
      windowRef,
      cdr,
      activeModal,
      authService,
      tipService,
      inputsService,
      translate.instant('PositionFormCtrl_AddExperience'),
      translate.instant('PositionFormCtrl_EditExperience')
    );
    this.iconRegistry.registerIcons([DfIconCalendar16]);
  }

  public onTagsEditorChange(items: any[], formControl: FormControl) {
    formControl.setValue(items);
    formControl.updateValueAndValidity();
  }

  protected buildFormFields(): DfFormFieldConfig[] {
    return [
      // When this is hooked up in the app, the title should always be editable, see PD-49607
      (this.isEditing
        ? this.builder.customField('title', 'Core_Title', this.readonlyField)
        : this.builder
            .title()
            .withPlaceholder(this.i18n.PositionFormCtrl_PositionTitle)
            .withDgatId('positionForm-dd2')
            .validatedByIndexed({
              emptyStringError: {
                expression: (control) => {
                  return (control.value || '').trim().length;
                },
                message: this.translate.instant('Core_FieldRequiredFormat', {
                  fieldName: this.translate.instant('Core_Title'),
                }),
              },
            })
      ).build(),
      this.builder
        .title()
        .withKey('organizationName')
        .labeled(this.i18n.PositionFormCtrl_Organization)
        .withPlaceholder(this.i18n.PositionFormCtrl_CompanyOrg)
        .asRequired()
        .withDgatId('positionForm-75a')
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translate.instant('Core_FieldRequiredFormat', {
              fieldName: this.translate.instant(
                this.i18n.PositionFormCtrl_Organization
              ),
            }),
          },
        })
        .build(),
      this.builder
        .checkbox('isCurrent', this.i18n.PositionFormCtrl_CurrentPosition)
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'startDate',
                this.i18n.PositionFormCtrl_StartDate,
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectStartDate,
                  customDgat: 'positionForm-dt1',
                }
              )
              .asRequired()
              .styledBy('grid__col-sm-4 guts-p-r-1 m-guts-p-r-0 guts-p-b-1')
              .build(),
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'endDate',
                this.i18n.PositionFormCtrl_EndDate,
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  customDgat: 'positionForm-dt2',
                  ariaLabel: this.i18n.Core_SelectEndDate,
                }
              )
              .hiddenWhen((_) => this.model.isCurrent)
              .asRequired()
              .validatedByIndexed({
                endDateError: {
                  expression: (control) => {
                    return (
                      control.value > this.model.extent.startDate ||
                      this.model.isCurrent
                    ); // no end date comparison needed for current positions,
                  },
                  message: this.i18n.PositionFormCtrl_EndAfterStart,
                },
              })
              .styledBy('grid__col-sm-4 guts-p-r-1 m-guts-p-r-0 guts-p-b-1')
              .build(),
            this.builder
              .requiredTextInput(
                'hoursPerWeek',
                this.i18n.PositionFormCtrl_HoursPerWeek
              )
              .validatedBy(Validators.min(1))
              .withHelp('PositionFormCtrl_WholeWeekHours')
              .withErrorMessages({
                min: this.translate.instant('Core_InvalidMinValue', { min: 1 }),
              })
              .ofType('number')
              .styledBy('grid__col-sm-4 guts-p-l-1 m-guts-p-l-0 guts-p-b-1')
              .build(),
          ],
          'df-form__row'
        )
        .styledBy('grid grid--bleed')
        .build(),
      this.builder
        .customField(
          'level',
          this.i18n.PositionFormCtrl_Seniority,
          this.seniority
        )
        .build(),
      this.builder.descriptionLong().styledBy('guts-m-b-1 ').build(),
      this.builder
        .customField('tags', 'Core_Skills', this.skillsViewRef, {
          topTags: this.userInterests,
        })
        .unwrapped() // The tag editor has its own label and doesn't require validation, so present it sans fresco's default field wrapper
        .build(),
    ];
  }

  protected initFormModel(input: Partial<UserPosition>) {
    this.model.title = input.title;
    this.model.description = input.description;
    this.model.isCurrent = input.isCurrent ?? false;
    this.model.level = input.level || ExperienceModalComponent.defaultSeniority;
    this.model.organizationName = input.organizationName;
    this.model.extent = {
      startDate: input.startDate ? new Date(input.startDate) : undefined,
      endDate: input.endDate ? new Date(input.endDate) : undefined,
      hoursPerWeek: (
        input.hoursPerWeek ?? ExperienceModalComponent.defaultHoursPerWeek
      ).toString(),
    };
    this.model.tags = input.tags ?? [];
  }

  protected createResult(
    model: ExperienceFormModel,
    defaults: Partial<UserPosition>
  ) {
    const result: AnyUserPositionParametersViewModel = {
      ...defaults,
      title: model.title,
      startDate: this.datePipe.transform(model.extent.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(model.extent.endDate, 'yyyy-MM-dd'),
      hoursPerWeek: parseInt(model.extent.hoursPerWeek),
      level: model.level,
      tags: model.tags,
      organizationName: model.organizationName,
      description: model.description,
      isCurrent: model.isCurrent,
    };
    return result;
  }
}
