import { Inject, Injectable } from '@angular/core';
import { SkillAssessments } from '@app/orgs/taxonomy-api.model';
import { Tag as PascalCaseTag } from '@app/shared/ajs/pascal-cased-types.model';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { camelCaseKeys, getDeepCopy } from '@app/shared/utils/property';
import {
  getPendingEvaluation,
  getPendingManagerRating,
  getPendingSelfRating,
  isRatingTypeAvailable,
} from '@app/shared/utils/tag-helpers';
import { WindowToken } from '@app/shared/window.token';
import { RequestSelfRatingModalComponent } from '@app/tags/components/request-self-rating-modal/request-self-rating-modal.component';
import {
  AssociateRatingModalActions,
  TagCompletedAssociateRatingModalComponent,
} from '@app/tags/components/tag-completed-associate-rating-modal/tag-completed-associate-rating-modal.component';
import { TagLevelDescriptionModalComponent } from '@app/tags/components/tag-level-description-modal/tag-level-description-modal.component';
import { TagRatingEndorsementCompletedModalComponent } from '@app/tags/components/tag-rating-endorsement-completed-modal/tag-rating-endorsement-completed-modal.component';
import { TagRatingModalMultiModeComponent } from '@app/tags/components/tag-rating-modal-multi-mode/tag-rating-modal-multi-mode.component';
import { TagRatingModalComponent } from '@app/tags/components/tag-rating-modal/tag-rating-modal.component';
import { TagRatingOverviewComponent } from '@app/tags/components/tag-rating-overview/tag-rating-overview.component';
import { TagRatingReviewIntegrationRatingsModalComponent } from '@app/tags/components/tag-rating-review-integration-ratings-modal/tag-rating-review-integration-ratings-modal.component';
import { UpdateSelfRatingComponent } from '@app/tags/components/update-self-rating/update-self-rating.component';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  InternalTagRatingTypes,
  ManagerUserTagRatingInfoResponse,
  RatingModalProperties,
} from '@app/tags/tags';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { startCase } from 'lodash-es';
import {
  EMPTY,
  forkJoin,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TagManagerRatingRequestModalComponent } from '../components/tag-manager-rating-request-modal/tag-manager-rating-request-modal.component';
import { CancelTagRatingRequestModalComponent } from './../components/cancel-tag-rating-request-modal/cancel-tag-rating-request-modal.component';
import { EvaluationService } from './evaluation.service';
import { TagFlagsService } from './tag-flags.service';
import {
  TagRatingTrackerService,
  TagRatingUpdatedActions,
} from './tag-rating-tracker.service';

@Injectable({ providedIn: 'root' })
export class TagRatingService {
  constructor(
    private focusStackService: FocusStackService,
    private authService: AuthService,
    private tagsService: TagsService,
    private translateService: TranslateService,
    private trackerService: TrackerService,
    private modalService: ModalService,
    private tagRatingTrackerService: TagRatingTrackerService,
    private notifierService: NotifierService,
    private http: NgxHttpClient,
    private evaluationService: EvaluationService,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private tagFlagsService: TagFlagsService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get tagRatingRange() {
    return {
      min: this.authService.authUser?.orgRatingScale?.anchorLow,
      max: this.authService.authUser?.orgRatingScale?.anchorHigh,
    };
  }

  public get tagRatingSliderConfig() {
    return {
      floor: this.tagRatingRange.min,
      ceil: this.tagRatingRange.max,
      showTicksValues: true,
      showSelectionBar: true,
      showOuterSelectionBars: false,
    };
  }

  private get authUser() {
    return this.authService.authUser;
  }

  /**
   * Modals
   */

  /**
   * Show a summary of rating information for the given tag for the current user
   *
   * @param event Event
   * @param tag Tag
   * @param searchCollection boolean
   * @param trackingLocation string
   * @returns Observable<void>
   */
  public openSkillModal(
    event,
    tag: TagsApi.TagDetails | Partial<TagsApi.TagDetails> | PascalCaseTag,
    searchCollection: boolean = false,
    trackingLocation?: string,
    focusElement?: (
      tag: TagsApi.TagDetails | Partial<TagsApi.TagDetails> | PascalCaseTag
    ) => HTMLElement
  ): Subscription {
    tag = camelCaseKeys(tag) as TagsApi.TagDetails;
    this.trackerService.trackEventData({
      action: 'Skill Rating Viewed',
      element: event?.target as HTMLElement,
      properties: {
        Location: trackingLocation,
        SkillId: tag.tagId,
        SkillName: tag.name,
        IsFocusSkill: tag.isFocused,
        IsOnProfile: tag.isFollowing,
      },
    });
    return this.modalService
      .show(TagRatingOverviewComponent, {
        inputs: {
          tag,
          searchCollection,
          trackingLocation,
        },
        // we want to handle our own focus
        errorOnDismiss: true,
      })
      .subscribe({
        complete: () => {
          // required to focus on element after modal closes
          if (focusElement) {
            this.focusStackService.push(focusElement(tag));
          } else {
            this.focusStackService.push(event?.target);
          }
          this.focusStackService.pop();
        },
        error: () => {
          // required using errorOnDismiss in the modal service
          return EMPTY;
        },
      });
  }

  /**
   * Display modal for updating self ratings for all users skills
   * @param trackingLocation string
   * @param tags Tag[]
   */
  public openMultiRatingModal(
    trackingLocation: string,
    tags: TagsApi.Tag[]
  ): Observable<string> {
    return this.modalService.show(TagRatingModalMultiModeComponent, {
      inputs: {
        tags,
        trackingLocation,
      },
    });
  }

  /**
   * Show completed user endorsement details
   * @param notification Notification
   */
  public openEndorsementCompletedModal(notification): Observable<void> {
    return this.modalService.show(TagRatingEndorsementCompletedModalComponent, {
      inputs: {
        notification,
      },
    });
  }

  /**
   * Show the attribute descriptions for each tag rating level
   * @param level
   */
  public openLevelDescriptionsModal(level: number | string): Observable<void> {
    return this.modalService.show(TagLevelDescriptionModalComponent, {
      windowClass: 'modal--secondary',
      inputs: {
        level,
        sliderConfig: {
          ...this.tagRatingSliderConfig,
          ariaLabel: this.translateService.instant('dgTagRating_Levels'),
        },
      },
    });
  }

  /**
   * Open modal that allows user to set their own self rating level
   */
  public openSelfRatingModal(
    event: Event,
    tag: TagsApi.Tag,
    forceNewRating?: boolean
  ): Observable<TagsApi.UserTagRating> {
    this.tagRatingTrackerService.trackRatingUpdateInitiated(
      event.target as HTMLElement,
      tag,
      InternalTagRatingTypes.self,
      this.authUser?.viewerProfile?.userProfileKey // logged in user is the owner here
    );

    const title = this.translateService.instant(
      'dgTagRating_RatingTitleFormat',
      { tagName: tag.title }
    );
    const modal = this.openRatingModal({
      event,
      title,
      tag,
      ratingType: InternalTagRatingTypes.self,
      initialValue: forceNewRating ? 1 : undefined, // we're adding a new rating, ignore `Rating.Level`
    });

    modal.subscribe((rating: TagsApi.UserTagRating) => {
      if (rating) {
        if (!tag.isFollowing) {
          this.tagsService.addUserTag(tag).subscribe();
        }
        this.tagsService
          .rateTag(
            (rating.level as unknown) as number,
            InternalTagRatingTypes.self,
            tag,
            null,
            event.target as HTMLElement
          )
          .subscribe();
      }
    });

    return modal;
  }

  /**
   * Open a generic modal for setting a skill rating. Used for both self and third party ratings.
   */
  public openRatingModal(
    ratingModal: RatingModalProperties
  ): Observable<TagsApi.UserTagRating> {
    const {
      event,
      title,
      tag,
      ratingType,
      emptyRating,
      showComments,
      placeholderText,
      initialValue,
      instructionsText,
      addNewSkill,
      windowClass,
      updateHistoryData,
      errorOnDismiss,
      headerOptions,
      bodyClasses,
      bodyStyle,
      allowClearRating,
      requester,
    } = ratingModal;

    const targetRating = this.getSkillTarget(tag);
    return this.modalService.show(TagRatingModalComponent, {
      windowClass,
      inputs: {
        currentRating: getLevelWithNoDefault(initialValue, tag as TagsApi.Tag),
        targetRating,
        ratingType,
        emptyRating,
        requester,
        title,
        comment: undefined,
        showComments,
        allowClearRating,
        addNewSkill,
        updateHistoryData,
        isHeaderBorderless: headerOptions?.isBorderless ? true : false,
        isHeaderCentered: headerOptions?.isCentered ? true : false,
        bodyClasses,
        bodyStyle,
        maxMessageLength: ratingModal.maxMessageLength || undefined,
      },
      errorOnDismiss: !!errorOnDismiss,
    });
  }

  /**
   * Open a generic modal for setting a skill rating. Used for both self and third party ratings.
   */
  public openRequestSelfRatingModal(ratingModal: RatingModalProperties) {
    const {
      title,
      placeholderText,
      windowClass,
      errorOnDismiss,
      headerOptions,
      bodyClasses,
      bodyStyle,
      userData,
    } = ratingModal;

    return this.modalService.show(RequestSelfRatingModalComponent, {
      windowClass,
      inputs: {
        title,
        placeholderText,
        comment: undefined,
        isHeaderBorderless: headerOptions?.isBorderless ? true : false,
        isHeaderCentered: headerOptions?.isCentered ? true : false,
        bodyClasses,
        bodyStyle,
        userData,
      },
      errorOnDismiss: !!errorOnDismiss,
    });
  }

  /**
   * Open a modal to request a user with a rating to re-rate themselves
   */
  public openUpdateSelfRatingModal(ratingModal: RatingModalProperties) {
    const {
      event,
      windowClass,
      errorOnDismiss,
      headerOptions,
      bodyClasses,
      bodyStyle,
      tag,
      userData,
      updateHistoryData,
    } = ratingModal;

    return this.modalService.show(UpdateSelfRatingComponent, {
      windowClass,
      inputs: {
        isHeaderBorderless: !!headerOptions?.isBorderless,
        isHeaderCentered: !!headerOptions?.isCentered,
        bodyClasses,
        bodyStyle,
        tag,
        userData,
        updateHistoryData,
      },
      errorOnDismiss: !!errorOnDismiss,
    });
  }

  /**
   * Request a tag rating from another user
   */
  public openRatingRequestModal(
    event: Event,
    ratingInfo: any,
    rater: Partial<UserProfileSummary>
  ) {
    return this.modalService.show(RequestSelfRatingModalComponent, {
      inputs: {
        ratingInfo: camelCaseKeys(ratingInfo),
        rater: rater ? camelCaseKeys(rater) : undefined,
      },
    });
  }

  /**
   * Confirm canceling rating request
   * @param event
   * @param type
   * @param tag
   * @param rating
   */
  public openCancelRatingRequestModal(
    event: Event,
    type:
      | InternalTagRatingTypes.manager
      | InternalTagRatingTypes.peer
      | InternalTagRatingTypes.self,
    tag: TagsApi.TagDetails,
    rating: Partial<TagsApi.UserTagRatingDetails>,
    userData?
  ) {
    let headerText, notifyMessage;
    let bodyTextKey = rating.rater
      ? 'TagRating_CancelRequestConfirmationFormat'
      : 'TagRating_CancelRequestConfirmationFormatNoManager';
    let bodyText = this.translateService.instant(bodyTextKey, {
      tagName: tag.title,
      raterName: rating.rater?.name,
    });
    switch (type) {
      case InternalTagRatingTypes.manager:
        headerText = 'TagRating_PendingManagerRequest';
        notifyMessage = 'dgTagRating_MngrRatingRequestCancelled';
        break;
      case InternalTagRatingTypes.self:
        bodyTextKey = 'TagRating_CancelRequestConfirmationFormat';
        bodyText = this.translateService.instant(bodyTextKey, {
          tagName: tag.title,
          raterName: userData.name,
        });
        headerText = 'dgTagRating_PendingSelfRatingRequest';
        notifyMessage = 'dgTagRating_SelfRatingRequestCancelled';
        break;
      case InternalTagRatingTypes.peer:
        headerText = 'TagRating_PendingPeerRequest';
        notifyMessage = 'dgTagRating_PeerRatingRequestCancelled';
        break;
    }
    const cancelButtonText = this.translateService.instant(
      'TagRating_KeepRequest'
    );
    const submitButtonText = this.translateService.instant(
      'dgTagRating_CancelRequest'
    );
    const inputs: SimpleModalInputBindings = {
      bodyText,
      canCancel: true,
      cancelButtonText,
      submitButtonText,
      headerText: this.translateService.instant(headerText),
    };

    const raterUserProfileKey =
      rating.rater?.userProfileKey ??
      rating.raterProfileKey ??
      userData?.userProfileKey;

    return this.modalService
      .show(CancelTagRatingRequestModalComponent, { inputs })
      .pipe(
        mergeMap(() => {
          if (type === InternalTagRatingTypes.self) {
            return this.tagsService.deleteTagRating(
              rating.userTagRatingId,
              this.authUser.defaultOrgId
            );
          } else {
            return this.tagsService.deleteTagRating(rating.userTagRatingId);
          }
        }),
        tap(() => {
          this.tagRatingTrackerService.trackRatingRequested(
            event.target as HTMLElement,
            tag,
            type,
            'cancelled',
            raterUserProfileKey,
            // non-owners (managers) can cancel rating requests so we get the owner from the rating
            rating.userProfileKey
          );

          this.notifierService.showSuccess(
            this.translateService.instant(notifyMessage)
          );

          this.tagsService.notifyUserTagsModified();
        })
      );
  }

  /**
   * Confirm canceling Manager Rating request
   * @param event Event
   * @param item Tag
   */
  public openCancelManagerRatingRequestModal(
    event: Event,
    item: TagsApi.Tag | PascalCaseTag
  ): Observable<any> {
    const tag: TagsApi.TagDetails = camelCaseKeys(item);
    // Since `Tag.Ratings` is usually a set of `UserTagRating`s, we need
    // to get `UserTagRatingDetails` for the `Rater` data
    return this.tagsService
      .getTagRatingDetails(
        this.authService.authUser.viewerProfile.userProfileKey,
        tag.tagId
      )
      .pipe(
        switchMap((ratings) => {
          const rating = getPendingManagerRating(ratings);
          return this.openCancelRatingRequestModal(
            event,
            InternalTagRatingTypes.manager,
            tag,
            rating
          );
        })
      );
  }

  /**
   * Confirm canceling Self Rating request
   * @param event Event
   * @param tag Tag
   * @param ratings Array of ratings
   * @param userData User Data for user with requested rating
   */
  public openCancelSelfRatingRequestModal(
    event: Event,
    tag: TagsApi.TagDetails,
    ratings: Partial<TagsApi.UserTagRatingDetails>[],
    userData
  ) {
    const rating = getPendingSelfRating(ratings);
    return this.openCancelRatingRequestModal(
      event,
      InternalTagRatingTypes.self,
      tag,
      rating,
      userData
    );
  }

  /**
   * Confirm canceling an in-progress Skill Review
   * @param target EventTarget
   * @param item Tag
   */
  public openCancelSkillReviewModal(
    target: EventTarget,
    item: TagsApi.Tag | PascalCaseTag
  ): Subscription {
    const tag: TagsApi.TagDetails = camelCaseKeys(item);
    // Since `Tag.Ratings` is usually a set of `UserTagRating`s, we need
    // to get `UserTagRatingDetails` for the `ExternalId`
    return this.tagsService
      .getTagRatingDetails(
        this.authService.authUser.viewerProfile.userProfileKey,
        tag.tagId
      )
      .pipe(
        switchMap((ratings) => {
          const { externalId } = getPendingEvaluation(ratings);
          const bodyText = this.translateService.instant(
            'confirmModal_EvalCancelApplicationBody',
            { tagName: tag.title }
          );
          const cancelButtonText = this.translateService.instant(
            'EvalApp_Stay'
          );
          const submitButtonText = this.translateService.instant(
            'EvalApp_Cancel'
          );
          const inputs: SimpleModalInputBindings = {
            bodyText,
            canCancel: true,
            cancelButtonText,
            submitButtonText,
          };
          return this.modalService.show(SimpleModalComponent, { inputs }).pipe(
            mergeMap(() =>
              this.evaluationService.deleteUserEvaluation(externalId)
            ),
            tap(() => {
              this.tagRatingTrackerService.trackSkillRatingUpdated(
                InternalTagRatingTypes.evaluation,
                TagRatingUpdatedActions.cancelled,
                null,
                null,
                tag,
                null,
                null,
                target as HTMLElement
              );
              this.notifierService.showSuccess(
                this.translateService.instant('EvaluationSvc_DeleteSuccess')
              );
              this.tagsService.notifyUserTagsModified();
            })
          );
        })
      )
      .subscribe();
  }

  /**
   * Confirm force starting a new Skill Review
   * @param target EventTarget
   * @param item Tag
   */
  public openForceStartSkillReviewModal(
    target: EventTarget,
    item: TagsApi.Tag | PascalCaseTag
  ): void {
    const tag: TagsApi.TagDetails = camelCaseKeys(item);
    const bodyText = this.translateService.instant(
      'confirmModal_EvalRestartApplicationBody'
    );
    const submitButtonText = this.translateService.instant('Core_Start');
    const inputs: SimpleModalInputBindings = {
      bodyText,
      canCancel: true,
      submitButtonText,
    };
    this.modalService
      .show(SimpleModalComponent, { inputs })
      .subscribe(() =>
        this.evaluationService.forceStartEvaluation(tag, target as HTMLElement)
      );
  }

  /**
   * Request a tag rating from users manager
   */
  public openManagerRatingRequestModal(
    event: Event,
    tag: TagsApi.TagDetails,
    rater?: Partial<UserProfileSummary>
  ): Observable<any> {
    this.tagRatingTrackerService.trackRatingUpdateInitiated(
      event.target as HTMLElement,
      tag,
      InternalTagRatingTypes.manager,
      this.authUser?.viewerProfile?.userProfileKey // logged in user is the owner here
    );
    return this.modalService
      .show(TagManagerRatingRequestModalComponent, {
        inputs: {
          tagId: tag.tagId,
          rater: rater ? camelCaseKeys(rater) : undefined,
        },
      })
      .pipe(
        tap((manager) => {
          const { userProfileKey } = manager;
          this.tagRatingTrackerService.trackRatingRequested(
            event.target as HTMLElement,
            tag,
            InternalTagRatingTypes.manager,
            'requested',
            userProfileKey,
            this.authUser?.viewerProfile.userProfileKey // logged in user is the owner here
          );
          return of(manager);
        })
      );
  }

  /**
   * View a completed skill rating provided by another user (manager/peer etc)
   * @param event Event
   * @param ratingInfo
   * @param allowRatingRequest boolean
   * @param trackingLocation string
   */
  public openCompletedAssociateRatingModal(
    event: Event,
    ratingInfo: Partial<TagsApi.UserTagRatingDetails>,
    allowRatingRequest: boolean = false,
    requestNewRating?: (event: Event) => any,
    trackingLocation?: string
  ): Subscription {
    // Placed here instead of in TagCompletedAssociateRatingModalComponent to avoid circular dependency
    const viewTagDetails = () => {
      const tag = this.tagsService.getTag(ratingInfo.tagId);
      // `getTag` response doesn't include `ratings` so...
      const ratingDetails = this.tagsService.getTagRatingDetails(
        ratingInfo.userProfileKey,
        ratingInfo.tagId
      );
      return forkJoin([tag, ratingDetails]).subscribe(
        ([tag, ratingDetails]) => {
          tag.ratings = ratingDetails;
          this.openSkillModal(event, tag, null, trackingLocation);
        }
      );
    };
    // Placed here instead of in TagCompletedAssociateRatingModalComponent to avoid circular dependency
    const viewLevelDescriptions = () => {
      this.openLevelDescriptionsModal(ratingInfo.level);
    };

    return this.modalService
      .show(TagCompletedAssociateRatingModalComponent, {
        inputs: {
          ratingInfo,
          allowRatingRequest,
        },
      })
      .pipe(
        tap(({ action, event }) => {
          const actions = {
            [AssociateRatingModalActions.RequestNewRating]: requestNewRating,
            [AssociateRatingModalActions.ViewTagDetails]: viewTagDetails,
            [AssociateRatingModalActions.ViewLevelDescriptions]: viewLevelDescriptions,
          };
          setTimeout(() => {
            // need timeout so the old modal can close & focus correctly
            actions[action]?.(event);
          }, 100);
        })
      )
      .subscribe();
  }

  /**
   * Open the modal to review integration skill assessments
   *
   * @param skillAssessments{SkillAssessments[]} array of integration skill assessments
   * @param canCanel {boolean} Can the modal canel
   * @param submitButtonText {string} Submit button text
   * @param headerText {string} Modal header text
   * @param isReviewOnly {boolean} Can the skills be added to the users profile or only for reiewing skills
   * @param providerName {string} Integration name of the skills
   */
  public openReviewIntegrationSkillsModal(
    skillAssessments: SkillAssessments[],
    submitButtonText: string,
    headerText: string,
    isReviewOnly: boolean,
    providerName: string
  ): Observable<void> {
    const skillAssessmentscamelCaseKeys = skillAssessments.map((skill) =>
      camelCaseKeys(skill)
    );
    const inputs = {
      skillAssessments: skillAssessmentscamelCaseKeys,
      submitButtonText,
      headerText,
      isReviewOnly,
      providerName,
    };
    return this.modalService.show(
      TagRatingReviewIntegrationRatingsModalComponent,
      {
        inputs,
      }
    );
  }

  /**
   * Set the skill target level for another user
   */
  public openSkillTargetModal(
    event: Event,
    requesterData: Partial<UserProfileSummary>,
    title: string,
    tag: TagsApi.Tag,
    initialValue?: number,
    onSave?,
    updateHistoryData?
  ) {
    const placeholderText = this.translateService.instant(
      'dgTagRating_TargetCommentPlaceholderFormat',
      { name: requesterData.name }
    );
    const modal = this.openRatingModal({
      event,
      title,
      tag,
      ratingType: InternalTagRatingTypes.target,
      showComments: true,
      placeholderText,
      initialValue,
      updateHistoryData,
    });

    this.trackerService.trackEventData({
      action: 'Skill Target Viewed',
      category: 'Skill Target',
      label: 'Skill Target Modal Opened',
      properties: { SkillName: tag.name, SkillId: tag.tagId },
    });

    return modal.subscribe(
      (rating: TagsApi.UserTagRating) => {
        const initialTag = getDeepCopy(tag);
        rating.comment = this.htmlToPlaintextPipe.transform(
          rating?.comment,
          true
        );
        if (onSave) {
          onSave(rating.level); // assume success
        }
        this.tagsService
          .rateTagForUser(
            requesterData.userProfileKey,
            (rating.level as unknown) as number,
            rating.comment,
            initialTag,
            InternalTagRatingTypes.target,
            requesterData
          )
          .subscribe();
      },
      () => {
        this.trackerService.trackEventData({
          action: 'Skill Target Canceled',
          category: 'Skill Target',
          label: 'Skill Target Modal Canceled',
          properties: {
            SkillName: tag.name,
            SkillId: tag.tagId,
          },
        });
      }
    );
  }

  /**
   * Display modal for notifications to a rater for associate rating requests
   *
   * @param requester UserProfileSummary
   * @param tagData { tagId, tagName }
   */
  // TODO: Refactor this to handle all rating requests (manager/peer/goal/self(?))
  public processAssociateRatingRequested(
    requester: Partial<UserProfileSummary>,
    tagData: { tagId: number; name: string }
  ) {
    const associate = camelCaseKeys(requester);
    this.tagsService
      .getRequestedTagRating(
        associate.userProfileKey,
        tagData.tagId,
        InternalTagRatingTypes.manager
      )
      .subscribe((rating: TagsApi.UserTagRating) => {
        if (rating && !rating.dateCompleted) {
          const modalTitle = this.translateService.instant(
            'dgTagRating_RatingOtherTitleFormat',
            {
              name: associate.name,
              tagName: tagData.name,
              ratingType: this.translateService.instant(
                'dgTagRating_ManagerAssessment'
              ),
            }
          );
          const modalPlaceholderText = this.translateService.instant(
            'dgTagRating_CommentPlaceholderFormat',
            { name: associate.name }
          );
          this.openRatingModal({
            title: modalTitle,
            tag: tagData,
            ratingType: InternalTagRatingTypes.manager,
            showComments: true,
            placeholderText: modalPlaceholderText,
            maxMessageLength: 4000,
            allowClearRating: false,
            requester: associate,
          }).subscribe((rating: TagsApi.UserTagRating) => {
            this.tagsService
              .rateTagForUser(
                associate.userProfileKey,
                (rating.level as unknown) as number,
                this.htmlToPlaintextPipe.transform(rating.comment),
                tagData as TagsApi.TagDetails,
                InternalTagRatingTypes.manager,
                requester
              )
              .subscribe();
          });
        } else if (rating === null || rating.dateCompleted) {
          // This will show if...
          // - The requester cancelled the request before the rater had a chance to complete it
          // - The rater already completed the rating, but clicked on the request notifiation/email link again
          this.openSimpleConfirmModal(
            'dgTagRating_RequestNotValid',
            'Core_Okay'
          );
        }
      });
  }

  /**
   * Display modal for notifications to a requester for associate
   * tag ratings (manager/peer/goal) completed by the rater
   *
   * @param type Manager | Peer | Goal
   * @param rater UserProfileSummary
   * @param tagId number
   */
  public processAssociateRatingCompleted(
    type: string,
    rater: Partial<UserProfileSummary>,
    tagId: number
  ) {
    this.getTagRatings(
      this.authUser.viewerProfile.userProfileKey,
      tagId
    ).subscribe((ratings: TagsApi.UserTagRatingDetails[]) => {
      if (
        !ratings.length ||
        (ratings.length === 1 && ratings[0].type !== type)
      ) {
        // rating request cancelled
        return this.openSimpleConfirmModal(
          'dgTagRating_RatingNotValid',
          'Core_Okay'
        );
      }
      // Get the latest rating
      let ratingInfo = ratings
        .filter(
          (rating) =>
            rating.type === type &&
            rating.raterProfileKey === rater.userProfileKey
        )
        .pop();
      if (!ratingInfo) {
        return;
      }
      ratingInfo = {
        ...ratingInfo,
        rater,
      } as TagsApi.UserTagRatingDetails;
      // Show completed rating
      if (ratingInfo.dateCompleted) {
        return this.openCompletedAssociateRatingModal(
          null,
          ratingInfo,
          false,
          null,
          'Notifications'
        );
      }
      // A new rating is pending
      return this.openSimpleConfirmModal(
        'dgTagRating_RatingNotValidNewRating',
        'Core_Okay'
      );
    });
  }

  /**
   * Display modal for notifications regarding requested Self ratings
   *
   * @param requestor UserProfileSummary
   * @param tagId number
   * @param tagName string
   * @param comments? string
   */
  public processSelfRatingRequested(
    requestor: Partial<UserProfileSummary>,
    tagId: number,
    tagName: string,
    comments?: string
  ) {
    return this.tagsService
      .getRequestedTagRating(
        this.authUser.viewerProfile.userProfileKey,
        tagId,
        InternalTagRatingTypes.self
      )
      .subscribe((rating: TagsApi.UserTagRating) => {
        if (rating && !rating.dateCompleted) {
          const title = this.translateService.instant(
            'dgTagRating_RatingOtherTitleFormat',

            {
              name: requestor.name,
              tagName: tagName,
              ratingType: this.translateService.instant(
                'dgTagRating_PeerRating'
              ),
            }
          );

          return this.modalService
            .show(TagRatingModalComponent, {
              inputs: {
                ratingType: InternalTagRatingTypes.self,
                title,
                showComments: false,
                allowClearRating: false,
              },
            })
            .subscribe((response: any) => {
              this.tagsService
                .rateTag(
                  response.level,
                  InternalTagRatingTypes.self,
                  {
                    tagId,
                    name: tagName,
                  } as TagsApi.Tag,
                  null,
                  event?.target ? (event.target as HTMLElement) : null
                )
                .subscribe();
            });
        } else if (rating === null || rating.dateCompleted) {
          // The rating was either cancelled or previously completed
          this.openSimpleConfirmModal(
            'dgTagRating_RequestNotValid',
            'Core_Okay'
          );
        }
      });
  }

  /**
   * Display modal for notifications regarding requested Peer ratings
   *
   * @param requestor UserProfileSummary
   * @param tagId number
   * @param name string
   * @param comments? string
   */
  // TODO: refactor to use `processAssociateRatingRequested` instead
  public processPeerRatingRequested(
    requester: Partial<UserProfileSummary>,
    tagId: number,
    name: string,
    comments?: string
  ) {
    return this.tagsService
      .getRequestedTagRating(
        requester.userProfileKey,
        tagId,
        InternalTagRatingTypes.peer
      )
      .subscribe((rating: TagsApi.UserTagRating) => {
        if (rating && !rating.dateCompleted) {
          const title = this.translateService.instant(
            'dgTagRating_RatingOtherTitleFormat',

            {
              name: requester.name,
              tagName: name,
              ratingType: this.translateService.instant(
                'dgTagRating_PeerRating'
              ),
            }
          );

          return this.modalService
            .show(TagRatingModalComponent, {
              inputs: {
                ratingType: InternalTagRatingTypes.peer,
                requester: requester,
                title,
                showComments: true,
                allowClearRating: false,
              },
            })
            .subscribe((response: any) => {
              this.tagsService
                .rateTagForUser(
                  requester.userProfileKey,
                  response?.level,
                  this.htmlToPlaintextPipe.transform(response.comment),
                  {
                    tagId,
                    name,
                  } as TagsApi.TagDetails,
                  InternalTagRatingTypes.peer,
                  requester
                )
                .subscribe();
            });
        } else if (rating === null || rating.dateCompleted) {
          // The rating was either cancelled or previously completed
          this.openSimpleConfirmModal(
            'dgTagRating_RequestNotValid',
            'Core_Okay'
          );
        }
      });
  }

  public showManagerRatingModal(
    requesterData,
    tagData,
    onSave?,
    ratingHistory?
  ) {
    const initialValue = tagData.level;
    const initialTag = getDeepCopy(tagData);
    const modalTitle = this.translateService.instant(
        'dgTagRating_RatingOtherTitleFormat',
        {
          name: requesterData.name,
          tagName: tagData.tagName,
          ratingType: this.translateService.instant(
            'dgTagRating_ManagerAssessment'
          ),
        }
      ),
      modalPlaceholderText = this.translateService.instant(
        'dgTagRating_CommentPlaceholderFormat',
        { name: requesterData.name }
      );

    const modal = this.openRatingModal({
      event: undefined,
      title: modalTitle,
      tag: tagData,
      ratingType: InternalTagRatingTypes.manager,
      showComments: true,
      placeholderText: modalPlaceholderText,
      initialValue,
      updateHistoryData: ratingHistory,
      requester: requesterData,
    });

    modal.subscribe((rating: TagsApi.UserTagRating) => {
      if (onSave) {
        onSave(rating.level); // assume success
      }
      this.tagsService
        .rateTagForUser(
          requesterData.userProfileKey,
          (rating.level as unknown) as number,
          this.htmlToPlaintextPipe.transform(rating.comment),
          initialTag,
          InternalTagRatingTypes.manager,
          requesterData
        )
        .subscribe();
    });
  }

  /**
   * For a manager to request a subordinate to complete a self rating
   *
   * @param userData
   * @param tagData
   * @param onSave
   */
  public showRequestSelfRatingModal(userData, tagData, onSave?) {
    const modalTitle = this.translateService.instant(
        'PersonalSkillView_RequestSelfRating_Title',
        {
          skillTitle: tagData.tagName,
        }
      ),
      modalPlaceholderText = this.translateService.instant(
        'Core_OptionalMessage_Request'
      );

    const modal = this.openRequestSelfRatingModal({
      event: undefined,
      title: modalTitle,
      tag: tagData,
      placeholderText: modalPlaceholderText,
      userData,
    });

    modal.subscribe((userData: any) => {
      this.tagsService
        .requestSelfTagRating(
          tagData.tagId,
          userData.userProfileKey,
          userData.userProfileKey,
          null,
          this.htmlToPlaintextPipe.transform(userData.comment)
        )
        .subscribe({
          next: () => {
            if (onSave) {
              onSave(); // assumes success
            }
          },
        });
    });
  }

  /**
   * Opens update self rating modal
   *
   * @param userData
   * @param tagData
   * @param onSave
   */
  public showUpdateSelfRatingModal(
    userData,
    tagData,
    updateHistoryData = null,
    onSave?
  ) {
    const modal = this.openUpdateSelfRatingModal({
      event: undefined,
      tag: tagData,
      userData,
      updateHistoryData,
    });

    modal.subscribe(() => {
      if (onSave) {
        onSave(); // assumes success
      }
    });
  }

  /**
   * Returns the aria label for the tag rating donut
   */
  public getAriaLabelForRatingDonut(
    level: string | number,
    inProgress: boolean,
    tag: TagsApi.Tag
  ): string {
    if (level) {
      return this.translateService.instant(
        'dgTagRating_A11ySkillReviewComplete',
        {
          skillName: tag.title,
          levelNumber: level,
        }
      );
    }

    if (inProgress) {
      return this.translateService.instant(
        'dgTagRating_A11ySkillReviewInProgress',
        {
          skillName: tag.title,
        }
      );
    }

    return this.translateService.instant('dgTagRating_A11yAddSkillReview', {
      skillName: tag.title,
    });
  }

  /**
   * API methods
   */

  public canUserOptIntoTeams(): Observable<boolean> {
    const params = { orgId: this.authService.authUser?.defaultOrgId };
    return !this.authService.isLoggedIn || this.authService.isConsumerUser
      ? of(false)
      : this.http.get<boolean>('/managers/CanUserOptIntoTeams', { params });
  }

  /**
   * Get user tag rating history by type
   *
   * @param orgId
   * @param userProfileKey
   * @param type
   * @param tagId
   */
  public getUserTagRatingInfo(
    orgId: number,
    userProfileKey: number,
    type: InternalTagRatingTypes,
    tagId: number
  ): Observable<ManagerUserTagRatingInfoResponse[]> {
    return this.http.get(`/managers/GetUserTagRatingInfo`, {
      params: {
        orgId,
        userProfileKey,
        type,
        tagId,
      },
    });
  }

  /**
   * Get the latest ratings for each rating type (credential/evaluation/manager/self/external)
   * @param userKey number
   * @param tagId number
   */
  public getTagRatings(
    userKey: number,
    tagId: number
  ): Observable<TagsApi.UserTagRating[]> {
    return this.http
      .get<TagsApi.UserTagRating[]>('/tag/GetTagRatings', {
        params: { userKey, tagId },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant('TagsSvc_GetTagRatingError'),
              error
            )
          )
        )
      );
  }

  /**
   * Helper methods
   */

  /**
   * Determine whether the given rating type is available for ANY of the given tags
   * @param tags Tag[]
   * @param type ratingTypeName
   * @returns boolean
   */
  // TODO: move to tag-helpers.ts? (after LD flag check is removed)
  public tagsHaveRatingTypeAvailable(
    tags: TagsApi.Tag[],
    type: string
  ): boolean {
    return tags.some((tag) => isRatingTypeAvailable(tag, type));
  }

  /**
   * Determine whether the current user may ADD a rating type for the given tag
   * @param tag Tag
   * @param ratingType string
   * @param isOwner boolean
   */
  public mayAddTagRatingType(
    tag: TagsApi.Tag,
    ratingType: string,
    isOwner: boolean
  ): boolean {
    const authUser = this.authService.authUser;
    const isAvailable = isRatingTypeAvailable(tag, ratingType);
    if (!isOwner || !authUser) {
      return false;
    }
    switch (ratingType) {
      case InternalTagRatingTypes.credential:
        return false;
      case InternalTagRatingTypes.evaluation:
        return isAvailable && authUser?.canEvaluateSkills;
      case InternalTagRatingTypes.manager:
        return isAvailable && authUser?.canRequestManagerRating;
      case InternalTagRatingTypes.peer:
        return (
          isAvailable &&
          authUser?.canRequestPeerRating &&
          this.tagFlagsService.showPeerRatings
        );
      case InternalTagRatingTypes.self:
        return isAvailable;
      default:
        // Fall through captures external ratings (Pluralsight etc)
        return isAvailable;
    }
  }

  /*
   * Load modals to request a tag rating or view a completed tag rating
   * Note: When a user clicks on a tag rating email notification, this is called when the app first initializes
   */
  public checkForRatingEmails() {
    // Parse query string
    const queryString = this.windowRef.location.hash
      ? window.location.hash.substring(1)
      : this.windowRef.location.search;
    const params = new URLSearchParams(queryString);
    let erate = params.get('erate') as any,
      eaction = params.get('eaction') as any,
      etagid = params.get('etagid') as any,
      etagname = params.get('etagname') as any,
      euid = params.get('euid') as any,
      ecomment = params.get('ecomment') as any;

    if (!this.authUser || !erate) {
      return;
    }
    if (
      erate === 'view' /* old manager rating completed */ ||
      eaction === 'view' /* new manager/peer completed */
    ) {
      if (erate === 'view') {
        erate = InternalTagRatingTypes.manager;
      }
      // Rating completed: open a modal to view the rating
      this.tagsService
        .getTagRatingRater(
          this.authUser.viewerProfile.userProfileKey,
          etagid,
          euid
        )
        .subscribe((rater) => {
          this.processAssociateRatingCompleted(startCase(erate), rater, etagid);
        });
    } else {
      // Rating requested: open a modal to complete the rating
      this.tagsService
        .getTagRatingRequester(
          euid,
          etagid,
          this.authUser.viewerProfile.userProfileKey
        )
        .subscribe((requester) => {
          // TODO: refactor after `processAssociateRatingRequested` is refactored to handle all types (manager/peer etc)
          switch (erate.toLowerCase()) {
            case InternalTagRatingTypes.manager.toLowerCase():
              this.processAssociateRatingRequested(requester, {
                tagId: etagid,
                name: etagname,
              });
              break;
            case InternalTagRatingTypes.peer.toLowerCase():
              this.processPeerRatingRequested(
                requester,
                etagid,
                etagname,
                ecomment
              );
              break;
            case InternalTagRatingTypes.self.toLowerCase():
              this.processSelfRatingRequested(euid, etagid, etagname, ecomment);
              break;
          }
        });
    }
  }

  public getSkillTarget(tag: Partial<TagsApi.TagDetails>) {
    let skillTarget;
    if (tag.requiredBySkillStandard) {
      skillTarget = +tag.requiredSkillStandardProficiencyLevel;
    } else {
      if (tag.ratings) {
        skillTarget = tag.ratings
          .filter((r) => r.type === InternalTagRatingTypes.target)
          .reduce((acc, curr) => {
            const level = +curr.level;
            if (level > acc) {
              acc = level;
            }
            return acc;
          }, 0);
      }
    }
    return skillTarget ? skillTarget : null;
  }

  private openSimpleConfirmModal(messageKey: string, buttonKey: string) {
    const bodyText = this.translateService.instant(messageKey);
    const submitButtonText = this.translateService.instant(buttonKey);
    const inputs: SimpleModalInputBindings = {
      bodyText,
      submitButtonText,
      canCancel: false,
    };
    return this.modalService.show(SimpleModalComponent, { inputs });
  }
}

function getLevel(initialValue: number, tagData: TagsApi.Tag) {
  if (initialValue !== undefined) {
    return initialValue;
  }
  const level =
    tagData.rating && tagData.rating.level ? tagData.rating.level : 1;
  return level;
}

function getLevelWithNoDefault(initialValue: number, tagData: TagsApi.Tag) {
  if (initialValue !== undefined) {
    return initialValue;
  }
  const level = tagData?.rating?.level;
  return level;
}
