import { Inject, Injectable } from '@angular/core';
import { MicrosoftTeamsToken, MSTeamsToken } from '@app/ms-teams/msteams.token';
import { from, Observable } from 'rxjs';
import { authentication, HostClientType } from '@microsoft/teams-js';
import { map } from 'rxjs/operators';

const NAV_ENTITY_ID_PREFIX = 'com.degreed.app.';

@Injectable({ providedIn: 'root' })
export class MSTeamsService {
  constructor(@Inject(MicrosoftTeamsToken) private microsoftTeams: MSTeamsToken) {}

  getAuthToken(): Observable<string> {
    return from(authentication.getAuthToken());
  }

  getHostApp(): Observable<HostClientType> {
    return from(this.microsoftTeams.getContext())
      .pipe(
        map((context) => {
          return context.app.host.clientType;
        }),
      );
  }

  getLocale(): Observable<string> {
    return from(this.microsoftTeams.getContext())
      .pipe(
        map((context) => {
          return context.app.locale;
        }),
      );
  }

  getCurrentTab(): Observable<string> {
    return from(this.microsoftTeams.getContext())
      .pipe(
        map(
          (context) => {
            const entityId = context.page.id.replace(NAV_ENTITY_ID_PREFIX, '');
            return entityId === 'search' ? 'learning/search' : entityId;
          }
        )
      );
  }

  getActivityFeedNotification(): Observable<string | Record<string, string>> {
    return from(this.microsoftTeams.getContext())
      .pipe(
        map((context) => {
          return context.page.subPageId;
        })
      )
  }

  getContext() {
    return from(this.microsoftTeams.getContext());
  }

  initialize(): Promise<void> {
    return this.microsoftTeams.initialize();
  }

  notifySuccess(): void {
    this.microsoftTeams.notifySuccess();
  }

  async initializeAndNotifySuccess(): Promise<void> {
    await this.initialize();
    this.notifySuccess();
  }
}