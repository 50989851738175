import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { AuthService } from '@app/shared/services/auth.service';
import { UploaderService } from '@app/uploader/uploader.service';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { OutcomesFacadeBaseV2 } from '@app/user-content/user-outcome-v2/services/outcomes-facade.base';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { readFirst } from '@dg/shared-rxjs';
import { BehaviorSubject, combineLatest, lastValueFrom, map, take } from 'rxjs';
import { DegreeMappingToAPI, DegreeModel } from '../../degree.model';
import { DegreeMapperService } from '../degree-mapper.service';
import { DegreeTrackerService } from '../degree-tracker.service';
import { DegreeService } from '../degree.service';
// import { DegreeUploadAdapter } from '@app/user-content/user-outcome/degree-modal/degree-upload.adapter';
import { UserOutcomeDegreeService } from '@app/outcomes/services/user-outcome-degree.service';
import { CourseService } from '@app/user-content/course.service';
import { DegreeUploadAdapter } from '../degree-upload.adapter';
import { TipService } from '@app/onboarding/services/tip.service';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable()
export class DegreeGlobalAddFacade extends OutcomesFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<DegreeModel>(undefined);

  constructor(
    public degreeService: DegreeService,
    public degreeMapperService: DegreeMapperService,
    public outcomeNotificationService: OutcomeNotificationService,
    public degreeTrackerService: DegreeTrackerService,
    public userOutcomeService: UserOutcomeService,
    public inputsService: InputsService,
    public outcomesService: OutcomesService,
    public authService: AuthService,
    public uploaderService: UploaderService,
    public courseService: CourseService,
    private userOutcomeDegreeService: UserOutcomeDegreeService,
    public tipService: TipService,
    public globalAddTrackingService: GlobalAddTrackingService
  ) {
    super(outcomesService, tipService);
  }

  protected get viewModel(): DegreeModel {
    return this.viewModel$.value as DegreeModel;
  }

  protected set viewModel(viewModel: DegreeModel) {
    this.viewModel$.next(viewModel);
  }

  public get snapshot(): DegreeModel {
    return readFirst(this.viewModel$);
  }

  public async onNext(url: string): Promise<void> {}

  public async onSubmit(form: FormGroup): Promise<void> {
    const formData = form.value;
    formData.gpa = parseFloat(formData.gpa);
    this.updateViewWithFormData(formData);
    const apiParameters = this.degreeMapperService.toApiParameters(
      this.viewModel as DegreeModel
    );
    this.submissionStatus$.next(SubmissionStatus.Submitting);

    try {
      if (this.viewModel.isEditing) {
        await this.degreeService.updateDegree(apiParameters);
      } else {
        await this.degreeService.addDegree(apiParameters);
      }
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
      this.performSuccessSideEffects();
      this.globalAddTrackingService.trackGlobalAddSaved(
        'Degree',
        this.viewModel.skills,
        this.viewModel.mediumConfidenceInferredSkills,
        this.viewModel.highConfidenceInferredSkills
      );
    } catch (error) {
      this.performFailureSideEffects();
      throw new Error('Error in DegreeFacade', error);
    }
    return;
  }

  protected updateViewWithFormData(formData: DegreeModel) {
    this.viewModel = {
      ...this.viewModel,
      ...formData,
    };
  }

  protected performSuccessSideEffects() {
    const apiParameters = this.degreeMapperService.toApiParameters(
      this.viewModel
    );

    if (!this.viewModel.isEditing) {
      this.outcomeNotificationService.notifyOutcomeCreated(
        this.viewModel.title
      );
      this.degreeTrackerService.trackDegreeAdded(apiParameters);
    } else {
      this.outcomeNotificationService.notifyOutcomeUpdated(
        this.viewModel.title
      );
      this.degreeTrackerService.trackDegreeUpdated(apiParameters);
    }

    this.userOutcomeService.notifyOutcomeModified('degree');
  }

  protected performFailureSideEffects() {
    this.outcomeNotificationService.notifyOutcomeCreateFailed();
  }

  public initializeViewModel(): void {
    super.initializeViewModel();

    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
    ]).pipe(map(([s]) => s === SubmissionStatus.Submitting));

    this.getDegreeLevels();

    this.viewModel = {
      ...this.viewModel,
      country: '',
      degree: null,
      endDate: null,
      gpa: null,
      imageUrl: '',
      isInternational: false,
      college: null,
      skills: [],
      title: '',
      userOutcomeId: null,
      collegeSearch: this.collegeSearch.bind(this),
      countrySearch: this.countrySearch.bind(this),
      loadInferredSkills: this.loadInferredSkills.bind(this),
      getDegreeLevels: this.getDegreeLevels.bind(this),
      uploadAdapter: new DegreeUploadAdapter(this.uploaderService),
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },
      inferredSkills$: this.inferredSkills$,
      shouldSpinSubmitButton$: shouldSpinSubmitButton$,
    };
  }

  public async initializeEdit(userOutcomeId: number): Promise<void> {
    // Update viewModel
    const request$ = this.userOutcomeService.getUserOutcome(
      this.authService.authUser.viewerProfile.userProfileKey,
      userOutcomeId
    );

    const degree = await lastValueFrom(request$);

    this.loadInferredSkills(degree.title);
    // Map response to view model
    const updatedView = this.degreeMapperService.toViewModel(
      degree as DegreeMappingToAPI
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isEditing: true,
    };
    return;
  }

  public loadInferredSkills(title: string): void {
    this.inferredSkillsTrigger$.next({ title });
  }

  public getDegreeLevels() {
    this.userOutcomeDegreeService
      .getDegreeLevels()
      .pipe(take(1))
      .subscribe((levels) => {
        this.viewModel.degreeLevels = levels;
      });
  }

  public collegeSearch(term: string) {
    const country = this.viewModel.isInternational
      ? this.viewModel.country
      : undefined;
    return this.courseService.getInstitutions(term, true, country);
  }

  public countrySearch(term: string) {
    return this.courseService.getCountries(term);
  }

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {
    this.onFormControlUpdate(formGroup, fieldName, response);

    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      imageUrl: response,
    };
    this.submissionStatus$.next(SubmissionStatus.None);
  }

  protected onContentFileChange(file: File) {
    this.viewModel = {
      ...this.viewModel,
      file,
    };
    this.viewModel.isSubmitButtonDisabled = true;
    this.submissionStatus$.next(SubmissionStatus.Pending);
  }

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
    this.submissionStatus$.next(SubmissionStatus.None);
  }
}
