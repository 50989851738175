import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import { MentionUserMatch } from '@app/comments/models/comment-api.model';
import { MentionService } from '@app/comments/services/mention.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {
  CommentFeedItemModel,
  CommentThreadContext,
  Resource,
} from '../../comments.model';
import { CommentFieldComponent } from '../comment-field/comment-field.component';
import { CommentModel } from '@app/inputs/inputs-api.model';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';

export interface CommentsResp {
  comment: string;
  mentions: MentionUserMatch[];
}
@Component({
  selector: 'dgx-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnChanges {
  @Input() public comment: CommentModel;
  @Input() public resource: Resource;
  @Input() public menuConfig: MenuViewModel[];
  @Input() public isPreview?: boolean;
  @Input() public referenceUserKey?: number;
  @Input() public parentReferenceId?: number;
  @Input() public groupId?: number;
  @Input() public groupPrivacy?: GroupPrivacyLevel;
  @Input() public context?: CommentThreadContext;
  @Output() public commentChange = new EventEmitter<CommentModel>();

  /**
   * The component used to edit the comment OR post a reply. There should only ever be one of those
   * components in the DOM at any one time.
   */
  @ViewChild('commentDiv')
  public commentDiv: ElementRef<HTMLDivElement>;
  @ViewChild(CommentFieldComponent)
  public commentField: CommentFieldComponent;
  public i18n = this.translateService.instant([
    'Core_SaveChanges',
    'Core_Cancel',
    'CommentsCtrl_Unlike',
    'CommentsCtrl_Like',
    'Core_Edit',
    'Core_Reply',
    'Pathways_Edited',
  ]);

  public isViewerComment: boolean;
  public isEditing = false;
  public isReplying = false;
  public isSubmitting = false;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private commentApiService: CommentsApiService,
    private translateService: TranslateService,
    private mentionService: MentionService,
    private windowLayoutService: WindowLayoutService
  ) {}

  get isBrowserExtension() {
    return this.windowLayoutService.isIframe;
  }

  public ngOnChanges({ comment }: SimpleChanges) {
    if (comment) {
      this.isViewerComment =
        this.comment.userProfileKey ===
        this.authService.authUser?.viewerProfile?.userProfileKey;
      this.getComment(comment);
    }
  }

  public getComment(comment) {
    if (comment?.currentValue !== undefined) {
      const _comment: CommentsResp = comment.currentValue;
      const displayFormat = this.mentionService.createMentionsFormat(
        _comment.comment,
        _comment.mentions
      );
      this.cdr.detectChanges();
      this.commentDiv.nativeElement.innerHTML = displayFormat;
      this.comment = {
        ...this.comment,
        comment: displayFormat,
      };
    }
  }

  public cancelEdit() {
    this.isEditing = false;
  }

  public editComment() {
    this.isEditing = true;
    this.isReplying = false;
  }

  public submitReply() {
    this.isSubmitting = true;
    this.commentApiService
      .addComment(
        this.resource,
        this.commentField.value,
        this.comment as CommentFeedItemModel,
        this.parentReferenceId,
        this.referenceUserKey,
        this.comment.mentions
      )
      .subscribe({
        next: (reply) => {
          (this.comment as CommentFeedItemModel).replies.push({
            parentId: this.comment.id,
            ...reply,
          });
          this.isReplying = false;
        },
        complete: () => {
          this.isSubmitting = false;
          this.cdr.markForCheck();
        },
      });
  }

  public toggleLike() {
    this.commentApiService
      .likeComment(this.resource, this.comment, this.parentReferenceId)
      .subscribe(() => {
        this.commentChange.emit(this.comment);
        this.cdr.markForCheck();
      });
  }

  public toggleReply() {
    this.isReplying = !this.isReplying;
    this.isEditing = false;
  }

  public updateComment() {
    const newComment = this.commentField.value;
    if (newComment !== undefined && newComment.length !== 0) {
      const commentWithMentionKeys =
        this.mentionService.getCommentWithMentionKeys(newComment);
      // eagerly show the new comments
      this.commentDiv.nativeElement.innerHTML = newComment;
      this.comment.comment = newComment;
      this.isEditing = false;
      this.commentApiService
        .editComment(
          this.comment.id,
          commentWithMentionKeys,
          this.resource.resourceType,
          this.resource.resourceId,
          this.parentReferenceId,
          this.comment.mentions
        )
        .subscribe(() => {
          this.comment.edited = true;
          this.comment.dateAdded =
            this.translateService.instant('dgComment_JustNow');
          this.commentChange.emit(this.comment);
          this.cdr.markForCheck();
        });
    }
  }
}
