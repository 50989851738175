<ng-container>
  <form
    [formGroup]="groupForm"
    (ngSubmit)="save()"
    class="form-wrap"
    novalidate
  >
    <div class="grid" [ngClass]="{ 'dg-modal__body': !editing }">
      <div class="grid__col-12">
        <div class="guts-m-b-1-half">
          <label for="Name" class="form-label"
            ><span>{{ i18n.GroupSettingsCtrl_GroupName }}</span
            ><df-label-marker></df-label-marker>
          </label>
          <!-- REGEX `pattern` used in the following inputs is: .*\S.*
            This ensures the input contains characters other than whitespace
          -->

          <!-- Auto-focus only on new (modal) -->
          <input
            *ngIf="!editing"
            dgxAutofocus
            id="Name"
            formControlName="name"
            type="text"
            pattern=".*\S.*"
            data-dgat="groupSettings-76e"
          />
          <!-- No auto-focus for editing mode -->
          <input
            *ngIf="editing"
            id="Name"
            formControlName="name"
            type="text"
            pattern=".*\S.*"
            data-dgat="groupSettings-76e"
          />
          <dgx-validate-field
            [message]="
              nameMessage ||
              (groupForm.get('name').touched &&
                groupForm.get('name').errors &&
                i18n.GroupSettingsCtrl_SpecifyName)
            "
          ></dgx-validate-field>
        </div>
        <div class="guts-m-b-1-half">
          <label for="Description" class="form-label"
            ><span>{{ i18n.GroupSettingsCtrl_Description }}</span
            ><df-label-marker></df-label-marker>
          </label>
          <input
            id="Description"
            formControlName="description"
            type="text"
            pattern=".*\S.*"
            data-dgat="groupSettings-3e8"
          />
          <dgx-validate-field
            [message]="
              groupForm.get('description').touched &&
              groupForm.get('description').errors &&
              i18n.Core_FieldRequired
            "
          ></dgx-validate-field>
        </div>
        <div *ngIf="showPrivacyOptions" class="guts-m-b-1-half">
          <div class="item">
            <label class="form-label"
              ><span>{{ i18n.GroupSettingsCtrl_PrivacySettings }}</span
              ><df-label-marker></df-label-marker
            ></label>
            <div class="guts-m-v-half">
              <div
                *ngFor="let privacyOption of privacyOptions"
                class="ib"
                data-dgat="group-settings-102"
              >
                <div
                  class="ib guts-m-r-1"
                  *ngIf="privacyOption.enabled"
                  data-dgat="groupSettings-e05"
                >
                  <label for="{{ privacyOption.name }}Option">
                    <input
                      id="{{ privacyOption.name }}Option"
                      type="radio"
                      name="privacyLevel"
                      formControlName="privacyLevel"
                      data-dgat="groupSettings-af3"
                      [value]="privacyOption.level"
                    />
                    {{ privacyOption.name }}
                  </label>
                </div>
              </div>
            </div>
            <p class="micro" data-dgat="groupSettings-d6a">
              {{ radioButtonTip }}
            </p>
          </div>
        </div>
        <div *ngIf="groupForm.get('privacyLevel').value !== 3">
          <dgx-tags-edit
            [tags]="getEditTags()"
            (tagsChange)="handleTagsChange($event)"
            customTagToolTip="{{ i18n.GroupSettingsCtrl_CustomTooltip }}"
          ></dgx-tags-edit>
        </div>
        <div
          class="guts-m-t-1-half"
          *ngIf="
            groupForm.get('privacyLevel').value === 3 && canCreateDynamicGroups
          "
        >
          <ng-container *ngIf="!isEditing; else dynamicGroupHeading">
            <div class="grid grid--bleed">
              <div class="grid__col-10">
                <label for="dynamicToggle" id="dynamicToggleLabel">
                  {{ i18n.GroupSettingsCtrl_DynamicMembership }}
                </label>
                <p class="par par--small par--light">
                  {{ i18n.GroupSettingsCtrl_DynamicMembership_Info }}
                </p>
              </div>
              <div class="right-text grid__col-2">
                <dgx-toggle-switch
                  id="dynamicToggle"
                  [ariaLabelledBy]="'dynamicToggleLabel'"
                  [isChecked]="
                    groupForm.get('membershipType').value === 'Dynamic'
                  "
                  (toggled)="setDynamicGroupOption($event)"
                ></dgx-toggle-switch>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="groupForm.get('membershipType')?.value === 'Dynamic'"
          [ngClass]="isEditing ? 'guts-m-t-2' : 'guts-m-t-3'"
        >
          <p
            class="par guts-p-b-1"
            [ngClass]="{ 'par--small font-semibold': !isEditing }"
          >
            {{ i18n.GroupSettingsCtrl_DynamicMembership_Instructions }}
          </p>
          <dgx-dynamic-group-definition
            [orgId]="newGroupOrg ?? group.organizationId"
            [predicates]="predicates"
          >
          </dgx-dynamic-group-definition>
        </div>
      </div>
    </div>

    <div class="grid" *ngIf="getDisplayAdvancedSettings()">
      <div
        class="grid__col-12 border-top"
        [ngClass]="{ 'guts-m-h-1': editing }"
      >
        <h3 class="h3 role-tile__title">
          {{ i18n.Core_AdvancedSettings }}
        </h3>
        <div class="guts-m-t-1-half">
          <label for="EnableOpportunities" class="form__label">
            <input
              id="EnableOpportunities"
              [checked]="groupForm.get('enableOpportunities').value"
              type="checkbox"
              name="enableOpportunities"
              formControlName="enableOpportunities"
              data-dgat="group-settings-483"
            />
            <span class="par par--small guts-m-l-half">{{
              i18n.GroupSettingsCtrl_EnableOpportunities
            }}</span></label
          >
        </div>
      </div>
    </div>

    <dgx-modal-footer
      *ngIf="!editing; else standardFooter"
      [canCancel]="true"
      [useDefaultSubmitButton]="false"
      (dismiss)="close()"
    >
      <ng-template *ngTemplateOutlet="saveButtons"></ng-template>
    </dgx-modal-footer>

    <ng-template #standardFooter>
      <div class="grid guts-p-v-0">
        <div class="grid__col-12">
          <div class="grid__cell right-text">
            <ng-template *ngTemplateOutlet="saveButtons"></ng-template>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #saveButtons>
      <button
        type="submit"
        df-button
        dfButtonType="primary"
        [disabled]="!groupForm.valid || saving"
        [class.is_disabled]="!groupForm.valid || saving"
        [attr.aria-disabled]="!groupForm.valid || saving"
        [attr.aria-label]="saving ? i18n.Core_Loading : i18n.Core_Save"
        [ngClass]="{ is_disabled: !groupForm.valid }"
        data-dgat="groupSettings-488"
      >
        <df-spinner-button [isSpinning]="saving">
          {{ i18n.Core_Save }}
        </df-spinner-button>
      </button>
    </ng-template>
  </form>
</ng-container>

<ng-container *ngIf="isEditing">
  <!-- Permissions -->
  <section
    *ngIf="canEditPermissions"
    class="grid__col-12 guts-p-v-0 guts-m-b-6"
  >
    <dgx-group-security
      [groupId]="group.groupId"
      [isAdministrativeGroup]="isAdministrativeGroup"
      [availablePermissions]="availablePermissions"
    ></dgx-group-security>
  </section>

  <!-- Delete group -->
  <section *ngIf="canDeleteGroup" class="guts-m-b-6">
    <div class="grid">
      <div class="grid__col-12">
        <h1 class="h3">{{ i18n.dgGroupDelete_DeleteGroupTitle }}</h1>
      </div>
    </div>

    <dgx-group-delete
      [groupId]="group.groupId"
      [orgId]="group.organizationId"
    ></dgx-group-delete>
  </section>
</ng-container>

<ng-template #dynamicGroupHeading>
  <p class="h2">{{ i18n.GroupSettingsCtrl_DynamicMembership }}</p>
</ng-template>
