import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LDFlagsService } from '@dg/shared-services';
import { useApolloLayout } from '@degreed/apollo-angular';

import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { OrgBaseGuard } from './org-base.guard';

@Injectable({
  providedIn: 'root',
})
export class SettingsHomeGuard extends OrgBaseGuard {
  constructor(
    router: Router,
    authService: AuthService,
    @Inject(WindowToken) windowRef: Window,
    private ldFlagsService: LDFlagsService
  ) {
    super(authService, windowRef, router);
  }

  override get hasPermission() {
    const layoutRefresh = useApolloLayout();
    const personalizedLearning = this.ldFlagsService.personalizedLearning;

    return (
      (personalizedLearning &&
        layoutRefresh &&
        this.orgInfo?.permissions?.editSettings) ??
      false
    );
  }
}
