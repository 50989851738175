import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';

// guards
import { BulkUploadGuard } from './bulk-upload.guard';
import { CatalogGuard } from './catalog.guard';
import { GroupsGuard } from './groups.guard';
import { InsightsGuard } from './insights.guard';
import { OrgBaseGuard } from './org-base.guard';
import { PathwaysGuard } from './pathways.guard';
import { PeopleRedirectGuard } from './people-redirect.guard';
import { PlansGuard } from './plans.guard';
import { SkillDevGuard } from './skill-dev.guard';
import { SkillsRedirectGuard } from './skills-redirect.guard';
import { ReportingAuthorizationService } from '@app/reporting/services/reporting-authorization.service';

@Injectable({
  providedIn: 'root',
})
export class OrgRedirectGuard extends OrgBaseGuard {
  constructor(
    authService: AuthService,
    @Inject(WindowToken) windowRef: Window,
    private bulkUploadGuard: BulkUploadGuard,
    private catalogGuard: CatalogGuard,
    private groupsGuard: GroupsGuard,
    private insightsGuard: InsightsGuard,
    private peopleRedirectGuard: PeopleRedirectGuard,
    private pathwaysGuard: PathwaysGuard,
    private plansGuard: PlansGuard,
    private reportingAuthorizationService: ReportingAuthorizationService,
    private skillDevGuard: SkillDevGuard,
    private skillsRedirectGuard: SkillsRedirectGuard,
    protected router: Router
  ) {
    super(authService, windowRef, router);
  }
  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.insightsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/insights/learning'));
    }
    if (this.groupsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/groups'));
    }
    if (this.peopleRedirectGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/members'));
    }
    if (this.plansGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/plans'));
    }
    if (this.skillDevGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/skill-dev'));
    }
    if (this.skillsRedirectGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/skills'));
    }
    if (this.pathwaysGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/pathways'));
    }
    if (this.catalogGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/content'));
    }
    if (this.bulkUploadGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/fileupload'));
    }
    if (
      this.reportingAuthorizationService.canViewReporting ||
      this.reportingAuthorizationService.canScheduleFTPReports
    ) {
      return this.router.parseUrl(this.resolveOrgUrl('/reporting'));
    }
    return this.router.parseUrl('/');
  }
}
