<ng-container *ngIf="input$ | async as input">
  <div class="preview-container rel __left-column border-bottom">
    <dgx-content-switcher
      [input]="input"
      [inputType]="inputType"
      [inputId]="inputId"
      *ngIf="canLoadAzureHostedFile"
    ></dgx-content-switcher>
  </div>
  <div class="rel __right-column border-left">
    <!-- Apply empty cardStyle property to remove unneeded top border -->
    <dgx-learning-resource-card
      [resource]="input"
      [isLoading]="false"
      [shouldTrackItemClicked]="false"
      cardStyle="box"
      [hideCompleteButton]="false"
    ></dgx-learning-resource-card>
    <dgx-comment-thread
      class="input-detail-modal__comments"
      [resource]="input"
    ></dgx-comment-thread>
    <dgx-footer-glasses></dgx-footer-glasses>
  </div>
</ng-container>
