import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Observable, delay, mergeMap, take, timer } from 'rxjs';

// misc
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { LocalityViewModel } from '@app/shared/components/address-suggest/address-suggest.service';
import { DgError } from '@app/shared/models/dg-error';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { MediaMetadataStatus } from '@app/user-content/user-input/media-modal/media-modal-facade-base';
import { readFirst } from '@dg/shared-rxjs';
import {
  CourseApiInputEdit,
  CourseImportProgress,
  CourseModel,
} from '../../course.model';

// services
import {
  ScormService,
  ScormXMLResultStatusType,
} from '@app/content-catalog/services/scorm.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { onFormControlUpdate } from '@app/shared/utils/angular-form-field-helpers';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { CourseService } from '@app/user-content/user-input-v2/inputs/course/services/course.service';
import { CourseUploadAdapter } from '@app/user-content/user-input/course-form/services/course-upload.adapter';
import { AuthService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { CourseBaseFacade } from '../course-base.facade.service';
import { CourseMapper } from '../course-mapper.service';
import { CourseNotificationService } from '../course-notification.service';
import { CourseTrackerService } from '../course-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class CourseCatalogFacade extends CourseBaseFacade {
  constructor(
    protected authService: AuthService,
    protected inputsService: InputsService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService,
    protected courseService: CourseService,
    protected courseMapper: CourseMapper,
    protected courseNotificationService: CourseNotificationService,
    private courseTrackerService: CourseTrackerService,
    private translate: TranslateService,
    private uploadAdapter: CourseUploadAdapter,
    private scormService: ScormService,
    datehandler: NgbDateParserFormatter
  ) {
    super(
      inputsService,
      inputImageUploadAdapterService,
      courseService,
      courseMapper,
      courseNotificationService,
      authService,
      datehandler
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): CourseModel {
    return readFirst(this.viewModel$);
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public initializeViewModel(inputContext: InputContext): void {
    super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: true, // always true since we are in catalog
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: false,
      },
      shouldDisableContentUploader: false,
      shouldShowContentUploader:
        this.authService.authUser.defaultOrgInfo.hasScorm,
    };

    if (this.viewModel.shouldShowContentUploader) {
      const updateCourse = inputContext.isEditing;

      this.setScormCourseImportUrl(updateCourse);
      this.scormService.cachedScormInfo = this.viewModel.scormInfo;

      this.viewModel = {
        ...this.viewModel,
        contentUpload: {
          // Content uploader events
          onContentFileChange: this.onContentFileChange.bind(this),
          onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
          onContentDelete: this.onContentDelete.bind(this),
          onContentUploadFailure: this.onContentUploadFailure.bind(this),
        },
        uploadAdapter: this.uploadAdapter,
        errorMessages: {
          invalidFileType: this.translate.instant(
            'CourseFormCtrl_ValidScormRequired'
          ),
          scormUploadErrorMessage: `${this.translate.instant(
            'Core_Upload'
          )} ${this.translate.instant('Core_Error')}`,
        },
      };
    }
  }

  public async initializeEdit(): Promise<void> {
    // Update viewModel
    const editEntry: CourseApiInputEdit = (await this.courseService.getCourse(
      this.viewModel.inputContext.inputId
    )) as CourseApiInputEdit;

    // Map response to view model
    const updatedView = this.mapperService.toViewModel(
      editEntry,
      this.viewModel
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      addToCatalog: true,
      isInitialForm: false,
      organizationId: this.orgId,
      isSessionDetailsToggledOn: !!updatedView.sessionDetails,
    };

    if (this.viewModel.shouldShowContentUploader) {
      this.scormService.cachedScormInfo.scormCourseId =
        this.viewModel.scormInfo?.scormCourseId;
    }
  }

  public async onNext(url: string): Promise<void> {
    this.submissionStatus$.next(SubmissionStatus.Submitting);
    try {
      if (!!url) {
        // 1. update the view model with the  url
        this.viewModel = { ...this.viewModel, courseUrl: url };
        // 2. Check and fetch any duplicates for the url
        // Note: We are going to continue on to the full form page even with a duplicate
        // https://degreedjira.atlassian.net/browse/PD-92403
        await this.fetchUrlDuplicates(url);
      }
      this.viewModel = {
        ...this.viewModel,
        isInitialForm: false,
        organizationId: this.orgId,
        owner: undefined,
      };
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
    } catch (e) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new DgError(this.translate.instant('Core_GeneralErrorMessage'), e);
    }
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      if (form.value.entryUrl) {
        await this.fetchUrlDuplicates(form.value.entryUrl);
      }
      await super.onSubmit(form);
      this.performSuccessSideEffects();
    } catch (e) {
      throw e;
    }
  }

  // TODO: Move to utils if possible when working on Event Sessions
  public onAddressChange(addressDetails: LocalityViewModel): void {
    const currentSession = { ...this.viewModel.sessionDetails };
    const updatedSession = {
      ...currentSession,
      locationAddress: addressDetails.locationAddress,
      city: addressDetails.city,
      country: addressDetails.country,
      countryCode: addressDetails.countryCode,
      latitude: addressDetails.latitude,
      longitude: addressDetails.longitude,
    };
    this.viewModel = {
      ...this.viewModel,
      sessions: [updatedSession],
      sessionDetails: updatedSession,
    };
  }

  public onToggleOfSessionDetails(isSessionDetailsToggledOn: boolean): void {
    this.viewModel = {
      ...this.viewModel,
      isSessionDetailsToggledOn,
    };
  }

  public updateDuration(durationHours: number, durationMinutes: number): void {
    this.viewModel = {
      ...this.viewModel,
      durationHours,
      durationMinutes,
    };
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.courseNotificationService.notifyCourseInputCreated(
        this.viewModel.name
      );

      return this.courseTrackerService.trackContentCatalogAdd(this.viewModel);
    }

    const apiParameters = this.mapperService.toApiParameters(this.viewModel);
    this.courseNotificationService.notifyCourseInputUpdated();
    this.courseTrackerService.trackContentCatalogUpdate(apiParameters);
  }

  // *******************************************************
  // SCORM Methods
  // *******************************************************

  public onContentDelete(formGroup: FormGroup) {
    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      hostedContentDetails: null,
      scormInfo: undefined,
    };
    this.setScormCourseImportUrl(false);
    this.scormService.cachedScormInfo = this.viewModel.scormInfo;

    formGroup.patchValue({
      contentUploader: '',
    });
  }

  /**
   * On upload success handle the scorm upload the the scorm service
   * @param formGroup
   * @param fieldName
   * @param response
   * @returns
   */
  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: string
  ) {
    let responseData;
    this.viewModel.shouldDisableContentUploader = true;
    try {
      responseData = JSON.parse(response);
    } catch (error) {
      this.viewModel.scormInfo.scormHasError = true;
      this.handleScormXmlResult(response);
      this.viewModel.shouldDisableContentUploader = false;
      return;
    }

    this.viewModel.scormInfo.scormHasError = false;
    this.handleScormJsonResult(formGroup, fieldName, responseData);
    onFormControlUpdate(formGroup, fieldName, responseData);

    return;
  }

  /**
   * If processing to Json fails then handle the parse error
   *
   * @param xmlResult
   */
  private handleScormXmlResult(xmlResult: string) {
    const processingType = this.scormService.getXMLResponseType(xmlResult);

    switch (processingType) {
      case ScormXMLResultStatusType.UploadResult:
        this.handleScormUploadResult(xmlResult);
        break;
      case ScormXMLResultStatusType.Processing:
        this.handleScormProcessingResult(xmlResult);
        break;
      default:
        // This seems to never be called as the default case i think it was extra precautionary
        console.warn('unhandled scorm result', xmlResult);
        this.viewModel.scormInfo.scormHasError = true;
        break;
    }
  }

  /**
   * If the json parse errors and the xml request is still processing
   * check to see if the processing has failed or try to continue processing
   * @param xmlResult
   * @returns
   */
  private handleScormProcessingResult(xmlResult: string) {
    const status: string =
      this.scormService.getStatusFromXmlResponse(xmlResult);

    // failed - need to go back to the file uploader with a validation
    if (status === ScormXMLResultStatusType.Fail.toString()) {
      this.courseTrackerService.trackScormFail(xmlResult);

      this.viewModel.scormInfo.scormCourseProcessingUrl = null;
      this.viewModel.scormInfo.scormHasError = true;
      return;
    }

    const progress: number =
      this.scormService.getProgressFromXmlResponse(xmlResult);

    // processing is still completing - poll again
    if (progress < 100) {
      this.getScormImportStatus()
        .pipe(delay(1000))
        .subscribe((xmlResult: string) => {
          this.handleScormXmlResult(xmlResult);
        });
      return;
    }

    this.courseTrackerService.trackScormUploadCompleted(xmlResult);

    // processing completed - set title which will be patched in the component and disable the next button
    this.viewModel.name = this.scormService.getTitleFromXmlResponse(xmlResult);
    this.viewModel.isSubmitButtonDisabled = false;
  }

  private handleScormUploadResult(xmlResult: string) {
    this.viewModel.scormInfo.scormToken =
      this.scormService.getTokenFromXmlResponse(xmlResult);
    this.getScormProcessingUrl()
      .pipe(
        mergeMap((url: string) => {
          this.viewModel.scormInfo.scormCourseProcessingUrl = url;
          return this.getScormImportStatus();
        })
      )
      .subscribe((xmlResult: string) => {
        this.handleScormXmlResult(xmlResult);
      });
  }

  private getScormProcessingUrl(): Observable<string> {
    return this.scormService.getScormCourseImportResultUrl(
      this.viewModel.scormInfo.scormToken,
      this.orgId
    );
  }

  /**
   * Handle the successful json parse of the scorm upload
   * @param formGroup
   * @param fieldName
   * @param contentUploadResponse
   */
  private handleScormJsonResult(formGroup, fieldName, contentUploadResponse) {
    this.getScormImportStatus(contentUploadResponse.courseImportId)
      .pipe(take(1))
      .subscribe((courseImportProgress: string) => {
        this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);
        const parsedCourseImportProgress: CourseImportProgress =
          JSON.parse(courseImportProgress);
        // When error occurs in scorm upload update with console warning message
        if (parsedCourseImportProgress.status === 'ERROR') {
          console.warn(
            'unhandled scorm result',
            parsedCourseImportProgress.message
          );
          this.viewModel.scormInfo.scormHasError = true;
          this.viewModel.shouldDisableContentUploader = false;
          this.mediaMetadataStatus$.next(MediaMetadataStatus.None);
        } else if (parsedCourseImportProgress.status !== 'COMPLETE') {
          const subscription = timer(1000)
            .pipe(
              take(1) // Emit only one value then complete
            )
            .subscribe(() => {
              this.handleScormJsonResult(
                formGroup,
                fieldName,
                contentUploadResponse
              );
              subscription.unsubscribe(); // Unsubscribe to prevent memory leaks
            });
        } else {
          this.viewModel.name =
            parsedCourseImportProgress.importResult.course.title;
          super.onContentUploadSuccess(
            formGroup,
            fieldName,
            contentUploadResponse
          );
          this.viewModel.scormInfo = this.scormService.cachedScormInfo;
          this.viewModel.isSubmitButtonDisabled = false;
          this.viewModel.shouldDisableContentUploader = false;
          this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
        }
        return;
      });
  }

  private getScormImportStatus(courseInputId?: string): Observable<string> {
    const url = courseInputId
      ? null
      : this.viewModel.scormInfo.scormCourseProcessingUrl;
    return this.scormService.getScormImportStatus(url, courseInputId);
  }

  /**
   * Set the scorm info url for the course
   * @param updateCourse if the course is being edited
   * @returns
   */
  private setScormCourseImportUrl(updateCourse: boolean): void {
    // initialize or re-initialize if stale, otherwise return the url
    if (
      !this.viewModel.scormInfo ||
      this.scormService.isStale(this.viewModel.scormInfo)
    ) {
      this.viewModel.scormInfo = this.scormService.initialize(
        updateCourse,
        this.viewModel.courseUrl
      );
    } else {
      return;
    }

    if (updateCourse) {
      const url = '/scorm/updatecourse';
      this.viewModel.scormInfo.scormCourseInputUrl = url;
    } else {
      this.viewModel.scormInfo.scormCourseInputUrl =
        this.scormService.getScormCourseImportUrString();
    }
  }
}
